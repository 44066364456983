import React from "react";
import logo from '../../assets/images/tawkeel-logo.png'
function Campaign({language}) {
  window.dataLayer = window.dataLayer || [];
  function gtag(){ window.dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-GQ56C3BVQL');
  return (
    <>
        <div className="loading">
          <img src={logo} all="Tawkeel Logo" className="animate-pulse"/>
          <p>Please Wait...</p>
        </div>
    </>
  );
}

export default Campaign;
