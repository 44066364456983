import React , {useState} from 'react'
import agentsMap from "../../assets/images/tawkeelAgents.png"
import mainLogo from "../../assets/images/main-logo.png"
import _T from '../../locale/Home/translator';
import { Link } from 'react-router-dom'
import Modal from "../../components/Modal"

function TawkeelAgents({ language }) {
    const [showModal, setShowModal] = useState(false)
    const [consent, setConsent] = useState(false)
    const [next, setNext] = useState(1)
    const reset = () => {
        setShowModal(false);
        setConsent(false);
        setNext(0);
    }
    return (
        <React.Fragment>
            <section id='agents'>
                <div className='wrapper agents-wrapper'>
                    <div className="section-left">
                        <img src={mainLogo} alt="Tawkeel" />
                        <h2 className='secondary-heading ibm-plex-sans-arabic-bold'>{_T("TAWKEEL AGENTS")}</h2>
                        <p className='text-paragraph mb-4'>{_T("Tawkeel Company partners with a wide network of accredited offices and external agents across the world to explain and promote its Umrah services to Muslims around the world.")}</p>
                        <img src={mainLogo} alt="Tawkeel" />
                        <h2 className='secondary-heading ibm-plex-sans-arabic-bold'>{_T("REGISTER NOW")}</h2>
                        <p className='text-paragraph mb-4'>{_T("Please choose your registration type")}</p>
                        <Link className="btn btn-primary registerAgent" to={"/en/quick-register"}>{_T("Register as Agent Agency")}</Link>
                        <button className="btn btn-primary" onClick={() => { setShowModal(true) }}>{_T("Register as A Performer")}</button>
                    </div>
                    <div className="section-right">
                        <img src={agentsMap} alt="App Screenshot" />
                    </div>
                </div>
            </section>
            <Modal isOpen={showModal} title={next === 0 ? _T("Register Now") : _T("Register as a Performer")} onClose={() => { reset() }} >
                {
                    next === 0 ?
                        <div>
                            <p className='mt-0 mb-1 text-gray-100 fw-400'>{_T("Please choose your registration type")}</p>
                            <Link type='button' to={"/en/quick-register"} className='btn btn-primary w-100 justify-center mt-3'>{_T("Register Agent Agency")}</Link>
                            {/* <Link type='button' to={"/en/agency-register"} className='btn btn-primary w-100 justify-center mt-3'>{_T("Register as Marketing Agency")}</Link> */}
                            <button type='button' onClick={() => { setNext(1) }} className='btn btn-secondary w-100 justify-center mt-3'>{_T("Register as a Performer")}</button>
                        </div> :
                        next === 1 ?
                            <div>
                                <p className='mt-0 mb-1 text-gray-100 fw-400 text-default fs-20'>{_T("There are special terms and conditions for registration as a performer,")}</p>
                                <ul>
                                    <li className='text-gray-200 text-default fs-20'>{_T("The applicant must be 18 years old and above")}</li>
                                    <li className='text-gray-200 text-default fs-20'>{_T("The applicant must be a resident of one of these cities: Jeddah, Mecca, taif")}</li>
                                    <li className='text-gray-200 text-default fs-20'>{_T("The applicant must have a valid Saudi national identity card, or legally reside in the kingdom of Saudi Arabia and have a valid residency")}</li>
                                    <li className='text-gray-200 text-default fs-20'>{_T("The applicant must have his / her own bank account")}</li>
                                    <li className='text-gray-200 text-default fs-20'>{_T("The applicant must have previously performed Umrah or Hajj")}</li>
                                    <li className='text-gray-200 text-default fs-20'>{_T("The applicant should have knowledge in using applications on smartphones.")}</li>
                                </ul>
                                <label onClick={() => { setConsent(!consent) }} className='d-flex align-items-center text-default fs-20'>
                                    {
                                        consent ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width={20} viewBox="0 0 24 24" className={language.text === "English" ? 'mr-2' : 'ml-2'}><path d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z" fill="rgba(194,159,96,1)"></path></svg> :
                                            <svg xmlns="http://www.w3.org/2000/svg" width={20} viewBox="0 0 24 24" className={language.text === "English" ? 'mr-2' : 'ml-2'}><path d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM5 5V19H19V5H5Z"></path></svg>
                                    }
                                    {_T("I agree to the above terms and conditions")}
                                </label>
                                <button disabled={!consent} type='button' onClick={() => { setNext(2) }} className='btn btn-primary w-100 justify-center mt-3'>{_T("Continue")}</button>
                            </div> :
                            <div>
                                <p className='mt-0 mb-1 text-gray-100 fw-400'>{_T("To register as a performer, follow these steps,")}</p>
                                <ul>
                                    <li className='text-gray-200'>{_T("Log in through the attached link")} <br /> <a href='https://tawkeel.onelink.me/u5qw/eea6bb58' className='text-primary' target='_blank' rel={"noreferrer"} onClick={reset}>https://tawkeel.onelink.me/u5qw/eea6bb58</a></li>
                                    <li className='text-gray-200'>{_T("Login through the mobile number or through the social platforms present in the application")}</li>
                                    <li className='text-gray-200'>{_T("Create or enter your 6-digit PIN number")}</li>
                                    <li className='text-gray-200'>{_T("Use the following referral code (SHAH_1)")}</li>
                                </ul>
                                <a href='https://tawkeel.onelink.me/u5qw/eea6bb58' target='_blank' rel={"noreferrer"} onClick={reset} className='btn btn-primary w-100 justify-center mt-3'>{_T("Finish")}</a>
                            </div>
                }
            </Modal>
        </React.Fragment>
    )
}

export default TawkeelAgents;