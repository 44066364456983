import React from 'react'
import "../../assets/css/umrah-al-badal.css"
import { languages } from '../../common/languages'
import Hero from './hero'
import Benefits from './benefits'
import FAQ from './faq'

function Index({ language }) {

    document.getElementsByTagName("html")[0].classList.add("dark-bg");
    document.getElementsByTagName("body")[0].classList.add("dark-bg");
    window.scrollTo({ top: 0});
    let direction = languages[language].direction
    if(direction === "RTL"){
        document.getElementsByTagName("body")[0].classList.add("RTL");
    }else{
        document.getElementsByTagName("body")[0].classList.remove("RTL");
    }
    return (
        <section id='umrah-badal-page'>
            <Hero language={languages[language]} />
            <Benefits language={languages[language]} />
            <div>
                <FAQ language={languages[language]} />
            </div>
        </section>
    )
}

export default Index;