function _T(text){
    const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
    const translations = {
        "The opportunity and ability to find someone to perform Umrah on your behalf at any time.": {
            "en": "The opportunity and ability to find someone to perform Umrah on your behalf at any time.",
            "ar": "الفرصة والقدرة على إيجاد من يقوم بأداء العمرة نيابة عنك في أي وقت.",
            "ur": ""
        },
        "Tawkeel": {
            "en": "Tawkeel",
            "ar": "توكيل",
            "ur": ""
        },
        "For Performer": {
            "en": "For Performer",
            "ar": "لمؤدين العمرة",
            "ur": ""
        },
        "For Seeker": {
            "en": "For Seeker",
            "ar": "لطالب العمرة",
            "ur": ""
        },
        "Help Center": {
            "en": "Help Center",
            "ar": "مركز المساعدة",
            "ur": ""
        },
        "Performer Help Center": {
            "en": "Performer Help Center",
            "ar": "مركز المساعدة للمؤدي",
            "ur": ""
        },
        "Download PDF": {
            "en": "Download PDF",
            "ar": "تحميل",
            "ur": ""
        },
        "Register as Umrah Performer": {
            "en": "Register as Umrah Performer",
            "ar": "التسجيل كمؤدي لعمرة البدل",
            "ur": ""
        },
        "How to register as a performer": {
            "en": "How to register as a performer",
            "ar": "طرق التسجيل كمؤدي",
            "ur": ""
        },
        "Through Mobile Number": {
            "en": "Through Mobile Number",
            "ar": "التسجيل من خلال رقم الهاتف المحمول",
            "ur": ""
        },
        "Through social media platforms": {
            "en": "Through social media platforms",
            "ar": "التسجيل كمؤدي من خلال المنصات الاجتماعية بالتطبيق",
            "ur": ""
        },
        "Verification": {
            "en": "Verification",
            "ar": "تَحَقّق",
            "ur": ""
        },
        "Eligibility Quiz": {
            "en": "Eligibility Quiz",
            "ar": "اختبار الأهلية",
            "ur": ""
        },
        "Authorization": {
            "en": "Authorization",
            "ar": "تفويض",
            "ur": ""
        },
        "Main Screen": {
            "en": "Main Screen",
            "ar": "محتوى الشاشة الرئيسية",
            "ur": ""
        },
        "Home": {
            "en": "Home",
            "ar": "الصفحة الرئيسة",
            "ur": ""
        },
        "Orders": {
            "en": "Orders",
            "ar": "الطلبات",
            "ur": ""
        },
        "Performing the rituals of Umrah": {
            "en": "Performing the rituals of Umrah",
            "ar": "أداء مناسك العمرة البدل",
            "ur": ""
        },
        "How to approve the application": {
            "en": "How to approve the application",
            "ar": "طريقة الموافقة على الطلب",
            "ur": ""
        },
        "Confirmation": {
            "en": "Confirmation",
            "ar": "تأكيد الطلب",
            "ur": ""
        },
        "Performing Umrah": {
            "en": "Performing Umrah",
            "ar": "أداء العمرة",
            "ur": ""
        },
        "Steps to Perform Umrah": {
            "en": "Steps to Perform Umrah",
            "ar": "خطوات تأدية عمرة البدل",
            "ur": ""
        },
        "Intention": {
            "en": "Intention",
            "ar": "النية",
            "ur": ""
        },
        "Reaching Grand Mosque": {
            "en": "Reaching Grand Mosque",
            "ar": "الوصول إلى المسجد الحرام",
            "ur": ""
        },
        "Performing Tawaf": {
            "en": "Performing Tawaf",
            "ar": "الطواف",
            "ur": ""
        },
        "Prayer behind Maqam Ibrahim": {
            "en": "Prayer behind Maqam Ibrahim",
            "ar": "الصلاة خلف مقام إبراهيم",
            "ur": ""
        },
        "Perform Saey": {
            "en": "Perform Saey",
            "ar": "السعي",
            "ur": ""
        },
        "Shave or Cut Hair": {
            "en": "Shave or Cut Hair",
            "ar": "حلق أو قص الشعر",
            "ur": ""
        },
        "Chat": {
            "en": "Chat",
            "ar": "الدردشة",
            "ur": ""
        },
        "Settings": {
            "en": "Settings",
            "ar": "الاعدادات",
            "ur": ""
        },
        "User Details": {
            "en": "User Details",
            "ar": "تفاصيل المستخدم",
            "ur": ""
        },
        "Application Language": {
            "en": "Application Language",
            "ar": "لغة التطبيق",
            "ur": ""
        },
        "Speaking language": {
            "en": "Speaking language",
            "ar": "لغة التحدث",
            "ur": ""
        },
        "Location Service and Sharing": {
            "en": "Location Service and Sharing",
            "ar": "خدمة ومشاركة الموقع",
            "ur": ""
        },
        "Notifications": {
            "en": "Notifications",
            "ar": "الاشعارات",
            "ur": ""
        },
        "About Application": {
            "en": "About Application",
            "ar": "نبذه عن التطبيق",
            "ur": ""
        },
        "Register as Umrah Performer": {
            "en": "Register as Umrah Performer",
            "ar": "التسجيل كمؤدي لعمرة البدل",
            "ur": ""
        },
        "The Umrah performer can register as a performer of Umrah through the application using the given link below, after being selected by the seeker:": {
            "en": "The Umrah performer can register as a performer of Umrah through the application using the given link below, after being selected by the seeker:",
            "ar": "يمكن لمؤدي العمرة التسجيل كمؤدي للعمرة عبر التطبيق من خلال الرابط ادناه بعد ترشيحه عن طريق المشرفين الخاصين بتوكيل:",
            "ur": ""
        },
        "Tawkeel Application": {
            "en": "Tawkeel Application",
            "ar": "تطبيق توكيل",
            "ur": ""
        },
        "It is an application based on providing pilgrims to perform the rituals of Umrah, on behalf of the incapable and deceased Muslims, which is permitted by the true Islamic religion.": {
            "en": "It is an application based on providing pilgrims to perform the rituals of Umrah, on behalf of the incapable and deceased Muslims, which is permitted by the true Islamic religion.",
            "ar": "هو تطبيق مبنى على توفير معتمرين للقيام بأداء مناسك العمرة، بالنيابة عن المسلمين الغير قادرين والمتوفيين، والتي سمح بها الدين الإسلامي الحنيف.",
            "ur": ""
        },
        "How to register as a performer": {
            "en": "How to register as a performer",
            "ar": "طرق التسجيل كمؤدي",
            "ur": ""
        },
        "You can register as a performer of Umrah through a mobile phone number, or through one of the social platforms in the application. In order to be able to register as a performer of Umrah, please go through the following steps": {
            "en": "ou can register as a performer of Umrah through a mobile phone number, or through one of the social platforms in the application. In order to be able to register as a performer of Umrah, please go through the following steps",
            "ar": "يمكنك التسجيل كمؤدي للعمرة من خلال رقم الهاتف المحمول، أو من خلال أحد المنصات الاجتماعية الموجودة بالتطبيق. حتى تتمكن من التسجيل كمؤدي للعمرة يرجى اتباع الخطوات التالية",
            "ur": ""
        },
        "Through Mobile Number": {
            "en": "Through Mobile Number",
            "ar": "التسجيل من خلال رقم الهاتف المحمول",
            "ur": ""
        },
        "Please select the country code according to your country of residence": {
            "en": "Please select the country code according to your country of residence",
            "ar": "يرجى اختيار مفتاح الاتصال وفقاً للدولة المقيم بها",
            "ur": ""
        },
        "When you finish entering the mobile phone number, please set your 6-digit OTP": {
            "en": "When you finish entering the mobile phone number, please set your 6-digit OTP",
            "ar": "عند الانتهاء من ادخال رقم الهاتف المحمول، يرجى تعيين الرمز سري الخاص بك المكون من ٦ ارقام",
            "ur": ""
        },
        "Proceed to creating the profile to fill in the required data, such as: the referral code, which is mandatory to register as a performer. As this reference code can be obtained through the authorized agent (administrator) by us, and in case the performer does not have the referral code, he can contact customer service via": {
            "en": "Proceed to creating the profile to fill in the required data, such as: the referral code, which is mandatory to register as a performer. As this reference code can be obtained through the authorized agent (administrator) by us, and in case the performer does not have the referral code, he can contact customer service via",
            "ar": "الانتقال الى انشاء الملف الشخصي لتعبئة البيانات المطلوبة، مثل: كود الإحالة الذي يعد إلزامياً للتسجيل كمؤدي. حيث ان هذا الرمز المرجعي يمكنك الحصول عليه من خلال الوكيل المعتمد (المشرف) من قبلنا، وفي حال لم يكون لدى المؤدي كود الإحالة يمكنه التواصل مع خدمة العملاء عبر",
            "ur": ""
        },
        "Call the international customer service number": {
            "en": "Call the international customer service number",
            "ar": "الاتصال بالرقم الموحد الخاص بخدمة العملاء",
            "ur": ""
        },
        "Or through live chat in the app or email to customer service": {
            "en": "Or through live chat in the app or email to customer service",
            "ar": "أو من خلال الدردشة الحية في التطبيق أو البريد الإلكتروني لخدمة العملاء",
            "ur": ""
        },
        "Through social media platforms": {
            "en": "Through social media platforms",
            "ar": "التسجيل كمؤدي من خلال المنصات الاجتماعية بالتطبيق",
            "ur": ""
        },
        "Please choose one of these platforms in the application (Facebook, Twitter, Google Gmail, Apple) the platform through which you want to register": {
            "en": "Please choose one of these platforms in the application (Facebook, Twitter, Google Gmail, Apple) the platform through which you want to register",
            "ar": "يرجى اختيار احد هذه المنصات الموجودة بالتطبيق (الفيسبوك، التويتر، قوقل جي ميل، ابل)المنصة التي تريد التسجيل من خلالها",
            "ur": ""
        },
        "Move to creating the profile to fill in the required data such as the referral code, which is mandatory to register as a mod. This is a reference code that you can obtain through the agent authorized by us, and in the event that the performer does not have the status code, he can contact customer service": {
            "en": "Move to creating the profile to fill in the required data such as the referral code, which is mandatory to register as a mod. This is a reference code that you can obtain through the agent authorized by us, and in the event that the performer does not have the status code, he can contact customer service",
            "ar": "الانتقال الى انشاء الملف الشخصي لتعبئة البيانات المطلوبة مثل كود الإحالة الذي يعد إلزامياً للتسجيل كمودي. هذا الرمز مرجعي يمكنك الحصول عليه من خلال الوكيل المعتمد من قبلنا، وفي حال لم يكون لدى المؤدي كود الحالة يمكنه التواصل مع خدمة العملاء",
            "ur": ""
        },
        "Verification": {
            "en": "Verification",
            "ar": "تَحَقّق",
            "ur": ""
        },
        "To successfully register as a performance item, you must go through the following verification process:": {
            "en": "To successfully register as a performance item, you must go through the following verification process:",
            "ar": "للتسجيل بنجاح كمؤدي عمرة البدل ، يجب أن تمر بعملية التحقق التالية:",
            "ur": ""
        },
        "Eligibility Quiz": {
            "en": "Eligibility Quiz",
            "ar": "اختبار الأهلية",
            "ur": ""
        },
        "Answer a set of questions to check the eligibility of the performer": {
            "en": "Answer a set of questions to check the eligibility of the performer",
            "ar": "أجب على مجموعة من الأسئلة للتأكد من أهليتك",
            "ur": ""
        },
        "Authorization": {
            "en": "Authorization",
            "ar": "تفويض",
            "ur": ""
        },
        "Enter the ID number and attach a copy of the ID card": {
            "en": "Enter the ID number and attach a copy of the ID card",
            "ar": "أدخل رقم الهوية وأرفق نسخة من البطاقة الشخصية",
            "ur": ""
        },
        "Enter your bank account information (bank address, bank name, bank number).": {
            "en": "Enter your bank account information (bank address, bank name, bank number).",
            "ar": "أدخل معلومات حسابك المصرفي (عنوان البنك، واسم البنك، ورقم البنك).",
            "ur": ""
        },
        "Main Screen": {
            "en": "Main Screen",
            "ar": "محتوى الشاشة الرئيسية",
            "ur": ""
        },
        "Home": {
            "en": "Home",
            "ar": "الصفحة الرئيسة",
            "ur": ""
        },
        "Through this page, you can know your performance in general": {
            "en": "Through this page, you can know your performance in general",
            "ar": "من خلال هذه الصفحة، يمكنك معرفة أدائك بشكل عام",
            "ur": ""
        },
        "Ratings by customers in terms of ratings": {
            "en": "Ratings by customers in terms of ratings",
            "ar": "التقييم من قبل العملاء من حيث التصنيفات",
            "ur": ""
        },
        "Number of Umrah requests completed": {
            "en": "Number of Umrah requests completed",
            "ar": "عدد الطلبات العمرة المنجزة",
            "ur": ""
        },
        "The number of Umrah applications rejected": {
            "en": "The number of Umrah applications rejected",
            "ar": "عدد الطلبات العمرة المرفوضة",
            "ur": ""
        },
        "The number of canceled Umrah requests": {
            "en": "The number of canceled Umrah requests",
            "ar": "عدد الطلبات العمرة الملغاة",
            "ur": ""
        },
        "Your current balance": {
            "en": "Your current balance",
            "ar": "رصيدك الحالي",
            "ur": ""
        },
        "Orders": {
            "en": "Orders",
            "ar": "الطلبات",
            "ur": ""
        },
        "Through this screen, you can view the status of your Umrah allowance requests, which are divided into two parts": {
            "en": "Through this screen, you can view the status of your Umrah allowance requests, which are divided into two parts",
            "ar": "يمكنك من خلال هذه الشاشة الاطلاع على حالة طلبات عمرة البدل الخاصة بك والتي تنقسم إلى قسمين",
            "ur": ""
        },
        "Current Orders:": {
            "en": "Current Orders:",
            "ar": "الطلبات الحالية:",
            "ur": ""
        },
        "By clicking on the icon, you can see the details of the active Umrahs that have been accepted, are ready to be performed and are being worked on": {
            "en": "By clicking on the icon, you can see the details of the active Umrahs that have been accepted, are ready to be performed and are being worked on",
            "ar": "من خلال النقر على الأيقونة، يمكنك رؤية تفاصيل العمرات النشطة التي تم قبولها، وهي جاهزة لأدائها وجاري العمل عليها",
            "ur": ""
        },
        "Previous Orders:": {
            "en": "Previous Orders: ",
            "ar": "الطلبات السابقة:",
            "ur": ""
        },
        "Record all Umrah requests you received and their status in the system. Completed orders and canceled orders from previous orders can be viewed": {
            "en": "Record all Umrah requests you received and their status in the system. Completed orders and canceled orders from previous orders can be viewed",
            "ar": "سجل بجميع طلبات العمرة التي تلقيتها وحالتها في النظام. يمكن عرض الطلبات المنجزة والطلبات الملغاة من الطلبات السابقة",
            "ur": ""
        },
        "Performing the rituals of Umrah": {
            "en": "Performing the rituals of Umrah",
            "ar": "أداء مناسك العمرة البدل",
            "ur": ""
        },
        "1 - How to approve the application": {
            "en": "1 - How to approve the application",
            "ar": "١ - طريقة الموافقة على الطلب",
            "ur": ""
        },
        "By clicking on the current requests icon as in the previous figure, the new requests page will appear and you can accept or reject the request": {
            "en": "By clicking on the current requests icon as in the previous figure, the new requests page will appear and you can accept or reject the request",
            "ar": "من خلال النقر على أيقونة الطلبات الحالية كما في الشكل السابق، ستظهر صفحة الطلبات الجديدة ويمكنك قبول الطلب أو رفضه",
            "ur": ""
        },
        "2 - Confirm the order": {
            "en": "2 - Confirm the order",
            "ar": "٢ - تأكيد الطلب",
            "ur": ""
        },
        "When you accept the application, this means that you need to wait for the customer's confirmation to start performing the Umrah. It is also preferable, when accepting the request, to extract the Umrah permit from the Tawakkalna or Umrah application, and then to set an appointment through the Tawkeel application according to the date on the permit.": {
            "en": "When you accept the application, this means that you need to wait for the customer's confirmation to start performing the Umrah. It is also preferable, when accepting the request, to extract the Umrah permit from the Tawakkalna or Umrah application, and then to set an appointment through the Tawkeel application according to the date on the permit.",
            "ar": "عند قبولك للتطبيق، هذا يعني أنك بحاجة إلى انتظار تأكيد العميل للبدء بأداء العمرة. كما يفضل عند قبول الطلب استخراج تصريح العمرة من تطبيق توكلنا أو اعتمرنا ثم تحديد موعد عبر تطبيق توكيل وفقاً على الموعد الموجود بالتصريح",
            "ur": ""
        },
        "3 - Performing the Umrah": {
            "en": "3 - Performing the Umrah",
            "ar": "٣ - أداء العمرة",
            "ur": ""
        },
        "Once the customer accepts the order, you can start by following these steps": {
            "en": "Once the customer accepts the order, you can start by following these steps",
            "ar": "بمجرد قبول العميل للطلب، يمكنك البدء باتباع الخطوات التالية",
            "ur": ""
        },
        "Download the picture wearing Ihram": {
            "en": "Download the picture wearing Ihram",
            "ar": "تحميل الصورة بلبس الإحرام",
            "ur": ""
        },
        "A pledge to perform Umrah": {
            "en": "A pledge to perform Umrah",
            "ar": "تعهد بأداء العمرة",
            "ur": ""
        },
        "Read the supplications according to the client's recommendation": {
            "en": "Read the supplications according to the client's recommendation",
            "ar": "قراءة الأدعية حسب توصية العميل",
            "ur": ""
        },
        "When performing the first step of the rituals of Umrah, the performer must sign the intention to perform Umrah on behalf of others": {
            "en": "When performing the first step of the rituals of Umrah, the performer must sign the intention to perform Umrah on behalf of others",
            "ar": "يجب على المؤدي عند قيامه بالخطوة الأولى من مناسك العمرة، وهي عقد نية أداء العمرة نيابة عن الغير",
            "ur": ""
        },
        "The method of performing Umrah on behalf of others through the application consists of 6 steps that can be traced from the Umrah tracking screen": {
            "en": "The method of performing Umrah on behalf of others through the application consists of 6 steps that can be traced from the Umrah tracking screen",
            "ar": "تتكون طريقة أداء العمرة عن الغير من خلال التطبيق من ٦ خطوات يمكن تتبعها من شاشة تتبع العمرة",
            "ur": ""
        },
        "The performer can correctly follow the steps of the Umrah rituals through the application": {
            "en": "The performer can correctly follow the steps of the Umrah rituals through the application",
            "ar": "يستطيع المؤدي اتباع خطوات مناسك العمرة بشكل صحيح عبر التطبيق",
            "ur": ""
        },
        "The performer of the Umrah can also get acquainted with the status of the request for Umrah from beginning to end with the possibility of communicating with the Umrah student during the performance of Umrah through the live chat in the application": {
            "en": "The performer of the Umrah can also get acquainted with the status of the request for Umrah from beginning to end with the possibility of communicating with the Umrah student during the performance of Umrah through the live chat in the application",
            "ar": "كما يمكن لمؤدي العمرة ان يتعرف على حالة الطلب العمرة من البداية الى النهاية مع امكانيه التواصل مع طالب العمرة اثناء تأدية العمرة من خلال الدردشة المباشرة الموجودة بالتطبيق",
            "ur": ""
        },
        "Steps to perform Umrah": {
            "en": "Steps to perform Umrah",
            "ar": "خطوات تأدية عمرة البدل",
            "ur": ""
        },
        "1 - Intention": {
            "en": "1 - Intention",
            "ar": "١ - النية",
            "ur": ""
        },
        "In order for the application to function properly, the performer must be present at the meeqaat, establish the intention for Umrah, and is prohibited from it. The audio recording of the intention must be clearly recorded.": {
            "en": "In order for the application to function properly, the performer must be present at the meeqaat, establish the intention for Umrah, and is prohibited from it. The audio recording of the intention must be clearly recorded.",
            "ar": "لكي يعمل التطبيق بشكل صحيح، يجب أن يكون المؤدي حاضرًا في الميقات، ويقيم نية العمرة، ويحرم منه ويجب التسجيل الصوتي للنية بتسجيل واضح",
            "ur": ""
        },
        "2 - Arrival at the Grand Mosque": {
            "en": "2 - Arrival at the Grand Mosque",
            "ar": "٢ - الوصول إلى المسجد الحرام",
            "ur": ""
        },
        "Upon reaching the Haram and making sure that the Umrah permit is valid, you must then press the “Start” button and then the “Finish” button to move to the next step.": {
            "en": "Upon reaching the Haram and making sure that the Umrah permit is valid, you must then press the “Start” button and then the “Finish” button to move to the next step.",
            "ar": 'عند الوصول إلى الحرم والتأكد من أن تصريح العمرة ساري المفعول، يجب الضغط بعد ذلك على زر "ابدأ" ثم زر "إنهاء "للانتقال الى الخطوة التالية',
            "ur": ""
        },
        "3 - Performing Tawaf": {
            "en": "3 - Performing Tawaf",
            "ar": "٣ - الطواف",
            "ur": ""
        },
        "You should expose your right shoulder and lay. The two ends of the garment on the left shoulder of the Ihram garment": {
            "en": "You should expose your right shoulder and lay. The two ends of the garment on the left shoulder of the Ihram garment",
            "ar": "يجب أن تكشف كتفك الأيمن وتضع. طرفي الثوب على الكتف الأيسر من لباس الإحرام",
            "ur": ""
        },
        "The application will ask you to allow access to your current location": {
            "en": "The application will ask you to allow access to your current location",
            "ar": "سوف يطلب منك التطبيق السماح بالوصول الى موقعك الحالي",
            "ur": ""
        },
        "You are in the tawaf. When you reach the starting point of the tawaf against the Black Stone, the tawaf runs start automatically which then leads to the midpoint and then the ending point to complete the circuit. You can see this counter and it is updated upon completion of each run. In this way, when the seventh round is completed, the tawaf is completed": {
            "en": "You are in the tawaf. When you reach the starting point of the tawaf against the Black Stone, the tawaf runs start automatically which then leads to the midpoint and then the ending point to complete the circuit. You can see this counter and it is updated upon completion of each run. In this way, when the seventh round is completed, the tawaf is completed",
            "ar": "أنت في المكان المخصص للطواف عندما تصل إلى نقطة البداية للطواف مقابل الحجر الأسود، تبدأ اشواط الطواف تلقائيًا والتي تؤدي بعد ذلك إلى نقطة المنتصف ثم نقطة النهاية لإكمال الشوط. يمكنك رؤية هذا العداد ويتم تحديثه عند الانتهاء من كل شوط. بهذه الطريقة، وعند الانتهاء من الشوط السابع، يتم الانتهاء من الطواف",
            "ur": ""
        },
        "There is also a supplication for each round of tawaf for the performer to recite": {
            "en": "There is also a supplication for each round of tawaf for the performer to recite",
            "ar": "كما يوجد دعاء لكل شوط طواف ليقرأه المؤدي",
            "ur": ""
        },
        "4 - Praying behind the shrine of Ibrahim": {
            "en": "4 - Praying behind the shrine of Ibrahim",
            "ar": "٤ - الصلاة خلف مقام إبراهيم",
            "ur": ""
        },
        "After completing the tawaf, you must pray two rak'ahs behind the shrine of Ibrahim, peace be upon him.": {
            "en": "After completing the tawaf, you must pray two rak'ahs behind the shrine of Ibrahim, peace be upon him.",
            "ar": "بعد الانتهاء من الطواف، عليك ان تصلي ركعتان خلف مقام إبراهيم عليه السلام.",
            "ur": ""
        },
        "5 - Performing Saey": {
            "en": "5 - Performing Saey",
            "ar": "٥ - السعي",
            "ur": ""
        },
        "The performer begins with Safa and ends with Marwa, and this is considered one round": {
            "en": "The performer begins with Safa and ends with Marwa, and this is considered one round",
            "ar": "يبدأ المؤدي بالصفا وينتهي بالمروة ويعتبر هذا شوطا واحداً",
            "ur": ""
        },
        "You must confirm by clicking on the start button when you reach Safa, and then pressing again on the performance counter button to move to the next round": {
            "en": "You must confirm by clicking on the start button when you reach Safa, and then pressing again on the performance counter button to move to the next round",
            "ar": "يجب التأكد بالنقر على زر البدء عند الوصول الى الصفا، ثم الضغط مرة أخرى على زر عداد الأداء للانتقال الى الشوط التالي",
            "ur": ""
        },
        "In the application there are also supplications for each half sought to be read by the performer": {
            "en": "In the application there are also supplications for each half sought to be read by the performer",
            "ar": "كما يوجد بالتطبيق ادعيه لكل شوط سعي ليقرأها المؤدي",
            "ur": ""
        },
        "6 - Shaving or cutting hair": {
            "en": "6 - Shaving or cutting hair",
            "ar": "٦ - حلق أو قص الشعر",
            "ur": ""
        },
        "After completing the Tawaf and Sa`y, you must shave or cut your hair and be sure to click on the camera icon to take a picture indicating a haircut or shortening": {
            "en": "After completing the Tawaf and Sa`y, you must shave or cut your hair and be sure to click on the camera icon to take a picture indicating a haircut or shortening",
            "ar": "بعد الانتهاء من أداء الطواف والسعي، يجب أن تحلق أو تقص شعرك وتأكد من الضغط على أيقونة الكاميرا لالتقاط صورة تشير إلى حلاقة أو تقصير الشعر",
            "ur": ""
        },
        "Chat": {
            "en": "Chat",
            "ar": "الدردشة",
            "ur": ""
        },
        "Through this screen, you can communicate with the customer to find out the types of dhikr required": {
            "en": "Through this screen, you can communicate with the customer to find out the types of dhikr required",
            "ar": "من خلال هذه الشاشة يمكنك التواصل مع العميل للتعرف على أنواع الأذكار المطلوبة",
            "ur": ""
        },
        "Or send photos of the rituals of Umrah or anything else related to Umrah.": {
            "en": "Or send photos of the rituals of Umrah or anything else related to Umrah.",
            "ar": "أو إرسال صور مناسك العمرة أو إي شيء اخر متعلق بالعمرة.",
            "ur": ""
        },
        "Settings": {
            "en": "Settings",
            "ar": "الاعدادات",
            "ur": ""
        },
        "Through this screen, you can enter, modify and update the following data": {
            "en": "Through this screen, you can enter, modify and update the following data",
            "ar": "من خلال هذه الشاشة، يمكنك الدخول وتعديل وتحديث البيانات التالية",
            "ur": ""
        },
        "1 - User Details": {
            "en": "1 - User Details",
            "ar": "١ - تفاصيل المستخدم",
            "ur": ""
        },
        "Tawkeel allows you to keep your profile information such as your full name, email address, mobile phone number, profile picture and gender": {
            "en": "Tawkeel allows you to keep your profile information such as your full name, email address, mobile phone number, profile picture and gender",
            "ar": "يتيح لك توكيل الاحتفاظ بمعلومات ملفك الشخصي مثل الاسم الكامل وعنوان البريد الإلكتروني ورقم الهاتف المحمول وصورة الملف الشخصي والجنس",
            "ur": ""
        },
        "2 - Application Language": {
            "en": "2 - Application Language",
            "ar": "٢ - لغة التطبيق",
            "ur": ""
        },
        "The application contains a wide range of languages, more than six languages ​​are available in the application.": {
            "en": "The application contains a wide range of languages, more than six languages ​​are available in the application.",
            "ar": "يحتوي التطبيق على مجموعة واسعة من اللغات، أكثر من ست لغات متاحه بالتطبيق.",
            "ur": ""
        },
        "3 - Speaking Language": {
            "en": "3 - Speaking Language",
            "ar": "٣ - لغة التحدث",
            "ur": ""
        },
        "You can modify and update the language of communication with the Umrah seeker.": {
            "en": "You can modify and update the language of communication with the Umrah seeker.",
            "ar": "يمكنك تعديل وتحديث لغة التواصل مع طالب العمرة.",
            "ur": ""
        },
        "The performer can add 4 languages ​​as these languages ​​are spoken by the performer fluently to communicate properly with the Umrah student from all over the Islamic world.": {
            "en": "The performer can add 4 languages ​​as these languages ​​are spoken by the performer fluently to communicate properly with the Umrah student from all over the Islamic world. ",
            "ar": "يمكن للمؤدي إضافة ٤ لغات حيث ان هذه اللغات يتحدثها المؤدي بطلاقه للتواصل بشكل صحيح مع الطالب للعمرة من جميع انحاء العالم الإسلامي. ",
            "ur": ""
        },
        "4 - Location Service and Sharing": {
            "en": "4 - Location Service and Sharing",
            "ar": "٤ - خدمة ومشاركة الموقع",
            "ur": ""
        },
        "Activate the ability to allow access to the current location through satellites.": {
            "en": "Activate the ability to allow access to the current location through satellites.",
            "ar": "تفعيل امكانيه السماح للوصول إلى الموقع الحالي من خلال الأقمار الاصطناعية.",
            "ur": ""
        },
        "5 - Notifications": {
            "en": "5 - Notifications",
            "ar": "٥ - الاشعارات",
            "ur": ""
        },
        "You can activate or disable the option to receive notifications, the performer can receive notifications such as (new request, end date of Umrah, new features in the system, etc.).": {
            "en": "You can activate or disable the option to receive notifications, the performer can receive notifications such as (new request, end date of Umrah, new features in the system, etc.).",
            "ar": "يمكنك تفعيل او تعطيل خيار تلقي الاشعارات، يمكن للمؤدي بتلقي اشعارات مثل (طلب جديد ، تاريخ انتهاء العمرة، ميزات جديده في النظام، ،وما الى ذلك).",
            "ur": ""
        },
        "6 - About the application": {
            "en": "6 - About the application",
            "ar": "٦ - نبذه عن التطبيق",
            "ur": ""
        },
        "You can get additional information about the app from the about section like About Tawkeel, User Manual, Technical Support, Terms and Conditions, FAQ, About Applicaion, etc.": {
            "en": "You can get additional information about the app from the about section like About Tawkeel, User Manual, Technical Support, Terms and Conditions, FAQ, About Applicaion, etc.",
            "ar": "يمكنك الحصول على معلومات إضافية حول التطبيق  مثل دليل المستخدم ، الدعم الفني ، الشروط والأحكام ، الأسئلة الشائعة ،  إلخ.",
            "ur": ""
        },
        "Seeker Help Center": {
            "en": "Seeker Help Center",
            "ar": "مركز مساعدة الباحث",
            "ur": ""
        },
        "Register as Umrah Seeker": {
            "en": "Register as Umrah Seeker",
            "ar": "سجل كباحث عمرة",
            "ur": ""
        },
        "Mobile Number": {
            "en": "Mobile Number",
            "ar": "رقم الهاتف المحمول",
            "ur": ""
        },
        "Place Orders": {
            "en": "Place Orders",
            "ar": "إنشاء طلب",
            "ur": ""
        },
        "Service Selection": {
            "en": "Service Selection",
            "ar": "اختيار الخدمة",
            "ur": ""
        },
        "Supplications Options": {
            "en": "Supplications Options",
            "ar": "خيارات الأذكار",
            "ur": ""
        },
        "Payment Process": {
            "en": "Payment Process",
            "ar": "قدم الآن",
            "ur": ""
        },
        "Requests": {
            "en": "Requests",
            "ar": "الطلبات",
            "ur": ""
        },
        "Umrah allowance Tracking": {
            "en": "Umrah allowance Tracking",
            "ar": "تتبع العمرة",
            "ur": ""
        },
        "Chatting": {
            "en": "Chatting",
            "ar": "الدردشة",
            "ur": ""
        },
        "User details": {
            "en": "User details",
            "ar": "بيانات المستخدم",
            "ur": ""
        },
        "App Language": {
            "en": "App Language",
            "ar": "لغة التطبيق",
            "ur": ""
        },
        "Payment Details": {
            "en": "Payment Details",
            "ar": "بيانات الدفع",
            "ur": ""
        },
        "Location Service": {
            "en": "Location Service",
            "ar": "خدمة الموقع",
            "ur": ""
        },
        "Discount Code": {
            "en": "Discount Code",
            "ar": "رمز الخصم",
            "ur": ""
        },
        "About the App": {
            "en": "About the App",
            "ar": "حول التطبيق",
            "ur": ""
        },
        "The seekers can register in the Tawkeel mobile app through one of the following steps:": {
            "en": "The seekers can register in the Tawkeel mobile app through one of the following steps:",
            "ar": 'يمكن للراغبين التسجيل في تطبيق "توكيل" من خلال إحدى الخطوات التالية:',
            "ur": ""
        },
        "After you enter the mobile number, you will receive a text message with a verification code so that the application can complete the registration process.": {
            "en": "After you enter the mobile number, you will receive a text message with a verification code so that the application can complete the registration process.",
            "ar": "بعد إدخال رقم الهاتف المحمول ، ستتلقى رسالة نصية تحتوي على رمز التحقق حتى يتمكن التطبيق من إكمال عملية التسجيل.",
            "ur": ""
        },
        "Choose the right platform for you and then follow the instructions. Once proceed with mobile number sign up or social media platform, lands on Profile screen to provide basic info along with Discount Code which is a reference number obtained through the authorized agents in user's country, if any.": {
            "en": "Choose the right platform for you and then follow the instructions. Once proceed with mobile number sign up or social media platform, lands on Profile screen to provide basic info along with Discount Code which is a reference number obtained through the authorized agents in user's country, if any.",
            "ar": "اختر النظام الأساسي المناسب لك ثم اتبع التعليمات. بمجرد المضي قدمًا في تسجيل رقم الهاتف المحمول أو منصة التواصل الاجتماعي ، ينتقل إلى شاشة الملف الشخصي لتوفير المعلومات الأساسية جنبًا إلى جنب مع رمز الخصم وهو رقم مرجعي تم الحصول عليه من خلال الوكلاء المعتمدين في بلد المستخدم ، إن وجد.",
            "ur": ""
        },
        "Through this screen, you can request the umrah allowance for yourself or any other person. Select the Umrah tap, select the relation from the scrolling menu, and add the person's name. After that, place the order.": {
            "en": "Through this screen, you can request the umrah allowance for yourself or any other person. Select the Umrah tap, select the relation from the scrolling menu, and add the person's name. After that, place the order.",
            "ar": "من خلال هذه الشاشة يمكنك طلب بدل العمرة لنفسك أو لأي شخص آخر. حدد صنبور العمرة ، وحدد العلاقة من قائمة التمرير ، وأضف اسم الشخص. بعد ذلك ، ضع الطلب.",
            "ur": ""
        },
        "As you can see on your home screen, umrah allowance cost would be based on your region such as if you are from Saudi Arabia then umrah cost would be shown in SAR for each order,after placing order, you will go to the supplication selection screen.": {
            "en": "As you can see on your home screen, umrah allowance cost would be based on your region such as if you are from Saudi Arabia then umrah cost would be shown in SAR for each order,after placing order, you will go to the supplication selection screen.",
            "ar": "كما ترى على شاشتك الرئيسية ، فإن تكلفة بدل العمرة تعتمد على منطقتك مثل إذا كنت من المملكة العربية السعودية ، فستظهر تكلفة العمرة بالريال السعودي لكل طلب ، وبعد تقديم الطلب ستنتقل إلى شاشة اختيار الدعاء .",
            "ur": ""
        },
        "After service selection, you are ready to fill a specific supplication or select from the ready-made options, as shown on the next screen. This step is optional.": {
            "en": "After service selection, you are ready to fill a specific supplication or select from the ready-made options, as shown on the next screen. This step is optional.",
            "ar": "بعد اختيار الخدمة ، تكون جاهزًا لملء دعاء معين أو الاختيار من بين الخيارات الجاهزة ، كما هو موضح في الشاشة التالية. هذه الخطوة اختيارية.",
            "ur": ""
        },
        "After supplication options selection,you can proceed to make a payment.You have to pay for the order via Payfort which supports multiple payment options such as VISA, Master card, Mada etc. OR Payment can also be made via E-wallet.Whereas, E-wallet has your account balance. E-wallet option would only be shown when you have an account balance.": {
            "en": "After supplication options selection,you can proceed to make a payment.You have to pay for the order via Payfort which supports multiple payment options such as VISA, Master card, Mada etc. OR Payment can also be made via E-wallet.Whereas, E-wallet has your account balance. E-wallet option would only be shown when you have an account balance.",
            "ar": "بعد تحديد خيارات الدعاء ، يمكنك المتابعة لإجراء الدفع. يجب عليك الدفع مقابل الطلب عبر Payfort الذي يدعم خيارات دفع متعددة مثل VISA و Master Card و Mada وما إلى ذلك أو يمكن أيضًا السداد عبر المحفظة الإلكترونية. المحفظة الإلكترونية لديها رصيد حسابك. لن يظهر خيار المحفظة الإلكترونية إلا عندما يكون لديك رصيد في الحساب.",
            "ur": ""
        },
        "Another way to make a payment is from card & e-wallet both by enabling the toggle to use the amount in e-wallet too in a case when balance is less than the order amount. Then, such transaction from card & wallet can be placed.": {
            "en": "Another way to make a payment is from card & e-wallet both by enabling the toggle to use the amount in e-wallet too in a case when balance is less than the order amount. Then, such transaction from card & wallet can be placed.",
            "ar": "هناك طريقة أخرى للدفع وهي من البطاقة والمحفظة الإلكترونية من خلال تمكين التبديل لاستخدام المبلغ في المحفظة الإلكترونية أيضًا في حالة عندما يكون الرصيد أقل من مبلغ الطلب. بعد ذلك ، يمكن وضع مثل هذه المعاملة من البطاقة والمحفظة.",
            "ur": ""
        },
        "After successful payment, order is placed and waiting for a performer to accept the order.": {
            "en": "After successful payment, order is placed and waiting for a performer to accept the order.",
            "ar": "بعد إتمام السداد ، يتم تقديم الطلب والانتظار حتى يقبل المؤدي الطلب.",
            "ur": ""
        },
        "Order is auto-assigned to the available performers. Once a performer accepts an order, you need to confirm the order.": {
            "en": "Order is auto-assigned to the available performers. Once a performer accepts an order, you need to confirm the order.",
            "ar": "يتم تخصيص الطلب تلقائيًا للمؤدين المتاحين. بمجرد قبول المؤدي للطلب ، تحتاج إلى تأكيد الطلب.",
            "ur": ""
        },
        "Through this screen, you can see the status of your Umrah allowance requests, which are divided into two parts:": {
            "en": "Through this screen, you can see the status of your Umrah allowance requests, which are divided into two parts:",
            "ar": "يمكنك من خلال هذه الشاشة الاطلاع على حالة طلبات بدل العمرة الخاصة بك والتي تنقسم إلى قسمين: ",
            "ur": ""
        },
        "Current Requests:": {
            "en": "Current Requests:",
            "ar": "الطلبات الحالية:",
            "ur": ""
        },
        "Previous Requests:": {
            "en": "Previous Requests:",
            "ar": "الطلبات السابقة:",
            "ur": ""
        },
        "By clicking on the icon, you can see your current Umrah performer detail and status. All the accepted, In-progress requests can be shown here.": {
            "en": "By clicking on the icon, you can see your current Umrah performer detail and status. All the accepted, In-progress requests can be shown here.",
            "ar": "من خلال النقر على الأيقونة ، يمكنك رؤية تفاصيل أداء العمرة وحالتها الحالية. يمكن عرض جميع الطلبات المقبولة و قيد الاداء هنا.",
            "ur": ""
        },
        "A record of all the orders you made and their status in the system. Cancelled, Refunded & Completed orders can be viewed from Previous Requests.": {
            "en": "A record of all the orders you made and their status in the system. Cancelled, Refunded & Completed orders can be viewed from Previous Requests.",
            "ar": "سجل لجميع الطلبات التي قمت بها وحالتها في النظام. يمكن عرض الطلبات الملغاة والمسترجعة والمكتملة من الطلبات السابقة.",
            "ur": ""
        },
        "Tawkeel App has a unique & competitive advantage that allows you to track all the rituals of Umrah (Manasik) from your mobile, step by step, and the ability to chat with the umrah performer if you need something to say to him. You can request more than one order at the same time.": {
            "en": "Tawkeel App has a unique & competitive advantage that allows you to track all the rituals of Umrah (Manasik) from your mobile, step by step, and the ability to chat with the umrah performer if you need something to say to him. You can request more than one order at the same time.",
            "ar": "يتمتع تطبيق توكيل بميزة فريدة وتنافسية تتيح لك تتبع جميع مناسك العمرة (المناسك) من جوالك خطوة بخطوة وإمكانية التواصل مع مؤدي العمرة وقت. يمكنك طلب أكثر من طلب في نفس الوقت.",
            "ur": ""
        },
        "Chatting service provides you a channel to communicate with the Umrah performer for any other things to be arranged or say in the rituals of Umrah (Manasik).": {
            "en": "Chatting service provides you a channel to communicate with the Umrah performer for any other things to be arranged or say in the rituals of Umrah (Manasik).",
            "ar": "توفر لك خدمة الدردشة قناة للتواصل مع المعتمر لأي أمور أخرى يتم ترتيبها أو قولها في مناسك العمرة (المناسك).",
            "ur": ""
        },
        "From this screen, you can enter or update the following:": {
            "en": "From this screen, you can enter or update the following:",
            "ar": "من هذه الشاشة ، يمكنك إدخال أو تحديث ما يلي: ",
            "ur": ""
        },
        "1. User details": {
            "en": "1. User details",
            "ar": "1. تفاصيل المستخدم ",
            "ur": ""
        },
        "Tawkeel App allows you to maintain the your profile information such as your full name, email address, mobile number, profile picture and gender": {
            "en": "Tawkeel App allows you to maintain the your profile information such as your full name, email address, mobile number, profile picture and gender",
            "ar": "يسمح لك تطبيق توكيل بالحفاظ على معلومات ملفك الشخصي مثل الاسم الكامل وعنوان البريد الإلكتروني ورقم الجوال وصورة الملف الشخصي والجنس ",
            "ur": ""
        },
        "2. App Language": {
            "en": "2. App Language",
            "ar": "2. لغة التطبيق",
            "ur": ""
        },
        "Tawkeel App contains a wide range of languages, more than six languages.": {
            "en": "Tawkeel App contains a wide range of languages, more than six languages.",
            "ar": "يحتوي تطبيق توكيل على مجموعة واسعة من اللغات ، أكثر من ست لغات. ",
            "ur": ""
        },
        "3. Payment Details": {
            "en": "3. Payment Details",
            "ar": "3. بيانات الدفع",
            "ur": ""
        },
        "You can view the transactions made to purchase orders along with their invoices.": {
            "en": "You can view the transactions made to purchase orders along with their invoices.",
            "ar": "يمكنك عرض المعاملات التي تم إجراؤها على أوامر الشراء مع فواتيرها. ",
            "ur": ""
        },
        "4. Notifications": {
            "en": "4. Notifications",
            "ar": "4. إشعارات",
            "ur": ""
        },
        "You can choose to enable or disable your notifications": {
            "en": "You can choose to enable or disable your notifications",
            "ar": "يمكنك اختيار تمكين أو تعطيل الإخطارات الخاصة بك ",
            "ur": ""
        },
        "5. Location Service": {
            "en": "5. Location Service",
            "ar": "5. خدمة الموقع",
            "ur": ""
        },
        "Activate the location service to identify the Umrah performer's current location for a better tracking follow-up.": {
            "en": "Activate the location service to identify the Umrah performer's current location for a better tracking follow-up.",
            "ar": "تفعيل خدمة الموقع لتحديد موقع المعتمرين الحالي لتتبع أفضل.",
            "ur": ""
        },
        "6. Discount Code": {
            "en": "6. Discount Code",
            "ar": "6. رمز الخصم",
            "ur": ""
        },
        "Since discount code can be availed one time. From setting, another discount code can be added to avail the service.": {
            "en": "Since discount code can be availed one time. From setting, another discount code can be added to avail the service.",
            "ar": "حيث يمكن الاستفادة من رمز الخصم مرة واحدة. من الإعدادات ، يمكن إضافة رمز خصم للاستفادة من الخدمة.",
            "ur": ""
        },
        "7. About the App": {
            "en": "7. About the App",
            "ar": "7. حول التطبيق ",
            "ur": ""
        },
        "You can get additional information about the application from the about section such as about Tawkeel, User Manual, Partners, FAQs etc": {
            "en": "You can get additional information about the application from the about section such as about Tawkeel, User Manual, Partners, FAQs etc",
            "ar": 'يمكنك الحصول على معلومات إضافية حول التطبيق من قسم "عن توكيل" مثل دليل المستخدم ، والشركاء ، والأسئلة الشائعة ، إلخ.',
            "ur": ""
        },
    }
    return translations[text] ? translations[text][language] : text; 
}
export default _T;