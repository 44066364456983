import React from "react";
import Navbar from "../../components/NavbarInner";
import Footer from "../../components/FooterInner";
import Hero from "./Hero";
import {languages} from '../../common/languages'
import "../../assets/css/terms-and-conditions.css";
import _T from "../../locale/TermsAndConditions/translator";
import { useEffect } from "react";
import { RP } from "../../components/FacebookPixel";
import '../../components/TikTokPixel.js';
function Index({language}) {
  window.scrollTo({ top: 0});
  let direction = languages[language].direction
  if(direction === "RTL"){
    document.getElementsByTagName("body")[0].classList.add("RTL");
  }else{
    document.getElementsByTagName("body")[0].classList.remove("RTL");
  }
  useEffect(()=>{
    RP.pageView();
  },[])
  return (
    <>
        <Navbar language={languages[language]}/>
        <Hero heading={_T("Terms and Conditions")} language={languages[language]}/>
        <Footer language={languages[language]}/>
    </>
  );
}

export default Index;
