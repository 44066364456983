import React from 'react'
import tawkeel_gold from '../../assets/images/Tawkeel_GOLD.png'
import _T from '../../locale/UmrahAlBadal/translator';

function Hero({ language }) {
    return (
        <section id='umrahBadal-hero'>
            <div className='hero-img '>
                <div className='tawkeel-gold'>
                    <img src={tawkeel_gold} className='img' alt="Tawkeel Screenshot" />
                    <h3 className='umrah-heading'>{_T("Umrah Badal")}</h3>
                </div>
            </div>
        </section>
    )
}

export default Hero;