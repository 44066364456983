import React from 'react'
import {Link} from 'react-router-dom'
import helpCenterHero from '../../assets/images/help-center-image.png'
import _T from '../../locale/QuickRegister/translator';

function section_scroll(e,id){
    e.preventDefault();
    let scrollDiv = document.getElementById(id).offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
}
function Hero(){
    return (
        <section id='helpCenterHome'>
            <div className='wrapper helpCenterHome-wrapper'>
                <div className='section-left'>
                    <h1 className='primary-heading'>{_T("Quick Register")}</h1>
                    <p className='sub-heading'>{_T("Register as a Marketing agency and provide opportunities to people to help them find someone to perform Umrah on their behalf at any time.")}</p>
                    <div className='btn-group'>
                        <a href='#' onClick={(e)=>{section_scroll(e,'quickRegister')}} className='btn btn-primary'>{_T("Register Now")}</a>
                    </div>
                </div>
                <div className='section-right'>
                    <img src={helpCenterHero} />
                </div>
            </div>
        </section>
    )
}

export default Hero;