import React from 'react'
import helpCenterHero from '../../assets/images/terms-and-conditions.png'
import _T from '../../locale/FAQ/translator';


function Hero({language}){
    
    function section_scroll(id){
        let scrollDiv = document.getElementById(id).offsetTop;
        window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
    }

    return (
        <section id='termsConditionsHome'>
            <div className='wrapper termsConditionsHome-wrapper'>
                <div className='section-left'>
                    <h1 className='primary-heading'>Agency Contract</h1>
                    <p className='text-paragraph'>We understand that you may have queries regarding our offerings, so please read the terms and conditions for use as mentioned below</p>
                    <div className='btn-group'>
                        <button className='btn btn-primary-home' onClick={()=>{section_scroll('contract')}}>{_T("Continue")}</button>
                    </div>
                </div>
                {/* <div className='section-right'>
                    <img src={helpCenterHero} alt="Agency Contract"/>
                </div> */}
            </div>
        </section>
    )
}

export default Hero;