import React from 'react'
import mainLogo from "../../assets/images/main-logo.png"
import _T from '../../locale/Agents/translator';

function section_scroll(e,id){
    e.preventDefault();
    let scrollDiv = document.getElementById(id).offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
}
function Hero(){
    return (
        <section id='helpCenterHome'>
            <div className='wrapper helpCenterHome-wrapper'>
                <div className='section-left'>
                    <img src={mainLogo} alt="Tawkeel" />
                    <h1 className='primary-heading'>{_T("Agents")}</h1>
                    <p className='text-paragraph'>{_T("Tawkeel application partners with a wide network of accredited offices and external agents across the world to showcase our Umrah services to Muslims around the world.")}</p>
                    <div className='btn-group'>
                        <a href='#' onClick={(e)=>{section_scroll(e,'agents')}} className='btn btn-primary'>{_T("Find Agent")}</a>
                    </div>
                </div>
                <div className='section-right'>
                    {/* <img src={helpCenterHero} /> */}
                </div>
            </div>
        </section>
    )
}

export default Hero;