function _T(text) {
    const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
    const translations = {
        "Terms and Conditions": {
            "en": "Terms and Conditions",
            "ar": "الأحكام والشروط",
            "ur": ""
        },
        "Tawkeel": {
            "en": "Tawkeel",
            "ar": "توكيل",
            "ur": ""
        },
        "For Seeker": {
            "en": "For Seeker",
            "ar": "لطالب العمرة",
            "ur": ""
        },
        "For Performer": {
            "en": "For Performer",
            "ar": "للمؤدين",
            "ur": ""
        },
        "Please read the terms and conditions for use as per your specified user role.": {
            "en": "Please read the terms and conditions for use as per your specified user role.",
            "ar": "يرجى قراءة الشروط والأحكام للاستخدام بحسب نوع المستخدم.",
            "ur": ""
        },
        "Performer Terms and Conditions": {
            "en": "Performer Terms and Conditions",
            "ar": "الأحكام والشروط",
            "ur": ""
        },
        "Seeker Terms and Conditions": {
            "en": "Seeker Terms and Conditions",
            "ar": "الأحكام والشروط",
            "ur": ""
        },
        "Introduction": {
            "en": "Introduction",
            "ar": "مقدمة",
            "ur": ""
        },
        "We are proud of your visit to Tawkeel application.": {
            "en": "We are proud of your visit to Tawkeel application.",
            "ar": "نحن فخورون بزيارتكم لتطبيق توكيل.",
            "ur": ""
        },
        "We provide the services of Tawkeel application as an online platform intermediate between the stores on one hand and the client on the other hand in the Kingdom of Saudi Arabia in accordance with Saudi laws, one of the projects and activities of the Tawkeel Commercial Services Company, based in the Kingdom of Saudi Arabia. We provide these terms and conditions to govern and regulate the legal relationships between Tawkeel and its users, so please read these terms in an appropriate attention before using the application.": {
            "en": "We provide the services of Tawkeel application as an online platform intermediate between the stores on one hand and the client on the other hand in the Kingdom of Saudi Arabia in accordance with Saudi laws, one of the projects and activities of the Tawkeel Commercial Services Company, based in the Kingdom of Saudi Arabia. We provide these terms and conditions to govern and regulate the legal relationships between Tawkeel and its users, so please read these terms in an appropriate attention before using the application.",
            "ar": "نقدم خدمات تطبيق توكيل كمنصة عبر الإنترنت وسيطة بين المتاجر من جهة والعميل من جهة أخرى في المملكة العربية السعودية وفق القوانين السعودية ، أحد مشاريع وأنشطة شركة التوكيل للخدمات التجارية ، مقرها في المملكة العربية السعودية. نحن نقدم هذه الشروط والأحكام لتنظيم وتنظيم العلاقات القانونية بين توصيل ومستخدميها ، لذا يرجى قراءة هذه الشروط بالاهتمام المناسب قبل استخدام التطبيق.",
            "ur": ""
        },
        "Definitions": {
            "en": "Definitions",
            "ar": "تعريفات",
            "ur": ""
        },
        "In applying the provisions of this agreement, wherever contained in it, the following words and phrases have the meaning outlined associated to each of them:": {
            "en": "In applying the provisions of this agreement, wherever contained in it, the following words and phrases have the meaning outlined associated to each of them:",
            "ar": "في تطبيق أحكام هذه الاتفاقية ، أينما وردت فيها ، يكون للكلمات والعبارات التالية المعنى المبين المرتبط بكل منها:",
            "ur": ""
        },
        '"Agreement" refers to the contract that connects the application to its users, which includes the Terms and Conditions Document and the Privacy Policy Document.': {
            "en": '"Agreement" refers to the contract that connects the application to its users, which includes the Terms and Conditions Document and the Privacy Policy Document.',
            "ar": 'تشير "الاتفاقية" إلى العقد الذي يربط التطبيق بمستخدميه ، والذي يتضمن وثيقة الشروط والأحكام ووثيقة سياسة الخصوصية.',
            "ur": ""
        },
        '"Tawkeel", "application", "application", "website", "we" or "our" refers to TAWKEEL application and the website www.tawkeel.com as well as Tawkeel Commercial Services Company in the Kingdom of Saudi Arabia, Jeddah, Address: Al-Salamah neighborhood - Quraish Street, Commercial Register No. 4030413635, Date: 30.8.1442, Phone No. 800 244 2203 (Toll free) +966 800 244 2203 (International), E-mail: support@tawkeel.com.': {
            "en": '"Tawkeel", "application", "application", "website", "we" or "our" refers to TAWKEEL application and the website www.tawkeel.com as well as Tawkeel Commercial Services Company in the Kingdom of Saudi Arabia, Jeddah, Address: Al-Salamah neighborhood - Quraish Street, Commercial Register No. 4030413635, Date: 30.8.1442, Phone No. 800 244 2203 (Toll free) +966 800 244 2203 (International), E-mail: support@tawkeel.com.',
            "ar": 'تشير كلمة "توكيل" أو "التطبيق" أو "التطبيق" أو "الموقع الإلكتروني" أو "نحن" أو "خاصتنا" إلى تطبيق توكيل والموقع الإلكتروني www.tawkeel.com وكذلك شركة توكيل للخدمات التجارية في المملكة العربية السعودية ، جدة ، العنوان: حي السلامة - شارع قريش، سجل تجاري رقم 4030413635، التاريخ: 30.8.1442، هاتف رقم  8002442203 (هاتف مجاني) 9668002442203 (دولي)، البريد الإلكتروني: support@tawkeel. كوم.',
            "ur": ""
        },
        '"Seeker" refers to every Muslim person who wishes to authorize someone else to perform the rituals of Umrah in the Kingdom of Saudi Arabia in accordance with the provisions of the Sunnah.': {
            "en": '"Seeker" refers to every Muslim person who wishes to authorize someone else to perform the rituals of Umrah in the Kingdom of Saudi Arabia in accordance with the provisions of the Sunnah.',
            "ar": 'يقصد بلفظ "موكل"كل مسلم يرغب في تفويض غيره لأداء مناسك العمرة في المملكة العربية السعودية وفق أحكام السنة النبوية.',
            "ur": ""
        },
        '"Performer of the Umrah Al badal", "Performer", "User", "You" or "Your" refers to refers to every Muslim person who uses "Tawkeel" application for the purpose of performing the Umrah Al Badal on behalf of the persons wishing to do so "seekers" with the obligation to perform the Umrah in accordance with the rituals and stuff stated in Sunnah, along with the obligation to the provisions of this agreement and regulations in force in the Kingdom of Saudi Arabia.': {
            "en": '"Performer of the Umrah Al badal", "Performer", "User", "You" or "Your" refers to refers to every Muslim person who uses "Tawkeel" application for the purpose of performing the Umrah Al Badal on behalf of the persons wishing to do so "seekers" with the obligation to perform the Umrah in accordance with the rituals and stuff stated in Sunnah, along with the obligation to the provisions of this agreement and regulations in force in the Kingdom of Saudi Arabia.',
            "ar": 'تشير عبارة "مؤدي عمرة البدل" أو "المؤدي" أو "المستخدم" أو "أنت" أو "الخاص بك" إلى كل مسلم يستخدم تطبيق "توكيل" بغرض أداء عمرة البدال نيابة عن الأشخاص. الراغبين في ذلك مع الالتزام بأداء العمرة وفقا للشعائر والأشياء المنصوص عليها في السنة النبوية ، مع الالتزام بأحكام هذه الاتفاقية والأنظمة المعمول بها في المملكة العربية السعودية.',
            "ur": ""
        },
        '"Third parties" refers to all persons and bodies that we do not have control over and are not under our supervision.': {
            "en": '"Third parties" refers to all persons and bodies that we do not have control over and are not under our supervision.',
            "ar": 'تشير "الأطراف الثالثة" إلى جميع الأشخاص والهيئات التي لا نسيطر عليها ولا تخضع لإشرافنا.',
            "ur": ""
        },
        '"Laws" refer to the laws applicable in the Kingdom of Saudi Arabia.': {
            "en": '"Laws" refer to the laws applicable in the Kingdom of Saudi Arabia.',
            "ar": 'تشير "القوانين" إلى القوانين المعمول بها في المملكة العربية السعودية.',
            "ur": ""
        },
        "Descriptions of Services": {
            "en": "Descriptions of Services",
            "ar": "أوصاف الخدمات",
            "ur": ""
        },
        "The application serves as an electronic medium to perform the Umrah Al Badal, so we allow each person who is unable to perform the Umrah to contact through the application with persons wishing to perform the Umrah on behalf of others, while enabling the one who requests the service to track the steps of the performer from his point of departure to the performance of the full rituals, taking into account the stuff stated in Sunnah that related to the performance of Umrah.": {
            "en": "The application serves as an electronic medium to perform the Umrah Al Badal, so we allow each person who is unable to perform the Umrah to contact through the application with persons wishing to perform the Umrah on behalf of others, while enabling the one who requests the service to track the steps of the performer from his point of departure to the performance of the full rituals, taking into account the stuff stated in Sunnah that related to the performance of Umrah.",
            "ar": "يعمل التطبيق كوسيط الكتروني لأداء مناسك العمرة لذلك نسمح لكل شخص غير قادر على اداء العمرة بالتواصل من خلال التطبيق مع الراغبين في اداء العمرة نيابة عن الغير مع تمكين من يطلب خدمة لتتبع خطوات المؤدي من نقطة انطلاقه إلى أداء المناسك كاملة ، مع مراعاة ما ورد في السنة من أمور تتعلق بأداء العمرة.",
            "ur": ""
        },
        "Seeker's Conditions": {
            "en": "Seeker's Conditions",
            "ar": "شروط  الموكل",
            "ur": ""
        },
        'To benefit from the application\'s services, the seeker must register an account on the application, choose the person "performer" who will perform the Umrah Al Badal, and agree with him on the date of performing the Umrah and prove that through the application.': {
            "en": 'To benefit from the application\'s services, the seeker must register an account on the application, choose the person "performer" who will perform the Umrah Al Badal, and agree with him on the date of performing the Umrah and prove that through the application.',
            "ar": 'للاستفادة من خدمات التطبيق يجب على الطالب تسجيل حساب في التطبيق واختيار الشخص "المؤدي" الذي سيقوم بأداء عمرة البدال والاتفاق معه على موعد أداء العمرة وإثبات ذلك من خلال التطبيق.',
            "ur": ""
        },
        "The Seeker knows and agrees that the service is not free and is performed for a fee and he is obligated to pay it to the 'Tawkeel' application in accordance with our payment policy.": {
            "en": "The Seeker knows and agrees that the service is not free and is performed for a fee and he is obligated to pay it to the 'Tawkeel' application in accordance with our payment policy.",
            "ar": 'يعرف الموكل ويوافق على أن الخدمة ليست مجانية ويتم تنفيذها مقابل مالي وهو ملزم  بدفعها لتطبيق "توكيل" وفقًا لسياسة الدفع الخاصة بنا.',
            "ur": ""
        },
        "If the seeker has some requirements, they must be clarified to the performer before taking the step of intention, and the performer performs the Umrah in accordance with the provisions of the Sunnah, and the seeker is responsible for following him up during the performance of the Umrah.": {
            "en": "If the seeker has some requirements, they must be clarified to the performer before taking the step of intention, and the performer performs the Umrah in accordance with the provisions of the Sunnah, and the seeker is responsible for following him up during the performance of the Umrah.",
            "ar": 'إذا كان لدى الطالب بعض المتطلبات ، وجب إيضاحها للمؤدي قبل اتخاذ خطوة النية ، وأدى المؤدي العمرة وفق أحكام السنة ، ويكون الطالب مسؤولاً عن متابعته أثناء أداءه. العمرة.',
            "ur": ""
        },
        'The seeker knows and agrees that the application is just an intermediary between the seeker and the performer of the Umrah Al Badal and that the seeker chooses the performer under his own responsibility without any legal responsibility on the application.': {
            "en": 'The seeker knows and agrees that the application is just an intermediary between the seeker and the performer of the Umrah Al Badal and that the seeker chooses the performer under his own responsibility without any legal responsibility on the application.',
            "ar": "يعرف الموكل ويوافق على أن التطبيق هو مجرد وسيط بين الطالب ومؤدي عمرة البدل وأن الطالب يختار المؤدي على مسؤوليته الخاصة دون أي مسؤولية قانونية على التطبيق.",
            "ur": ""
        },
        'The application allows the seeker to follow the performer up during the performance of the rituals of Umrah, by connecting to the application, and this way is the only guarantee provided by the application regarding the performance of the service, and this requires the seeker access to the Internet.': {
            "en": 'The application allows the seeker to follow the performer up during the performance of the rituals of Umrah, by connecting to the application, and this way is the only guarantee provided by the application regarding the performance of the service, and this requires the seeker access to the Internet.',
            "ar": "يتيح التطبيق  للموكل متابعة المؤدي أثناء أداء مناسك العمرة ، من خلال الاتصال بالتطبيق ، وهذه الطريقة هي الضمان الوحيد الذي يقدمه التطبيق فيما يتعلق بأداء الخدمة ، وهذا يتطلب وصول الموكل إلى الإنترنت.",
            "ur": ""
        },
        'The application does not guarantee that the performer chosen by the seeker will implement the service or perform it properly. However, the application will not transfer money to the performer unless he performs the service properly.': {
            "en": 'The application does not guarantee that the performer chosen by the seeker will implement the service or perform it properly. However, the application will not transfer money to the performer unless he performs the service properly.',
            "ar": 'لا يضمن التطبيق أن المؤدي الذي اختاره الموكل سوف ينفذ الخدمة أو يؤديها بشكل صحيح. ومع ذلك ، لن يقوم التطبيق بتحويل الأموال إلى المؤدي ما لم يؤد الخدمة بشكل صحيح.',
            "ur": ""
        },
        'The application does not guarantee that the mobile phone of the performer will work during the period of performing the rituals or that he will be in constant contact with the application during the implementation of the service, and the seeker must offer Internet access on his mobile phone to be able to follow the performer up.': {
            "en": 'The application does not guarantee that the mobile phone of the performer will work during the period of performing the rituals or that he will be in constant contact with the application during the implementation of the service, and the seeker must offer Internet access on his mobile phone to be able to follow the performer up.',
            "ar": 'التطبيق لا يضمن أن الهاتف المحمول الخاص  بالمؤديين سيعمل خلال فترة أداء الشعائر أو أنه سيكون على اتصال دائم بالتطبيق أثناء تنفيذ الخدمة ، ويجب على الموكل أن يوفر الوصول إلى الإنترنت على هاتفه المحمول لتتمكن من متابعة المؤدي.',
            "ur": ""
        },
        'There may be compelling circumstances or formal procedures within the Kingdom that prevent the completion of the Umrah on time, and therefore the application and the performer are exempted by the seeker from any legal responsibility.': {
            "en": 'There may be compelling circumstances or formal procedures within the Kingdom that prevent the completion of the Umrah on time, and therefore the application and the performer are exempted by the seeker from any legal responsibility.',
            "ar": 'قد تكون هناك ظروف قاهرة أو إجراءات رسمية داخل المملكة تمنع إتمام العمرة في الوقت المحدد ، وبالتالي يُعفى الطالب والمقدم من أي مسؤولية قانونية.',
            "ur": ""
        },
        "Payment Policy": {
            "en": "Payment Policy",
            "ar": "الية الدفع",
            "ur": ""
        },
        "The customer must pay in accordance with this payment policy adopted by us, and you know and agree that changes to the payment policy may occur from time to time.": {
            "en": "The customer must pay in accordance with this payment policy adopted by us, and you know and agree that changes to the payment policy may occur from time to time.",
            "ar": "يجب على العميل الدفع وفقًا لسياسة الدفع هذه المعتمدة من قبلنا ، وأنت تعلم وتوافق على أن التغييرات في سياسة الدفع قد تحدث من وقت لآخر.",
            "ur": ""
        },
        "The official currency in the Kingdom is adopted in determining the prices of the services and purchase processes through the application.": {
            "en": "The official currency in the Kingdom is adopted in determining the prices of the services and purchase processes through the application.",
            "ar": "يتم اعتماد العملة الرسمية في المملكة في تحديد أسعار الخدمات وعمليات الشراء من خلال التطبيق.",
            "ur": ""
        },
        "The seeker is obligated to pay for performing the Umrah to the 'Tawkeel' Application , and the cost of the service is 305 $ to be paid through the application via the payment methods approved by us.": {
            "en": "The seeker is obligated to pay for performing the Umrah to the 'Tawkeel' Application , and the cost of the service is 305 $ to be paid through the application via the payment methods approved by us.",
            "ar": 'يلتزم الطالب بدفع قيمة أداء العمرة لتطبيق "توكيل" ، وتبلغ تكلفة الخدمة 305 دولار أمريكي يتم دفعها من خلال التطبيق عبر طرق الدفع المعتمدة لدينا.',
            "ur": ""
        },
        "The application may request to pay by certain methods of payment for the purposes of ensuring the seriousness of payment or services of the customer.": {
            "en": "The application may request to pay by certain methods of payment for the purposes of ensuring the seriousness of payment or services of the customer.",
            "ar": "قد يطلب التطبيق الدفع بطرق دفع معينة لأغراض التأكد من جدية الدفع أو خدمات العميل.",
            "ur": ""
        },
        "Bank transfers may be charged and therefore these charges are borne by the customer.": {
            "en": "Bank transfers may be charged and therefore these charges are borne by the customer.",
            "ar": "قد يتم فرض رسوم على التحويلات المصرفية ، وبالتالي يتحمل العميل هذه الرسوم.",
            "ur": ""
        },
        "Administrative fees may be charged at any time relating to the use of the application, and we will make this clearly advertised through the application.": {
            "en": "Administrative fees may be charged at any time relating to the use of the application, and we will make this clearly advertised through the application.",
            "ar": "قد يتم فرض رسوم إدارية في أي وقت فيما يتعلق باستخدام التطبيق ، وسوف نقوم بالإعلان عن ذلك بوضوح من خلال التطبيق.",
            "ur": ""
        },
        "The customer bears the responsibility for the whole payment process, and therefore we do not bear any errors in the payment process.": {
            "en": "The customer bears the responsibility for the whole payment process, and therefore we do not bear any errors in the payment process.",
            "ar": "يتحمل العميل المسؤولية عن عملية الدفع بأكملها ، وبالتالي لا نتحمل أي أخطاء في عملية الدفع.",
            "ur": ""
        },
        "The customer must maintain the confidentiality of his payment data, and he must be careful to avoid using suspicious methods or unlicensed software.": {
            "en": "The customer must maintain the confidentiality of his payment data, and he must be careful to avoid using suspicious methods or unlicensed software.",
            "ar": "يجب على العميل الحفاظ على سرية بيانات الدفع الخاصة به ، وعليه توخي الحذر لتجنب استخدام الأساليب المشبوهة أو البرامج غير المرخصة.",
            "ur": ""
        },
        "The application may set an administrative fee for the service within the application, and the application will inform the performer of this fee before it is deducted from the amount.": {
            "en": "The application may set an administrative fee for the service within the application, and the application will inform the performer of this fee before it is deducted from the amount.",
            "ar": 'قد يحدد التطبيق رسومًا إدارية للخدمة داخل التطبيق ، وسيقوم التطبيق بإبلاغ المؤدي بهذه الرسوم قبل خصمها من المبلغ.',
            "ur": ""
        },
        "The application does not bear any statutory responsibility or compensation if the amount is confiscated by the official bodies in the Kingdom for any reason.": {
            "en": "The application does not bear any statutory responsibility or compensation if the amount is confiscated by the official bodies in the Kingdom for any reason.",
            "ar": "لا يتحمل التطبيق أي مسؤولية قانونية أو تعويض في حال مصادرة المبلغ من قبل الجهات الرسمية في المملكة لأي سبب من الأسباب.",
            "ur": ""
        },
        "We accept payments online using Visa and MasterCard credit/debit card in SAR.": {
            "en": "We accept payments online using Visa and MasterCard credit/debit card in SAR.",
            "ar": "نقبل المدفوعات عبر الإنترنت باستخدام بطاقة الائتمان / الخصم Visa و MasterCard بالريال السعودي.",
            "ur": ""
        },
        "We accept payments online using PayPal.": {
            "en": "We accept payments online using PayPal.",
            "ar": "نحن نقبل المدفوعات عبر الإنترنت باستخدام PayPal.",
            "ur": ""
        },
        "Refunds will be done only through the Original Mode of Payment": {
            "en": "Refunds will be done only through the Original Mode of Payment",
            "ar": "لن يتم استرداد الأموال إلا من خلال طريقة الدفع الأصلية",
            "ur": ""
        },
        "All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties": {
            "en": "All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties",
            "ar": "لن يتم تخزين جميع تفاصيل بطاقات الائتمان / الخصم ومعلومات التعريف الشخصية أو بيعها أو مشاركتها أو تأجيرها أو تأجيرها لأي طرف ثالث",
            "ur": ""
        },
        "Any dispute or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of Saudi Arabia.": {
            "en": "Any dispute or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of Saudi Arabia.",
            "ar": "يخضع أي نزاع أو مطالبة تنشأ عن أو فيما يتعلق بهذا الموقع الإلكتروني ويتم تفسيره وفقًا لقوانين المملكة العربية السعودية.",
            "ur": ""
        },
        "Saudi Arabia is our country of domicile.": {
            "en": "Saudi Arabia is our country of domicile.",
            "ar": "المملكة العربية السعودية هي بلد إقامتنا.",
            "ur": ""
        },
        "https://tawkeel.com/ will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of Saudi Arabia.": {
            "en": "https://tawkeel.com/ will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of Saudi Arabia.",
            "ar": "https://tawkeel.com/ لن يتعامل أو يقدم أي خدمات أو منتجات إلى أي من دول مكتب مراقبة الأصول الأجنبية (OFAC) الخاضعة لعقوبات وفقًا لقانون المملكة العربية السعودية.",
            "ur": ""
        },
        "Refunds will be done only through the Original Mode of Payment.": {
            "en": "Refunds will be done only through the Original Mode of Payment.",
            "ar": "سيتم استرداد المبالغ فقط من خلال طريقة الدفع الأصلية.",
            "ur": ""
        },
        "Cancellation Policy": {
            "en": "Cancellation Policy",
            "ar": "سياسة الإلغاء",
            "ur": ""
        },
        "Once the seeker order through the application, he will not be able to cancel that order.": {
            "en": "Once the seeker order through the application, he will not be able to cancel that order.",
            "ar": "بمجرد أن يأمر الطالب من خلال التطبيق ، لن يكون قادرًا على إلغاء هذا الطلب.",
            "ur": ""
        },
        "The performer has the right to cancel before making the step of the Umrah intent, after that the performer will not have the right to cancel the Umrah.": {
            "en": "The performer has the right to cancel before making the step of the Umrah intent, after that the performer will not have the right to cancel the Umrah.",
            "ar": "يحق للمؤدي الإلغاء قبل اتخاذ خطوة نية العمرة ، وبعد ذلك لا يحق للمؤدي إلغاء العمرة.",
            "ur": ""
        },
        "In the event that the performer cancels the Umrah in violation of the provisions of this agreement and without a legal justification, the application has the right to permanently block his account and the seeker has the right to claim for compensation.": {
            "en": "In the event that the performer cancels the Umrah in violation of the provisions of this agreement and without a legal justification, the application has the right to permanently block his account and the seeker has the right to claim for compensation.",
            "ar": "في حالة قيام المؤدي بإلغاء العمرة بالمخالفة لأحكام هذه الاتفاقية وبدون مبرر قانوني ، يحق للتطبيق حظر حسابه نهائيًا ويحق للطالب المطالبة بالتعويض.",
            "ur": ""
        },
        "Electronic Contract": {
            "en": "Electronic Contract",
            "ar": "العقد الإلكتروني",
            "ur": ""
        },
        "Since the services we provide are all electronic, and we deal with all our users electronically, we have had to waive written contracts and documents that must be signed with each user and counting on the electronic documents and contracts within a systematic framework in which we take into account all applicable legal provisions.": {
            "en": "Since the services we provide are all electronic, and we deal with all our users electronically, we have had to waive written contracts and documents that must be signed with each user and counting on the electronic documents and contracts within a systematic framework in which we take into account all applicable legal provisions.",
            "ar": "نظرًا لأن الخدمات التي نقدمها جميعها إلكترونية، وأن التعامل مع كافة مستخدمينا يتم بشكل إلكتروني، كان لزامًا علينا التنازل عن العقود والوثائق الخطية التي يجب أن توقع مع كل مستخدم والاعتداد بالوثائق والعقود الالكترونية وذلك في إطار نظامي نراعي فيه كافة الأحكام القانونية المعمول بها.",
            "ur": ""
        },
        "This agreement is an electronic contract between the application and all its users, replaces all oral or written agreements prior to accession to it, and this contract is legally valid as it meets all legal requirements of consent, subject-matter and cause, and offer and acceptance are available in this electronic contract which are required by the applicable laws for the validity of the contracts.": {
            "en": "This agreement is an electronic contract between the application and all its users, replaces all oral or written agreements prior to accession to it, and this contract is legally valid as it meets all legal requirements of consent, subject-matter and cause, and offer and acceptance are available in this electronic contract which are required by the applicable laws for the validity of the contracts.",
            "ar": "تعد هذه الاتفاقية عقدًا إلكترونيًا بين التطبيق وجميع مستخدميه، يحل محل كافة الاتفاقات الشفهية أو الخطية السابقة على الانضمام إليها، ويعد هذا العقد صحيح من الناحية القانونية حيث يتوافر فيه كافة الشروط القانونية من تراضي ومحل وسبب، ويتوافر في هذا العقد الالكتروني الإيجاب والقبول اللذان تتطلبهما القوانين المعمول بها لصحة العقود.",
            "ur": ""
        },
        "This electronic contract produces all the legal effects entail by the written contracts signed between the parties, and this contract replaces them, takes their judgment and entails their effects.": {
            "en": "This electronic contract produces all the legal effects entail by the written contracts signed between the parties, and this contract replaces them, takes their judgment and entails their effects.",
            "ar": "ينتج هذا العقد الإلكتروني كافة الآثار القانونية التي ترتبها العقود الخطية الموقعة بين الأطراف، ويحل هذا العقد ومحلها ويأخذ حكمها ويرتب ذات أثارها.",
            "ur": ""
        },
        'This electronic contract is subject to modification and updating continuously to comply with the services provided by the "Tawkeel" application, and the user is responsible for revising this agreement periodically to revise any modification to those terms and conditions, and any use or access to our services after the modifications we have made is an express consent to them and an acceptance of the new terms.': {
            "en": 'This electronic contract is subject to modification and updating continuously to comply with the services provided by the "Tawkeel" application, and the user is responsible for revising this agreement periodically to revise any modification to those terms and conditions, and any use or access to our services after the modifications we have made is an express consent to them and an acceptance of the new terms.',
            "ar": "يخضع هذا العقد الإلكتروني للتعديل والتحديث بشكل مستمر ليتوافق مع الخدمات التي يتم تقدميها من جانب تطبيق “توكيل“، ويتحمل المستخدم مسئولية مراجعة هذه الاتفاقية بشكل دوري لمراجعة أي تعديل على تلك الشروط والأحكام، ويعد أي استخدام أو وصوله لخدماتنا بعد التعديلات التي قمنا بها موافقة صريحة عليها وقبولاً بالأحكام الجديدة.",
            "ur": ""
        },
        'You are not licensed to interpret or construe any of the conditions of this agreement, and "Tawkeel" platform is the only authority competent to interpret or construe the terms of this agreement, so you should contact us immediately in the case that none of these terms are clear to you.': {
            "en": 'You are not licensed to interpret or construe any of the conditions of this agreement, and "Tawkeel" platform is the only authority competent to interpret or construe the terms of this agreement, so you should contact us immediately in the case that none of these terms are clear to you.',
            "ar": "غير مرخص لك بتفسير أو تأويل أي من شروط هذه الاتفاقية، وتعد تطبيق “توكيل” هي الجهة الوحيدة المختصة بتفسير أو تأويل أحكام هذه الاتفاقية، لذلك عليك التواصل معنا على الفور في حال عدم وضوح أي من هذه الشروط بالنسبة لك.",
            "ur": ""
        },
        'In electronic contracts, the consent to them don\'t require obtaining written signatures of the users, but the consent to them is done by electronic means, and in "Tawkeel" application there are two types of consent and accession to this agreement, namely express consent and tacit consent, which are ultimately considered an electronic signature on this agreement:': {
            "en": 'In electronic contracts, the consent to them don\'t require obtaining written signatures of the users, but the consent to them is done by electronic means, and in "Tawkeel" application there are two types of consent and accession to this agreement, namely express consent and tacit consent, which are ultimately considered an electronic signature on this agreement:',
            "ar": "في العقود الإلكترونية لا يتطلب الموافقة عليها الحصول على توقيعات خطية خاصة بالمستخدمين، وإنما يتم الموافقة عليها بوسائل إلكترونية، وفي تطبيق “توكيل” يوجد نوعان من الموافقة والانضمام إلى هذه الاتفاقية، وهم الموافقة الصريحة والموافقة الضمنية واللذان يعدان في النهاية توقيعات إلكترونية على هذه الاتفاقية:",
            "ur": ""
        },
        "Express consent: consent is express if the user registers his account on the Tawkeel application, provides services through it or requests to benefit from the services provided through it, and this consent is considered an electronic signature of this agreement.": {
            "en": "Express consent: consent is express if the user registers his account on the Tawkeel application, provides services through it or requests to benefit from the services provided through it, and this consent is considered an electronic signature of this agreement.",
            "ar": "الموافقة الصريحة: تكون الموافقة صريحة في حالة قيام المستخدم بتسجيل حسابه بتطبيق Tawkeel، أو تقديم الخدمات من خلاله أو طلب الاستفادة من الخدمات المقدمة من خلاله، وهذه الموافقة تعد توقيعًا إلكترونيًا على هذه الاتفاقية.",
            "ur": ""
        },
        "Tacit consent: consent is tacit if the user uses or visits the Tawkeel application services or stays on the application for long periods of time, and this type of use is considered an electronic signature of this agreement.": {
            "en": "Tacit consent: consent is tacit if the user uses or visits the Tawkeel application services or stays on the application for long periods of time, and this type of use is considered an electronic signature of this agreement.",
            "ar": "الموافقة الضمنية: تكون الموافقة ضمنية في حالة قيام المستخدم باستخدام خدمات تطبيق توكيل أو زيارته أو البقاء في التطبيق لفترات طويلة، ويعد هذا النوع من الاستخدام توقيعًا إلكترونيًا على هذه الاتفاقية.",
            "ur": ""
        },
        "Terms of accession to the agreement": {
            "en": "Terms of accession to the agreement",
            "ar": "شروط الانضمام إلى الاتفاقية",
            "ur": ""
        },
        "As this agreement is an electronic contract between the Tawkeel application and all its users, legal and legitimate capacity must be available in all the users of the application in accordance with applicable laws.": {
            "en": "As this agreement is an electronic contract between the Tawkeel application and all its users, legal and legitimate capacity must be available in all the users of the application in accordance with applicable laws.",
            "ar": "باعتبار أن هذه الاتفاقية عقد إلكتروني بين تطبيق توكيل وجميع مستخدميه، فيجب أن يتوافر في جميع مستخدمي التطبيق الأهلية المعتبرة شرعًا وقانونًا طبقًا للقوانين المعمول بها.",
            "ur": ""
        },
        "The user must be not less than 18 years old at the time of accession to this agreement, and if the user is less than that, he/she must be accede to this agreement by the legal guardian in accordance with the provisions of guardianship applicable.": {
            "en": "The user must be not less than 18 years old at the time of accession to this agreement, and if the user is less than that, he/she must be accede to this agreement by the legal guardian in accordance with the provisions of guardianship applicable.",
            "ar": "يجب ألا يقل عمر المستخدم عن 18 عامًا وقت الانضمام إلى هذه الاتفاقية، فإذا كان المستخدم أقل من ذلك فيجب أن ينضم إلى هذه الاتفاقية بواسطة الولي الجبري أو الوصي عليه وفقًا لأحكام الولاية المعمول بها.",
            "ur": ""
        },
        "The user must see the provisions of this agreement in full before accession to it, as the user's entry with us in this agreement means that he/she has seen all its terms, and is bound by it and by all the legal implications arising from the application of its provisions.": {
            "en": "The user must see the provisions of this agreement in full before accession to it, as the user's entry with us in this agreement means that he/she has seen all its terms, and is bound by it and by all the legal implications arising from the application of its provisions.",
            "ar": "يجب أن يطلع المستخدم على أحكام هذه الاتفاقية بشكل كامل قبل الانضمام إليها، فدخول المستخدم معنا في هذه الاتفاقية يعني اطلاعه على كافة شروطها، ويعد ملزمًا بها وبكافة الآثار القانونية الناشئة عن تطبيق أحكامها.",
            "ur": ""
        },
        "Throughout the period of accession to this agreement, the user must ensure that all the terms and requirements mentioned in the agreement, as well as all the terms and requirements stipulated in the applicable laws, are available.": {
            "en": "Throughout the period of accession to this agreement, the user must ensure that all the terms and requirements mentioned in the agreement, as well as all the terms and requirements stipulated in the applicable laws, are available.",
            "ar": "يجب أن يضمن المستخدم طوال فترة انضمامه إلى هذه الاتفاقية توافر جميع الشروط والمتطلبات المذكورة في الاتفاقية وكذلك كافة الشروط والمتطلبات المنصوص عليها في القوانين المعمول بها.",
            "ur": ""
        },
        "If the user loses any of the terms of this agreement, this agreement will be null for that user, while applying all legal implications arising during the period of its validity to the user.": {
            "en": "If the user loses any of the terms of this agreement, this agreement will be null for that user, while applying all legal implications arising during the period of its validity to the user.",
            "ar": "في حال فقد المستخدم أي شرط من شروط هذه الاتفاقية ستكون هذه الاتفاقية لاغية بالنسبة لهذا المستخدم مع تطبيق كافة الآثار القانونية الناشئة خلال فترة سريانها على المستخدم.",
            "ur": ""
        },
        "Legal Notices": {
            "en": "Legal Notices",
            "ar": "الإخطارات القانونية",
            "ur": ""
        },
        "All legal notices required to be communicated in accordance with applicable laws are sent through the means of communication provided to us by the user, which includes telephone call or telephone messages or e-mail, and these telephone or electronic notices replace all legally required written notices and are officially approved between the parties.": {
            "en": "All legal notices required to be communicated in accordance with applicable laws are sent through the means of communication provided to us by the user, which includes telephone call or telephone messages or e-mail, and these telephone or electronic notices replace all legally required written notices and are officially approved between the parties.",
            "ar": "كافة الإخطارات القانونية المطلوب تبليغها وفقًا للقوانين المعمول بها يتم إرسالها عبر وسائل الاتصال التي زودنا بها المستخدم، والتي تشمل الاتصال بالهاتف أو الرسائل عبر الهاتف أو عبر البريد الالكتروني، وتحل هذه الإخطارات الهاتفية أو الإلكترونية محل كافة الإخطارات الخطية المطلوبة قانونًا، وتكون معتمدة بشكل رسمي بين الأطراف.",
            "ur": ""
        },
        "Each user acknowledges that he/she has waived any legal rights to receive any written notices from the Tawkeel application, and acknowledges that the application shall bound not to provide written notices and are replaced by telephone or electronic notices in the production of their legal effects as pointed out above, and is invoked before all official authorities.": {
            "en": "Each user acknowledges that he/she has waived any legal rights to receive any written notices from the Tawkeel application, and acknowledges that the application shall bound not to provide written notices and are replaced by telephone or electronic notices in the production of their legal effects as pointed out above, and is invoked before all official authorities.",
            "ar": "يقر كل مستخدم بتنازله عن أي حقوق قانونية في تلقي أي إخطارات خطية من جانب تطبيق توكيل، ويقر بأن التطبيق غير ملزم بتقديم الإخطارات الخطية ويحل محلها الإخطارات الهاتفية أو الإلكترونية في إنتاج آثارها القانونية على النحو المشار إليه أعلاه، ويتم الاعتداد بها أمام كافة الجهات الرسمية.",
            "ur": ""
        },
        "www.tawkeel.com will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of Saudi Arabia": {
            "en": "www.tawkeel.com will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of Saudi Arabia",
            "ar": "لن يتعامل موقع www.tawkeel.com مع أي خدمات أو منتجات أو يقدمها إلى أي من الدول التي تفرض عقوبات على مكتب مراقبة الأصول الأجنبية (OFAC) وفقًا لقانون المملكة العربية السعودية",
            "ur": ""
        },
        "Conditions of Account": {
            "en": "Conditions of Account",
            "ar": "شروط الحساب",
            "ur": ""
        },
        "The seeker can register his account on the application that allows him to have many different features and services. The seeker must register on the application with a decent name, and undertakes not to register with any unknown, unreal or misleading names.": {
            "en": "The seeker can register his account on the application that allows him to have many different features and services. The seeker must register on the application with a decent name, and undertakes not to register with any unknown, unreal or misleading names.",
            "ar": "يستطيع العميل تسجيل حسابه بالتطبيق الذي يتيح له العديد من المزايا والخدمات المختلفة. ويجب على العميل التسجيل في التطبيق باسم لائق، ويتعهد بعدم التسجيل بأي أسماء مجهولة أو غير حقيقية أو مضللة.",
            "ur": ""
        },
        "To register the membership on the application, the seeker shall provide us with the following data (name, mobile number, e-mail, country).": {
            "en": "To register the membership on the application, the seeker shall provide us with the following data (name, mobile number, e-mail, country).",
            "ar": "لتسجيل العضوية بالتطبيق يجب على العميل تزويدنا بالبيانات الآتية (الاسم، رقم الجوال، البريد الالكتروني، الدولة).",
            "ur": ""
        },
        "The seeker shall register for membership on Tawkeel application with his data and email, so that he can receive any notifications sent to him from Tawkeel application.": {
            "en": "The seeker shall register for membership on Tawkeel application with his data and email, so that he can receive any notifications sent to him from Tawkeel application.",
            "ar": "يجب على العميل تسجيل العضوية بتطبيق توكيل ببيانات وبريد الكتروني تابعين له، حتى يتمكن من استلام أي إشعارات مرسلة له من تطبيق توكيل.",
            "ur": ""
        },
        "Any seeker isforbidden to register on our application with more than one account and we will delete all accounts if we found that out. The seeker under 18 years old shall be prohibited from registering an account with us or in case we have broken down his account before due to the violation of our terms and conditions or the privacy policy.": {
            "en": "Any seeker isforbidden to register on our application with more than one account and we will delete all accounts if we found that out. The seeker under 18 years old shall be prohibited from registering an account with us or in case we have broken down his account before due to the violation of our terms and conditions or the privacy policy.",
            "ar": "يحظر على أي عميل التسجيل لدينا بأكثر من حساب وسنقوم بحذف جميع الحسابات في حال اكتشافنا ذلك. كما يحظر على العميل تسجيل حساب لدينا إذا كان أقل من 18 عام، أو تم إدانته بجريمة تحرش أو اعتداء جنسي، أو إذا كان مشمول بحظر استخدام خدماتنا بموجب القانون أو حكم قضائي أو كنا قد قمنا بتعطيل حسابه من قبل بسبب انتهاك شروطنا وأحكامنا أو سياسة الخصوصية.",
            "ur": ""
        },
        "The seeker shall maintain his username and password, and not disclose them to others, and in all cases the seeker is committed to all the transactions made through his account on the application.": {
            "en": "The seeker shall maintain his username and password, and not disclose them to others, and in all cases the seeker is committed to all the transactions made through his account on the application.",
            "ar": "يجب على العميل الحفاظ على اسم المستخدم وكلمة المرور الخاصة به، وألا يقوم بالإفصاح عنهم للغير، وفي جميع الأحوال يلتزم العميل بكافة التعاملات التي تتم من خلال حسابه بالتطبيق.",
            "ur": ""
        },
        "The account on the application is provided with a licensing system for using the account, so we have the right to revoke this License and terminate the account at any time without providing any reasons.": {
            "en": "The account on the application is provided with a licensing system for using the account, so we have the right to revoke this License and terminate the account at any time without providing any reasons.",
            "ar": "يقدم الحساب بالتطبيق بنظام الترخيص بالاستخدام، وبالتالي يحق لنا سحب هذا الترخيص وإنهاء استخدام الحساب في أي وقت من الأوقات ودون إبداء أي أسباب.",
            "ur": ""
        },
        "All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties.": {
            "en": "All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties.",
            "ar": "جميع تفاصيل بطاقات الائتمان / الخصم ومعلومات التعريف الشخصية لن يتم تخزينها أو بيعها أو مشاركتها أو تأجيرها أو تأجيرها لأي طرف ثالث",
            "ur": ""
        },
        "Conditions of Use": {
            "en": "Conditions of Use",
            "ar": "شروط الاستخدام",
            "ur": ""
        },
        "These conditions were formulated in accordance with Saudi laws. Products are provided through the application in accordance with the laws applicable in the Kingdom of Saudi Arabia, therefore you comply with these laws regardless of the country to which you belong.": {
            "en": "These conditions were formulated in accordance with Saudi laws. Products are provided through the application in accordance with the laws applicable in the Kingdom of Saudi Arabia, therefore you comply with these laws regardless of the country to which you belong.",
            "ar": "تم صياغة هذه الشروط وفق القوانين السعودية. ويتم توفير المنتجات من خلال التطبيق وفق القوانين المعمول بها في المملكة العربية السعودية، وبالتالي فأنت تلتزم بهذه القوانين بغض النظر عن الدولة التي تنتمي إليها.",
            "ur": ""
        },
        "The user must be legally qualified to enter into this Agreement with us and he must be 18 years and older at the time of using our application.": {
            "en": "The user must be legally qualified to enter into this Agreement with us and he must be 18 years and older at the time of using our application.",
            "ar": "يجب أن يكون المستخدم مؤهل قانونًا بالدخول معنا في هذه الاتفاقية، وأن يكون بلغ من العمر 18 عامًا فأكثر وقت استخدام تطبيقنا الالكتروني.",
            "ur": ""
        },
        "Any information required must be entered on the application correctly and you must be responsible for it, its recency, quality, and legality, and you are obligated to update it whenever any change occurs.": {
            "en": "Any information required must be entered on the application correctly and you must be responsible for it, its recency, quality, and legality, and you are obligated to update it whenever any change occurs.",
            "ar": "يجب إدخال أي معلومات مطلوبة بالتطبيق بشكل صحيح وتكون مسئول عنها وعن دقتها وجودتها وحداثتها وقانونيتها، وتلتزم بتحديثها كلما حدث أي تغيير لها.",
            "ur": ""
        },
        "The application must be used within the purposes declared through these terms or those declared through the pages of the application, and that our services should be used in good faith and not to manipulate the application or its clients in any way.": {
            "en": "The application must be used within the purposes declared through these terms or those declared through the pages of the application, and that our services should be used in good faith and not to manipulate the application or its clients in any way.",
            "ar": "يجب استخدام التطبيق في حدود الأغراض المعلنة من خلال هذه الشروط أو تلك المعلنة عبر صفحات التطبيق، وأن يتم استخدام خدماتنا بحسن نية وعدم التلاعب على التطبيق أو المتعاملين من خلالها بأي صورة من الصور.",
            "ur": ""
        },
        "The application must be used legally and that this use should be serious and the users shouldn't use the application for the purposes of fraud,scam, illegal communication, fake communication or harming to any party and that exercising any actions that expose the application to the legal accountability must be forbidden.": {
            "en": "The application must be used legally and that this use should be serious and the users shouldn't use the application for the purposes of fraud,scam, illegal communication, fake communication or harming to any party and that exercising any actions that expose the application to the legal accountability must be forbidden.",
            "ar": "يجب أن يتم استخدام التطبيق بشكل قانوني، وأن يتسم هذا الاستخدام بالجدية وأن يبتعد المستخدمين عن استخدام التطبيق لأغراض التدليس أو الاحتيال أو التواصل غير المشروع أو التواصل الوهمي أو الإضرار بأي طرف من الأطراف، ويحظر مباشرة أي أعمال من شأنها تعريض التطبيق للمساءلة القانونية.",
            "ur": ""
        },
        "The user must notify us in case of finding out any illegal practices or activities through the application.": {
            "en": "The user must notify us in case of finding out any illegal practices or activities through the application.",
            "ar": "يجب على المستخدم إخطارنا في حالة اكتشاف أي ممارسات أو أنشطة غير قانونية من خلال التطبيق.",
            "ur": ""
        },
        "The user must maintain the reputation of the application, not to offend the application directly or indirectly, does not cause us direct or indirect damages, and does not cause us any legal claims.": {
            "en": "The user must maintain the reputation of the application, not to offend the application directly or indirectly, does not cause us direct or indirect damages, and does not cause us any legal claims.",
            "ar": "يجب أن يحافظ المستخدم على سمعة التطبيق وألا يسيء للتطبيق بشكل مباشر أو غير مباشر، وألا يتسبب لنا في أضرار مباشرة أو غير مباشرة، وألا يتسبب لنا في أي مطالبات قانونية.",
            "ur": ""
        },
        "It is forbidden to resell or commercially exploit any part of the application, or profiteering from this, except for activities permitted by us.": {
            "en": "It is forbidden to resell or commercially exploit any part of the application, or profiteering from this, except for activities permitted by us.",
            "ar": "يحظر إعادة بيع أي جزء من أجزاء التطبيق أو استغلاله بشكل تجاري والتربح من وراء ذلك باستثناء الأنشطة المصرح بها من جانبنا.",
            "ur": ""
        },
        "Tawkeel application reserves the right to make any modifications or changes to its application and to policies and agreements associated with Tawkeel including terms and conditions.": {
            "en": "Tawkeel application reserves the right to make any modifications or changes to its application and to policies and agreements associated with Tawkeel including terms and conditions.",
            "ar": "يحتفظ تطبيق توكيل لنفسه بالحق بأن يجري أية تعديلات أو تغييرات على تطبيقه الالكتروني وعلى السياسات والاتفاقيات المرتبطة بتطبيق توكيل بما في ذلك الشروط والأحكام.",
            "ur": ""
        },
        "The application reserves all its legal rights in the case that any user of the application violates our legal, legitimate rights or our rights enshrined in this agreement.": {
            "en": "The application reserves all its legal rights in the case that any user of the application violates our legal, legitimate rights or our rights enshrined in this agreement.",
            "ar": "يحتفظ التطبيق بكافة حقوقه القانونية في حالة إخلال أي من مستخدمي التطبيق بحقوقنا القانونية والمشروعة أو بحقوقنا المنصوص عليها في هذه الاتفاقية.",
            "ur": ""
        },
        "Content Policy": {
            "en": "Content Policy",
            "ar": "سياسة المحتوى",
            "ur": ""
        },
        "The content is provided through Tawkeel application for information purposes only, and for the purposes of providing our services exclusively, and it should not be dedicated for any content, websites or bodies that are not ours.": {
            "en": "The content is provided through Tawkeel application for information purposes only, and for the purposes of providing our services exclusively, and it should not be dedicated for any content, websites or bodies that are not ours.",
            "ar": " يُقدم المحتوى من خلال تطبيق توكيل لأغراض المعلومات فقط، ولأغراض تقديم خدماتنا وبشكل حصري، ولا يجوز تخصيصه لأي محتوى أو مواقع أو جهات غير تابعة لنا.",
            "ur": ""
        },
        "The application provides the content through it for the purposes of providing and displaying our services to the users and marketing our services through the application.": {
            "en": "The application provides the content through it for the purposes of providing and displaying our services to the users and marketing our services through the application.",
            "ar": " يوفر التطبيق المحتوى من خلاله لأغراض تقديم وعرض خدماتنا على المستخدمين وتسويق خدماتنا من خلال التطبيق.",
            "ur": ""
        },
        "Texts, drawings, photographs, logos, illustrations, explanations, data and other materials provided by us on or through the services of Tawkeel application in addition to its selection, compilation and arrangement that may contain errors, omissions, typographical errors or to be outdated, and the application may change, delete or update any content at any time without prior notice.": {
            "en": "Texts, drawings, photographs, logos, illustrations, explanations, data and other materials provided by us on or through the services of Tawkeel application in addition to its selection, compilation and arrangement that may contain errors, omissions, typographical errors or to be outdated, and the application may change, delete or update any content at any time without prior notice.",
            "ar": " النصوص والرسومات والصور الفوتوغرافية والشعارات والرسوم التوضيحية والشروحات والبيانات والمواد الأخرى المقدمة منا على أو من خلال خدمات تطبيق توكيل بالإضافة إلى اختيارهم وتجميعهم وترتيبهم من المحتمل أن يحتوي على أخطاء أو إغفال أو أخطاء مطبعية أو أن يكون قديماً، ويجوز للتطبيق أن يغير أو يحذف أو يحدث أي محتوى في أي وقت بدون إخطار مسبق.",
            "ur": ""
        },
        "The data available through the application may include some unintentional errors, so you exempt us from any liability arising from this. Also, you undertake to contact us to inquire and request to correct any errors in the data.": {
            "en": "The data available through the application may include some unintentional errors, so you exempt us from any liability arising from this. Also, you undertake to contact us to inquire and request to correct any errors in the data.",
            "ar": " قد تتضمن البيانات المتاحة من خلال التطبيق بعض الأخطاء غير المتعمدة، وبالتالي فأنت تعفينا من أي مسئولية ناشئة عن ذلك، كما أنك تتعهد بالتواصل معنا للاستفسار وطلب تصحيح أي أخطاء موجودة في البيانات.",
            "ur": ""
        },
        "The user must check any content available through the application, check its authenticity and accuracy and to conduct due diligence like the person who is keen to handle the content.": {
            "en": "The user must check any content available through the application, check its authenticity and accuracy and to conduct due diligence like the person who is keen to handle the content.",
            "ar": " يجب على المستخدم التحقق من أي محتوى متاح من خلال التطبيق، والتحقق من مدى صحته ودقته وأن يبذل عناية الشخص الحريص في التعامل مع المحتوى.",
            "ur": ""
        },
        "Tawkeel application doesn't bear any legal liability arising from the content available through the application, and you explicitly exempt us from doing so.": {
            "en": "Tawkeel application doesn't bear any legal liability arising from the content available through the application, and you explicitly exempt us from doing so.",
            "ar": " لا يتحمل تطبيق توكيل أي مسئولية قانونية ناشئة عن المحتوى المتاح من خلال التطبيق، وأنت تعفينا عن ذلك صراحة.",
            "ur": ""
        },
        "All the data available to other people through the application is provided at their own responsibility without any guarantees from the application.": {
            "en": "All the data available to other people through the application is provided at their own responsibility without any guarantees from the application.",
            "ar": " كافة البيانات المتوفرة لأشخاص آخرين عبر التطبيق يتم توفيرها على مسئوليتهم الشخصية دون تقديم أي ضمانات من جانب التطبيق.",
            "ur": ""
        },
        "The content provided by the user through the application must be legal and does not include any assault on the rights of others.": {
            "en": "The content provided by the user through the application must be legal and does not include any assault on the rights of others.",
            "ar": " يجب أن يكون المحتوى الذي يقدمه المستخدم من خلال التطبيق قانوني ولا يتضمن أي اعتداء على الحقوق الخاصة بالآخرين.",
            "ur": ""
        },
        "The user is legally responsible for any content, data, information, files, documents, papers or messages that he provides, sends or exchanges through the application.": {
            "en": "The user is legally responsible for any content, data, information, files, documents, papers or messages that he provides, sends or exchanges through the application.",
            "ar": " يكون المستخدم مسؤول قانونًا عن أي محتوى أو بيانات أو معلومات أو ملفات أو وثائق أو مستندات أو رسائل يقوم بتوفيرها أو إرسالها أو تبادلها من خلال التطبيق.",
            "ur": ""
        },
        "The user is responsible before all bodies, authorities, courts and investigative bodies for any sanctions or compensations related to any illegal content he provides through the application.": {
            "en": "The user is responsible before all bodies, authorities, courts and investigative bodies for any sanctions or compensations related to any illegal content he provides through the application.",
            "ar": " يكون المستخدم مسؤول أمام جميع الجهات والسلطات والمحاكم وجهات التحقيق عن أي جزاءات أو تعويضات متعلقة بأي محتوى غير قانوني يقوم بتوفيره من خلال التطبيق.",
            "ur": ""
        },
        "Third Parties": {
            "en": "Third Parties",
            "ar": "الطرف  الثالث",
            "ur": ""
        },
        "The third parties may assist us in providing our services, and the application release its legal responsibility for any direct or indirect, intentional or unintentional errors made by the third parties which provide services through the application.": {
            "en": "The third parties may assist us in providing our services, and the application release its legal responsibility for any direct or indirect, intentional or unintentional errors made by the third parties which provide services through the application.",
            "ar": " قد يساعدنا في تقديم خدماتنا  طرف ثالث، ويخلي التطبيق مسئوليته القانونية عن أي أخطاء مباشرة أو غير مباشرة، متعمدة أو غير متعمدة، تقع من أي طرف ثالث الذي يقوم  بتقديم الخدمات عبر التطبيق  .",
            "ur": ""
        },
        "Terms and conditions of third parties may be applied to the user, and these are not subject to our control and therefore the user must access to and agree on these policies before benefiting from the services provided by the third parties through us.": {
            "en": "Terms and conditions of third parties may be applied to the user, and these are not subject to our control and therefore the user must access to and agree on these policies before benefiting from the services provided by the third parties through us.",
            "ar": " قد يطبق على المستخدم شروط وأحكام خاصة بالطرف الثالث وهذه لا تخضع لسيطرتنا وبالتالي يجب على المستخدم الإطلاع على هذه السياسات والموافقة عليها قبل الاستفادة من الخدمات التي يقدمها الطرف الثالث  من خلالنا .",
            "ur": ""
        },
        "Intellectual Property Rights": {
            "en": "Intellectual Property Rights",
            "ar": "حقوق الملكية الفكرية",
            "ur": ""
        },
        "The application and all its material and moral elements are privately owned by Tawkeel Commercial Services in the Kingdom of Saudi Arabia, and it should not be imitated, copied or re-used in any way. All the contents of the application (content, lists, texts, images, video, symbols, numbers, letters, icons, buttons, music, data, information) is subject to legal protection under the laws of the Kingdom of Saudi Arabia and international conventions, and We have the right to defend our copyrights by all legal means.": {
            "en": "The application and all its material and moral elements are privately owned by Tawkeel Commercial Services in the Kingdom of Saudi Arabia, and it should not be imitated, copied or re-used in any way. All the contents of the application (content, lists, texts, images, video, symbols, numbers, letters, icons, buttons, music, data, information) is subject to legal protection under the laws of the Kingdom of Saudi Arabia and international conventions, and We have the right to defend our copyrights by all legal means.",
            "ar": " التطبيق وجميع عناصرها المادية والمعنوية مملوكة ملكية خاصة لشركة توكيل للخدمات التجارية بالمملكة العربية السعودية، ولا يجوز تقليدها أو نسخها أو إعادة استغلالها بأي شكل من الأشكال. وجميع المحتويات الخاصة بالتطبيق من (محتوى، قوائم، نصوص، صور، فيديو، رموز، أرقام، حروف، أيقونات، أزرار، موسيقى، بيانات، معلومات) خاضعة للحماية القانونية بموجب القوانين المعمول بها في المملكة العربية السعودية والاتفاقيات الدولية، ويحق الدفاع عن حقوق التأليف والنشر الخاصة بنا بكافة الطرق القانونية.",
            "ur": ""
        },
        '"Tawkeel" is a trademark used by us and it should not be infringed, imitated, copied, traded illegally or used on marks or services that are not ours, and in case of an attack on that trademark, we have the right to take all the legal procedures that preserves all our commercial rights.': {
            "en": '"Tawkeel" is a trademark used by us and it should not be infringed, imitated, copied, traded illegally or used on marks or services that are not ours, and in case of an attack on that trademark, we have the right to take all the legal procedures that preserves all our commercial rights.',
            "ar": "“توكيل Tawkeel” هي علامة تجارية ومستخدمة من جانبنا ولا يجوز التعدي عليها أو تقليدها أو نسخها أو تداولها بشكل غير قانوني أو استخدامها على علامات أو خدمات غير تابعة لنا، وفي حالة الاعتداء على تلك العلامة يحق لنا اتخاذ كافة الإجراءات القانونية التي تحفظ كافة حقوقنا التجارية عليها.",
            "ur": ""
        },
        "Legal Responsibility": {
            "en": "Legal Responsibility",
            "ar": "المسئولية القانونية",
            "ur": ""
        },
        "Each seeker is personally responsible for all the actions and activities which he carries out through the application, and the application will not be subsidiary or solidarity responsible of any users.": {
            "en": "Each seeker is personally responsible for all the actions and activities which he carries out through the application, and the application will not be subsidiary or solidarity responsible of any users.",
            "ar": " كل عميل مسئول مسئولية شخصية عن كافة الأفعال والأنشطة التي يقوم بها من خلال التطبيق، ولن يكون التطبيق مسئولة بالتضامن أو بالتبعية عن أي من المستخدمين.",
            "ur": ""
        },
        "Each seeker is personally responsible in case he does not comply with the commitments imposed on him under these applicable terms or policies.": {
            "en": "Each seeker is personally responsible in case he does not comply with the commitments imposed on him under these applicable terms or policies.",
            "ar": " كل عميل مسئول مسئولية شخصية في حالة عدم التزامه بالالتزامات المفروضة عليه بموجب هذه الشروط أو السياسات المطبقة.",
            "ur": ""
        },
        "Each seeker is personally responsible in case he violates the laws applicable in the country to which he belongs, and the application will not be subsidiary or solidarity responsible of any users.": {
            "en": "Each seeker is personally responsible in case he violates the laws applicable in the country to which he belongs, and the application will not be subsidiary or solidarity responsible of any users.",
            "ar": " كل عميل مسئول مسئولية شخصية في حالة إخلاله بالقوانين المعمول بها في الدولة التي ينتمي إليها، ولن يكون التطبيق مسئول بالتضامن أو بالتبعية عن أي من المستخدمين.",
            "ur": ""
        },
        "The seeker shall bear the legal responsibility in case that he violates any of our rights under these terms and conditions, attacks on any of our rights, our ownership of the application or any of its elements.": {
            "en": "The seeker shall bear the legal responsibility in case that he violates any of our rights under these terms and conditions, attacks on any of our rights, our ownership of the application or any of its elements.",
            "ar": " يتحمل العميل المسئولية القانونية في حالة إخلاله بأي حق من حقوقنا بموجب هذه الشروط والأحكام أو الاعتداء على أي حق من حقوقنا أو ملكيتنا للتطبيق أو أي من عناصره.",
            "ur": ""
        },
        "The seeker shall bear the legal responsibility in case of offending the application in any website, application, social media, email or through any other means.": {
            "en": "The seeker shall bear the legal responsibility in case of offending the application in any website, application, social media, email or through any other means.",
            "ar": " يتحمل العميل المسئولية القانونية في حالة الإساءة للتطبيق في أي موقع أو تطبيق أو وسائل تواصل اجتماعي أو عبر البريد الالكتروني أو من خلال أي وسيلة أخرى.",
            "ur": ""
        },
        "The seeker shall bear the legal responsibility in case of offending of any other person through the application, without original, subsidiary or joint responsibility on the application.": {
            "en": "The seeker shall bear the legal responsibility in case of offending of any other person through the application, without original, subsidiary or joint responsibility on the application.",
            "ar": " يتحمل العميل المسئولية القانونية في حالة الإساءة لأي شخص آخر من خلال التطبيق، دون أدنى مسئولية أصلية أو تبعية أو تضامنية على التطبيق.",
            "ur": ""
        },
        "The seeker shall be bound to compensate us for all the damages to the application as a result of any illegitimate or unauthorized use by the user or any of his followers.": {
            "en": "The seeker shall be bound to compensate us for all the damages to the application as a result of any illegitimate or unauthorized use by the user or any of his followers.",
            "ar": " يلتزم العميل بتعويضنا عن كافة الأضرار التي تصيب التطبيق نتيجة أي استخدام غير مشروع أو غير من مفوض من جانب المستخدم أو أي من تابعيه.",
            "ur": ""
        },
        "The seeker shall be bound to compensate us in case of offending us, the administration of the application, employees, partners, distributors, agents, our affiliates or any other person who belongs to Tawkeel application.": {
            "en": "The seeker shall be bound to compensate us in case of offending us, the administration of the application, employees, partners, distributors, agents, our affiliates or any other person who belongs to Tawkeel application.",
            "ar": " يلتزم العميل بتعويضنا في حالة الإساءة لنا أو لإدارة التطبيق أو الموظفين أو الشركاء أو الموزعين أو الوكلاء أو التابعين لنا أو أي شخص آخر ينتمي إلى تطبيق توكيل.",
            "ur": ""
        },
        "We reserve all our legal rights arising from this Agreement at any time, and that not using the right at a specific time does not mean to waive it at all other times.": {
            "en": "We reserve all our legal rights arising from this Agreement at any time, and that not using the right at a specific time does not mean to waive it at all other times.",
            "ar": " نحتفظ بكافة حقوقنا القانونية الناشئة عن هذه الاتفاقية في أي وقت من الأوقات، ولا يعني عدم استخدام لذلك الحق في وقت معين التنازل عنه في جميع الأوقات الأخرى.",
            "ur": ""
        },
        "Disclaimer of Liability": {
            "en": "Disclaimer of Liability",
            "ar": "إخلاء المسؤولية",
            "ur": ""
        },
        "The user release us from the responsibility for all the illegitimate activities which are not our and may occur through the application, the application cannot control all the actions carried through it, and the affected must inform us to take the necessary actions towards stopping the source of the damage.": {
            "en": "The user release us from the responsibility for all the illegitimate activities which are not our and may occur through the application, the application cannot control all the actions carried through it, and the affected must inform us to take the necessary actions towards stopping the source of the damage.",
            "ar": " يخلي العميل مسئوليتنا عن كافة الأنشطة غير المشروعة غير التابعة لنا التي قد تحدث عبر التطبيق، فالتطبيق لا يستطيع السيطرة على كافة الأفعال التي تتم من خلاله ويجب على المتضرر إبلاغنا لاتخاذ اللازم نحو وقف مصدر الضرر.",
            "ur": ""
        },
        "The application may discontinue from time to time and it may permanently discontinue, whether for voluntary or involuntary reasons, and therefore you exempt us from any legal responsibility in case of a permanent or temporary discontinuing of the application or any of its services.": {
            "en": "The application may discontinue from time to time and it may permanently discontinue, whether for voluntary or involuntary reasons, and therefore you exempt us from any legal responsibility in case of a permanent or temporary discontinuing of the application or any of its services.",
            "ar": " التطبيق قد يتوقف من وقت لآخر وقد يتوقف بشكل دائم سواء لأسباب إرادية أو غير إرادية وبالتالي فأنت تعفينا من أي مسئولية قانونية في حالة التوقف الدائم أو المؤقت للتطبيق أو أي من خدماته.",
            "ur": ""
        },
        "The application does not provide any type of insurance or compensations to any of its users, and anyone who uses the application and its services at its own responsibility, and the application will not be responsible to face any of the users for any reason resulting from the use of the application or its services or apply our terms and policies.": {
            "en": "The application does not provide any type of insurance or compensations to any of its users, and anyone who uses the application and its services at its own responsibility, and the application will not be responsible to face any of the users for any reason resulting from the use of the application or its services or apply our terms and policies.",
            "ar": " التطبيق لا يقدم أي نوع من أنواع التأمين أو التعويضات لأي من مستخدميه، وكل شخص يستخدم التطبيق وخدماته على مسئوليته الشخصية، ولن يكون التطبيق مسئول في مواجهة أي من المستخدمين لأي سبب ناتج عن استخدام التطبيق أو خدماتها أو تطبيق شروطنا وسياساتنا.",
            "ur": ""
        },
        "The application is not responsible for any comments or content posted through it, or for any communications within or outside the application.": {
            "en": "The application is not responsible for any comments or content posted through it, or for any communications within or outside the application.",
            "ar": " التطبيق غير مسئول عن أي تعليقات أو محتوى منشور من خلالها، أو عن أي عمليات تواصل داخل التطبيق أو خارجها.",
            "ur": ""
        },
        "The seeker releases the application's responsibility for all errors it makes, whether intentional or unintentional.": {
            "en": "The seeker releases the application's responsibility for all errors it makes, whether intentional or unintentional.",
            "ar": " يخلي العميل مسؤولية التطبيق عن كافة الأخطاء التي يرتكبها سواء كانت متعمدة أو غير متعمدة.",
            "ur": ""
        },
        "Communications and Notifications": {
            "en": "Communications and Notifications",
            "ar": "الاتصالات والإشعارات",
            "ur": ""
        },
        "The application communicates with you from time to time through the contact details you have provided to us, and under this agreement you authorize us to communicate with you online or by telephone, and in case you do not want to receive communication from us you must notify us and we will immediately discontinue to communicate with you, but this means that our services for you may be permanently discontinue.": {
            "en": "The application communicates with you from time to time through the contact details you have provided to us, and under this agreement you authorize us to communicate with you online or by telephone, and in case you do not want to receive communication from us you must notify us and we will immediately discontinue to communicate with you, but this means that our services for you may be permanently discontinue.",
            "ar": " يقوم التطبيق بالتواصل معك من وقت لآخر من خلال بيانات الاتصال التي قدمتها لنا، وبموجب هذه الاتفاقية فأنت تفوضنا في التواصل معك إلكترونيًا أو هاتفيًا، وفي حالة عدم رغبتك في تلقي الاتصالات منا يجب عليك إخطارًا وسنتوقف فورًا عن التواصل معك، ولكن هذا يعني أن خدماتنا قد تتوقف بالنسبة لك بشكل دائم.",
            "ur": ""
        },
        "Any notifications the application wants to notify the users with are made through their own contact details, and the user is supposed to know about with the notification once the application sends it to him. In case that the user wants to send us notifications, this must be done through our e-mail (support@tawkeel.com).": {
            "en": "Any notifications the application wants to notify the users with are made through their own contact details, and the user is supposed to know about with the notification once the application sends it to him. In case that the user wants to send us notifications, this must be done through our e-mail (support@tawkeel.com).",
            "ar": " أي إشعارات يرغب التطبيق في إبلاغها للمستخدمين تتم من خلال بيانات الاتصال الخاصة بهم، ويفترض علم المستخدم بالإشعار بمجرد قيام التطبيق بإرساله له. وفي حالة رغبة المستخدم في إرسال الإشعارات لنا يجب أن يتم ذلك من خلال بريدنا الإلكتروني (support@tawkeel.com).",
            "ur": ""
        },
        "Amendments and Additions": {
            "en": "Amendments and Additions",
            "ar": "التعديلات والإضافات",
            "ur": ""
        },
        "You know and agree that our services may be continuously amended or updated, and our terms, conditions and privacy policy may be amended, updated or added from time to time, and the application will not be obliged to notify any of its users, so you must review this agreement before any process you make through the application, and accordingly you acknowledge the right of Tawkeel at any time and without prior notice and at its sole discretion to review these terms and conditions or impose new terms and conditions related to Tawkeel You bear the responsibility for periodically reviewing these terms and conditions to review any amendment on these terms and conditions, and any use or obtain of the products or services of Tawkeel constitutes your consent to these reviews or additions.": {
            "en": "You know and agree that our services may be continuously amended or updated, and our terms, conditions and privacy policy may be amended, updated or added from time to time, and the application will not be obliged to notify any of its users, so you must review this agreement before any process you make through the application, and accordingly you acknowledge the right of Tawkeel at any time and without prior notice and at its sole discretion to review these terms and conditions or impose new terms and conditions related to Tawkeel You bear the responsibility for periodically reviewing these terms and conditions to review any amendment on these terms and conditions, and any use or obtain of the products or services of Tawkeel constitutes your consent to these reviews or additions.",
            "ar": "أنت تعلم وتوافق على أن خدماتنا قد يتم تعديلها أو تحديثها بشكل مستمر، كما أن شروطنا وأحكامنا وسياسة الخصوصية قد يتم تعديلها أو تحديثها أو الإضافة عليها من وقت لآخر، ولن يكون التطبيق ملزم بإخطار أي من مستخدميه، لذلك يجب عليك مراجعة هذه الاتفاقية قبل أي عملية تقوم بها من خلال التطبيق، وبناءً على ذلك أنت تقر بحق تطبيق توكيل في أي وقت وبدون إخطار مسبق وبناء على تقديرها وحدها دون غيرها مراجعة هذه الشروط والأحكام أو فرض شروط وأحكام جديدة متعقلة بخدمات تطبيق توكيل أو الحصول عليها وتتحمل أنت مسؤولية مراجعة هذه الشروط والأحكام بشكل دوري لمراجعة أي تعديل على تلك الشروط والأحكام، ويشكل أي استخدام أو حصول لك على منتجات أو خدمات تطبيق توكيل موافقتكم على تلك المراجعات أو الإضافات.",
            "ur": ""
        },
        "Cancellation": {
            "en": "Cancellation",
            "ar": "الإلغاء",
            "ur": ""
        },
        "The application has the right to cancel any of the services available through it, adjust the application completely, change it or change its activity. We also have the right to cancel the terms, conditions and privacy policy or replace them at any time without requiring your consent.": {
            "en": "The application has the right to cancel any of the services available through it, adjust the application completely, change it or change its activity. We also have the right to cancel the terms, conditions and privacy policy or replace them at any time without requiring your consent.",
            "ar": "يحق للتطبيق إلغاء أي من الخدمات المتاحة من خلاله أو تعديل التطبيق بشكل كامل أو تغييرها أو تغيير نشاطها. كما يحق لنا إلغاء الشروط والأحكام وسياسة الخصوصية أو استبدالهم في أي وقت دون أن يتطلب ذلك الحصول على موافقتك.",
            "ur": ""
        },
        "Privacy and Confidentiality": {
            "en": "Privacy and Confidentiality",
            "ar": "الخصوصية والسرية",
            "ur": ""
        },
        "Data collection and processing are subject to the privacy policy approved by the application, which is an integral part of the terms and conditions.": {
            "en": "Data collection and processing are subject to the privacy policy approved by the application, which is an integral part of the terms and conditions.",
            "ar": " يخضع جمع ومعالجة البيانات لسياسة الخصوصية المعتمدة من التطبيق، والتي تعد جزء لا يتجزأ من الشروط والأحكام.",
            "ur": ""
        },
        "The user shall be bound to maintain the confidentiality of all internal transactions of the application, all financial reports, commissions, fees, employee's data, and any data or documents that specific person are authorized to access to or not prepared for access by others.": {
            "en": "The user shall be bound to maintain the confidentiality of all internal transactions of the application, all financial reports, commissions, fees, employee's data, and any data or documents that specific person are authorized to access to or not prepared for access by others.",
            "ar": " يلتزم المستخدم بالحفاظ على سرية كافة المعاملات الداخلية الخاصة بالتطبيق وكافة التقارير المالية والعمولات والرسوم وبيانات الموظفين وأي بيانات أو وثائق مصرح لأشخاص محددين بالإطلاع عليها أو غير معدة لاطلاع الغير عليها.",
            "ur": ""
        },
        "The user shall be bound not to collect or exploit any data of any other parties who use the application, such as clients and service providers, and the use of data is limited to the purposes of providing services or benefiting from them only.": {
            "en": "The user shall be bound not to collect or exploit any data of any other parties who use the application, such as clients and service providers, and the use of data is limited to the purposes of providing services or benefiting from them only.",
            "ar": " يلتزم المستخدم بعدم جمع أو استغلال أي بيانات خاصة بأي أطراف أخرى تستخدم التطبيق كالعملاء ومزودي الخدمات، ويقتصر استخدام البيانات على أغراض تزويد الخدمات أو الاستفادة منها فقط.",
            "ur": ""
        },
        "The application maintains the confidentiality of all data that you provided us with or that related to your activities through the application and is accessed to by our employees and service providers and the third parties relevant to this data or who explicitly authorized by the user or the application to use the data or by virtue of the nature of the transaction itself that allows it.": {
            "en": "The application maintains the confidentiality of all data that you provided us with or that related to your activities through the application and is accessed to by our employees and service providers and the third parties relevant to this data or who explicitly authorized by the user or the application to use the data or by virtue of the nature of the transaction itself that allows it.",
            "ar": " يحتفظ التطبيق بسرية كافة البيانات التي زودتنا بها أو المتعلقة بنشاطاتك من خلال التطبيق ويتم الإطلاع عليها من جانب موظفينا ومزودي الخدمات التابعين لنا والأطراف الثالثة ذات العلاقة بهذه البيانات أو المفوضين بشكل صريح من المستخدم أو التطبيق باستخدام البيانات أو بحكم طبيعة المعاملة ذاتها التي تسمح بذلك.",
            "ur": ""
        },
        "Law": {
            "en": "Law",
            "ar": "القانون",
            "ur": ""
        },
        "* The interpretation and implementation of the provisions of this document are subject to the laws in force in the Kingdom of Saudi Arabia, and these conditions are not limited to the items contained therein, but rather extend to include all legal texts regulating civil and commercial relations in force in the Kingdom of Saudi Arabia as long as they are complementary rules and do not contradict directly or indirectly with the items contained in this document": {
            "en": "* The interpretation and implementation of the provisions of this document are subject to the laws in force in the Kingdom of Saudi Arabia, and these conditions are not limited to the items contained therein, but rather extend to include all legal texts regulating civil and commercial relations in force in the Kingdom of Saudi Arabia as long as they are complementary rules and do not contradict directly or indirectly with the items contained in this document",
            "ar": "* يخضع تفسير وتنفيذ بنود هذه الوثيقة للقوانين المعمول بها في المملكة العربية السعودية، ولا تقتصر هذه الشروط على البنود الواردة فيها وإنما تمتد لتشمل كافة النصوص القانونية المنظمة للعلاقات المدنية والتجارية المعمول بها في المملكة العربية السعودية طالما كانت قواعد مكملة ولا تتعارض بشكل مباشر أو غير مباشر مع البنود الواردة في هذه الوثيقة.",
            "ur": ""
        },
        "* Any dispute or claim arising out of or in connection with this website shall be governed by and construed in accordance with the laws of the Kingdom of Saudi Arabia": {
            "en": "* Any dispute or claim arising out of or in connection with this website shall be governed by and construed in accordance with the laws of the Kingdom of Saudi Arabia",
            "ar": "* يخضع أي نزاع أو مطالبة تنشأ عن أو فيما يتعلق بهذا الموقع الإلكتروني ويتم تفسيره وفقًا لقوانين المملكة العربية السعودية",
            "ur": ""
        },
        "* Saudi Arabia is our country of domicile": {
            "en": "* Saudi Arabia is our country of domicile",
            "ar": "* المملكة العربية السعودية بلد موطننا",
            "ur": ""
        },
        "Jurisdiction": {
            "en": "Jurisdiction",
            "ar": "الاختصاص القضائي",
            "ur": ""
        },
        "* The Saudi judiciary is competent to adjudicate any dispute arising regarding the interpretation or implementation of any clause of this document in accordance with Saudi laws, and in the event that any clause is excluded by a judicial ruling, this does not prejudice the validity of the other clauses and it remains valid and produces its legal effects unless the application cancels the agreement.": {
            "en": "* The Saudi judiciary is competent to adjudicate any dispute arising regarding the interpretation or implementation of any clause of this document in accordance with Saudi laws, and in the event that any clause is excluded by a judicial ruling, this does not prejudice the validity of the other clauses and it remains valid and produces its legal effects unless the application cancels the agreement.",
            "ar": "* يختص القضاء السعودي بالفصل في أي نزاع ينشأ بشأن تفسير أو تنفيذ أي بند من بنود هذه الوثيقة وفق القوانين السعودية، وفي حالة استبعاد أي بند بموجب حكم قضائي فإن ذلك لا يخل بصلاحية البنود الأخرى وتظل سارية ومنتجة لآثارها القانونية ما لم تقم التطبيق بإلغاء الاتفاقية.",
            "ur": ""
        },
        "Arabic Language": {
            "en": "Arabic Language",
            "ar": "اللغة العربية",
            "ur": ""
        },
        "The language of the document is Arabic, and if it is translated into any other language, the Arabic text is applicable before all the official and unofficial bodies if the foreign translation conflicts with it.": {
            "en": "The language of the document is Arabic, and if it is translated into any other language, the Arabic text is applicable before all the official and unofficial bodies if the foreign translation conflicts with it.",
            "ar": "تم صياغة هذه الوثيقة باللغة العربية، وفي حالة ترجمتها لأي لغة أخرى فإن النص العربي هو المعمول به أمام كافة الجهات الرسمية وغير الرسمية إذا تعارضت الترجمة الأجنبية معه.",
            "ur": ""
        },
        "Duration and Withdrawal": {
            "en": "Duration and Withdrawal",
            "ar": "مدة الاتفاقية والانسحاب منها",
            "ur": ""
        },
        "The validity of these terms and conditions will start from the date on which your data is registered via the Tawkeel application or by your use of any of our services that do not require subscription or registration in the application, and they will remain in effect unless terminated in accordance with the items contained in these terms and conditions.": {
            "en": "The validity of these terms and conditions will start from the date on which your data is registered via the Tawkeel application or by your use of any of our services that do not require subscription or registration in the application, and they will remain in effect unless terminated in accordance with the items contained in these terms and conditions.",
            "ar": "يبدأ سريان الشروط والأحكام هذه اعتباراً من تاريخ تسجيل بياناتك عبر تطبيق توكيل أو باستخدامك أي من خدماتنا التي لا تتطلب الاشتراك أو التسجيل في التطبيق وتظل سارية ما لم يتم إنهاؤها وفقاً للبنود الوارد في الشروط والأحكام هذه.",
            "ur": ""
        },
        "Contact Us": {
            "en": "Contact Us",
            "ar": "الاتصال بنا",
            "ur": ""
        },
        "You can contact us through:": {
            "en": "You can contact us through:",
            "ar": "يمكنك التواصل معنا عبر:",
            "ur": ""
        },
        "Email:": {
            "en": "Email:",
            "ar": "البريد الإلكتروني:",
            "ur": ""
        },
        "Call us at the number": {
            "en": "Call us at the number",
            "ar": "اتصل بنا على الرقم",
            "ur": ""
        },
        "Toll Free": {
            "en": "Toll Free",
            "ar": "رقم مجاني",
            "ur": ""
        },







        'Express Consent: Consent is express if the user registers his account on the Tawkeel application, provides services through it or requests to benefit from the services provided through it, and this consent is considered an electronic signature of this agreement.': {
            'en': 'Express Consent: Consent is express if the user registers his account on the Tawkeel application, provides services through it or requests to benefit from the services provided through it, and this consent is considered an electronic signature of this agreement.',
            'ar': 'الموافقة الصريحة: تكون الموافقة صريحة في حالة قيام المستخدم بتسجيل حسابه بتطبيق Tawkeel، أو تقديم الخدمات من خلاله أو طلب الاستفادة من الخدمات المقدمة من خلاله، وهذه الموافقة تعد توقيعًا إلكترونيًا على هذه الاتفاقية.',
            'ur': ''
        },
        'Tacit Consent: Consent is tacit if the user uses or visits the Tawkeel application services or stays on the application for long periods of time, and this type of use is considered an electronic signature of this agreement.': {
            'en': 'Tacit Consent: Consent is tacit if the user uses or visits the Tawkeel application services or stays on the application for long periods of time, and this type of use is considered an electronic signature of this agreement.',
            'ar': 'الموافقة الضمنية: تكون الموافقة ضمنية في حالة قيام المستخدم باستخدام خدمات تطبيق توكيل أو زيارته أو البقاء في التطبيق لفترات طويلة، ويعد هذا النوع من الاستخدام توقيعًا إلكترونيًا على هذه الاتفاقية.',
            'ur': ''
        },
        'The performer can register his account on the application that allows him to have many different features and services. The performer must register on the application with a decent name, and undertakes not to register with any unknown, unreal or misleading names.': {
            'en': 'The performer can register his account on the application that allows him to have many different features and services. The performer must register on the application with a decent name, and undertakes not to register with any unknown, unreal or misleading names.',
            'ar': 'يستطيع المؤدي تسجيل حسابه بالتطبيق الذي يتيح له العديد من المزايا والخدمات المختلفة. ويجب على المؤدي التسجيل في التطبيق باسم لائق، ويتعهد بعدم التسجيل بأي أسماء مجهولة أو غير حقيقية أو مضللة.',
            'ur': ''
        },
        'To register the membership on the application, the performer must provide us with the following data (name, mobile number, e-mail, A bank account in the performer\'s name, a copy of the national ID/ the residence permit, the performer should be signed up on Tawakkalna” application).': {
            'en': 'To register the membership on the application, the performer must provide us with the following data (name, mobile number, e-mail, A bank account in the performer\'s name, a copy of the national ID/ the residence permit, the performer should be signed up on Tawakkalna” application).',
            'ar': 'لتسجيل العضوية بالتطبيق يجب على المؤدي تزويدنا بالبيانات الآتية (الاسم، رقم الجوال، البريد الالكتروني، حساب بنكي باسم المؤدي، صورة الهوية الوطنية/الاقامة، أن يكون المؤدي مسجل في تطبيق “توكلنا واعتمرنا).',
            'ur': ''
        },
        'The performer must register for membership on Tawkeel application with his data and email, so that he can receive any notifications sent to him from Tawkeel application.': {
            'en': 'The performer must register for membership on Tawkeel application with his data and email, so that he can receive any notifications sent to him from Tawkeel application.',
            'ar': 'يجب على المؤدي تسجيل العضوية بتطبيق توكيل ببيانات وبريد الكتروني تابعين له، حتى يتمكن من استلام أي إشعارات مرسلة له من تطبيق توكيل.',
            'ur': ''
        },
        'Any performer is forbidden to register on our application with more than one account and we will delete all accounts if we found that out. The performer under 18 years old shall be prohibited from registering an account with us or in case we have broken down his account before due to the violation of our terms and conditions or the privacy policy.': {
            'en': 'Any performer is forbidden to register on our application with more than one account and we will delete all accounts if we found that out. The performer under 18 years old shall be prohibited from registering an account with us or in case we have broken down his account before due to the violation of our terms and conditions or the privacy policy.',
            'ar': 'يحظر على أي مؤدي التسجيل لدينا بأكثر من حساب وسنقوم بحذف جميع الحسابات في حال اكتشافنا ذلك. كما يحظر على المؤدي تسجيل حساب لدينا إذا كان أقل من 18 عام، أو تم إدانته بجريمة تحرش أو اعتداء جنسي، أو إذا كان مشمول بحظر استخدام خدماتنا بموجب القانون أو حكم قضائي أو كنا قد قمنا بتعطيل حسابه من قبل بسبب انتهاك شروطنا وأحكامنا أو سياسة الخصوصية.',
            'ur': ''
        },
        'The performer shall maintain his username and password, and not disclose them to others, and in all cases the performer is committed to all the transactions made through his account on the application.': {
            'en': 'The performer shall maintain his username and password, and not disclose them to others, and in all cases the performer is committed to all the transactions made through his account on the application.',
            'ar': 'يجب على المؤدي الحفاظ على اسم المستخدم وكلمة المرور الخاصة به، وألا يقوم بالإفصاح عنهم للغير، وفي جميع الأحوال يلتزم المؤدي بكافة التعاملات التي تتم من خلال حسابه بالتطبيق.',
            'ur': ''
        },
        'The application must be used within the purposes declared through these terms or those declared through the pages of the application, and that our services should be used in good faith and not to manipulate the application or its Seekers in any way.': {
            'en': 'The application must be used within the purposes declared through these terms or those declared through the pages of the application, and that our services should be used in good faith and not to manipulate the application or its Seekers in any way.',
            'ar': 'يجب استخدام التطبيق في حدود الأغراض المعلنة من خلال هذه الشروط أو تلك المعلنة عبر صفحات التطبيق، وأن يتم استخدام خدماتنا بحسن نية وعدم التلاعب على التطبيق أو المتعاملين من خلالها بأي صورة من الصور.',
            'ur': ''
        },
        'The application must be used legally and that this use should be serious and the users shouldn\'t use the application for the purposes of fraud, scam, illegal communication, fake communication or harming to any party and that exercising any actions that expose the application to the legal accountability must be forbidden.': {
            'en': 'The application must be used legally and that this use should be serious and the users shouldn\'t use the application for the purposes of fraud, scam, illegal communication, fake communication or harming to any party and that exercising any actions that expose the application to the legal accountability must be forbidden.',
            'ar': 'يجب أن يتم استخدام التطبيق بشكل قانوني، وأن يتسم هذا الاستخدام بالجدية وأن يبتعد المستخدمين عن استخدام التطبيق لأغراض التدليس أو الاحتيال أو التواصل غير المشروع أو التواصل الوهمي أو الإضرار بأي طرف من الأطراف، ويحظر مباشرة أي أعمال من شأنها تعريض التطبيق للمساءلة القانونية.',
            'ur': ''
        },
        'The performer must be a Saudi national or residing in the Kingdom officially, and the performer is obligated to provide us with the national ID card if he is a Saudi or his residence permit if he is residing in the Kingdom, and the residence permit must be valid.': {
            'en': 'The performer must be a Saudi national or residing in the Kingdom officially, and the performer is obligated to provide us with the national ID card if he is a Saudi or his residence permit if he is residing in the Kingdom, and the residence permit must be valid.',
            'ar': 'يجب أن يكون المؤدي سعودي الجنسية أو مقيم بالمملكة بشكل رسمي، ويلتزم المؤدي بتزويدنا بالهوية الوطنية إذا كان سعودي أو الاقامة الخاصة به إذا كان مقيم بالمملكة، ويجب أن تكون الاقامة سارية.',
            'ur': ''
        },
        'The performer should be signed up on (Tawakkalna) application.': {
            'en': 'The performer should be signed up on (Tawakkalna) application.',
            'ar': 'يجب أن يكون المؤدي مسجل في تطبيق (توكلنا واعتمرنا).',
            'ur': ''
        },
        'The performer must be vaccinated with 2 doses of coronavirus vaccines.': {
            'en': 'The performer must be vaccinated with 2 doses of coronavirus vaccines.',
            'ar': 'يجب على المؤدي أن يكون محصن بلقاحات ضد فيروس كورونا بعدد 2 جرعة.',
            'ur': ''
        },
        'The performer must perform the Umrah at the time agreed upon with the Seeker, should devote himself full-time to perform the Umrah, and not to be associated with any other business during this period.': {
            'en': 'The performer must perform the Umrah at the time agreed upon with the Seeker, should devote himself full-time to perform the Umrah, and not to be associated with any other business during this period.',
            'ar': 'يجب على المؤدي تأدية العمرة في الموعد المتفق عليه مع العميل، وأن يتفرغ لأداء العمرة بشكل كامل وألا يكون مرتبط بأي أعمال أخرى خلال هذه الفترة.',
            'ur': ''
        },
        'The performer must perform the Umrah in accordance with the Sunnah and should not commit any Sharia violations, and that the Umrah must be performed with the intent specified by the one who requests the service.': {
            'en': 'The performer must perform the Umrah in accordance with the Sunnah and should not commit any Sharia violations, and that the Umrah must be performed with the intent specified by the one who requests the service.',
            'ar': 'يجب على المؤدي تأدية العمرة وفق السنة النبوية الشريفة وألا يرتكب أي مخالفات شرعية وأن تكون تأدية العمرة بالنية التي يحددها طالب الخدمة.',
            'ur': ''
        },
        'The performer must be in constant contact with the application while performing the Umrah so that the one who requests the service can track the steps and perform the rituals.': {
            'en': 'The performer must be in constant contact with the application while performing the Umrah so that the one who requests the service can track the steps and perform the rituals.',
            'ar': 'يجب أن يكون المؤدي على اتصال دائم بالتطبيق أثناء تأدية العمرة ليتمكن طالب الخدمة من تتبع الخطوات وتأدية المناسك.',
            'ur': ''
        },
        'The performer is prohibited from manipulating the application or the one who requests the service, and that the service is provided in good faith and as agreed with the Seeker.': {
            'en': 'The performer is prohibited from manipulating the application or the one who requests the service, and that the service is provided in good faith and as agreed with the Seeker.',
            'ar': 'يحظر على المؤدي التلاعب على التطبيق أو طالب الخدمة وأن يتم تقديم الخدمة بحسن نية ووفق الاتفاق مع العميل.',
            'ur': ''
        },
        'The performer is obligated to obtain the necessary permits to perform the Umrah requested by the competent authorities in order to avoid that the performer would be fined, and in the event of a violation, the performer shall bear the full fine.': {
            'en': 'The performer is obligated to obtain the necessary permits to perform the Umrah requested by the competent authorities in order to avoid that the performer would be fined, and in the event of a violation, the performer shall bear the full fine.',
            'ar': 'يلتزم المؤدي باستخراج التصاريح اللازمة لأداء العمرة التي تطلبها الجهات المختصة منعًا لفرض أي غرامات على المؤدي، وفي حال المخالفة يتحمل المؤدي الغرامة كاملة.',
            'ur': ''
        },
        'In the event that the performer breaches any obligations stipulated in this agreement, the Sunnah, or any other Sharia provisions, the application and/or the Seeker has the right to cancel the service and not transfer the specified amount for performing the Umrah.': {
            'en': 'In the event that the performer breaches any obligations stipulated in this agreement, the Sunnah, or any other Sharia provisions, the application and/or the Seeker has the right to cancel the service and not transfer the specified amount for performing the Umrah.',
            'ar': 'في حال إخلال المؤدي بأي التزامات منصوص عليها في هذه الاتفاقية أو في السنة النبوية الشريفة أو أي أحكام شرعية أخرى يحق للتطبيق و/أو العميل إلغاء الخدمة وعدم تحويل المبلغ المحدد لأداء العمرة.',
            'ur': ''
        },
        'In the event that the performer commits any statutory violations, he bears full legal responsibility without any responsibility on the application, and the performer releases our legal responsibility for any violations committed by him.': {
            'en': 'In the event that the performer commits any statutory violations, he bears full legal responsibility without any responsibility on the application, and the performer releases our legal responsibility for any violations committed by him.',
            'ar': 'في حال ارتكاب المؤدي لأي مخالفات نظامية فإنه يتحمل المسؤولية القانونية الكاملة دون أدنى مسؤولية على التطبيق، ويخلي المؤدي مسؤوليتنا القانونية عن أي مخالفات يرتكبها.',
            'ur': ''
        },
        'Terms of the Performer of the Umrah Al Badal': {
            'en': 'Terms of the Performer of the Umrah Al Badal',
            'ar': 'شروط مؤدي عمرة البدل',
            'ur': ''
        },
        'The performer shall receive an amount of up to 150 riyals for each completed order, and the order must fulfil all the requirements stipulated in this agreement.': {
            'en': 'The performer shall receive an amount of up to 150 riyals for each completed order, and the order must fulfil all the requirements stipulated in this agreement.',
            'ar': 'سيتلقى المؤدي مبلغًا يصل إلى 150 ريال لكل طلب مكتمل ، ويجب أن يفي الطلب بجميع المتطلبات المنصوص عليها في هذه الاتفاقية.',
            'ur': ''
        },
        'The aforementioned amount of the Umrah is inclusive of all transportation, accommodation, food, clothing and all that the Umrah pilgrim needs to perform the Umrah.': {
            'en': 'The aforementioned amount of the Umrah is inclusive of all transportation, accommodation, food, clothing and all that the Umrah pilgrim needs to perform the Umrah.',
            'ar': 'يكون مبلغ العمرة المذكور شامل كافة الانتقالات والاقامة والمأكل والملبس وكل ما يحتاجه المعتمر لأداء العمرة.',
            'ur': ''
        },
        'The performer must provide us with a bank account in his personal name stated in the national identity or valid residence. If the performer provides us with an account in another person\'s name, the application will not be able to transfer the amount to the performer.': {
            'en': 'The performer must provide us with a bank account in his personal name stated in the national identity or valid residence. If the performer provides us with an account in another person\'s name, the application will not be able to transfer the amount to the performer.',
            'ar': 'يجب على المؤدي تزويدنا بحساب بنكي باسمه الشخصي الثابت بالهوية الوطنية أو الاقامة السارية، فإذا زودنا المؤدي بحساب باسم شخص آخر غيره فلن يتمكن التطبيق من تحويل المبلغ إلى المؤدي.',
            'ur': ''
        },
        'The amount will be transferred to the performer within a maximum period of 15 days from the date the order is properly completed.': {
            'en': 'The amount will be transferred to the performer within a maximum period of 15 days from the date the order is properly completed.',
            'ar': 'يتم تحويل المبلغ للمؤدي خلال مدة أقصاها 15 يوم من تاريخ اكتمال الطلب بالشكل الصحيح.',
            'ur': ''
        },
        'The performer shall bear any fees related to the performance of the service or the transfer of funds, and the application has the right to deduct these fees from the amount before transferring it to the performer.': {
            'en': 'The performer shall bear any fees related to the performance of the service or the transfer of funds, and the application has the right to deduct these fees from the amount before transferring it to the performer.',
            'ar': 'يتحمل المؤدي أي رسوم متعلقة بأداء الخدمة أو تحويل الأموال، ويحق للتطبيق خصم هذه الرسوم من المبلغ قبل تحويله إلى المؤدي.',
            'ur': ''
        },
        'The performer bears full legal responsibility for the transfer data he provides to us, and the application will not be responsible in any way if the transfer method is illegal or in the event that the performer is unable to receive his dues.': {
            'en': 'The performer bears full legal responsibility for the transfer data he provides to us, and the application will not be responsible in any way if the transfer method is illegal or in the event that the performer is unable to receive his dues.',
            'ar': 'يتحمل المؤدي المسؤولية القانونية الكاملة عن بيانات التحويل التي يزودنا بها، والتطبيق لن يكون مسؤول بأي شكل من الأشكال إذا كانت وسيلة التحويل غير قانونية أو في حالة عدم تمكن المؤدي من استلام مستحقاته.',
            'ur': ''
        },
        'Once the Seeker order through the application, he will not be able to cancel that order.': {
            'en': 'Once the Seeker order through the application, he will not be able to cancel that order.',
            'ar': 'بمجرد تقديم العميل للطلب من خلال التطبيق فإنه لن يكون بإمكانه إلغاء الطلب.',
            'ur': ''
        },
        'In the event that the performer cancels the Umrah in violation of the provisions of this agreement and without a legal justification, the application has the right to permanently block his account and the Seeker has the right to claim for compensation.': {
            'en': 'In the event that the performer cancels the Umrah in violation of the provisions of this agreement and without a legal justification, the application has the right to permanently block his account and the Seeker has the right to claim for compensation.',
            'ar': 'في حال قيام المؤدي بإلغاء العمرة بالمخالفة لأحكام هذه الاتفاقية ودون وجود مبرر قانوني، يحق للتطبيق حظر حسابه بشكل دائم كما يحق للعميل مطالبته بالتعويضات.',
            'ur': ''
        },
        'Each performer is personally responsible for all the actions and activities which he carries out through the application, and the application will not be subsidiary or solidarity responsible of any users.': {
            'en': 'Each performer is personally responsible for all the actions and activities which he carries out through the application, and the application will not be subsidiary or solidarity responsible of any users.',
            'ar': 'كل مؤدي مسؤول مسؤولية شخصية عن كافة الأفعال والأنشطة التي يقوم بها من خلال التطبيق، ولن يكون التطبيق مسؤولا بالتضامن أو بالتبعية عن أي من المستخدمين.',
            'ur': ''
        },
        'Each performer is personally responsible in case he does not comply with the commitments imposed on him under these applicable terms or policies.': {
            'en': 'Each performer is personally responsible in case he does not comply with the commitments imposed on him under these applicable terms or policies.',
            'ar': 'كل مؤدي مسؤول مسؤولية شخصية في حالة عدم التزامه بالالتزامات المفروضة عليه بموجب هذه الشروط أو السياسات المطبقة.',
            'ur': ''
        },
        'Each performer is personally responsible in case he violates the laws applicable in the Kingdom of Saudi Arabia or the country to which he belongs, and the application will not be subsidiary or solidarity responsible of any users.': {
            'en': 'Each performer is personally responsible in case he violates the laws applicable in the Kingdom of Saudi Arabia or the country to which he belongs, and the application will not be subsidiary or solidarity responsible of any users.',
            'ar': 'كل مؤدي مسؤول مسؤولية شخصية في حالة إخلاله بالقوانين المعمول بها في المملكة العربية السعودية أو في الدولة التي ينتمي إليها، ولن يكون التطبيق مسؤول بالتضامن أو بالتبعية عن أي من المستخدمين.',
            'ur': ''
        },
        'The performer shall bear the legal responsibility in case that he violates any of our rights under these terms and conditions, attacks on any of our rights, our ownership of the application or any of its elements.': {
            'en': 'The performer shall bear the legal responsibility in case that he violates any of our rights under these terms and conditions, attacks on any of our rights, our ownership of the application or any of its elements.',
            'ar': 'يتحمل المؤدي المسؤولية القانونية في حالة إخلاله بأي حق من حقوقنا بموجب هذه الشروط والأحكام أو الاعتداء على أي حق من حقوقنا أو ملكيتنا للتطبيق أو أي من عناصره.',
            'ur': ''
        },
        'The performer shall bear the legal responsibility in case of offending the application in any website, application, social media, email or through any other means.': {
            'en': 'The performer shall bear the legal responsibility in case of offending the application in any website, application, social media, email or through any other means.',
            'ar': 'يتحمل المؤدي المسؤولية القانونية في حالة الإساءة للتطبيق في أي موقع أو تطبيق أو وسائل تواصل اجتماعي أو عبر البريد الالكتروني أو من خلال أي وسيلة أخرى.',
            'ur': ''
        },
        'The performer shall bear the legal responsibility in case of offending of any other person through the application, without original, subsidiary or joint responsibility on the application.': {
            'en': 'The performer shall bear the legal responsibility in case of offending of any other person through the application, without original, subsidiary or joint responsibility on the application.',
            'ar': 'يتحمل المؤدي المسؤولية القانونية في حالة الإساءة لأي شخص آخر من خلال التطبيق، دون أدنى مسؤولية أصلية أو تبعية أو تضامنية على التطبيق.',
            'ur': ''
        },
        'The performer shall be bound to compensate us for all the damages to the application as a result of any illegitimate or unauthorized use by the user or any of his followers.': {
            'en': 'The performer shall be bound to compensate us for all the damages to the application as a result of any illegitimate or unauthorized use by the user or any of his followers.',
            'ar': 'يلتزم المؤدي بتعويضنا عن كافة الأضرار التي تصيب التطبيق نتيجة أي استخدام غير مشروع أو غير من مفوض من جانب المستخدم أو أي من تابعيه.',
            'ur': ''
        },
        'The performer shall be bound to compensate us in case of offending us, the administration of the application, employees, partners, distributors, agents, our affiliates or any other person who belongs to Tawkeel application.': {
            'en': 'The performer shall be bound to compensate us in case of offending us, the administration of the application, employees, partners, distributors, agents, our affiliates or any other person who belongs to Tawkeel application.',
            'ar': 'يلتزم المؤدي بتعويضنا في حالة الإساءة لنا أو لإدارة التطبيق أو الموظفين أو الشركاء أو الموزعين أو الوكلاء أو التابعين لنا أو أي شخص آخر ينتمي إلى تطبيق توكيل.',
            'ur': ''
        },
        'The performer releases the application\'s responsibility for all errors it makes, whether intentional or unintentional.': {
            'en': 'The performer releases the application\'s responsibility for all errors it makes, whether intentional or unintentional.',
            'ar': 'يخلي المؤدي مسؤولية التطبيق عن كافة الأخطاء التي يرتكبها سواء كانت متعمدة أو غير متعمدة.',
            'ur': ''
        },
        'Any notifications the application wants to notify the users with are made through their own contact details, and the user is supposed to know about with the notification once the application sends it to him. In case that the user wants to send us notifications, this must be done through our e-mail (support@tawkeel.com)': {
            'en': 'Any notifications the application wants to notify the users with are made through their own contact details, and the user is supposed to know about with the notification once the application sends it to him. In case that the user wants to send us notifications, this must be done through our e-mail (support@tawkeel.com)',
            'ar': 'أي إشعارات يرغب التطبيق في إبلاغها للمستخدمين تتم من خلال بيانات الاتصال الخاصة بهم، ويفترض علم المستخدم بالإشعار بمجرد قيام التطبيق بإرساله له. وفي حالة رغبة المستخدم في إرسال الإشعارات لنا يجب أن يتم ذلك من خلال بريدنا الإلكتروني (support@tawkeel.com).',
            'ur': ''
        },
        'The interpretation and implementation of the items of this document is subject to the laws applicable in the Kingdom of Saudi Arabia, and these terms are not limited to the items contained in it, but extend to include all the legal provisions regulating civil and commercial relations applicable in the Kingdom of Saudi Arabia so far as they were a complementary rule and do not directly or indirectly conflict with the items of this document.': {
            'en': 'The interpretation and implementation of the items of this document is subject to the laws applicable in the Kingdom of Saudi Arabia, and these terms are not limited to the items contained in it, but extend to include all the legal provisions regulating civil and commercial relations applicable in the Kingdom of Saudi Arabia so far as they were a complementary rule and do not directly or indirectly conflict with the items of this document.',
            'ar': 'يخضع تفسير وتنفيذ بنود هذه الوثيقة للقوانين المعمول بها في المملكة العربية السعودية، ولا تقتصر هذه الشروط على البنود الواردة فيها وإنما تمتد لتشمل كافة النصوص القانونية المنظمة للعلاقات المدنية والتجارية المعمول بها في المملكة العربية السعودية طالما كانت قواعد مكملة ولا تتعارض بشكل مباشر أو غير مباشر مع البنود الواردة في هذه الوثيقة.',
            'ur': ''
        },
        'The Saudi judiciary shall adjudicate disputes arising about the interpretation or implementation of any item of this document in accordance with the Saudi laws, and in case that any item is excluded by a judicial decision, this is without prejudice to the competence of other items and that the items remain valid and productive for its legal effects unless the application cancels the agreement.': {
            'en': 'The Saudi judiciary shall adjudicate disputes arising about the interpretation or implementation of any item of this document in accordance with the Saudi laws, and in case that any item is excluded by a judicial decision, this is without prejudice to the competence of other items and that the items remain valid and productive for its legal effects unless the application cancels the agreement.',
            'ar': 'يختص القضاء السعودي بالفصل في أي نزاع ينشأ بشأن تفسير أو تنفيذ أي بند من بنود هذه الوثيقة وفق القوانين السعودية، وفي حالة استبعاد أي بند بموجب حكم قضائي فإن ذلك لا يخل بصلاحية البنود الأخرى وتظل سارية ومنتجة لآثارها القانونية ما لم تقم التطبيق بإلغاء الاتفاقية.',
            'ur': ''
        },
        'The performer shall be bound to provide the service to the Seeker in the form advertised through the application, and bears legal responsibility in the event of a breach of this obligation, and the Seeker has the right to recourse against the performer legally before all the competent authorities.': {
            'en': 'The performer shall be bound to provide the service to the Seeker in the form advertised through the application, and bears legal responsibility in the event of a breach of this obligation, and the Seeker has the right to recourse against the performer legally before all the competent authorities.',
            'ar': 'يلتزم مؤدي عمرة البدل بتقديم الخدمة للعميل بالشكل المعلن عنه من خلال التطبيق، ويتحمل المسئولية القانونية في حالة إخلاله بهذا الالتزام، ويحق للعميل الرجوع على مؤدي عمرة البدل قانونًا أمام كافة الجهات المختصة.',
            'ur': ''
        },
        'The performer knows and agrees that the application does not provide any kind of insurance or compensation for any damages to the performer during the implementation of the service, and the performer bears all consequences arising from the implementation of the service, this include releases the application\'s responsibility from all damages, fines, accidents, intentional errors, negligence, death, etc.': {
            'en': 'The performer knows and agrees that the application does not provide any kind of insurance or compensation for any damages to the performer during the implementation of the service, and the performer bears all consequences arising from the implementation of the service, this include releases the application\'s responsibility from all damages, fines, accidents, intentional errors, negligence, death, etc.',
            'ar': 'يعلم المؤدي ويوافق على أن التطبيق لا يقدم أي نوع من أنواع التأمين أو التعويض عن أي أضرار تصيب المؤدي أثناء تنفيذ الخدمة، ويتحمل المؤدي كافة النتائج الناشئة عن تنفيذ الخدمة ويشمل ذلك إخلاء المسؤولية عن كافة الأضرار والغرامات والحوادث والأخطاء المتعمدة والإهمال والوفاة وغير ذلك.',
            'ur': ''
        },
        'Compensations': {
            'en': 'Compensations',
            'ar': 'التعويضات',
            'ur': ''
        },
        "Location Permissions and Usage": {
            "en": "LOCATION PERMISSIONS AND USAGE",
            "ar": "أذونات الموقع والاستخدام",
            "ur": ""
        },
        'Our app requires access to your device’s location in order to track your location during Umrah journeys. We collect both coarse and fine location data to provide accurate tracking and navigation services to help you perform Umrah rituals safely and efficiently.': {
            'en': 'Our app requires access to your device’s location in order to track your location during Umrah journeys. We collect both coarse and fine location data to provide accurate tracking and navigation services to help you perform Umrah rituals safely and efficiently.',
            'ar': '      يتطلب تطبيقنا الوصول إلى موقع جهازك من أجل تتبع موقعك أثناء رحلات العمرة. نقوم بجمع بيانات الموقع الأساسية والغير أساسية لتوفير خدمات تتبع وملاحة دقيقة لمساعدتك على أداء  العمرة بأمان وكفاءة.',
            'ur': ''
        },
        'Why We Need Your Location': {
            'en': 'Why We Need Your Location',
            'ar': 'لماذا يتطلب الوصول الى موقعك ',
            'ur': ''
        },
        'Umrah Tracking: We use your location data to track your movements during Umrah journeys, including your current position, route, and points of interest. This information is essential for providing real-time navigation, guidance, and assistance to help you complete your Umrah rituals.': {
            'en': 'Umrah Tracking: We use your location data to track your movements during Umrah journeys, including your current position, route, and points of interest. This information is essential for providing real-time navigation, guidance, and assistance to help you complete your Umrah rituals.',
            'ar': 'تتبع العمرة: نستخدم بيانات موقعك لتتبع تحركاتك أثناء رحلات العمرة ، بما في ذلك موقعك الحالي وطريقك ونقاط اهتمامك. هذه المعلومات ضرورية لتوفير التنقل والتوجيه والمساعدة في الوقت الفعلي لمساعدتك على إكمال العمرة.',
            'ur': ''
        },
        'How We Use Your Location': {
            'en': 'How We Use Your Location',
            'ar': 'لماذا نستخدم موقعك ',
            'ur': ''
        },
        'Navigation: Your location data is used to provide turn-by-turn navigation, route planning, and guidance throughout your Umrah journey. We utilize mapping and GPS technologies to display your current location on maps and provide directions to various landmarks, holy sites, and facilities.': {
            'en': 'Navigation: Your location data is used to provide turn-by-turn navigation, route planning, and guidance throughout your Umrah journey. We utilize mapping and GPS technologies to display your current location on maps and provide directions to various landmarks, holy sites, and facilities.',
            'ar': 'الملاحة: يتم استخدام بيانات الموقع الخاص بك لتوفير بدوره عن طريق بدوره الملاحة ، وتخطيط الطريق ، والتوجيه طوال رحلة العمرة الخاص بك. نحن نستخدم تقنيات رسم الخرائط ونظام تحديد المواقع العالمي لعرض موقعك الحالي على الخرائط وتوفير الاتجاهات إلى مختلف المعالم والأماكن المقدسة والمرافق.',
            'ur': ''
        },
        'Safety and Assistance: In case of emergencies or unforeseen circumstances, your location data enables us to provide immediate assistance, support, and guidance to ensure your safety and well-being during Umrah rituals.': {
            'en': 'Safety and Assistance: In case of emergencies or unforeseen circumstances, your location data enables us to provide immediate assistance, support, and guidance to ensure your safety and well-being during Umrah rituals.',
            'ar': 'السلامة والمساعدة: في حالات الطوارئ أو الظروف غير المتوقعة ، تمكننا بيانات موقعك من تقديم المساعدة والدعم والتوجيه الفوري لضمان سلامتك ورفاهيتك أثناء العمرة.',
            'ur': ''
        },
        'Sharing of Location Data': {
            'en': 'Sharing of Location Data',
            'ar': 'مشاركة بيانات الموقع',
            'ur': ''
        },
        'Third-party Services: We do not share your location data with third-party service providers, such as mapping and navigation platforms. Although we only share the location with the seeker who ordered the umrah and wants to track the umrah performer.': {
            'en': 'Third-party Services: We do not share your location data with third-party service providers, such as mapping and navigation platforms. Although we only share the location with the seeker who ordered the umrah and wants to track the umrah performer.',
            'ar': 'خدمات الجهات الخارجية: لا نشارك بيانات موقعك مع مزودي خدمات الجهات الخارجية ، مثل منصات الخرائط والملاحة. على الرغم من أننا نشارك الموقع فقط مع الباحث الذي أمر بالعمرة ويريد تتبع أداء العمرة.',
            'ur': ''
        },
        'Your Privacy Rights': {
            'en': 'Your Privacy Rights',
            'ar': 'حقوق الخصوصية الخاصة بك',
            'ur': ''
        },
        'Opt-out Options: You have the right to opt-out of location tracking at any time by adjusting the app’s settings or disabling location permissions on your device. However, please note that disabling location tracking may limit the functionality of certain features within the app.': {
            'en': 'Opt-out Options: You have the right to opt-out of location tracking at any time by adjusting the app’s settings or disabling location permissions on your device. However, please note that disabling location tracking may limit the functionality of certain features within the app.',
            'ar': 'خيارات إلغاء الاشتراك: يحق لك إلغاء الاشتراك في تتبع الموقع في أي وقت عن طريق ضبط إعدادات التطبيق أو تعطيل أذونات الموقع على جهازك. ومع ذلك ، يرجى ملاحظة أن تعطيل تتبع الموقع قد يحد من وظائف بعض الميزات داخل التطبيق.',
            'ur': ''
        },
    }
    return translations[text] ? translations[text][language] : text;
}
export default _T;