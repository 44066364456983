let countries = [
    {
        name: "Afghanistan",
        code: "AF",
        currency: "AFN",
        dialling_code: "+93"
    },
    {
        name: "Albania",
        code: "AL",
        currency: "ALL",
        dialling_code: "+355"
    },
    {
        name: "Algeria",
        code: "DZ",
        currency: "DZD",
        dialling_code: "+213"
    },
    {
        name: "American Samoa",
        code: "AS",
        currency: "USD",
        dialling_code: "+1"
    },
    {
        name: "Andorra",
        code: "AD",
        currency: "EUR",
        dialling_code: "+376"
    },
    {
        name: "Angola",
        code: "AO",
        currency: "AOA",
        dialling_code: "+244"
    },
    {
        name: "Anguilla",
        code: "AI",
        currency: "XCD",
        dialling_code: "+43"
    },
    {
        name: "Antigua and Barbuda",
        code: "AG",
        currency: "XCD",
        dialling_code: "+1"
    },
    {
        name: "Argentina",
        code: "AR",
        currency: "ARS",
        dialling_code: "+54"
    },
    {
        name: "Armenia",
        code: "AM",
        currency: "AMD",
        dialling_code: "+374"
    },
    {
        name: "Aruba",
        code: "AW",
        currency: "AWG",
        dialling_code: "+297"
    },
    {
        name: "Australia",
        code: "AU",
        currency: "AUD",
        dialling_code: "+61"
    },
    {
        name: "Azerbaijan",
        code: "AZ",
        currency: "AZN",
        dialling_code: "+994"
    },
    {
        name: "Bahamas",
        code: "BS",
        currency: "BSD",
        dialling_code: "+1"
    },
    {
        name: "Bahrain",
        code: "BH",
        currency: "BHD",
        dialling_code: "+973"
    },
    {
        name: "Bangladesh",
        code: "BD",
        currency: "BDT",
        dialling_code: "+880"
    },
    {
        name: "Barbados",
        code: "BB",
        currency: "BBD",
        dialling_code: "+1"
    },
    {
        name: "Belarus",
        code: "BY",
        currency: "BYN",
        dialling_code: "+375"
    },
    {
        name: "Belgium",
        code: "BE",
        currency: "EUR",
        dialling_code: "+32"
    },
    {
        name: "Belize",
        code: "BZ",
        currency: "BZD",
        dialling_code: "+501"
    },
    {
        name: "Benin",
        code: "BJ",
        currency: "XOF",
        dialling_code: "+229"
    },
    {
        name: "Bermuda",
        code: "BM",
        currency: "BMD",
        dialling_code: "+1"
    },
    {
        name: "Bhutan",
        code: "BT",
        currency: "BTN",
        dialling_code: "+975"
    },
    {
        name: "Bolivia",
        code: "BO",
        currency: "BOB",
        dialling_code: "+591"
    },
    {
        name: "Bosnia and Herzegovina",
        code: "BA",
        currency: "BAM",
        dialling_code: "+387"
    },
    {
        name: "Botswana",
        code: "BW",
        currency: "BWP",
        dialling_code: "+267"
    },
    {
        name: "Brazil",
        code: "BR",
        currency: "BRL",
        dialling_code: "+55"
    },
    {
        name: "British Indian Ocean Territory",
        code: "IO",
        currency: "USD",
        dialling_code: "+246"
    },
    {
        name: "Virgin Islands (British)",
        code: "VG",
        currency: "USD",
        dialling_code: "+1"
    },
    {
        name: "Virgin Islands (U.S.)",
        code: "VI",
        currency: "USD",
        dialling_code: "+1"
    },
    {
        name: "Brunei Darussalam",
        code: "BN",
        currency: "BND",
        dialling_code: "+673"
    },
    {
        name: "Bulgaria",
        code: "BG",
        currency: "BGN",
        dialling_code: "+359"
    },
    {
        name: "Burkina Faso",
        code: "BF",
        currency: "XOF",
        dialling_code: "+226"
    },
    {
        name: "Burundi",
        code: "BI",
        currency: "BIF",
        dialling_code: "+257"
    },
    {
        name: "Cambodia",
        code: "KH",
        currency: "KHR",
        dialling_code: "+855"
    },
    {
        name: "Cameroon",
        code: "CM",
        currency: "XAF",
        dialling_code: "+237"
    },
    {
        name: "Canada",
        code: "CA",
        currency: "CAD",
        dialling_code: "+1"
    },
    {
        name: "Cabo Verde",
        code: "CV",
        currency: "CVE",
        dialling_code: "+238"
    },
    {
        name: "Cayman Islands",
        code: "KY",
        demonym: "Caymanian",
        currency: "KYD",
        dialling_code: "+1"
    },
    {
        name: "Central African Republic",
        code: "CF",
        currency: "XAF",
        dialling_code: "+236"
    },
    {
        name: "Chile",
        code: "CL",
        currency: "CLP",
        dialling_code: "+56"
    },
    {
        name: "China",
        code: "CN",
        currency: "CNY",
        dialling_code: "+86"
    },
    {
        name: "Colombia",
        code: "CO",
        currency: "COP",
        dialling_code: "+57"
    },
    {
        name: "Comoros",
        code: "KM",
        currency: "KMF",
        dialling_code: "+269"
    },
    {
        name: "Congo",
        code: "CG",
        currency: "XAF",
        dialling_code: "+242"
    },
    {
        name: "Congo",
        code: "CD",
        currency: "CDF",
        dialling_code: "+243"
    },
    {
        name: "Cook Islands",
        code: "CK",
        currency: "NZD",
        dialling_code: "+682"
    },
    {
        name: "Costa Rica",
        code: "CR",
        currency: "CRC",
        dialling_code: "+506"
    },
    {
        name: "Croatia",
        code: "HR",
        currency: "HRK",
        dialling_code: "+385"
    },
    {
        name: "Cuba",
        code: "CU",
        currency: "CUC",
        dialling_code: "+53"
    },
    {
        name: "Cuba",
        code: "CU",
        currency: "CUC",
        dialling_code: "+53"
    },
    {
        name: "Cyprus",
        code: "CY",
        currency: "EUR",
        dialling_code: "+357"
    },
    {
        name: "Czech Republic",
        code: "CZ",
        currency: "CZK",
        dialling_code: "+420"
    },
    {
        name: "Denmark",
        code: "DK",
        currency: "DKK",
        dialling_code: "+45"
    },
    {
        name: "Djibouti",
        code: "DJ",
        currency: "DJF",
        dialling_code: "+253"
    },
    {
        name: "Dominica",
        code: "DM",
        currency: "XCD",
        dialling_code: "+1"
    },
    {
        name: "Dominican Republic",
        code: "DO",
        currency: "DOP",
        dialling_code: "+1"
    },
    {
        name: "Ecuador",
        code: "EC",
        currency: "USD",
        dialling_code: "+593"
    },
    {
        name: "Egypt",
        code: "EG",
        currency: "EGP",
        dialling_code: "+20"
    },
    {
        name: "El Salvador",
        code: "SV",
        currency: "USD",
        dialling_code: "+503"
    },
    {
        name: "Equatorial Guinea",
        code: "GQ",
        currency: "XAF",
        dialling_code: "+240"
    },
    {
        name: "Eritrea",
        code: "ER",
        currency: "ERN",
        dialling_code: "+291"
    },
    {
        name: "Estonia",
        code: "EE",
        currency: "EUR",
        dialling_code: "+372"
    },
    {
        name: "Ethiopia",
        code: "ET",
        currency: "ETB",
        dialling_code: "+251"
    },
    {
        name: "Falkland Islands",
        code: "FK",
        currency: "FKP",
        dialling_code: "+500"
    },
    {
        name: "Faroe Islands",
        code: "FO",
        currency: "DKK",
        dialling_code: "+298"
    },
    {
        name: "Fiji",
        code: "FJ",
        currency: "FJD",
        dialling_code: "+679"
    },
    {
        name: "Finland",
        code: "FI",
        currency: "EUR",
        dialling_code: "+358"
    },
    {
        name: "France",
        code: "FR",
        demonym: "French",
        currency: "EUR",
        dialling_code: "+33"
    },
    {
        name: "French Guiana",
        code: "GF",
        currency: "EUR",
        dialling_code: "+594"
    },
    {
        name: "French Polynesia",
        code: "PF",
        currency: "XPF",
        dialling_code: "+689"
    },
    {
        name: "Gabon",
        code: "GA",
        currency: "XAF",
        dialling_code: "+241"
    },
    {
        name: "Gambia",
        code: "GM",
        currency: "GMD",
        dialling_code: "+220"
    },
    {
        name: "Georgia",
        code: "GE",
        currency: "GEL",
        dialling_code: "+995"
    },
    {
        name: "Germany",
        code: "DE",
        currency: "EUR",
        dialling_code: "+49"
    },
    {
        name: "Ghana",
        code: "GH",
        currency: "GHS",
        dialling_code: "+233"
    },
    {
        name: "Gibraltar",
        code: "GI",
        currency: "GIP",
        dialling_code: "+350"
    },
    {
        name: "Greece",
        code: "GR",
        currency: "EUR",
        dialling_code: "+30"
    },
    {
        name: "Greenland",
        code: "GL",
        currency: "DKK",
        dialling_code: "+299"
    },
    {
        name: "Grenada",
        code: "GD",
        currency: "XCD",
        dialling_code: "+1"
    },
    {
        name: "Guadeloupe",
        code: "GP",
        currency: "EUR",
        dialling_code: "+590"
    },
    {
        name: "Guam",
        code: "GU",
        currency: "USD",
        dialling_code: "+1"
    },
    {
        name: "Guatemala",
        code: "GT",
        currency: "GTQ",
        dialling_code: "+502"
    },
    {
        name: "Guinea",
        code: "GN",
        currency: "GNF",
        dialling_code: "+224"
    },
    {
        name: "Guinea-Bissau",
        code: "GW",
        currency: "XOF",
        dialling_code: "+245"
    },
    {
        name: "Guyana",
        code: "GY",
        currency: "GYD",
        dialling_code: "+592"
    },
    {
        name: "Haiti",
        code: "HT",
        currency: "HTG",
        dialling_code: "+509"
    },
    {
        name: "Holy See",
        code: "VA",
        currency: "EUR",
        dialling_code: "+39"
    },
    {
        name: "Honduras",
        code: "HN",
        currency: "HNL",
        dialling_code: "+504"
    },
    {
        name: "Hong Kong",
        code: "HK",
        currency: "HKD",
        dialling_code: "+852"
    },
    {
        name: "Hungary",
        code: "HU",
        currency: "HUF",
        dialling_code: "+36"
    },
    {
        name: "Iceland",
        code: "IS",
        currency: "ISK",
        dialling_code: "+354"
    },
    {
        name: "India",
        code: "IN",
        currency: "INR",
        dialling_code: "+91"
    },
    {
        name: "Indonesia",
        code: "ID",
        currency: "IDR",
        dialling_code: "+62"
    },
    {
        name: "Côte d'Ivoire",
        code: "CI",
        currency: "XOF",
        dialling_code: "+225"
    },
    {
        name: "Iran",
        code: "IR",
        currency: "IRR",
        dialling_code: "+98"
    },
    {
        name: "Iraq",
        code: "IQ",
        currency: "IQD",
        dialling_code: "+964"
    },
    {
        name: "Ireland",
        code: "IE",
        currency: "EUR",
        dialling_code: "+353"
    },
    {
        name: "Italy",
        code: "IT",
        currency: "EUR",
        dialling_code: "+39"
    },
    {
        name: "Jamaica",
        code: "JM",
        currency: "JMD",
        dialling_code: "+1"
    },
    {
        name: "Japan",
        code: "JP",
        currency: "JPY",
        dialling_code: "+81"
    },
    {
        name: "Jordan",
        code: "JO",
        currency: "JOD",
        dialling_code: "+962"
    },
    {
        name: "Kazakhstan",
        code: "KZ",
        currency: "KZT",
        dialling_code: "+7"
    },
    {
        name: "Kenya",
        code: "KE",
        currency: "KES",
        dialling_code: "+254"
    },
    {
        name: "Kiribati",
        code: "KI",
        currency: "AUD",
        dialling_code: "+686"
    },
    {
        name: "Kuwait",
        code: "KW",
        currency: "KWD",
        dialling_code: "+965"
    },
    {
        name: "Kyrgyzstan",
        code: "KG",
        currency: "KGS",
        dialling_code: "+996"
    },
    {
        name: "Lao",
        code: "LA",
        currency: "LAK",
        dialling_code: "+856"
    },
    {
        name: "Latvia",
        code: "LV",
        currency: "EUR",
        dialling_code: "+371"
    },
    {
        name: "Lebanon",
        code: "LB",
        currency: "LBP",
        dialling_code: "+961"
    },
    {
        name: "Lesotho",
        code: "LS",
        currency: "LSL",
        dialling_code: "+266"
    },
    {
        name: "Liberia",
        code: "LR",
        currency: "LRD",
        dialling_code: "+231"
    },
    {
        name: "Libya",
        code: "LY",
        currency: "LYD",
        dialling_code: "+218"
    },
    {
        name: "Liechtenstein",
        code: "LI",
        currency: "CHF",
        dialling_code: "+423"
    },
    {
        name: "Lithuania",
        code: "LT",
        currency: "EUR",
        dialling_code: "+370"
    },
    {
        name: "Luxembourg",
        code: "LU",
        currency: "EUR",
        dialling_code: "+352"
    },
    {
        name: "Macao",
        code: "MO",
        currency: "MOP",
        dialling_code: "+853"
    },
    {
        name: "Macedonia",
        code: "MK",
        currency: "MKD",
        dialling_code: "+389"
    },
    {
        name: "Madagascar",
        code: "MG",
        currency: "MGA",
        dialling_code: "+261"
    },
    {
        name: "Malawi",
        code: "MW",
        currency: "MWK",
        dialling_code: "+265"
    },
    {
        name: "Malaysia",
        code: "MY",
        currency: "MYR",
        dialling_code: "+60"
    },
    {
        name: "Maldives",
        code: "MV",
        currency: "MVR",
        dialling_code: "+960"
    },
    {
        name: "Mali",
        code: "ML",
        currency: "XOF",
        dialling_code: "+223"
    },
    {
        name: "Malta",
        code: "MT",
        currency: "EUR",
        dialling_code: "+356"
    },
    {
        name: "Marshall Islands",
        code: "MH",
        currency: "USD",
        dialling_code: "+692"
    },
    {
        name: "Martinique",
        code: "MQ",
        currency: "EUR",
        dialling_code: "+596"
    },
    {
        name: "Mauritania",
        code: "MR",
        currency: "MRO",
        dialling_code: "+222"
    },
    {
        name: "Mauritius",
        code: "MU",
        currency: "MUR",
        dialling_code: "+230"
    },
    {
        name: "Mayotte",
        code: "YT",
        currency: "EUR",
        dialling_code: "+262"
    },
    {
        name: "Mexico",
        code: "MX",
        currency: "MXN",
        dialling_code: "+52"
    },
    {
        name: "Micronesia",
        code: "FM",
        currency: "USD",
        dialling_code: "+691"
    },
    {
        name: "Moldova",
        code: "MD",
        currency: "MDL",
        dialling_code: "+373"
    },
    {
        name: "Monaco",
        code: "MC",
        currency: "EUR",
        dialling_code: "+377"
    },
    {
        name: "Mongolia",
        code: "MN",
        currency: "MNT",
        dialling_code: "+976"
    },
    {
        name: "Montenegro",
        code: "ME",
        currency: "EUR",
        dialling_code: "+382"
    },
    {
        name: "Montserrat",
        code: "MS",
        currency: "XCD",
        dialling_code: "+1"
    },
    {
        name: "Morocco",
        code: "MA",
        currency: "MAD",
        dialling_code: "+212"
    },
    {
        name: "Mozambique",
        code: "MZ",
        currency: "MZN",
        dialling_code: "+258"
    },
    {
        name: "Myanmar",
        code: "MM",
        currency: "MMK",
        dialling_code: "+95"
    },
    {
        name: "Namibia",
        code: "NA",
        currency: "NAD",
        dialling_code: "+264"
    },
    {
        name: "Nauru",
        code: "NR",
        currency: "AUD",
        dialling_code: "+674"
    },
    {
        name: "Nepal",
        code: "NP",
        currency: "NPR",
        dialling_code: "+977"
    },
    {
        name: "Netherlands",
        code: "NL",
        currency: "EUR",
        dialling_code: "+31"
    },
    {
        name: "New Caledonia",
        code: "NC",
        currency: "XPF",
        dialling_code: "+687"
    },
    {
        name: "New Zealand",
        code: "NZ",
        currency: "NZD",
        dialling_code: "+64"
    },
    {
        name: "Nicaragua",
        code: "NI",
        currency: "NIO",
        dialling_code: "+505"
    },
    {
        name: "Niger",
        code: "NE",
        currency: "XOF",
        dialling_code: "+227"
    },
    {
        name: "Nigeria",
        code: "NG",
        currency: "NGN",
        dialling_code: "+234"
    },
    {
        name: "Niue",
        code: "NU",
        currency: "NZD",
        dialling_code: "+683"
    },
    {
        name: "Norfolk Island",
        code: "NF",
        currency: "AUD",
        dialling_code: "+672"
    },
    {
        name: "North Korea",
        code: "KP",
        currency: "KPW",
        dialling_code: "+850"
    },
    {
        name: "Northern Mariana Islands",
        code: "MP",
        currency: "USD",
        dialling_code: "+1"
    },
    {
        name: "Norway",
        code: "NO",
        currency: "NOK",
        dialling_code: "+47"
    },
    {
        name: "Oman",
        code: "OM",
        currency: "OMR",
        dialling_code: "+968"
    },
    {
        name: "Pakistan",
        code: "PK",
        currency: "PKR",
        dialling_code: "+92"
    },
    {
        name: "Palau",
        code: "PW",
        currency: "USD",
        dialling_code: "+680"
    },
    {
        name: "Palestine",
        code: "PS",
        currency: "ILS",
        dialling_code: "+970"
    },
    {
        name: "Panama",
        code: "PA",
        currency: "USD",
        dialling_code: "+507"
    },
    {
        name: "Papua New Guinea",
        code: "PG",
        currency: "PGK",
        dialling_code: "+675"
    },
    {
        name: "Paraguay",
        code: "PY",
        currency: "PYG",
        dialling_code: "+595"
    },
    {
        name: "Peru",
        code: "PE",
        currency: "PEN",
        dialling_code: "+51"
    },
    {
        name: "Philippines",
        code: "PH",
        currency: "PHP",
        dialling_code: "+63"
    },
    {
        name: "Poland",
        code: "PL",
        currency: "PLN",
        dialling_code: "+48"
    },
    {
        name: "Portugal",
        code: "PT",
        currency: "EUR",
        dialling_code: "+351"
    },
    {
        name: "Puerto Rico",
        code: "PR",
        currency: "USD",
        dialling_code: "+1"
    },
    {
        name: "Qatar",
        code: "QA",
        currency: "QAR",
        dialling_code: "+974"
    },
    {
        name: "Republic of Kosovo",
        code: "XK",
        currency: "EUR",
        dialling_code: "+381"
    },
    {
        name: "Réunion",
        code: "RE",
        currency: "EUR",
        dialling_code: "+262"
    },
    {
        name: "Romania",
        code: "RO",
        currency: "RON",
        dialling_code: "+40"
    },
    {
        name: "Russian Federation",
        code: "RU",
        currency: "RUB",
        dialling_code: "+7"
    },
    {
        name: "Rwanda",
        code: "RW",
        currency: "RWF",
        dialling_code: "+250"
    },
    {
        name: "Saint Barthélemy",
        code: "BL",
        currency: "EUR",
        dialling_code: "+590"
    },
    {
        name: "Saint Helena",
        code: "SH",
        currency: "SHP",
        dialling_code: "+290"
    },
    {
        name: "Saint Kitts and Nevis",
        code: "KN",
        currency: "XCD",
        dialling_code: "+1"
    },
    {
        name: "Saint Lucia",
        code: "LC",
        currency: "XCD",
        dialling_code: "+1"
    },
    {
        name: "Saint Martin",
        code: "MF",
        currency: "EUR",
        dialling_code: "+590"
    },
    {
        name: "Saint Pierre and Miquelon",
        code: "PM",
        currency: "EUR",
        dialling_code: "+508"
    },
    {
        name: "Saint Vincent and the Grenadines",
        code: "VC",
        currency: "XCD",
        dialling_code: "+1"
    },
    {
        name: "Samoa",
        code: "WS",
        currency: "WST",
        dialling_code: "+685"
    },
    {
        name: "San Marino",
        code: "SM",
        currency: "EUR",
        dialling_code: "+378"
    },
    {
        name: "Sao Tome and Principe",
        code: "ST",
        currency: "STD",
        dialling_code: "+239"
    },
    {
        name: "Saudi Arabia",
        code: "SA",
        currency: "SAR",
        dialling_code: "+966"
    },
    {
        name: "Senegal",
        code: "SN",
        currency: "XOF",
        dialling_code: "+221"
    },
    {
        name: "Serbia",
        code: "RS",
        currency: "RSD",
        dialling_code: "+381"
    },
    {
        name: "Seychelles",
        code: "SC",
        currency: "SCR",
        dialling_code: "+248"
    },
    {
        name: "Sierra Leone",
        code: "SL",
        currency: "SLL",
        dialling_code: "+232"
    },
    {
        name: "Singapore",
        code: "SG",
        currency: "SGD",
        dialling_code: "+65"
    },
    {
        name: "Singapore",
        code: "SG",
        currency: "SGD",
        dialling_code: "+65"
    },
    {
        name: "Slovakia",
        code: "SK",
        currency: "EUR",
        dialling_code: "+421"
    },
    {
        name: "Slovenia",
        code: "SI",
        currency: "EUR",
        dialling_code: "+386"
    },
    {
        name: "Solomon Islands",
        code: "SB",
        currency: "SBD",
        dialling_code: "+677"
    },
    {
        name: "Somalia",
        code: "SO",
        currency: "SOS",
        dialling_code: "+252"
    },
    {
        name: "South Africa",
        code: "ZA",
        currency: "ZAR",
        dialling_code: "+27"
    },
    {
        name: "South Korea",
        code: "KR",
        currency: "KRW",
        dialling_code: "+82"
    },
    {
        name: "Spain",
        code: "ES",
        currency: "EUR",
        dialling_code: "+34"
    },
    {
        name: "Sri Lanka",
        code: "LK",
        currency: "LKR",
        dialling_code: "+94"
    },
    {
        name: "Sudan",
        code: "SD",
        currency: "SDG",
        dialling_code: "+249"
    },
    {
        name: "Suriname",
        code: "SR",
        currency: "SRD",
        dialling_code: "+597"
    },
    {
        name: "Swaziland",
        code: "SZ",
        currency: "SZL",
        dialling_code: "+268"
    },
    {
        name: "Sweden",
        code: "SE",
        currency: "SEK",
        dialling_code: "+46"
    },
    {
        name: "Switzerland",
        code: "CH",
        currency: "CHF",
        dialling_code: "+41"
    },
    {
        name: "Syrian Arab Republic",
        code: "SY",
        currency: "SYP",
        dialling_code: "+963"
    },
    {
        name: "Taiwan",
        code: "TW",
        currency: "TWD",
        dialling_code: "+886"
    },
    {
        name: "Tajikistan",
        code: "TJ",
        currency: "TJS",
        dialling_code: "+992"
    },
    {
        name: "Tanzania",
        code: "TZ",
        currency: "TZS",
        dialling_code: "+255"
    },
    {
        name: "Thailand",
        code: "TH",
        currency: "THB",
        dialling_code: "+66"
    },
    {
        name: "Timor-Leste",
        code: "TL",
        currency: "USD",
        dialling_code: "+670"
    },
    {
        name: "Togo",
        code: "TG",
        currency: "XOF",
        dialling_code: "+228"
    },
    {
        name: "Tokelau",
        code: "TK",
        currency: "NZD",
        dialling_code: "+690"
    },
    {
        name: "Tonga",
        code: "TO",
        currency: "TOP",
        dialling_code: "+676"
    },
    {
        name: "Trinidad and Tobago",
        code: "TT",
        currency: "TTD",
        dialling_code: "+1"
    },
    {
        name: "Tunisia",
        code: "TN",
        currency: "TND",
        dialling_code: "+216"
    },
    {
        name: "Turkey",
        code: "TR",
        currency: "TRY",
        dialling_code: "+90"
    },
    {
        name: "Turkmenistan",
        code: "TM",
        currency: "TMT",
        dialling_code: "+993"
    },
    {
        name: "Turks and Caicos Islands",
        code: "TC",
        currency: "USD",
        dialling_code: "+1"
    },
    {
        name: "Tuvalu",
        code: "TV",
        currency: "AUD",
        dialling_code: "+688"
    },
    {
        name: "Uganda",
        code: "UG",
        currency: "UGX",
        dialling_code: "+256"
    },
    {
        name: "Ukraine",
        code: "UA",
        currency: "UAH",
        dialling_code: "+380"
    },
    {
        name: "United Arab Emirates",
        code: "AE",
        currency: "AED",
        dialling_code: "+971"
    },
    {
        name: "United Kingdom",
        code: "GB",
        currency: "GBP",
        dialling_code: "+44"
    },
    {
        name: "United States of America",
        code: "US",
        currency: "USD",
        dialling_code: "+1"
    },
    {
        name: "Uruguay",
        code: "UY",
        currency: "UYU",
        dialling_code: "+598"
    },
    {
        name: "Uzbekistan",
        code: "UZ",
        currency: "UZS",
        dialling_code: "+998"
    },
    {
        name: "Vanuatu",
        code: "VU",
        currency: "VUV",
        dialling_code: "+678"
    },
    {
        name: "Venezuela",
        code: "VE",
        currency: "VEF",
        dialling_code: "+58"
    },
    {
        name: "Viet Nam",
        code: "VN",
        currency: "VND",
        dialling_code: "+84"
    },
    {
        name: "Wallis and Futuna",
        code: "WF",
        currency: "XPF",
        dialling_code: "+681"
    },
    {
        name: "Wallis and Futuna",
        code: "WF",
        currency: "XPF",
        dialling_code: "+681"
    },
    {
        name: "Yemen",
        code: "YE",
        currency: "YER",
        dialling_code: "+967"
    },
    {
        name: "Zambia",
        code: "ZM",
        currency: "ZMW",
        dialling_code: "+260"
    },
    {
        name: "Zimbabwe",
        code: "ZW",
        currency: "BWP",
        dialling_code: "+263"
    },
    {
        name: "Benin",
        code: "BEN",
        dialling_code: "+229",
        currency: "XOF"
    },
    {
        name: "Chad",
        code: "TD",
        dialling_code: "+235",
        currency: "XAF"
    },
    {
        name: "Austria",
        code: "AT",
        dialling_code: "+43",
        currency: "EUR"
    }
]

export { countries };