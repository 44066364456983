import React from 'react'
import { Link } from 'react-router-dom'
import helpCenterHero from '../../assets/images/help-center-image.png'
import mainLogo from "../../assets/images/main-logo.png"
import _T from '../../locale/QuickRegister/translator';

function section_scroll(e, id) {
    e.preventDefault();
    let scrollDiv = document.getElementById(id).offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
}
function Hero() {
    return (
        <section id='helpCenterHome'>
            <div className='wrapper helpCenterHome-wrapper'>
                <div className='section-left'>
                    <img src={mainLogo} alt="Tawkeel" />
                    <h1 className='primary-heading ibm-plex-sans-arabic-bold'>{_T("Tawkeel")}</h1>
                    <h2 className='sub-heading'>{_T("Quick Register")}</h2>
                    <p className='text-paragraph'>{_T("Register as a seeker agency and provide Tawkeel service to people around the world")}</p>
                    <div className='btn-group'>
                        <a href='#' onClick={(e) => { section_scroll(e, 'quickRegister') }} className='btn btn-primary'>{_T("Register Now")}</a>
                    </div>
                </div>
                <div className='section-right'>
                    {/* <img src={helpCenterHero} /> */}
                </div>
            </div>
        </section>
    )
}

export default Hero;