import React from 'react'
import aboutScreenshot from "../../assets/images/about-screenshot.png"
import mainLogo from "../../assets/images/main-logo.png"
import _T from '../../locale/Home/translator';


function About({ language }) {
    function section_scroll(e, id) {
        e.preventDefault();
        let scrollDiv = document.getElementById(id).offsetTop;
        window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
    }
    return (
        <section id='about'>
            <div className='wrapper about-wrapper'>
                <div className="overlay"></div>
                <div className="section-left">
                    <div className="about-logo text-center">
                        <img src={mainLogo} alt="Tawkeel" />
                    </div>
                    <h2 className='secondary-heading ibm-plex-sans-arabic-bold'>{_T("What is Tawkeel?")}</h2>
                    <p className='text-paragraph mb-4'>{_T('The application provides the service of "Umrah Al-Badal" by appointing qualified, trusted, and authorized performers from Nafath to perform Umrah for Muslims who are unable to perform it themselves, officially and reliably, and under full supervision.')}</p>
                    <a className="btn btn-download" href='#' onClick={(e) => { section_scroll(e, 'home') }}>{_T("Download App")}</a>
                </div>
                <div className="section-right">
                    <img src={aboutScreenshot} alt="App Screenshot" />
                </div>
            </div>
        </section>
    )
}

export default About;