import React from 'react'
import {Link} from 'react-router-dom'
import helpCenterHero from '../../assets/images/privacy-policy.png'
import mainLogo from "../../assets/images/main-logo.png"
import _T from '../../locale/PrivacyPolicy/translator';

function Hero({heading,language}){
    return (
        <section id='privacyPolicyHome'>
            <div className='wrapper privacyPolicyHome-wrapper'>
                <div className='section-left'>
                    <img src={mainLogo} alt="Tawkeel" />
                    <h1 className='primary-heading'>{heading}</h1>
                    <p className='text-paragraph'>{ _T("Please read the privacy policy for use as per your specified user role.") }</p>
                    <div className='btn-group hero-btn-group'>
                        <Link to={language.text === "English" ? "/en/privacy-policy/performer" : "/privacy-policy/performer"} className='btn btn-primary-home mt-1'>{ _T("For Performer")}</Link>
                        <Link to={language.text === "English" ? "/en/privacy-policy/seeker" : "/privacy-policy/seeker"} className='btn btn-primary-home mt-1'>{ _T("For Seeker")}</Link>
                    </div>
                </div>
                <div className='section-right'>
                    {/* <img src={helpCenterHero} alt="Privacy Policy"/> */}
                </div>
            </div>
        </section>
    )
}

export default Hero;