function _T(text){
    const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
    const translations = {
        "Privacy Policy": {
            "en": "Privacy Policy",
            "ar": "سياسة الخصوصية",
            "ur": ""
        },
        "Please read the privacy policy for use as per your specified user role.": {
            "en": "Please read the privacy policy for use as per your specified user role.",
            "ar": "يرجى قراءة سياسة الخصوصية للاستخدام بحسب نوع المستخدم",
            "ur": ""
        },
        "Tawkeel": {
            "en": "Tawkeel",
            "ar": "توكيل",
            "ur": ""
        },
        "For Performer": {
            "en": "For Performer",
            "ar": "للمؤدين",
            "ur": ""
        },
        "For Seeker": {
            "en": "For Seeker",
            "ar": "لطالب العمرة",
            "ur": ""
        },
        "Performer Privacy Policy": {
            "en": "Performer Privacy Policy",
            "ar": " سياسة الخصوصية المؤدي ",
            "ur": ""
        },
        "Introduction": {
            "en": "Introduction",
            "ar": "مقدمة",
            "ur": ""
        },
        "Tawkeel application represented Tawkeel Commercial Services Company in Kingdom of Saudi Arabia always seeks to maintain the privacy of your personal data, and we are complying with the provisions of the Data Protection and the right to Privacy Laws regarding the protection of your personal data, and we have formulated this “Privacy Policy” to show you the nature of the data collected about you, and the purposes for which this data is used, so please read this policy carefully.": {
            "en": "Tawkeel application represented Tawkeel Commercial Services Company in Kingdom of Saudi Arabia always seeks to maintain the privacy of your personal data, and we are complying with the provisions of the Data Protection and the right to Privacy Laws regarding the protection of your personal data, and we have formulated this “Privacy Policy” to show you the nature of the data collected about you, and the purposes for which this data is used, so please read this policy carefully.",
            "ar": 'يسعى تطبيق توكيل الذي يمثل شركة توكيل للخدمات التجارية في المملكة العربية السعودية دائمًا إلى الحفاظ على خصوصية بياناتك الشخصية ، ونحن نلتزم بأحكام حماية البيانات والحق في قوانين الخصوصية فيما يتعلق بحماية بياناتك الشخصية ، و لقد قمنا بصياغة "سياسة الخصوصية" هذه لنبين لك طبيعة البيانات التي تم جمعها عنك ، والأغراض التي تستخدم من أجلها هذه البيانات ، لذا يرجى قراءة هذه السياسة بعناية.',
            "ur": ""
        },
        "The term data refers to all the data relating to the users of Tawkeel application, which includes their name, address, contact details and any other data that identify the user as well as other data related to the user's activities through the application and data related to the devices used in the process of accessing the application.": {
            "en": "The term data refers to all the data relating to the users of Tawkeel application, which includes their name, address, contact details and any other data that identify the user as well as other data related to the user's activities through the application and data related to the devices used in the process of accessing the application.",
            "ar": "يشير مصطلح البيانات إلى جميع البيانات المتعلقة بمستخدمي تطبيق توصيل ، والتي تشمل الاسم والعنوان وتفاصيل الاتصال وأي بيانات أخرى تحدد هوية المستخدم بالإضافة إلى البيانات الأخرى المتعلقة بأنشطة المستخدم من خلال التطبيق والبيانات المتعلقة به. الأجهزة المستخدمة في عملية الوصول إلى التطبيق.",
            "ur": ""
        },
        "1. Personal data we collect": {
            "en": "1. Personal data we collect",
            "ar": "1. البيانات الشخصية التي نجمعها",
            "ur": ""
        },
        "We collect the data provided by the performer to register his account on the application which includes (the first name, the last name, the email, the country choice and mobile number).": {
            "en": "We collect the data provided by the performer to register his account on the application which includes (the first name, the last name, the email, the country choice and mobile number).",
            "ar": "نقوم بجمع البيانات المقدمة من قبل المؤدي لتسجيل حسابه في التطبيق والتي تشمل (الاسم الأول ، والاسم الأخير ، والبريد الإلكتروني ، واختيار الدولة ، ورقم الهاتف المحمول).",
            "ur": ""
        },
        "Data that you provide by filling in forms on the application. This includes information provided at the time of registration or at the time of posting material or requesting further services.": {
            "en": "Data that you provide by filling in forms on the application. This includes information provided at the time of registration or at the time of posting material or requesting further services.",
            "ar": "البيانات التي تقدمها من خلال ملء النماذج في الطلب. يتضمن ذلك المعلومات المقدمة في وقت التسجيل أو في وقت نشر المواد أو طلب المزيد من الخدمات.",
            "ur": ""
        },
        "The data added by the user to his personal file and the content published by him via the application.": {
            "en": "The data added by the user to his personal file and the content published by him via the application.",
            "ar": "البيانات التي أضافها المستخدم إلى ملفه الشخصي والمحتوى المنشور من قبله عبر التطبيق.",
            "ur": ""
        },
        "The content of the services provided by the performer including all the information, data and messages.": {
            "en": "The content of the services provided by the performer including all the information, data and messages.",
            "ar": "محتوى الخدمات التي يقدمها المؤدي بما في ذلك جميع المعلومات والبيانات والرسائل.",
            "ur": ""
        },
        "A copy of the national ID card if the performer is a Saudi national, and a copy of the residence permit of the non-Saudi resident.": {
            "en": "A copy of the national ID card if the performer is a Saudi national, and a copy of the residence permit of the non-Saudi resident.",
            "ar": "صورة من بطاقة الهوية الوطنية إذا كان المؤدي سعودي الجنسية وصورة من إقامة المقيم غير السعودي.",
            "ur": ""
        },
        "The bank account data that the performer provides to us in order for the application to transfer the dues through it, and which must be in the performer's name.": {
            "en": "The bank account data that the performer provides to us in order for the application to transfer the dues through it, and which must be in the performer's name.",
            "ar": "بيانات الحساب البنكي التي يقدمها لنا المؤدي ليقوم التطبيق بتحويل المستحقات من خلاله والتي يجب أن تكون باسم المؤدي.",
            "ur": ""
        },
        "The data of the performer recorded in “Tawakkalna” application as a condition for providing services through the application.": {
            "en": "The data of the performer recorded in “Tawakkalna” application as a condition for providing services through the application.",
            "ar": 'بيانات المؤدي المسجلة في تطبيق "توكلنا" كشرط لتقديم الخدمات من خلال التطبيق.',
            "ur": ""
        },
        "The data of ordering the service, which includes (the application fee, the name of the performer, the client, the date of the Umrah, the cost of performing the Umrah, the date the order is opened, the date the order is completed).": {
            "en": "The data of ordering the service, which includes (the application fee, the name of the performer, the client, the date of the Umrah, the cost of performing the Umrah, the date the order is opened, the date the order is completed).",
            "ar": "بيانات طلب الخدمة والتي تشمل (رسوم الطلب ، اسم المؤدي ، العميل ، تاريخ العمرة ، تكلفة أداء العمرة ، تاريخ فتح الأمر ، تاريخ إتمام الطلب) .",
            "ur": ""
        },
        "Locate the performer during the implementation of the service, this includes communicate through the application and operate the GPS technology.": {
            "en": "Locate the performer during the implementation of the service, this includes communicate through the application and operate the GPS technology.",
            "ar": "حدد مكان المؤدي أثناء تنفيذ الخدمة ، وهذا يشمل التواصل من خلال التطبيق وتشغيل تقنية GPS.",
            "ur": ""
        },
        "The data you provide us with when you report a problem in the application.": {
            "en": "The data you provide us with when you report a problem in the application.",
            "ar": "البيانات التي تزودنا بها عند الإبلاغ عن مشكلة في التطبيق.",
            "ur": ""
        },
        "The data you provide us with when you communicate with us or the customer service for any reason.": {
            "en": "The data you provide us with when you communicate with us or the customer service for any reason.",
            "ar": "البيانات التي تزودنا بها عند تواصلك معنا أو مع خدمة العملاء لأي سبب من الأسباب.",
            "ur": ""
        },
        "The data you provide us with when you evaluate the services you received via the application.": {
            "en": "The data you provide us with when you evaluate the services you received via the application.",
            "ar": "البيانات التي تزودنا بها عند تقييم الخدمات التي تلقيتها عبر التطبيق.",
            "ur": ""
        },
        "The user shall grant us a non-exclusive, unlimited and irrevocable license to use his personal information submitted by him in the registration form online for the purposes of providing services, updating them or notifying him of matters connected with providing the services of the application.": {
            "en": "The user shall grant us a non-exclusive, unlimited and irrevocable license to use his personal information submitted by him in the registration form online for the purposes of providing services, updating them or notifying him of matters connected with providing the services of the application.",
            "ar": "يمنحنا المستخدم ترخيصًا غير حصري وغير محدود وغير قابل للإلغاء لاستخدام معلوماته الشخصية التي قدمها في نموذج التسجيل عبر الإنترنت لأغراض تقديم الخدمات أو تحديثها أو إخطاره بالأمور المتعلقة بتقديم خدمات التطبيق.",
            "ur": ""
        },
        "2. Content": {
            "en": "2. Content",
            "ar": "2. المحتوى",
            "ur": ""
        },
        "The performer provides us with the content of messages, orders and comments through the application at his personal responsibility.": {
            "en": "The performer provides us with the content of messages, orders and comments through the application at his personal responsibility.",
            "ar": "يزودنا المؤدي بمحتوى الرسائل والطلبات والتعليقات من خلال التطبيق على مسئوليته الشخصية.",
            "ur": ""
        },
        "The performer provides us with the content of the messages he sends to the application to inquire about the content of the services.": {
            "en": "The performer provides us with the content of the messages he sends to the application to inquire about the content of the services.",
            "ar": "يزودنا المؤدي بمحتوى الرسائل التي يقوم بإرسالها إلى التطبيق للاستفسار عن محتوى الخدمات. ",
            "ur": ""
        },
        "We collect the data you provide to us to report on comments or problems that violate the terms and policies of Tawkeel application.": {
            "en": "We collect the data you provide to us to report on comments or problems that violate the terms and policies of Tawkeel application.",
            "ar": "نقوم بجمع البيانات التي تزودنا بها للإبلاغ عن التعليقات أو المشاكل التي تنتهك شروط وسياسات تطبيق توكيل. ",
            "ur": ""
        },
        "3. Data of Contact": {
            "en": "3. Data of Contact",
            "ar": "3. بيانات الاتصال",
            "ur": ""
        },
        "The performer provides us with his number and his contact details when he contacts us through the application, whether via the application, email or phone.": {
            "en": "The performer provides us with his number and his contact details when he contacts us through the application, whether via the application, email or phone.",
            "ar": "يزودنا المؤدي برقم وبيانات الاتصال به، عندما يقوم بالاتصال بنا من خلال التطبيق سواء عبر التطبيق أو عبر البريد الالكتروني أو عبر الهاتف.",
            "ur": ""
        },
        "We allow the performer to communicate with the application, in this case the customer provides us with the following information (name, email, subject of the message, content of the message).": {
            "en": "We allow the performer to communicate with the application, in this case the customer provides us with the following information (name, email, subject of the message, content of the message).",
            "ar": "نتيح للمؤدي التواصل مع التطبيق، وفي هذه الحالة يقدم لنا العميل المعلومات الآتية (الاسم، البريد الالكتروني، موضوع الرسالة، محتوى الرسالة).",
            "ur": ""
        },
        "We collect the data you provide to us while communicating with us to inquire about any of our services or to encounter the problems that facing your use of the application.": {
            "en": "We collect the data you provide to us while communicating with us to inquire about any of our services or to encounter the problems that facing your use of the application.",
            "ar": "نجمع البيانات التي تقدمها لنا أثناء التواصل معنا للاستفسار عن أي من خدماتنا أو مواجهة المشكلات التي تواجه استخدامك للتطبيق.",
            "ur": ""
        },
        "4. Data that are collected automatically": {
            "en": "4. Data that are collected automatically",
            "ar": "4. البيانات التي يتم جمعها تلقائيًا",
            "ur": ""
        },
        "Tawkeel application collects some data for the users, which does not identify the user or its contact data, and is automatically collected, which includes, for example (data of the devices used in contacts, time of contact, its duration, the location of contact, IP address, pages visited, advertisement categories that the user target).": {
            "en": "Tawkeel application collects some data for the users, which does not identify the user or its contact data, and is automatically collected, which includes, for example (data of the devices used in contacts, time of contact, its duration, the location of contact, IP address, pages visited, advertisement categories that the user target).",
            "ar": "يجمع تطبيق توصيل بعض البيانات للمستخدمين ، والتي لا تحدد هوية المستخدم أو بيانات الاتصال الخاصة به ، ويتم جمعها تلقائيًا والتي تشمل على سبيل المثال (بيانات الأجهزة المستخدمة في جهات الاتصال ، ووقت الاتصال ، ومدتها ، وموقع الاتصال ، عنوان IP ، الصفحات التي تمت زيارتها ، فئات الإعلان التي يستهدفها المستخدم).",
            "ur": ""
        },
        "5. Cookies Policy": {
            "en": "5. Cookies Policy",
            "ar": "5. ملفات تعريف الارتباط (سياسة الكوكيز)",
            "ur": ""
        },
        "TAWKEEL application adopts the cookies technology, which are small files stored within the hard disk of your computer. When you visit specific web pages from our application, the cookies identify your browser through a unique and random number without disclosing any personal information about you. The cookies help us to improve your experience of using the application and finding out which sections have the largest share of visit from our application.": {
            "en": "TAWKEEL application adopts the cookies technology, which are small files stored within the hard disk of your computer. When you visit specific web pages from our application, the cookies identify your browser through a unique and random number without disclosing any personal information about you. The cookies help us to improve your experience of using the application and finding out which sections have the largest share of visit from our application.",
            "ar": "يعتمد تطبيق Tawkeel على تقنية ملفات تعريف الارتباط ، وهي ملفات صغيرة مخزنة داخل القرص الصلب لجهاز الكمبيوتر الخاص بك. عندما تزور صفحات ويب معينة من تطبيقنا ، تحدد ملفات تعريف الارتباط متصفحك من خلال رقم فريد وعشوائي دون الكشف عن أي معلومات شخصية عنك. تساعدنا ملفات تعريف الارتباط على تحسين تجربتك في استخدام التطبيق ومعرفة الأقسام التي لها أكبر حصة من الزيارة من تطبيقنا.",
            "ur": ""
        },
        "6. Third Parties": {
            "en": "6. Third Parties",
            "ar": "6. الأطراف الثالثة",
            "ur": ""
        },
        "The application provides links to other websites, and by clicking on them you will be redirected to these websites.": {
            "en": "The application provides links to other websites, and by clicking on them you will be redirected to these websites.",
            "ar": "يوفر التطبيق روابط لمواقع أخرى ، وبالضغط عليها سيتم إعادة توجيهك إلى هذه المواقع.",
            "ur": ""
        },
        "Terms and conditions of third parties or merchants may be applied to the user, and these are not subject to our control and therefore the user must access to and agree on these policies before benefiting from the services or products provided by the third parties or merchants.": {
            "en": "Terms and conditions of third parties or merchants may be applied to the user, and these are not subject to our control and therefore the user must access to and agree on these policies before benefiting from the services or products provided by the third parties or merchants.",
            "ar": "يجوز تطبيق شروط وأحكام الجهات الخارجية أو التجار على المستخدم ، وهي لا تخضع لسيطرتنا ، وبالتالي يجب على المستخدم الوصول إلى هذه السياسات والموافقة عليها قبل الاستفادة من الخدمات أو المنتجات التي تقدمها الأطراف الثالثة أو التجار.",
            "ur": ""
        },
        "7. Purposes of Collecting Data": {
            "en": "7. Purposes of Collecting Data",
            "ar": "7. أغراض جمع البيانات",
            "ur": ""
        },
        "We collect data to register your account on the application, so that you can use our services and the various features we offer.": {
            "en": "We collect data to register your account on the application, so that you can use our services and the various features we offer.",
            "ar": "نجمع البيانات لتسجيل حسابك بالتطبيق، لتتمكن من استخدام خدماتنا والمزايا المختلفة التي نوفرها.",
            "ur": ""
        },
        "We collect data to enable the performer to communicate with the one who requests the service through the application to agree on performing Umrah.": {
            "en": "We collect data to enable the performer to communicate with the one who requests the service through the application to agree on performing Umrah.",
            "ar": "نجمع البيانات لتمكين المؤدي من التواصل مع طالب الخدمة عبر التطبيق للاتفاق حول تأدية العمرة.",
            "ur": ""
        },
        "We collect data to open and process orders through the application and to complete orders when the performer has completed the agreed service.": {
            "en": "We collect data to open and process orders through the application and to complete orders when the performer has completed the agreed service.",
            "ar": "نجمع البيانات لفتح الطلبات من خلال التطبيق ومعالجتها وإكمال الطلبات عند انتهاء المؤدي من تنفيذ الخدمة المتفق عليها.",
            "ur": ""
        },
        "We collect data to track the steps of the performer during the implementation of the service and the rituals of the Umrah so that the client can follow up the performer while performing the rituals.": {
            "en": "We collect data to track the steps of the performer during the implementation of the service and the rituals of the Umrah so that the client can follow up the performer while performing the rituals.",
            "ar": "نجمع البيانات لتتبع خطوات المؤدي أثناء تنفيذ الخدمة وتأدية مناسك العمرة ليقوم العميل بمتابعة تأدية المناسك من جانب المؤدي.",
            "ur": ""
        },
        "We collect data to decide the complaints submitted by the customer and this includes having access to the content of the provided service and all the correspondence done between the customer and the performer.": {
            "en": "We collect data to decide the complaints submitted by the customer and this includes having access to the content of the provided service and all the correspondence done between the customer and the performer.",
            "ar": "نجمع البيانات للبت في التظلمات المقدمة من العميل ويشمل ذلك الاطلاع على محتوى الخدمة المقدمة وكافة المراسلات التي تمت بين العميل والمؤدي.",
            "ur": ""
        },
        "We collect the data to transfer the performer's receivables in accordance with our terms and conditions.": {
            "en": "We collect the data to transfer the performer's receivables in accordance with our terms and conditions.",
            "ar": "نجمع البيانات لتحويل مستحقات المؤدي وفقًا للشروط والأحكام الخاصة بنا.",
            "ur": ""
        },
        "We collect your data to enable you to add comments through the application.": {
            "en": "We collect your data to enable you to add comments through the application.",
            "ar": "نجمع بياناتك لتمكينك من إضافة التعليقات من خلال التطبيق.",
            "ur": ""
        },
        "We collect your data for the purposes of documenting the contact details you have provided us with, and verifying your possession of them.": {
            "en": "We collect your data for the purposes of documenting the contact details you have provided us with, and verifying your possession of them.",
            "ar": "نجمع بياناتك لأغراض توثيق بيانات الاتصال التي قمت بتزويدنا بها والتحقق من حيازتك لها.",
            "ur": ""
        },
        "We collect your data to enable you to assess the services you get through the application.": {
            "en": "We collect your data to enable you to assess the services you get through the application.",
            "ar": "نجمع بياناتك لتمكينك من تقييم الخدمات التي تحصل عليها من خلال التطبيق.",
            "ur": ""
        },
        "We collect your data to enable you to contact us and provide replies to inquiries you have provided to us through the application.": {
            "en": "We collect your data to enable you to contact us and provide replies to inquiries you have provided to us through the application.",
            "ar": "نجمع بياناتك لتمكينك من الاتصال بنا وتقديم الردود على الاستفسارات التي قدمتها لنا من خلال التطبيق.",
            "ur": ""
        },
        "We collect your data to individualize the content and the advertisements for you through the application.": {
            "en": "We collect your data to individualize the content and the advertisements for you through the application.",
            "ar": "نجمع بياناتك لتخصيص المحتوى والإعلانات لك من خلال التطبيق.",
            "ur": ""
        },
        "We collect your data to nominate the advertisements and the products that we believe are of interest to you.": {
            "en": "We collect your data to nominate the advertisements and the products that we believe are of interest to you.",
            "ar": "نجمع بياناتك لترشيح الإعلانات والمنتجات التي نعتقد أنها تثير اهتمامك.",
            "ur": ""
        },
        "We collect your data for statistical and internal work purposes on the application.": {
            "en": "We collect your data for statistical and internal work purposes on the application.",
            "ar": "نجمع بياناتك للأغراض الإحصائية وأغراض العمل الداخلي بالتطبيق.",
            "ur": ""
        },
        "We collect your data for law enforcement purposes or public authority decisions.": {
            "en": "We collect your data for law enforcement purposes or public authority decisions.",
            "ar": "نجمع بياناتك لأغراض تنفيذ القانون أو قرارات السلطة العامة.",
            "ur": ""
        },
        "8. Limits of Data Disclosure": {
            "en": "8. Limits of Data Disclosure",
            "ar": "8. حدود الكشف عن بياناتك",
            "ur": ""
        },
        "We disclose your data to our partners on the application, employees, those who work with us and clients for the purposes of implementing the services of Tawkeel application.": {
            "en": "We disclose your data to our partners on the application, employees, those who work with us and clients for the purposes of implementing the services of Tawkeel application.",
            "ar": "نكشف عن بياناتك لشركائنا في التطبيق والموظفين والعاملين لدينا والعملاء لأغراض تنفيذ الخدمات خاصة بتطبيق توكيل",
            "ur": ""
        },
        "We disclose your data to comply with the provisions of the law and, to execute the judicial decisions and the decisions of the competent authorities in the Kingdom.": {
            "en": "We disclose your data to comply with the provisions of the law and, to execute the judicial decisions and the decisions of the competent authorities in the Kingdom.",
            "ar": "نكشف عن بياناتك للالتزام بأحكام القانون وتنفيذًا للأحكام القضائية وقرارات الجهات المختصة بالمملكة.",
            "ur": ""
        },
        "We disclose your data to implement our terms, conditions and any other agreement between you and the application.": {
            "en": "We disclose your data to implement our terms, conditions and any other agreement between you and the application.",
            "ar": "نكشف عن بياناتك لتنفيذ شروطنا وأحكامنا وأي اتفاق آخر بين التطبيق وبينك.",
            "ur": ""
        },
        "We may disclose your data to our co-providers as electronic payment providers for the purposes of verifying the validity of these transactions.": {
            "en": "We may disclose your data to our co-providers as electronic payment providers for the purposes of verifying the validity of these transactions.",
            "ar": "قد نكشف عن بياناتك للجهات التي تشاركنا تقديم الخدمات كجهات الدفع الالكتروني وذلك لأغراض التأكد من صحة هذه العمليات.",
            "ur": ""
        },
        "9. Storage of The Data": {
            "en": "9. Storage of The Data",
            "ar": "9. تخزين البيانات",
            "ur": ""
        },
        "We application all your data on fully protected and secure servers to protect your data from any hacking or piracy, and the storage of your data may require its transferring outside the Kingdom of Saudi Arabia in case the application utilizes a hosting service provider. Therefore, you authorize us to deal with others regarding your data and authorize us to transfer and store it on our servers.": {
            "en": "We application all your data on fully protected and secure servers to protect your data from any hacking or piracy, and the storage of your data may require its transferring outside the Kingdom of Saudi Arabia in case the application utilizes a hosting service provider. Therefore, you authorize us to deal with others regarding your data and authorize us to transfer and store it on our servers.",
            "ar": "نقوم بتخزين كافة بياناتك على خوادم محمية بشكل كامل ومؤمنة لحماية بياناتك من أي اختراق أو عملية قرصنة، وقد يتطلب تخزين بياناتك نقلها خارج المملكة العربية السعودية في حالة استعانة التطبيق بمزود خدمة استضافة خارجي، لذلك فأنت تفوضنا بالتعامل مع الآخرين بشأن بياناتك كما تفوضنا في نقلها وتخزينها على الخوادم التي نتعامل معها.",
            "ur": ""
        },
        "10. Integrity of The Data": {
            "en": "10. Integrity of The Data",
            "ar": "10. سلامة البيانات",
            "ur": ""
        },
        "You know that the Internet is not a 100% safe and secure mean, so our databases may be hacked into even though we have taken proper data protection procedures. From the perspective of our respect for your right to protect your data we will notify you within 3 days of the time we know about the hacking.": {
            "en": "You know that the Internet is not a 100% safe and secure mean, so our databases may be hacked into even though we have taken proper data protection procedures. From the perspective of our respect for your right to protect your data we will notify you within 3 days of the time we know about the hacking.",
            "ar": "أنت تعلم أن الانترنت ليس وسيلة آمنة ومضمونة بنسبة 100%، لذلك قد يحدث اختراق لقواعد بياناتنا رغم اتخاذنا الإجراءات السليمة لحماية البيانات، ومن منطلق احترامنا لحقك في حماية بياناتك سوف نقوم بإخطارك خلال ثلاثة أيام من وقت علمنا بالاختراق.",
            "ur": ""
        },
        "11. Non-disclosure Agreement": {
            "en": "11. Non-disclosure Agreement",
            "ar": "11. اتفاقية عدم الإفصاح",
            "ur": ""
        },
        "The parties to this agreement shall be bound to maintain the confidentiality and privacy of their personal data and they declare and shall be personally liable that the disclosure by any of them of the personal data to the other party is without any intervention by us and he shall be liable for that and we shall not incur any liability.": {
            "en": "The parties to this agreement shall be bound to maintain the confidentiality and privacy of their personal data and they declare and shall be personally liable that the disclosure by any of them of the personal data to the other party is without any intervention by us and he shall be liable for that and we shall not incur any liability.",
            "ar": "يلتزم أطراف هذه الاتفاقية بالمحافظة على سرية وخصوصية بياناتهم الشخصية، ويقرون وعلى مسئوليتهم الشخصية بأن إفصاح أيًا منهم عن البيانات الشخصية للطرف الآخر يكون على مسئوليته دون أي تدخل منا، ودون أي مسئولية علينا.",
            "ur": ""
        },
        "The parties shall be bound not to disclose any personal data through the use of messages within the application and they shall be personally liable for any disclosure of this kind.": {
            "en": "The parties shall be bound not to disclose any personal data through the use of messages within the application and they shall be personally liable for any disclosure of this kind.",
            "ar": "يلتزم الأطراف بعدم الإفصاح عن أي بيانات شخصية خلال استخدام خاصية الرسائل داخل التطبيق، وأنهم مسئولون مسئولية شخصية عن أي إفصاح من هذا النوع.",
            "ur": ""
        },
        "You shall be bound to maintain the confidentiality of all the data connected with the details of the transactions via the application and not to use it in whichever form and you shall be legally liable and liable in contract for any illegal use or not permitted in relation to this data.": {
            "en": "You shall be bound to maintain the confidentiality of all the data connected with the details of the transactions via the application and not to use it in whichever form and you shall be legally liable and liable in contract for any illegal use or not permitted in relation to this data.",
            "ar": "الالتزام بالمحافظة على سرية كافة البيانات المتعلقة بتفاصيل المعاملات عبر التطبيق، وعدم استخدامها بأي شكل كان، وتعد مسئولاً مسئولية عقدية وقانونية عن أي استخدام غير مشروع أو غير مصرح به لهذه البيانات.",
            "ur": ""
        },
        "The parties undertake to maintain the secrets of the application and each of them shall be liable to us for any breach of the confidentiality of the site and its processes.": {
            "en": "The parties undertake to maintain the secrets of the application and each of them shall be liable to us for any breach of the confidentiality of the site and its processes.",
            "ar": "يتعهد الأطراف بالمحافظة على أسرار التطبيق، ويُسأل كلاً منهم في مواجهتنا عن أي إخلال بسرية الموقع وعملياته.",
            "ur": ""
        },
        "You shall be bound to abstain from disclosing any data connected with the operating of the application to third parties whether for free or not.": {
            "en": "You shall be bound to abstain from disclosing any data connected with the operating of the application to third parties whether for free or not.",
            "ar": "عدم الإفصاح عن أية بيانات متعلقة بتشغيل التطبيق إلى الغير سواء بمقابل أو بدون مقابل",
            "ur": ""
        },
        "You declare that you avoid the following of any means that helps the collection of data about the other users including e-mails, the mobile numbers and their other means of telecommunications.": {
            "en": "You declare that you avoid the following of any means that helps the collection of data about the other users including e-mails, the mobile numbers and their other means of telecommunications.",
            "ar": "الاقرار بعدم إتباع أي وسيلة من شأنها أن تساعد على جمع البيانات عن المستخدمين الآخرين، بما في ذلك البريد الالكتروني وأرقام الجوال ووسائل الاتصال الأخرى الخاصة بهم.",
            "ur": ""
        },
        "12. App Permissions Policies": {
            "en": "12. App Permissions Policies",
            "ar": "12. سياسات أذونات التطبيق",
            "ur": ""
        },
        "We are getting User locations to check for their Country. If they are from Saudia then we are hiding the Seeker account option from them. Then we are getting the location from the Performer side only when we are tracking the Umrah service. Not every time. This is a compulsory feature for our App because Seekers will not be able to see the location of Performer.": {
            "en": "We are getting User locations to check for their Country. If they are from Saudia then we are hiding the Seeker account option from them. Then we are getting the location from the Performer side only when we are tracking the Umrah service. Not every time. This is a compulsory feature for our App because Seekers will not be able to see the location of Performer.",
            "ar": "نحصل على مواقع المستخدمين للتحقق من بلدهم. إذا كانوا من السعودية ، فنحن نخفي خيار حساب الباحث عنهم. ثم نحصل على الموقع من جانب المؤدي فقط عندما نتتبع خدمة العمرة. ليس في كل مرة. هذه ميزة إلزامية لتطبيقنا لأن الباحثين لن يتمكنوا من رؤية موقع المؤدي",
            "ur": ""
        },
        "Gallery: We need gallery permission to allow Performer to upload their ID card, or change the Profile Picture": {
            "en": "Gallery: We need gallery permission to allow Performer to upload their ID card, or change the Profile Picture",
            "ar": "معرض نحن بحاجة إلى إذن المعرض للسماح المؤدي بتحميل بطاقة الهوية الخاصة بهم ، أو تغيير صورة الملف الشخصي",
            "ur": ""
        },
        "Camera: We need gallery permission to allow Performer to directly upload the picture of their haircut from their camera because it is necessary to make sure they have the hair cut for the rituals": {
            "en": "Camera: We need gallery permission to allow Performer to directly upload the picture of their haircut from their camera because it is necessary to make sure they have the hair cut for the rituals",
            "ar": "الكاميرا: نحتاج إلى إذن السماح للوصول الى الكاميرا ليتمكن المؤدين بتحميل صورة قص شعرهم مباشرةً من الكاميرا لأنه من الضروري التأكد من قص شعرهم ليتم حل الاحرام",
            "ur": ""
        },
        "Microphone: We need access to a microphone to allow users to send voice messages": {
            "en": "Microphone: We need access to a microphone to allow users to send voice messages",
            "ar": " الميكروفون نحتاج إلى الوصول إلى ميكروفون للسماح للمستخدمين بإرسال رسائل صوتية",
            "ur": ""
        },
        "Wake lock: We need Wake Lock permissions to allow user to not have the app locked while they are performing the ritual": {
            "en": "Wake lock: We need Wake Lock permissions to allow user to not have the app locked while they are performing the ritual",
            "ar": " قفل التنبيه نحتاج إلى أذونات Wake Lock للسماح للمستخدم بعدم قفل التطبيق أثناء أداء الطقوس ",
            "ur": ""
        },
        "Internet: We need Internet Permission to allow the performer to upload their location data So that seekers can see what has tasks have they completed on their side when performing ritual": {
            "en": "Internet: We need Internet Permission to allow the performer to upload their location data So that seekers can see what has tasks have they completed on their side when performing ritual",
            "ar": "الإنترنت: نحتاج إلى إذن الإنترنت للسماح للمؤدين بتحميل بيانات الموقع الخاصة بهم حتى يتمكن طالبين العمرة من معرفة المهام التي أكملوها من جانبهم عند أداء المناسك",
            "ur": ""
        },
        "13. Request Data Deletion": {
            "en": "13. Request Data Deletion",
            "ar": "13. طلب حذف البيانات",
            "ur": ""
        },
        "If you wish to have your personal data removed from our application, please contact us at support@tawkeel.com. Please also make sure to mention your email address or mobile number which was used to create your account.": {
            "en": "If you wish to have your personal data removed from our application, please contact us at support@tawkeel.com. Please also make sure to mention your email address or mobile number which was used to create your account.",
            "ar": "إذا كنت ترغب في إزالة بياناتك الشخصية من تطبيقنا ، فيرجى الاتصال بنا على support@tawkeel.com. يرجى أيضًا التأكد من ذكر عنوان بريدك الإلكتروني أو رقم هاتفك المحمول الذي تم استخدامه لإنشاء حسابك.",
            "ur": ""
        },
        "We will make every reasonable effort to remove your personal data in a timely manner, subject to any legal or regulatory requirements that may apply. Please note that we may need to retain certain personal data for a reasonable period of time to comply with our legal or regulatory obligations, or for legitimate business purposes such as resolving disputes or enforcing our agreements.": {
            "en": "We will make every reasonable effort to remove your personal data in a timely manner, subject to any legal or regulatory requirements that may apply. Please note that we may need to retain certain personal data for a reasonable period of time to comply with our legal or regulatory obligations, or for legitimate business purposes such as resolving disputes or enforcing our agreements.",
            "ar": "سنبذل قصارى جهدنا لإزالة بياناتك الشخصية في الوقت المناسب ، مع مراعاة أي متطلبات قانونية أو تنظيمية قد تنطبق. يرجى ملاحظة أننا قد نحتاج إلى الاحتفاظ ببيانات شخصية معينة لفترة زمنية معقولة للامتثال لالتزاماتنا القانونية أو التنظيمية ، أو لأغراض تجارية مشروعة مثل حل النزاعات أو إنفاذ اتفاقياتنا.",
            "ur": ""
        },
        "We will confirm receipt of your request within 10 days. We may require specific information from you to help us verify your identity and process your request. If we are unable to verify your identity, we may deny your request.": {
            "en": "We will confirm receipt of your request within 10 days. We may require specific information from you to help us verify your identity and process your request. If we are unable to verify your identity, we may deny your request.",
            "ar": "سنؤكد استلام طلبك في غضون 10 أيام. قد نطلب منك معلومات محددة لمساعدتنا في التحقق من هويتك ومعالجة طلبك. إذا لم نتمكن من التحقق من هويتك ، فقد نرفض طلبك.",
            "ur": ""
        },
        "14. Location Permissions and Usage": {
            "en": "14. LOCATION PERMISSIONS AND USAGE",
            "ar": "14. أذونات الموقع والاستخدام",
            "ur": ""
        },
        "We have the right to amend this privacy policy at any time, and we will publish the amendments within this document. Also, we will send a public notification via the application. And your continued use of the application after the amendments of this policy is considered as an express consent of you on these amendments, so please review this policy constantly.": {
            "en": "We have the right to amend this privacy policy at any time, and we will publish the amendments within this document. Also, we will send a public notification via the application. And your continued use of the application after the amendments of this policy is considered as an express consent of you on these amendments, so please review this policy constantly.",
            "ar": "يحق لنا تعديل سياسة الخصوصية هذه في أي وقت، وسنقوم بنشر التعديلات ضمن هذه الوثيقة كما سنقوم بإرسال إشعار عام عبر التطبيق، ويعد استمرارك في استخدام التطبيق بعد تعديلات هذه السياسة موافقة صريحة منك على هذه التعديلات، لذا يرجى مراجعة هذه السياسة باستمرار.",
            "ur": ""
        },
        "15. Contact Us": {
            "en": "15. Contact Us",
            "ar": "15. الاتصال بنا",
            "ur": ""
        },
        "You can contact us through:": {
            "en": "You can contact us through:",
            "ar": "يمكنك التواصل معنا عبر:",
            "ur": ""
        },
        "Email: support@tawkeel.com": {
            "en": "Email: support@tawkeel.com",
            "ar": "البريد الإلكتروني: support@tawkeel.com",
            "ur": ""
        },
        "Call us on the number": {
            "en": "Call us on the number",
            "ar": "اتصل بنا على الرقم",
            "ur": ""
        },
        "Toll Free": {
            "en": "Toll Free",
            "ar": "اتصال مجاني",
            "ur": ""
        },
        "International": {
            "en": "International",
            "ar": "دولي",
            "ur": ""
        },
        "Seeker Privacy Policy": {
            "en": "Seeker Privacy Policy",
            "ar": "سياسة الخصوصية الموكل ",
            "ur": ""
        },
        "We collect the data provided by the client to register his account on the application which includes (the first name, the last name, the email, the country choice and mobile number).": {
            "en": "We collect the data provided by the client to register his account on the application which includes (the first name, the last name, the email, the country choice and mobile number).",
            "ar": "نجمع البيانات التي قدمها العميل لتسجيل حسابه بالتطبيق والتي تشمل (الاسم الأول، الاسم الأخير، البريد الالكتروني، اختيار الدولة، رقم الجوال).",
            "ur": ""
        },
        "The client's data, the service description, and the client requirements that he provides the performer with before taking the step of intention and starting to implement the service.": {
            "en": "The client's data, the service description, and the client requirements that he provides the performer with before taking the step of intention and starting to implement the service.",
            "ar": "بيانات العميل ووصف الخدمة ومتطلبات العميل التي يزود المؤدي بها قبل أخذ خطوة النية والبدء في تنفيذ الخدمة.",
            "ur": ""
        },
        "Electronic payment data or bank transfer data that the client provides us with for purposes of paying for the service (the cost of the Umrah service), which is reserved in the application until the performer finishes performing the Umrah.": {
            "en": "Electronic payment data or bank transfer data that the client provides us with for purposes of paying for the service (the cost of the Umrah service), which is reserved in the application until the performer finishes performing the Umrah.",
            "ar": "بيانات الدفع الإلكتروني أو بيانات التحويل البنكي التي يزودنا بها العميل لأغراض دفع قيمة الخدمة (تكلفة خدمة العمرة) والتي يتم حجزها لدى التطبيق لحين انتهاء المؤدي من أداء العمرة.",
            "ur": ""
        },
        "The client provides us with the content of messages, orders and comments through the application at his personal responsibility.": {
            "en": "The client provides us with the content of messages, orders and comments through the application at his personal responsibility.",
            "ar": "يزودنا العميل بمحتوى الرسائل والطلبات والتعليقات من خلال التطبيق على مسئوليته الشخصية.",
            "ur": ""
        },
        "The client provides us with the content of the messages he sends to the application to inquire about the content of the services.": {
            "en": "The client provides us with the content of the messages he sends to the application to inquire about the content of the services.",
            "ar": "يزودنا العميل بمحتوى الرسائل التي يقوم بإرسالها إلى التطبيق للاستفسار عن محتوى الخدمات.",
            "ur": ""
        },
        "The client provides us with his number and his contact details when he contacts us through the application, whether via the application, email or phone.": {
            "en": "The client provides us with his number and his contact details when he contacts us through the application, whether via the application, email or phone.",
            "ar": "يزودنا العميل برقم وبيانات الاتصال به، عندما يقوم بالاتصال بنا من خلال التطبيق سواء عبر التطبيق أو عبر البريد الالكتروني أو عبر الهاتف.",
            "ur": ""
        },
        "We allow the client to communicate with the application, in this case the customer provides us with the following information (name, email, subject of the message, content of the message).": {
            "en": "We allow the client to communicate with the application, in this case the customer provides us with the following information (name, email, subject of the message, content of the message).",
            "ar": "نتيح للعميل التواصل مع التطبيق، وفي هذه الحالة يقدم لنا العميل المعلومات الآتية (الاسم، البريد الالكتروني، موضوع الرسالة، محتوى الرسالة).",
            "ur": ""
        },
        "We collect data to enable the client to communicate with the client through the application to agree on performing Umrah.": {
            "en": "We collect data to enable the client to communicate with the client through the application to agree on performing Umrah.",
            "ar": "نجمع البيانات لتمكين العميل من التواصل مع المؤدي عبر التطبيق للاتفاق حول تأدية العمرة.",
            "ur": ""
        },
        "We collect data to enable the client to pay for the service, follow the performer up, and ensure that the service is implemented before the application transfers the performer's dues.": {
            "en": "We collect data to enable the client to pay for the service, follow the performer up, and ensure that the service is implemented before the application transfers the performer's dues.",
            "ar": "نجمع البيانات لتمكين العميل من دفع مقابل الخدمة ومتابعة المؤدي والتأكد من تنفيذ الخدمة قبل قيام التطبيق بتحويل مستحقات المؤدي.",
            "ur": ""
        },
        "The user shall be bound not to collect or exploit any data of any other parties who use the application, such as clients and service providers, and the use of data is limited to the purposes of providing services or benefiting from them only.": {
            "en": "The user shall be bound not to collect or exploit any data of any other parties who use the application, such as clients and service providers, and the use of data is limited to the purposes of providing services or benefiting from them only",
            "ar": "يتطلب تطبيقنا الوصول إلى موقع جهازك من أجل تتبع موقعك أثناء رحلات العمرة. نقوم بجمع بيانات الموقع الأساسية والغير أساسية لتوفير خدمات تتبع وملاحة دقيقة لمساعدتك على أداء  العمرة بأمان وكفاءة.",
            "ur": ""
        },
        "Our app requires access to your device’s location in order to track your location during Umrah journeys. We collect both coarse and fine location data to provide accurate tracking and navigation services to help you perform Umrah rituals safely and efficiently.": {
            "en": "Our app requires access to your device’s location in order to track your location during Umrah journeys. We collect both coarse and fine location data to provide accurate tracking and navigation services to help you perform Umrah rituals safely and efficiently.",
            "ar": "يتطلب تطبيقنا الوصول إلى موقع جهازك من أجل تتبع موقعك أثناء رحلات العمرة. نقوم بجمع بيانات الموقع الأساسية والغير أساسية لتوفير خدمات تتبع وملاحة دقيقة لمساعدتك على أداء  العمرة بأمان وكفاءة.",
            "ur": ""
        },
        "Why We Need Your Location": {
            "en": "Why We Need Your Location",
            "ar": "لماذا يتطلب الوصول الى موقعك ",
            "ur": ""
        },
        "Umrah Tracking: We use your location data to track your movements during Umrah journeys, including your current position, route, and points of interest. This information is essential for providing real-time navigation, guidance, and assistance to help you complete your Umrah rituals.": {
            "en": "Umrah Tracking: We use your location data to track your movements during Umrah journeys, including your current position, route, and points of interest. This information is essential for providing real-time navigation, guidance, and assistance to help you complete your Umrah rituals.",
            "ar": "تتبع العمرة: نستخدم بيانات موقعك لتتبع تحركاتك أثناء رحلات العمرة ، بما في ذلك موقعك الحالي وطريقك ونقاط اهتمامك. هذه المعلومات ضرورية لتوفير التنقل والتوجيه والمساعدة في الوقت الفعلي لمساعدتك على إكمال العمرة.",
            "ur": ""
        },
        "How We Use Your Location": {
            "en": "How We Use Your Location",
            "ar": "لماذا نستخدم موقعك ",
            "ur": ""
        },
        "Sharing of Location Data": {
            "en": "Sharing of Location Data",
            "ar": "مشاركة بيانات الموقع",
            "ur": ""
        },
        "Navigation: Your location data is used to provide turn-by-turn navigation, route planning, and guidance throughout your Umrah journey. We utilize mapping and GPS technologies to display your current location on maps and provide directions to various landmarks, holy sites, and facilities.": {
            "en": "Navigation: Your location data is used to provide turn-by-turn navigation, route planning, and guidance throughout your Umrah journey. We utilize mapping and GPS technologies to display your current location on maps and provide directions to various landmarks, holy sites, and facilities.",
            "ar": "الملاحة: يتم استخدام بيانات الموقع الخاص بك لتوفير بدوره عن طريق بدوره الملاحة ، وتخطيط الطريق ، والتوجيه طوال رحلة العمرة الخاص بك. نحن نستخدم تقنيات رسم الخرائط ونظام تحديد المواقع العالمي لعرض موقعك الحالي على الخرائط وتوفير الاتجاهات إلى مختلف المعالم والأماكن المقدسة والمرافق.",
            "ur": ""
        },
        "Safety and Assistance: In case of emergencies or unforeseen circumstances, your location data enables us to provide immediate assistance, support, and guidance to ensure your safety and well-being during Umrah rituals.": {
            "en": "Safety and Assistance: In case of emergencies or unforeseen circumstances, your location data enables us to provide immediate assistance, support, and guidance to ensure your safety and well-being during Umrah rituals.",
            "ar": "السلامة والمساعدة: في حالات الطوارئ أو الظروف غير المتوقعة ، تمكننا بيانات موقعك من تقديم المساعدة والدعم والتوجيه الفوري لضمان سلامتك ورفاهيتك أثناء العمرة.",
            "ur": ""
        },
        "Your Privacy Rights": {
            "en": "Sharing of Location Data",
            "ar": "مشاركة بيانات الموقع",
            "ur": ""
        },
        "Third-party Services: We do not share your location data with third-party service providers, such as mapping and navigation platforms. Although we only share the location with the seeker who ordered the umrah and wants to track the umrah performer.": {
            "en": "Third-party Services: We do not share your location data with third-party service providers, such as mapping and navigation platforms. Although we only share the location with the seeker who ordered the umrah and wants to track the umrah performer.",
            "ar": "خدمات الجهات الخارجية: لا نشارك بيانات موقعك مع مزودي خدمات الجهات الخارجية ، مثل منصات الخرائط والملاحة. على الرغم من أننا نشارك الموقع فقط مع الباحث الذي أمر بالعمرة ويريد تتبع أداء العمرة.",
            "ur": ""
        },
        "Your Privacy Rights": {
            "en": "Your Privacy Rights",
            "ar": "حقوق الخصوصية الخاصة بك",
            "ur": ""
        },
        "Opt-out Options: You have the right to opt-out of location tracking at any time by adjusting the app’s settings or disabling location permissions on your device. However, please note that disabling location tracking may limit the functionality of certain features within the app.": {
            "en": "Opt-out Options: You have the right to opt-out of location tracking at any time by adjusting the app’s settings or disabling location permissions on your device. However, please note that disabling location tracking may limit the functionality of certain features within the app.",
            "ar": "خيارات إلغاء الاشتراك: يحق لك إلغاء الاشتراك في تتبع الموقع في أي وقت عن طريق ضبط إعدادات التطبيق أو تعطيل أذونات الموقع على جهازك. ومع ذلك ، يرجى ملاحظة أن تعطيل تتبع الموقع قد يحد من وظائف بعض الميزات داخل التطبيق.",
            "ur": ""
        },
        "15. Amendment of The Privacy Policy": {
            "en": "15. Amendment of The Privacy Policy",
            "ar": "15. تعديل سياسة الخصوصية",
            "ur": ""
        },
        "16. Contact Us": {
            "en": "16. Contact Us",
            "ar": "16. اتصل بنا",
            "ur": ""
        },
        
    }
    return translations[text] ? translations[text][language] : text; 
}
export default _T;
