import React from 'react'
import { Link } from 'react-router-dom'
import n1 from '../../assets/images/news/news-01.png'
import n2 from '../../assets/images/news/news-02.png'
import n3 from '../../assets/images/news/news-03.png'
import p1 from '../../assets/images/gallery/Picture1.jpg'
import p2 from '../../assets/images/gallery/Picture2.jpg'
import p3 from '../../assets/images/gallery/Picture3.jpg'
import p4 from '../../assets/images/gallery/Picture4.jpg'
import defaultImage from '../../assets/images/default-news.jpg'
import mainLogo from "../../assets/images/main-logo.png"
import _T from '../../locale/Home/translator'
import { useState } from 'react'
import _TN from '../../locale/News/translator'
function News({ language }) {
    const [showModal, setShowModal] = useState(false)
    const [showVideoModal, setShowVideoModal] = useState(false)
    const [selected, setSelected] = useState(null)
    const [selectedVideo, setSelectedVideo] = useState(null)
    const [caption, setCaption] = useState("")
    return (
        <section id='news' className='bg-light'>
            <div className='wrapper news-wrapper'>
                <div className='section-top'>
                    <h2 className='secondary-heading'>{_T("Our News")}</h2>
                    <div className='news-list'>
                        <a href='https://www.spa.gov.sa/viewstory.php?lang=ar&newsid=2416797' target={"_blank"} rel="noreferrer">
                            <img src={n1} alt="Saudi Press Agency" />
                        </a>
                        <a href='https://sabq.org/saudia/p14ijrl0lf' target={"_blank"} rel="noreferrer">
                            <img src={n2} alt="SAQB Online Newspaper" />
                        </a>
                        <a href='https://www.thenationalnews.com/gulf-news/2023/01/11/hajj-expo-2023-all-you-need-to-know-about-the-pilgrimage/' target={"_blank"} rel="noreferrer">
                            <img src={n3} alt="The National News" />
                        </a>
                    </div>
                    <div className='news-card-list'>
                        <div className='news-card'>
                            <div>
                                <img className='news-image' alt='news-thumbnail' src='https://malaysiagazette.com/wp-content/uploads/2024/01/umrah.jpg' onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} />
                                <a href='https://malaysiagazette.com/2024/01/10/mata-aplikasi-tawkeel-jalin-kerjasama-elak-penipuan-badal-umrah/' target={"_blank"} rel="noreferrer" className='news-title'>{_TN('MATA and Tawkeel collaborate to avoid Umrah Al Badal fraud')}</a>
                                <p className='news-description'>{_TN('KUALA LUMPUR - The Malaysian Association of Travel Agencies (MATA) has partnered with Tawkeel, a leading Saudi technology company, to leverage innovative technology solutions to prevent fraud by Umrah Al Badal operators.')}</p>
                            </div>
                            <a href='https://malaysiagazette.com/2024/01/10/mata-aplikasi-tawkeel-jalin-kerjasama-elak-penipuan-badal-umrah/' target={"_blank"} rel="noreferrer" className='news-link'>{_TN('Read More')}</a>
                        </div>
                        <div className='news-card'>
                            <div>
                                <img className='news-image' alt='news-thumbnail' src='https://media.assettype.com/sabq%2F2023-01%2F73f498cf-38c1-4b2b-ade3-82ba885408d9%2Fsabq_2023_01_d9648695_cc65_4321_ba17_64cbce26ce75_cats_30.avif?w=1200&auto=format%2Ccompress&fit=max' onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} />
                                <a href='https://sabq.org/saudia/p14ijrl0lf' target={"_blank"} rel="noreferrer" className='news-title'>{_TN('Tawkeel introduces its services at the Hajj and Umrah Expo 2023')}</a>
                                <p className='news-description'>{_TN('The Tawkeel application for Hajj and Umrah showcased its services provided to facilitate the performance of rituals for those unable to do so and targets Muslims around the world.')}</p>
                            </div>
                            <a href='https://sabq.org/saudia/p14ijrl0lf' target={"_blank"} rel="noreferrer" className='news-link'>{_TN('Read More')}</a>
                        </div>
                        {/* <div className='news-card'>
                            <img className='news-image' alt='news-thumbnail' src='https://thenational-the-national-prod.cdn.arcpublishing.com/resizer/v2/TADZDRFKCRF5JPE474KLI6FA74?smart=true&auth=e10b4e199d2d2b6f187c8db602b8798e8919d01dcadd5184ec4464101e6ad3a0&width=1000&height=562' onError={(e) => { e.target.onerror = null; e.target.src=defaultImage }}/>
                            <a href='https://www.thenationalnews.com/gulf-news/2023/01/11/hajj-expo-2023-all-you-need-to-know-about-the-pilgrimage/' target={"_blank"} rel="noreferrer" className='news-title'>{_TN('Hajj 2023: How technology is transforming pilgrimage')}</a>
                            <p className='news-description'>{_TN('A Saudi start-up is showing off its Tawkeel application that helps Muslims around the world to delegate rituals to be performed by others on their behalf.')}</p>
                            <a href='https://www.thenationalnews.com/gulf-news/2023/01/11/hajj-expo-2023-all-you-need-to-know-about-the-pilgrimage/' target={"_blank"} rel="noreferrer" className='news-link'>{_TN('Read More')}</a>
                        </div> */}
                    </div>
                    <Link className='btn btn-download mb-5' to={language.code === "en" ? "/en/news" : "/news"}>{_TN("View More News")}</Link>
                </div>
            </div>
            <section id="gallery">
                <div className='wrapper gallery-wrapper'>
                    <div className="overlay"></div>
                    <div className='section-right'>
                        <div className="section-logo text-center">
                            <img src={mainLogo} className='logo-img' alt="Tawkeel" />
                        </div>
                        <h2 className='secondary-heading mb-4 text-center'>{_T("Photo Gallery")}</h2>
                        <div className='photo-gallery'>
                            <div className='single-image' onClick={() => { setShowModal(true); setSelected(p1); setCaption("With His Excellency the Minister of Hajj and Umrah, Dr. Tawfiq Al-Rabiah, during his visit to Tawkeel at the Hajj and Umrah Exhibition for the year 2023") }}>
                                <img src={p1} alt="With His Excellency the Minister of Hajj and Umrah, Dr. Tawfiq Al-Rabiah, during his visit to Tawkeel at the Hajj and Umrah Exhibition for the year 2023" />
                                <i className="ri-image-2-line"></i>
                            </div>
                            <div className='single-image' onClick={() => { setShowModal(true); setSelected(p2); setCaption("The General Manager of Tawkeel Eng. Raed Kayyal with the news anchor during a TV interview about the Tawkeel application, which was broadcast on Al-Ekhbariya channel") }}>
                                <img src={p2} alt="The General Manager of Tawkeel Eng. Raed Kayyal with the news anchor during a TV interview about the Tawkeel application, which was broadcast on Al-Ekhbariya channel" />
                                <i className="ri-image-2-line"></i>
                            </div>
                            <div className='single-image' onClick={() => { setShowModal(true); setSelected(p3); setCaption("Some of Tawkeel's visitors at the Hajj and Umrah exhibition for the year 2023") }}>
                                <img src={p3} alt="Some of Tawkeel's visitors at the Hajj and Umrah exhibition for the year 2023" />
                                <i className="ri-image-2-line"></i>
                            </div>
                            <div className='single-image' onClick={() => { setShowModal(true); setSelected(p4); setCaption("During a TV interview with Iqraa TV at the Hajj and Umrah Fair for the year 2023") }}>
                                <img src={p4} alt="During a TV interview with Iqraa TV at the Hajj and Umrah Fair for the year 2023" />
                                <i className="ri-image-2-line"></i>
                            </div>
                            <div className='single-image' onClick={() => { setShowVideoModal(true); setSelectedVideo("https://tawkeelprod.blob.core.windows.net/webvideos/gallery-video-01.mp4"); }}>
                                <video>
                                    <source src="https://tawkeelprod.blob.core.windows.net/webvideos/gallery-video-01.mp4" type="video/mp4" />
                                </video>
                                <i className="ri-vidicon-line"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="certificate">
                <div className="wrapper certificate-wrapper">
                    <div className='section-certificate'>
                        <h2 className='secondary-heading text-center mb-4'>{_T("Certificates")}</h2>
                        <div className='certificate-list'>
                            <a className='single-certificate' href='https://tawkeelprod.blob.core.windows.net/tawkeel-certificates/tawkeel-licence-certificate.pdf'>
                                <i className="ri-arrow-right-up-line certificate-arrow"></i>
                                <h4>{_T("E-Commerce Authentication Certificate")}</h4>
                                <p>{_T("Authentication Number:")} <strong>0000008878</strong></p>
                                {/* <a  target='_blank' rel="noreferrer">{_T("View Certificate")}</a> */}
                            </a>
                            <a className='single-certificate' href='https://tawkeelprod.blob.core.windows.net/tawkeel-certificates/tawkeel-maroof-certiticate.pdf'>
                                <i className="ri-arrow-right-up-line certificate-arrow"></i>
                                <h4>{_T("Maroof Certificate")}</h4>
                                <p>{_T("Authentication Number:")} <strong>306523</strong></p>
                                {/* <a  target='_blank' rel="noreferrer">{_T("View Certificate")}</a> */}
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {
                showModal ?
                    <div className='modal'>
                        <div className='moda-close' onClick={() => { setShowModal(false) }}><i className="ri-close-line"></i></div>
                        <div className='moda-overlay' onClick={() => { setShowModal(false) }}></div>
                        <div className='modal-body'>
                            <img src={selected} alt={caption} />
                            <p>{_T(caption)}</p>
                        </div>
                    </div> : <></>
            }
            {
                showVideoModal ?
                    <div className='modal'>
                        <div className='moda-close' onClick={() => { setShowVideoModal(false) }}><i className="ri-close-line"></i></div>
                        <div className='moda-overlay' onClick={() => { setShowVideoModal(false) }}></div>
                        <div className='modal-body'>
                            <video src={selectedVideo} type="video/mp4" controls={true}>
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div> : <></>
            }


        </section>
    )
}
export default News;