function _T(text){
    const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
    const translations = {
        "TAWKEEL COMPANY FOR COMMERCIAL SERVICES is a Saudi company": {
            "en": "TAWKEEL COMPANY FOR COMMERCIAL SERVICES is a Saudi company",
            "ar": 'شركة توكيل هي شركة سعودية مسجلة',
            "ur": ""
        },
        "Tawkeel": {
            "en": "Tawkeel",
            "ar": "توكيل",
            "ur": ""
        },
        "Keeping pace with the Kingdom's Vision 2030": {
            "en": "Keeping pace with the Kingdom's Vision 2030",
            "ar": 'مواكبة لرؤية المملكة ٢٠٣٠ ',
            "ur": ""
        },
        "Quick Links": {
            "en": "Quick Links",
            "ar": "روابط سريعة",
            "ur": ""
        },
        "Performer Help Center": {
            "en": "Performer Help Center",
            "ar": "مركز مساعدة المؤدي",
            "ur": ""
        },
        "Seeker Help Center": {
            "en": "Seeker Help Center",
            "ar": "مركز مساعدة الباحث",
            "ur": ""
        },
        "Home": {
            "en": "Home",
            "ar": "مسكن",
            "ur": ""
        },
        "About": {
            "en": "About",
            "ar": "عن توكيل",
            "ur": ""
        },
        "FAQ": {
            "en": "FAQ",
            "ar": "الأسئلة الشائعة",
            "ur": ""
        },
        "Contact": {
            "en": "Contact",
            "ar": "اتصال",
            "ur": ""
        },
        "Help Center": {
            "en": "Help Center",
            "ar": "مركز المساعدة",
            "ur": ""
        },
        "Privacy Policy": {
            "en": "Privacy Policy",
            "ar": "سياسة الخصوصية",
            "ur": ""
        },
        "Terms of Use": {
            "en": "Terms of Use",
            "ar": "شروط الاستخدام",
            "ur": ""
        },
        "Contact Us": {
            "en": "Contact Us",
            "ar": "اتصل بنا",
            "ur": ""
        },
        "Ash Shati District, Jeddah": {
            "en": "Ash Shati District, Jeddah",
            "ar": "حي الشاطئ ,جده",
            "ur": ""
        },
        "Toll free": {
            "en": "Toll free",
            "ar": "اتصال مجاني",
            "ur": ""
        },
        "International": {
            "en": "International",
            "ar": "دولي",
            "ur": ""
        },
        "Download": {
            "en": "Download",
            "ar": "تحميل",
            "ur": ""
        },
        "The opportunity and ability to find someone to perform Umrah on your behalf at any time.": {
            "en": "The opportunity and ability to find someone to perform Umrah on your behalf at any time.",
            "ar": "الفرصة والقدرة على إيجاد من يقوم بأداء العمرة نيابة عنك في أي وقت.",
            "ur": ""
        },
        "All rights reserved": {
            "en": "All rights reserved",
            "ar": "جميع الحقوق محفوظة",
            "ur": ""
        },
    }
    return translations[text] ? translations[text][language] : text; 
}
export default _T;