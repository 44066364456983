function _T(text) {
    const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
    const translations = {
        "Quick Register": {
            "en": "Quick Register",
            "ar": "التسجيل السريع"
        },
        "Tawkeel": {
            "en": "Tawkeel",
            "ar": "توكيل",
            "ur": ""
        },
        "Agents": {
            "en": "Agents",
            "ar": "وكلاء",
            "ur": ""
        },
        "Find Agent": {
            "en": "Find Agent",
            "ar": "ابحث عن الوكيل",
            "ur": ""
        },
        "Our team of agents, committed to providing exceptional support. From technical troubleshooting to general inquiries, our agents are here to assist you every step of the way.": {
            "en": "Our team of agents, committed to providing exceptional support. From technical troubleshooting to general inquiries, our agents are here to assist you every step of the way.",
            "ar": "لدينا فريق من الوكلاء، ملتزمون بتقديم خدمة متميزة, باستكشاف الأخطاء الفنية و الإجابة على الاستفسارات العامة وكلائنا هنا لمساعدتك خطوة بخطوة",
            "ur": ""
        },
        "Register as a Marketing agency and provide opportunities to people to help them find someone to perform Umrah on their behalf at any time.": {
            "en": "Register as a Marketing agency and provide opportunities to people to help them find someone to perform Umrah on their behalf at any time.",
            "ar": "سجّل كوكالة تسويقية وساهم في نشر خدمة عمرة البدل لمساعدة الأشخاص غير القادرين في أي وقت ومكان"
        },
        "Register as a seeker agency and provide Tawkeel service to people around the world": {
            "en": "Register as a seeker agency and provide Tawkeel service to people around the world",
            "ar": "نرحب بانضمامك كوكيل لفريق توكيل والعمل معنا على نشر خدمة عمرة البدل "
        },
        "Register Now": {
            "en": "Register Now",
            "ar": "سجّل الان"
        },
        "Agency Logo is required": {
            "en": "Agency Logo is required",
            "ar": "مطلوب شعار الوكالة"
        },
        "Upload logo": {
            "en": "Upload logo",
            "ar": "حمّل الشعار"
        },
        "Provide valid agency title": {
            "en": "Provide valid agency title",
            "ar": "ادخل اسم الوكالة"
        },
        "Provide valid email address": {
            "en": "Provide valid email address",
            "ar": "ادخل البريد الإلكتورني"
        },
        "Provide valid phone number": {
            "en": "Provide valid phone number",
            "ar": "ادخل رقم الجوال"
        },
        "Provide valid agent name": {
            "en": "Provide valid agent name",
            "ar": "ادخل اسم الوكيل"
        },
        "Provide valid registration no": {
            "en": "Provide valid registration no",
            "ar": "ادخل رقم السجل التجاري"
        },
        "Provide year when agency was founded": {
            "en": "Provide year when agency was founded",
            "ar": "ادخل تاريخ إنشاء السجل التجاري"
        },
        "Provide agency address": {
            "en": "Provide agency address",
            "ar": "ادخل عنوان الوكالة"
        },
        "Select agency country": {
            "en": "Select agency country",
            "ar": "حدد دولة الوكالة"
        },
        "Provide agency city": {
            "en": "Provide agency city",
            "ar": "ادخل اسم المدينة"
        },
        "Please select at least one country (Max 3)": {
            "en": "Please select at least one country (Max 3)",
            "ar": "يرجى اختيار دولة واحدة على الأقل (3 كحد أقصى)"
        },
        "Ending date should be after xx-xx-xxxx": {
            "en": "Ending date should be after xx-xx-xxxx",
            "ar": "يجب أن يكون تاريخ الانتهاء بعد xx-xx-xxxx"
        },
        "Provide a registration date": {
            "en": "Provide a registration date",
            "ar": "ادخل تاريخ التسجيل"
        },
        "Provide bank name": {
            "en": "Provide bank name",
            "ar": "ادخل اسم البنك"
        },
        "Select bank country": {
            "en": "Select bank country",
            "ar": "حدد دولة البنك"
        },
        "Provide valid account IBAN": {
            "en": "Provide valid account IBAN",
            "ar": "ادخل حساب (آيبان) فعّال"
        },
        "Provide bank city": {
            "en": "Provide bank city",
            "ar": "ادخل مدينة البنك"
        },
        "Provide valid industry name": {
            "en": "Provide valid industry name",
            "ar": "ادخل اسم المنشأة"
        },
        "Provide valid order target between 100 to 1,000,000": {
            "en": "Provide valid order target between 100 to 1,000,000",
            "ar": "ادخل عدد الطلبات المتوقع بين 100 الى 1.000.000"
        },
        "Provide valid order amount between 78 USD to 1,000,000,000 USD": {
            "en": "Provide valid order amount between 78 USD to 1,000,000,000 USD",
            "ar": "ادخل مبلغ الطلبات المتوقع بين 78 الى 1.000.000 دولار"
        },
        "Provide valid designation": {
            "en": "Provide valid designation",
            "ar": "ادخل منصب الوكيل"
        },
        "Provide valid postal code": {
            "en": "Provide valid postal code",
            "ar": "ادخل الرمز البريدي"
        },
        "Atleast one verification document is required": {
            "en": "Atleast one verification document is required",
            "ar": "مطلوب ملف واحد على الأقل"
        },
        "Your registration has been completed. You will recieve a confirmation email at your given agency email address": {
            "en": "Your registration has been completed. You will recieve a confirmation email at your given agency email address",
            "ar": "تم الانتهاء من التسجيل الخاص بك. سيتم إرسال رسالة تأكيد عبر البريد الإلكتروني الخاص بوكالتك"
        },
        "Your registration could not be completed at the moment. Please try again": {
            "en": "Your registration could not be completed at the moment. Please try again",
            "ar": "لا يمكن إكمال تسجيلك في الوقت الحالي. حاول مرة اخرى"
        },
        "Please only upload files with allowed formats (jpg, jpeg, png, pdf)": {
            "en": "Please only upload files with allowed formats (jpg, jpeg, png, pdf)",
            "ar": "يُرجى تحميل الملفات ذات الصيغ المسموح بها (jpg، jpeg، png، pdf)"
        },
        "Please only upload image with allowed formats (jpg, jpeg, png)": {
            "en": "Please only upload image with allowed formats (jpg, jpeg, png)",
            "ar": "يُرجى تحميل الصورة بالصيغ المسموح بها فقط (jpg، jpeg، png)"
        },
        "Registration Information": {
            "en": "Registration Information",
            "ar": "معلومات التسجيل"
        },
        "Upload Logo": {
            "en": "Upload Logo",
            "ar": "حمّل الشعار"
        },
        "Agency Name": {
            "en": "Agency Name",
            "ar": "اسم الوكالة"
        },
        "Enter Agency Name": {
            "en": "Enter Agency Name",
            "ar": "ادخل اسم الوكالة"
        },
        "Agency's Number": {
            "en": "Agency's Number",
            "ar": "رقم الوكالة"
        },
        "Enter Agency's Number": {
            "en": "Enter Agency's Number",
            "ar": "ادخل رقم الوكالة"
        },
        "Agency's Email": {
            "en": "Agency's Email",
            "ar": "البريد الإلكتروني للوكالة"
        },
        "Enter Agency's Email": {
            "en": "Enter Agency's Email",
            "ar": "ادخل البريد الإلكتورني"
        },
        "Agency Industry": {
            "en": "Agency Industry",
            "ar": "مجال الوكالة"
        },
        "Enter Agency Industry e.g Marketing, Travel": {
            "en": "Enter Agency Industry e.g Marketing, Travel",
            "ar": "ادخل مجال الوكالة مثل : تسويق, سفر .. الخ"
        },
        "Commercial Registration No": {
            "en": "Commercial Registration No",
            "ar": "رقم السجل التجاري"
        },
        "Enter Commercial Registration No": {
            "en": "Enter Commercial Registration No",
            "ar": "ادخل رقم السجل التجاري"
        },
        "Commercial Registration Date": {
            "en": "Commercial Registration Date",
            "ar": "تاريخ السجل التجاري"
        },
        "Agency Address": {
            "en": "Agency Address",
            "ar": "عنوان الوكالة"
        },
        "Enter Agency Address": {
            "en": "Enter Agency Address",
            "ar": "ادخل عنوان الوكالة"
        },
        "Agency Postal Code": {
            "en": "Agency Postal Code",
            "ar": "الرمز البريدي للوكالة"
        },
        "Enter Agency Postal Code": {
            "en": "Enter Agency Postal Code",
            "ar": "ادخل الرمز البريدي للوكالة"
        },
        "Year Founded": {
            "en": "Year Founded",
            "ar": "سنة تأسيس الوكالة"
        },
        "Enter Year Founded": {
            "en": "Enter Year Founded",
            "ar": "ادخل سنة تأسيس الوكالة"
        },
        "Agency Country": {
            "en": "Agency Country",
            "ar": "دولة الوكالة"
        },
        "Select Agency Country": {
            "en": "Select Agency Country",
            "ar": "حدد دولة التي توجد بها الوكالة"
        },
        "Agency City": {
            "en": "Agency City",
            "ar": "مدينة الوكالة"
        },
        "Enter Agency City": {
            "en": "Enter Agency City",
            "ar": "أدخل المدينة التي توجد بها الوكالة"
        },
        "Agent Information": {
            "en": "Agent Information",
            "ar": "معلومات الوكيل"
        },
        "Please provide your agency's agent/representative information": {
            "en": "Please provide your agency's agent/representative information",
            "ar": "يرجى تقديم معلومات (وكيل/ممثل) وكالتك"
        },
        "Please provide the following information to create your agency account with Tawkeel": {
            "en": "Please provide the following information to create your agency account with Tawkeel",
            "ar": "برجاء تقديم المعلومات التالية لإنشاء حساب وكالتك مع توكيل"
        },
        "Agent's Name": {
            "en": "Agent's Name",
            "ar": "اسم الوكيل"
        },
        "Enter Agent Name": {
            "en": "Enter Agent Name",
            "ar": "ادخل اسم الوكيل"
        },
        "Agent's Number": {
            "en": "Agent's Number",
            "ar": "رقم الوكيل"
        },
        "Enter Agent's Number": {
            "en": "Enter Agent's Number",
            "ar": "ادخل رقم الهاتف الخاص بالوكيل"
        },
        "Agent's Email": {
            "en": "Agent's Email",
            "ar": "البريد الإلكتروني للوكيل"
        },
        "Enter Agent's Email": {
            "en": "Enter Agent's Email",
            "ar": "ادخل البريد الإلكتروني الخاص بالوكيل"
        },
        "Agent's Designation": {
            "en": "Agent's Designation",
            "ar": "مجال الوكيل"
        },
        "Enter Agent's Designation": {
            "en": "Enter Agent's Designation",
            "ar": "ادخل منصب الوكيل"
        },
        "Bank Information": {
            "en": "Bank Information",
            "ar": "معلومات البنك"
        },
        "Please provide your agency's bank information": {
            "en": "Please provide your agency's bank information",
            "ar": "يرجى تقديم معلومات البنك الخاصة بالوكالة"
        },
        "Bank Country": {
            "en": "Bank Country",
            "ar": "موقع البنك"
        },
        "Select Bank Country": {
            "en": "Select Bank Country",
            "ar": "حدد دولة البنك المصرفي"
        },
        "Bank City": {
            "en": "Bank City",
            "ar": "موقع البنك المدينة"
        },
        "Enter Bank City": {
            "en": "Enter Bank City",
            "ar": "ادخل موقع البنك"
        },
        "Bank Name": {
            "en": "Bank Name",
            "ar": "اسم البنك المصرفي"
        },
        "Enter Bank Name": {
            "en": "Enter Bank Name",
            "ar": "ادخل اسم البنك المصرفي"
        },
        "Account IBAN": {
            "en": "Account IBAN",
            "ar": "آيبان الحساب البنكي"
        },
        "Enter Account IBAN": {
            "en": "Enter Account IBAN",
            "ar": "ادخل آيبان الحساب البنكي"
        },
        "Contract Information": {
            "en": "Contract Information",
            "ar": "معلومات التواصل"
        },
        "Please provide your agency's contract information": {
            "en": "Please provide your agency's contract information",
            "ar": "يرجى إضافة معلومات التواصل الخاصة بالوكالة"
        },
        "Operational Countries": {
            "en": "Operational Countries",
            "ar": "الدول المستهدفة"
        },
        "Select Countries": {
            "en": "Select Countries",
            "ar": "حدد الدول"
        },
        "Max: 3": {
            "en": "Max: 3",
            "ar": "بحد أقصى 3 دول"
        },
        "Contract Ending Date": {
            "en": "Contract Ending Date",
            "ar": "تاريخ إنتهاء العقد"
        },
        "Contract Order Target": {
            "en": "Contract Order Target",
            "ar": "العدد المستهدف من التعاون"
        },
        "Enter Order Target": {
            "en": "Enter Order Target",
            "ar": "ادخل العدد المستهدف من التعاون"
        },
        "Contract Order Amount (USD)": {
            "en": "Contract Order Amount (USD)",
            "ar": "مبلغ التعاون المستهدف"
        },
        "Enter Order Amount": {
            "en": "Enter Order Amount",
            "ar": "ادخل مبلغ التعاون المستهدف"
        },
        "Verification Documents": {
            "en": "Verification Documents",
            "ar": "الوئائق المطلوبة"
        },
        "Upload Company License and Verification Documents": {
            "en": "Upload Company License and Verification Documents",
            "ar": "حمّل وثائق التراخيص الخاصة بالشركة"
        },
        "Registration Completed": {
            "en": "Registration Completed",
            "ar": "تم إكتمال التسجيل"
        },
        "Registration Failed": {
            "en": "Registration Failed",
            "ar": "فشل التسجيل"
        },
        "Try Again": {
            "en": "Try Again",
            "ar": "حاول مرة أخرى"
        }
    }
    return translations[text] ? translations[text][language] : text;
}
export default _T;