import { useEffect, useRef, useState } from "react";
import { languages } from "../../common/languages";
import Footer from "../../components/FooterInner";
import Navbar from "../../components/NavbarInner";
import Hero from "./Hero";
import SignatureCanvas from 'react-signature-canvas'
import axios from "axios";
import _T from "../../locale/QuickRegister/translator";
import { useParams, useSearchParams } from "react-router-dom";
import "../../assets/css/contract.css"
import logo from "../../assets/images/tawkeel-color-logo.png"
import stamp from "../../assets/images/contract/tawkeel-contract-stamp.png"
import { GetBaseURL } from "../../common/getBaseURL";

function Contract({ language }) {
    const [ search, setSearch] = useSearchParams();
    const signature = useRef(null);
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [contract, setContract] = useState(null);
    const [consent, setConsent] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [serverResponse, setServerResponse] = useState({ status: false, text: "", completed: false });
    const [signatureImage, setSignatureImage] = useState(null);

    useEffect(() => {
        getContractInfo();
    }, [])

    const getContractInfo = () => {
        axios.get(process.env.REACT_APP_API_BASEURL + 'admin/contract-by-agent?agentId=' + params.agent + "&contractId=" + params.contract).then(({ data }) => {
            if (data.status) {
                setContract(data.data)
                setLoading(false)
                // const countryList = data.data
                // setAllCountries(countries.filter((c)=>{ return countryList.includes(c.code) }))
            }
            setLoading(false)
        })
    }

    function section_scroll(id = "contract") {
        let scrollDiv = document.getElementById(id).offsetTop;
        window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
    }


    const dataURLToBlob = (dataURL) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    const onSubmit = () => {
        const hasSigned = !signature.current.isEmpty()
        const canvas = signature.current.getCanvas();
        const dataURL = canvas.toDataURL();
        if (hasSigned && dataURL) {
            const blob = dataURLToBlob(dataURL);
            setSignatureImage(dataURL)
            const formData = new FormData();
            formData.append('agency_user_id', params.agent);
            formData.append('contract_content', contract.contract_content);
            formData.append('signature', blob, 'signature.png');
            axios.post(process.env.REACT_APP_API_BASEURL + 'admin/sign-contract', formData, { headers: { 'content-type': 'multipart/form-data' } })
                .then(({ data }) => {
                    if (data.status) {
                        setServerResponse({ text: _T("Your contract has been signed. We shall review all your information. Once your information has been reviewd, we will send you an email to complete your process."), status: true, completed: true });
                        section_scroll("signatureSection")
                    }
                    else {
                        setServerResponse({ text: _T("Your e-signature could not be verified at the moment. Please try again"), status: false, completed: false });
                        setBtnLoading(false);
                        section_scroll("signatureSection")
                    }
                })
                .catch(error => {
                    setServerResponse({ text: _T("Your e-signature could not be verified at the moment. Please try again"), status: false, completed: false });
                    setBtnLoading(false);
                    section_scroll("signatureSection")
                });
        }
        else {
            setBtnLoading(false);
            section_scroll("signatureSection")
            setServerResponse({ text: _T("Your e-signature could not be verified at the moment. Please try again"), status: false, completed: false });
        }
    }
    return (
        <>
            <Navbar language={languages[language]} />
            <Hero language={languages[language]} />
            <main id="contract" className="english pt-5 pb-5">
                {
                    loading ?
                    <div className="wrapper">
                        <div className="process-loading">
                            <div className="process-loading-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div> :
                    !contract ?
                    <div className="wrapper">
                        <h1 className="secondary-heading text-center">Not Found</h1>
                        <p className="text-paragraph text-center mb-3">
                            We were unable to fetch your contract. Please contact tawkeel support at support@tawkeel.com
                        </p>
                    </div> :
                    <div className="wrapper">
                        <h1 className="secondary-heading mb-0">Terms and Conditions</h1>
                        <p className="text-paragraph mb-3">
                            By using our services, you agree to the following terms and conditions:
                        </p>
                        {
                            contract ?
                            <div className="contract-content">
                                <img src={logo} alt="logo" width={250} style={{ display: "block", margin: "30px auto" }}/>
                                <div dangerouslySetInnerHTML={{__html: contract.contract_content}}></div>
                                <img src={stamp} alt="stamp" width={120} className="mb-5"/>
                                <div id="signatureSection">
                                    {/* <h2 className="secondary-heading-alt">Your Information</h2>
                                    <p className="text-paragraph mb-3"><strong>Full Name:</strong> John Doe</p> */}
                                    <p style={{ maxWidth: "320px" }} className="text-paragraph mb-3 d-flex align-items-center justify-between"><strong>Signature:</strong> {!btnLoading && !serverResponse.completed && !contract.signature_url ? <span className="text-danger fs-14 fw-500 cursor-pointer" onClick={() => { signature.current.clear() }}>Clear</span> : <></>}</p>
                                    <div style={{ border: "1px solid #888", display: "inline-block" }} className="mb-2">
                                        {
                                            contract.signature_url ?
                                            <img src={contract.signature_url} alt="signature" className="sigCanvas" /> :
                                            serverResponse.completed && serverResponse.status ?
                                                <img src={signatureImage} alt="signature" className="sigCanvas" /> :
                                                <SignatureCanvas ref={signature} penColor='#066295' canvasProps={{ width: 320, height: 140, className: 'sigCanvas' }} />
                                        }
                                    </div>
                                </div>
                                {
                                    (serverResponse.completed && serverResponse.status) || contract.signature_url ? <></> :
                                        <label className="mb-4 d-block"><input type="checkbox" value={consent} checked={consent} onChange={(e) => { setConsent(e.target.checked) }} /> I have read and agree to the terms and conditions mentioned above</label>
                                }
                            </div> : <></>
                        }
                        {
                            (serverResponse.completed && serverResponse.status) || contract.signature_url ? <></> :
                                <button className="btn btn-primary" disabled={btnLoading || !consent} onClick={() => { setBtnLoading(true); onSubmit() }}>Submit Contract</button>
                        }
                        {
                            serverResponse.text ?
                                <p className={serverResponse.status ? "text-primary fs-14 fw-400" : "text-danger fs-14 fw-400"}>{serverResponse.text}</p> : <></>
                        }

                    </div>
                }
            </main>
            <Footer language={languages[language]} />
        </>
    )
}
export default Contract;