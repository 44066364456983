import Navbar from '../../components/NavbarInner'
import Footer from '../../components/FooterInner'
import {languages} from '../../common/languages'
import '../../assets/css/faq.css';
import Hero from './Hero';
import _T from '../../locale/FAQ/translator';
import { useState } from 'react';
import { useEffect } from 'react';
import { RP } from '../../components/FacebookPixel';
import '../../components/TikTokPixel.js';

function FAQ({language}){
    useEffect(()=>{
      RP.pageView();
    },[])

    function section_scroll(id){
        let scrollDiv = document.getElementById(id).offsetTop-100;
        window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
    }

    window.scrollTo({ top: 0});
    let direction = languages[language].direction
    if(direction === "RTL"){
      document.getElementsByTagName("body")[0].classList.add("RTL");
    }else{
      document.getElementsByTagName("body")[0].classList.remove("RTL");
    }

    function toggleQuestion(event) {
        const el = event.currentTarget
        if(el.classList.contains("single-faq")){
            if(el.classList.contains("open")){
                el.classList.remove('open')
            }
            else{
                el.classList.add('open')
            }
        }
    }
    
    return (
        <>
            <Navbar language={languages[language]}/>
            <Hero language={languages[language]}/>
            <section id='faqBody'>
                <div className='wrapper faqBody-wrapper'>
                    <div className='faq-navigation'>
                        <button className='btn btn-primary' onClick={()=>section_scroll('faq01')}>{_T('General Questions')}</button>
                        <button className='btn btn-primary' onClick={()=>section_scroll('faq02')}>{_T('Performer Related Questions')}</button>
                        <button className='btn btn-primary' onClick={()=>section_scroll('faq03')}>{_T('Questions Related to a Performer for Umrah')}</button>
                        <button className='btn btn-primary' onClick={()=>section_scroll('faq04')}>{_T('Payment Currency Questions')}</button>
                    </div>
                    <h2 className='secondary-heading text-center'>{_T('General Questions')}</h2>
                    <div className='faq-listing' id='faq01'>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: What is the Tawkeel application?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('An application based on providing pilgrims to perform the rituals of Umrah, on behalf of Muslims who are unable to perform Umrah and the deceased among them, which was permitted by the true Islamic religion.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: Is it permissible to perform Umrah instead of another person?')}</h3>
                            <div className='faq-answer RTL'>
                                <p>{_T('لا حرج إن شاء الله؛ لقوله ﷺ: العمرة إلى العمرة كفارة لما بينهما، ولم يشترط أياماً معلومة بينهما فالحديث عام، فإذا اعتمر الإنسان لنفسه وفرغ من عمرته ثم ذهب إلى التنعيم واعتمر لأبيه الميت أو الهرم الذي لا يستطيع، أو لأمه أو لغيرهما من الأموات أو لعجزهم لكبر السن فلا بأس، لكن إذا كان زحمة كأيام الحج فالأفضل أن يكتفي بعمرة واحدة، حتى يخفف على الناس، ولا يشق عليهم - ابن باز.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: Is the application available in my language?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('The application has more than 4 languages.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: Who is the Umrah Seeker?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('He is the one who is looking for someone to perform the Umrah on his behalf or the so-called "Seeker"')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: Who is the Performer?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('He is a sane adult Muslim from the people of Makkah or its surroundings who has performed the Umrah of Islam before and has the desire or intention to perform the Umrah on behalf of his client.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: How can I contact an agent?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('You have multiple ways to communicate with us. You can contact us via the unified customer service number 80024422203, via WhatsApp, or through the chat in the application. Regarding a specific question or issue, whether you need to speak with a customer service employee or any other matter.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: How can I send a complaint to Tawkeel?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('We take all customer feedback very seriously and our priority is to ensure that you are satisfied with your Umrah experience with us. If you need to report any problem or complaint about your experience, you can communicate through our e-mail or communicate with us via the unified number of the customer service center or through chatting with the application. It is always useful for us to review your comments to know how we can improve the services we provide. We will review your complaint as soon as possible and we send you a reference number via e-mail to assure you that we are resolving your complaint.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: What is the Umrah of the allowance?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('It is the Umrah that a person performs on behalf of another person who is unable to perform it either due to illness, death, etc')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: Is Umrah Al-Badal legally permissible?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('Yes, it is legally permissible. There are legal fatwas that support this. We have attached its links through the application’s web page www.tawkeel.com')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: What is the basis for selecting Umrah performers?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('Qualified performers are selected from the students of Al-Haram, Quran memorizers, and students of the Islamic University in Makkah Al-Mukarramah')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: How is my personal information protected?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('We have implemented and are working to maintain appropriate technical, organizational and security procedures, policies and processes, which are designed to protect information.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: How can I share my opinions about my Umrah experience?How can I share my opinions about my Umrah experience?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('We would like to know all your opinions and suggestions to help us improve the service we provide to you, you can email us at Support@tawkeel.com')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: Why is the application not working on my device?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('In the event that the application is on your device, and it did not work as required, this may be due to the old version of the application. You can update the application or contact the customer service center.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: I am facing a technical problem with the application?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('In the event that you are unable to order or encounter a technical problem while using the application, contact us immediately to assist you.')}</p>
                            </div>
                        </div>
                    </div>
                    <h2 className='secondary-heading text-center'>{_T('Performer Related Questions')}</h2>
                    <div className='faq-listing' id='faq02'>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T("Question: How can I register as a Umrah Performer?")}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('You can register as an Umrah performer through the application')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T("Question: What are the conditions for registering as a performer by applying at Tawkeel?")}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('The applicant must be a Muslim of the religion. - The applicant must be a Saudi citizen or an official resident in the Kingdom of Saudi Arabia, and registration is not allowed for those who have an expired identity card or residence permit. - The applicant\'s age must be 18 years or more. - The applicant must have submitted an Umrah or work in advance. - That the applicant has completed the doses for the Corona virus. - That the applicant has his own bank account. - The applicant must be residing in Jeddah, Makkah, or Taif.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T("Question: Why do I not have requests for Umrah allowance?")}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('If you do not receive a request to perform Umrah on behalf of others, please check the option to activate notifications until you receive requests for Umrah allowance, and you can also create a request to perform Umrah')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T("Question: If I am a visitor to the Kingdom, do I have the right to work?")}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('According to the terms and conditions, the pilgrims must be of Saudi nationality or officially residing in the Kingdom of Saudi Arabia.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T("Question: While I was performing Umrah and the application did not work for me, what can I do?")}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('If you encounter a problem while performing Umrah, you must immediately contact us so that we can serve you as required.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T("Question: If I already have an account and it does not work, what should I do?")}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('When you encounter a problem related to the account, you can contact customer service via the unified number or through the chat in the application.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T("Question: How can I join Tawkeel as a performer?")}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('You can register as an Umrah performer by downloading the copy of the performers. Please contact customer service to assist you in the registration process')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T("Question: What is the value of the amount that the performer charges for each completed request?")}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('The amounts charged by the performer range from 300 riyals to 500 Saudi riyals for each completed application.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T("Question: How long does it take for the amount to be deposited into the bank account of the performer after performing the Umrah?")}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('The Umrah amount is transferred within 5 working days from the date the application is properly completed.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T("Question: Does the performer have the right to cancel the order?")}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('The performer has the right to cancel before making the intention to perform the Umrah, after that the pilgrim will not have the right to cancel the Umrah.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T("Question: What happens if the Umrah is canceled after the start of the steps?")}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('Canceling the Umrah after starting it exposes the performer to a ban from using the application according to the mentioned terms of use')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T("Question: Can I change the account from a requester for Umrah to a performer?")}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('If you want to change the account from Umrah researcher to Umrah performer, you can create a new account with other data.')}</p>
                            </div>
                        </div>
                    </div>
                    <h2 className='secondary-heading text-center'>{_T('Questions Related to a Performer for Umrah')}</h2>
                    <div className='faq-listing' id='faq03'>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: The performer did not share the steps of performing Umrah with me?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('In the event that the performer does not share with the client the steps to perform the Umrah, you can contact us immediately to assist you.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: How do I know if my application has been accepted?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('You can know the status of a request and track it by clicking on requests, then clicking on the current request, and thus you can know the status of its request')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: Can I cancel the order?')}</h3>
                            <div className='faq-answer'>                                
                                <p>{_T('Answer:')} {_T('When the customer submits the order through the application, he will not be able to cancel the order under special conditions')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: Can I request several requests to perform Umrah at one time?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('You can request multiple Umrah allowance requests at the same time.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: Will a certificate of completion be obtained after completing the Umrah?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('Yes, and you can share and refer to it at any time')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: What is the mechanism for making sure that the Umrah is performed by the performer?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('There are places in the application during the Umrah trip to make sure of this by listening to record the intention of Umrah or video recording before starting the circumambulation or checking the pictures of the performer at the start and when cutting the ihram')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: Why should I store my credit card details?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('Storing your credit card details facilitates and speeds up the booking process, since you will not have to go through the process of entering your details every time.')}</p>
                            </div>
                        </div>
                    </div>
                    <h2 className='secondary-heading text-center'>{_T('Payment Currency Questions')}</h2>
                    <div className='faq-listing' id='faq04'>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: What are the available payment methods for the Tawkeel application?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('In Tawkeel Application, you can pay via MADA online (ATM card) - payment service by credit card (Visa - MasterCard), - Apple Pay. - Or through the electronic wallet in the application.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: Value Added Tax?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('Value Added Tax is an indirect tax imposed on all goods and services as applied by the General Authority of Zakat and Tax at the rate of 15%')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: Why was my payment declined?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('The payment attempt failed may be for one of the following reasons:')}</p>
                                <p>{_T('1- Insufficient balance, please check your account balance to make sure that you have enough funds to complete the payment process and have not exceeded the allowed limit.')}</p>
                                <p>{_T('2- The card verification code is incorrect, please check and try again.')}</p>
                                <p>{_T('3- An expired card, please remove the card or amend its data.')}</p>
                                <p>{_T('4- Entering wrong card information, please check the information and try payment again.')}</p>
                            </div>
                        </div>
                        <div className='single-faq' onClick={toggleQuestion}>
                            <h3 className='faq-question'>{_T('Question: What is an electronic wallet?')}</h3>
                            <div className='faq-answer'>
                                <p>{_T('Answer:')} {_T('A Tawkeel wallet is an electronic wallet linked to your account that allows you to make online purchases. You can use the available balance in your wallet to pay.')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer language={languages[language]}/>
        </>
    )
}

export default FAQ;