import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/tawkeel-logo.png'
import '../assets/css/navbar.css'
import _T from '../locale/Header/translator';
import './TikTokPixel.js';
import Modal from './Modal';

function toggle_navbar() {
    let body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("navbar-show")) {
        body.classList.remove("navbar-show");
    }
    else {
        body.classList.add("navbar-show");
    }
}
function section_scroll(e, id) {
    e.preventDefault();
    let scrollDiv = document.getElementById(id).offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
}
function switch_language(e, language) {
    e.preventDefault()
    let url = window.location.href
    if (language === "English") {
        url = url.replace('/en/', '/')
        url = url.replace('/en', '/')
        localStorage.setItem("lang", "ar")
    }
    else {
        var base_url = window.location.origin;
        url = base_url + '/en/' + url.replace(base_url + '/', '');
        localStorage.setItem("lang", "en")
    }
    window.location.href = url;
}


function Navbar({ language }) {
    const pageLoaded = useRef(1)
    const [showModal, setShowModal] = useState(false)
    const [consent, setConsent] = useState(false)
    const [next, setNext] = useState(0)

    const reset = () => {
        setShowModal(false);
        setConsent(false);
        setNext(0);
    }
    useEffect(() => {
        window.ttq.page();
    }, [pageLoaded.current])
    return (
        <React.Fragment>
            <div className="header-overlay"></div>
            <header>
                <div className="topbar">
                    <div className="wrapper topbar-wrapper">
                        <div className="topbar-left">
                            <span className='contact-label'>{_T("Contact Us")}</span>
                            <a href='tel:+9668002442203' className="btn has-icon escape font-escape topbar-whatsapp">
                                <i className="ri-whatsapp-fill mr-1"></i>
                                <span>+966 800 244 2203</span>
                            </a>
                            <a href='tel:+8002442203' className="btn has-icon escape font-escape topbar-phone">
                                <i className="ri-phone-fill mr-1"></i>
                                <span>800 244 2203</span>
                            </a>
                        </div>
                        <div className="topbar-right">
                            <div className='btn-group'>
                                <a onClick={(e) => { switch_language(e, language.text) }} href='#' className={`btn btn-lang has-stroke-icon escape font-escape ${language.code === "en" ? "text-ar" : "text-en"}`}>
                                <i className="ri-global-line mr-1 fs-22"></i>
                                    {language.text === "English" ? 'العربي' : 'English'}
                                </a>
                            </div>
                            <div className='mobile-toggle' onClick={toggle_navbar}><i className="ri-menu-5-line"></i></div>
                        </div>
                    </div>
                </div>
                <div className='wrapper header-wrapper'>
                    <div className='header-left'>
                        {/* <Link className='header-logo' to={language.text === "English" ? "/en" : "/"} ><img src={logo} alt="Tawkeel"/></Link> */}
                        <nav className='header-navigation'>
                            <ul className='navigation-menu-items'>
                                {/* <li className='menu-item'><a href='#' onClick={(e)=>{section_scroll(e,'home')}}>{_T("Home",language.code)}</a></li> */}
                                <li className='menu-item'><Link to={language.text === "English" ? "/en" : "/"} >{_T("Home", language.code)}</Link></li>
                                <li className='menu-item'><a href='#' onClick={(e) => { section_scroll(e, 'about') }}>{_T("About Tawkeel", language.code)}</a></li>
                                <li className='menu-item'><a href='#' onClick={(e) => { section_scroll(e, 'appObjectives') }}>{_T("Our Distinguishes", language.code)}</a></li>
                                <li className='menu-item'><a href='#' onClick={(e) => { section_scroll(e, 'screenshots') }}>{_T("How It Works", language.code)}</a></li>
                                <li className='menu-item'><Link to={language.text === "English" ? "/en/agents" : "/agents"}>{_T("Agents", language.code)}</Link></li>
                                <li className='menu-item'><a href='#' onClick={(e) => { section_scroll(e, 'news') }}>{_T("News", language.code)}</a></li>
                                <li className='menu-item'><a href='#' onClick={(e) => { section_scroll(e, 'gallery') }}>{_T("Gallery", language.code)}</a></li>
                                <li className='menu-item'><a href='#' onClick={(e) => { section_scroll(e, 'certificate') }}>{_T("Certificates", language.code)}</a></li>
                                <li className='menu-item'><a href='#' onClick={(e) => { section_scroll(e, 'contact') }}>{_T("Contact", language.code)}</a></li>
                                <li className='menu-item'><span className='cursor-pointer' onClick={() => { setShowModal(!showModal) }}>{_T("Register Now", language.code)}</span></li>
                            </ul>
                            <button onClick={toggle_navbar} className='btn-close'><i className="ri-close-line"></i></button>
                        </nav>
                    </div>
                    {/* <div className='header-right'>
                        <div className='btn-group'>
                            <a href='tel:+9668002442203' className="btn btn-light has-icon escape font-escape">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14.635" height="23.417" viewBox="0 0 14.635 23.417">
                                    <g id="phone_icon" data-name="phone_icon" transform="translate(-6.75)">
                                        <path id="phone_icon_01" data-name="phone_icon_01" d="M18.458,1.464H9.677A1.464,1.464,0,0,0,8.214,2.927V20.49a1.464,1.464,0,0,0,1.464,1.464h8.781a1.464,1.464,0,0,0,1.464-1.464V2.927A1.464,1.464,0,0,0,18.458,1.464ZM9.677,0A2.927,2.927,0,0,0,6.75,2.927V20.49a2.927,2.927,0,0,0,2.927,2.927h8.781a2.927,2.927,0,0,0,2.927-2.927V2.927A2.927,2.927,0,0,0,18.458,0Z" fillRule="evenodd"/>
                                        <path id="phone_icon_02" data-name="phone_icon_02" d="M17.214,29.927a1.464,1.464,0,1,0-1.464-1.464A1.464,1.464,0,0,0,17.214,29.927Z" transform="translate(-3.146 -9.437)" fillRule="evenodd"/>
                                    </g>
                                </svg>
                                +966 800 244 2203
                            </a>
                            <a onClick={(e)=>{switch_language(e,language.text)}} href='#' className={`btn btn-light has-stroke-icon escape font-escape ${ language.code === "en" ? "text-ar" : "text-en" }`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.927" height="20.391" viewBox="0 0 21.927 20.391">
                                    <g id="lang_arabic" data-name="lang_arabic" transform="translate(-2.525 -3.65)">
                                        <path id="lang_arabic_01" data-name="lang_arabic_01" d="M3.375,7.875H17.194" transform="translate(0 -1.072)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7"/>
                                        <path id="lang_arabic_02" data-name="lang_arabic_02" d="M13.5,4.5V6.8" transform="translate(-3.216 0)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7"/>
                                        <path id="lang_arabic_03" data-name="lang_arabic_03" d="M19.125,26.5,23.731,15.75,28.338,26.5" transform="translate(-5.002 -3.573)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7"/>
                                        <path id="lang_arabic_04" data-name="lang_arabic_04" d="M21.2,27h6.382" transform="translate(-5.661 -7.146)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7"/>
                                        <path id="lang_arabic_05" data-name="lang_arabic_05" d="M15.284,7.875a23.591,23.591,0,0,1-3.949,7.917,24.114,24.114,0,0,1-5.71,5.134" transform="translate(-0.715 -1.072)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7"/>
                                        <path id="lang_arabic_06" data-name="lang_arabic_06" d="M15.141,20.052a21.49,21.49,0,0,1-3.455-3.6A29.632,29.632,0,0,1,9,12.375" transform="translate(-1.786 -2.501)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7"/>
                                    </g>
                                </svg>
                                { language.text === "English" ? 'العربي' : 'English' }
                            </a>
                        </div>
                        <div className='mobile-toggle' onClick={toggle_navbar}><i className="ri-menu-5-line"></i></div>
                    </div> */}
                </div>
                <Modal isOpen={showModal} title={next === 0 ? _T("Register Now") : _T("Register as a Performer")} onClose={() => { reset() }} >
                    {
                        next === 0 ?
                            <div>
                                <p className='mt-0 mb-1 text-gray-100 fw-400'>{_T("Please choose your registration type")}</p>
                                <Link type='button' to={language.code === "en" ? "/en/quick-register" :  "/quick-register"} onClick={()=>{ setShowModal(false) }} className='btn btn-primary w-100 justify-center mt-3'>{_T("Register Agent Agency")}</Link>
                                {/* <Link type='button' to={"/en/agency-register"} className='btn btn-primary w-100 justify-center mt-3'>{_T("Register as Marketing Agency")}</Link> */}
                                <button type='button' onClick={() => { setNext(1) }} className='btn btn-primary w-100 justify-center mt-3'>{_T("Register as a Performer")}</button>
                            </div> :
                            next === 1 ?
                                <div>
                                    <p className='mt-0 mb-1 text-gray-100 fw-400 text-default fs-20'>{_T("There are special terms and conditions for registration as a performer,")}</p>
                                    <ul>
                                        <li className='text-gray-200 text-default fs-20'>{_T("The applicant must be 18 years old and above")}</li>
                                        <li className='text-gray-200 text-default fs-20'>{_T("The applicant must be a resident of one of these cities: Jeddah, Mecca, taif")}</li>
                                        <li className='text-gray-200 text-default fs-20'>{_T("The applicant must have a valid Saudi national identity card, or legally reside in the kingdom of Saudi Arabia and have a valid residency")}</li>
                                        <li className='text-gray-200 text-default fs-20'>{_T("The applicant must have his / her own bank account")}</li>
                                        <li className='text-gray-200 text-default fs-20'>{_T("The applicant must have previously performed Umrah or Hajj")}</li>
                                        <li className='text-gray-200 text-default fs-20'>{_T("The applicant should have knowledge in using applications on smartphones.")}</li>
                                    </ul>
                                    <label onClick={() => { setConsent(!consent) }} className='d-flex align-items-center text-default fs-20'>
                                        {
                                            consent ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width={20} viewBox="0 0 24 24" className={language.text === "English" ? 'mr-2' : 'ml-2'}><path d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z" fill="rgba(194,159,96,1)"></path></svg> :
                                                <svg xmlns="http://www.w3.org/2000/svg" width={20} viewBox="0 0 24 24" className={language.text === "English" ? 'mr-2' : 'ml-2'}><path d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM5 5V19H19V5H5Z"></path></svg>
                                        }
                                        {_T("I agree to the above terms and conditions")}
                                    </label>
                                    <button disabled={!consent} type='button' onClick={() => { setNext(2) }} className='btn btn-primary w-100 justify-center mt-3'>{_T("Continue")}</button>
                                </div> :
                                <div>
                                    <p className='mt-0 mb-1 text-gray-100 fw-400'>{_T("To register as a performer, follow these steps,")}</p>
                                    <ul>
                                        <li className='text-gray-200'>{_T("Log in through the attached link")} <br /> <a href='https://tawkeel.onelink.me/u5qw/eea6bb58' className='text-primary' target='_blank' rel={"noreferrer"} onClick={reset}>https://tawkeel.onelink.me/u5qw/eea6bb58</a></li>
                                        <li className='text-gray-200'>{_T("Login through the mobile number or through the social platforms present in the application")}</li>
                                        <li className='text-gray-200'>{_T("Create or enter your 6-digit PIN number")}</li>
                                        <li className='text-gray-200'>{_T("Use the following referral code (SHAH_1)")}</li>
                                    </ul>
                                    <a href='https://tawkeel.onelink.me/u5qw/eea6bb58' target='_blank' rel={"noreferrer"} onClick={reset} className='btn btn-primary w-100 justify-center mt-3'>{_T("Finish")}</a>
                                </div>
                    }
                </Modal>
            </header>
        </React.Fragment>
    )
}

export default Navbar;