import React, { useEffect } from 'react'
import Hero from "./Hero";
import Navbar from "../../components/NavbarInner";
import Footer from "../../components/FooterInner";
import {languages} from '../../common/languages'
import image_01 from '../../assets/images/help-center/1.png'
import image_02 from '../../assets/images/help-center/2.png'
import image_03 from '../../assets/images/help-center/3.jpg'
import image_04 from '../../assets/images/help-center/4.jpg'
import image_05 from '../../assets/images/help-center/5.jpg'
import image_06 from '../../assets/images/help-center/6.jpg'
import image_07 from '../../assets/images/help-center/7.jpg'
import image_08 from '../../assets/images/help-center/8.png'
import _T from '../../locale/HelpCenter/translator';
import { RP } from '../../components/FacebookPixel';
import '../../components/TikTokPixel.js';


function navigate_help_center(e,id,step){
    e.preventDefault()
    document.getElementsByClassName("helpCenter-container")[0].classList.remove("show-sidebar")
    let container = document.getElementsByClassName("helpCenter-content")[0]
    let loading = (100/document.getElementsByClassName("helpCenter-section").length)*step;
    document.getElementsByClassName("helpCenter-loading")[0].style.width = loading+"%"
    let offset = document.getElementById(id).offsetLeft 
    container.scrollTo({left: offset, behavior: 'smooth'});
}
function toggle_helpcenter_sidebar(){
    let e = document.getElementsByClassName("helpCenter-container")[0];
    if(e.classList.contains("show-sidebar")){
        document.getElementsByClassName("helpCenter-container")[0].classList.remove("show-sidebar")
    }
    else{
        document.getElementsByClassName("helpCenter-container")[0].classList.add("show-sidebar")
    }
}

document.addEventListener('click', function(event) {
    let sidebar = document.getElementsByClassName('helpCenter-sidebar')[0];
    if(sidebar){
        let toggle = document.getElementsByClassName('menuToggle')[0];
        let condition = sidebar.contains(event.target);
        let condition2 = toggle.contains(event.target);
        if (!condition && !condition2) {
            let e = document.getElementsByClassName("helpCenter-container")[0];
            if(e.classList.contains("show-sidebar")){
                document.getElementsByClassName("helpCenter-container")[0].classList.remove("show-sidebar")
            }
        }
    }
});

function SeekerHelpCenter({language}) {
    window.scrollTo({ top: 0});
    let direction = languages[language].direction
    const isEnglish = languages[language].text === "English" ? true : false
    if(direction === "RTL"){
      document.getElementsByTagName("body")[0].classList.add("RTL");
    }else{
      document.getElementsByTagName("body")[0].classList.remove("RTL");
    }

    useEffect(() => {
        const element = document.getElementById("helpCenter");
        let pos = element.offsetTop;
        window.scrollTo({ top: pos, behavior: 'smooth' });
    });
    useEffect(()=>{
      RP.pageView();
    },[])
    return (
        <>
            <Navbar language={languages[language]}/>
            <Hero heading={_T("Seeker Help Center")} language={languages[language]}/>
            <section id='helpCenter'>
                <div className='wrapper helpCenter-wrapper'>
                    <div className='helpCenter-container'>
                        <div className='helpCenter-header'>
                            <div className='header-left'>
                                <button className='menuToggle' onClick={toggle_helpcenter_sidebar}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                <h4>{_T("Seeker Help Center")}</h4>
                            </div>
                            <div className='header-right'>
                                <a href='https://tawkeelprod.blob.core.windows.net/webpdfs/[Seeker]%20User%20Manual.pdf' className='btn btn-light has-icon escape' download>
                                    <svg id="generatePDF" xmlns="http://www.w3.org/2000/svg" width="20.072" height="23.417" viewBox="0 0 20.072 23.417">
                                        <path id="generatePDF01" data-name="Path 11785" d="M21.8,27v3.345H8.423V27H6.75v3.345a1.673,1.673,0,0,0,1.673,1.673H21.8a1.673,1.673,0,0,0,1.673-1.673V27Z" transform="translate(-5.077 -8.601)" fill="#3f9a97"/>
                                        <path id="generatePDF02" data-name="Path 11786" d="M20.738,21.6l-1.183-1.183-2.163,2.163V15.75H15.72v6.835l-2.163-2.163L12.375,21.6l4.182,4.182Z" transform="translate(-6.521 -5.714)" fill="#3f9a97"/>
                                        <path id="generatePDF03" data-name="Path 11787" d="M29.768,3.923V2.25H24.75v8.363h1.673V7.268h2.509V5.6H26.423V3.923Z" transform="translate(-9.696 -2.25)" fill="#3f9a97"/>
                                        <path id="generatePDF04" data-name="Path 11788" d="M17.97,10.613H14.625V2.25H17.97a2.511,2.511,0,0,1,2.509,2.509V8.1A2.511,2.511,0,0,1,17.97,10.613ZM16.3,8.941H17.97a.837.837,0,0,0,.836-.836V4.759a.837.837,0,0,0-.836-.836H16.3Z" transform="translate(-7.098 -2.25)" fill="#3f9a97"/>
                                        <path id="generatePDF05" data-name="Path 11789" d="M8.682,2.25H4.5v8.363H6.173V8.1H8.682a1.675,1.675,0,0,0,1.673-1.673V3.923A1.674,1.674,0,0,0,8.682,2.25ZM6.173,6.432V3.923H8.682V6.432Z" transform="translate(-4.5 -2.25)" fill="#3f9a97"/>
                                    </svg>
                                    {_T('Download PDF')} </a>
                            </div>
                        </div>
                        <div className='helpCenter-sidebar'>
                            <ul>
                                <li>
                                    <a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide01",1)} }>{_T('Register as Umrah Seeker')}</a>
                                    <ol>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide01",1)} }>{_T('Mobile Number')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide01",1)} }>{_T('Through social media platforms')}</a></li>
                                    </ol>
                                </li>
                                <li>
                                    <a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide02",2)} }>{_T('Place Orders')}</a>
                                    <ol>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide02",2)} }>{_T('Service Selection')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide02",2)} }>{_T('Supplications Options')}</a></li>
                                    </ol>
                                </li>
                                <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide03",3)} }>{_T('Payment Process')}</a></li>
                                <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide04",4)} }>{_T('Requests')}</a></li>
                                <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide05",5)} }>{_T('Umrah allowance Tracking')}</a></li>
                                <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide06",6)} }>{_T('Chatting')}</a></li>
                                <li>
                                    <a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide07",7)} }>{_T('Settings')}</a>
                                    <ol>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide07",7)} }>{_T('User details')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide07",7)} }>{_T('App Language')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide07",7)} }>{_T('Payment Details')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide07",7)} }>{_T('Notifications')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide07",7)} }>{_T('Location Service')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide07",7)} }>{_T('Discount Code')}</a></li>
                                        <li><a href='#' className='slide-navigation' onClick={ (e)=>{navigate_help_center(e,"slide07",7)} }>{_T('About the App')}</a></li>
                                    </ol>
                                </li>
                            </ul>
                        </div>
                        <div className='helpCenter-content'>
                            <div className='helpCenter-section' id="slide01">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h2 className='section-primary-heading'>{_T("Register as Umrah Seeker")}</h2>
                                        <p className='section-paragraph'>{_T("The seekers can register in the Tawkeel mobile app through one of the following steps:")}</p>
                                        <h3 className='section-secondary-heading'>{_T("Mobile Number")}</h3>
                                        <p className='section-paragraph'>{_T("After you enter the mobile number, you will receive a text message with a verification code so that the application can complete the registration process.")}</p>
                                        <h3 className='section-secondary-heading'>{_T("Through social media platforms")}</h3>
                                        <p className='section-paragraph'>{_T("Choose the right platform for you and then follow the instructions. Once proceed with mobile number sign up or social media platform, lands on Profile screen to provide basic info along with Discount Code which is a reference number obtained through the authorized agents in user's country, if any.")}</p>
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={image_01} />
                                    </div>
                                </div>
                            </div>
                            <div className='helpCenter-section' id="slide02">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h3 className='section-primary-heading'>{_T("Place Orders")}</h3>
                                        <p className='section-paragraph'>{_T("Through this screen, you can request the umrah allowance for yourself or any other person. Select the Umrah tap, select the relation from the scrolling menu, and add the person's name. After that, place the order.")}</p>
                                        <h3 className='section-secondary-heading'>{_T("Service Selection")}</h3>
                                        <p className='section-paragraph'>{_T("As you can see on your home screen, umrah allowance cost would be based on your region such as if you are from Saudi Arabia then umrah cost would be shown in SAR for each order,after placing order, you will go to the supplication selection screen.")}</p>
                                        <h3 className='section-secondary-heading'>{_T("Supplications Options")}</h3>
                                        <p className='section-paragraph'>{_T("After service selection, you are ready to fill a specific supplication or select from the ready-made options, as shown on the next screen. This step is optional")}</p>
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={image_02} />
                                    </div>
                                </div>
                            </div>
                            <div className='helpCenter-section' id="slide03">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h2 className='section-primary-heading'>{_T("Payment Process")}</h2>
                                        <p className='section-paragraph'>{_T("After supplication options selection,you can proceed to make a payment.You have to pay for the order via Payfort which supports multiple payment options such as VISA, Master card, Mada etc. OR Payment can also be made via E-wallet.Whereas, E-wallet has your account balance. E-wallet option would only be shown when you have an account balance.")}</p>
                                        <p className='section-paragraph'>{_T("Another way to make a payment is from card & e-wallet both by enabling the toggle to use the amount in e-wallet too in a case when balance is less than the order amount. Then, such transaction from card & wallet can be placed.")}</p>
                                        <p className='section-paragraph'>{_T("After successful payment, order is placed and waiting for a performer to accept the order.")}</p>
                                        <p className='section-paragraph'>{_T("Order is auto-assigned to the available performers. Once a performer accepts an order, you need to confirm the order.")}</p>
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={image_03} />
                                    </div>
                                </div>
                            </div>
                            <div className='helpCenter-section' id="slide04">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h2 className='section-primary-heading'>{_T("Requests")}</h2>
                                        <p className='section-paragraph'>{_T("Through this screen, you can see the status of your Umrah allowance requests, which are divided into two parts:")}</p>
                                        <ol>
                                            <li><strong>{_T("Current Requests:")}</strong> {_T("By clicking on the icon, you can see your current Umrah performer detail and status. All the accepted, In-progress requests can be shown here.")}</li>
                                            <li><strong>{_T("Previous Requests:")}</strong>  {_T("A record of all the orders you made and their status in the system. Cancelled, Refunded & Completed orders can be viewed from Previous Requests.")}</li>
                                        </ol>
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={image_04} style={{maxWidth: "380px"}} />
                                    </div>
                                </div>
                            </div>
                            <div className='helpCenter-section' id="slide05">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h2 className='section-primary-heading'>{_T("Umrah allowance Tracking")}</h2>
                                        <p className='section-paragraph'>{_T("Tawkeel App has a unique & competitive advantage that allows you to track all the rituals of Umrah (Manasik) from your mobile, step by step, and the ability to chat with the umrah performer if you need something to say to him. You can request more than one order at the same time.")}</p>
                                    </div>
                                </div>
                                <div className='section-row'>
                                    <div className='section-column contains-image'>
                                        <img src={image_05} />
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={image_06} />
                                    </div>
                                </div>
                            </div>
                            <div className='helpCenter-section' id="slide06">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h2 className='section-primary-heading'>{_T("Chatting")}</h2>
                                        <p className='section-paragraph'>{_T("Chatting service provides you a channel to communicate with the Umrah performer for any other things to be arranged or say in the rituals of Umrah (Manasik).")}</p>
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={image_07} />
                                    </div>
                                </div>
                            </div>
                            <div className='helpCenter-section' id="slide07">
                                <div className='section-row'>
                                    <div className='section-column'>
                                        <h2 className='section-primary-heading'>{_T("Settings")}</h2>
                                        <p className='section-paragraph'>{_T("From this screen, you can enter or update the following:")}</p>
                                        <h3 className='section-sub-heading'>{_T("1. User details")}</h3>
                                        <p className='section-paragraph'>{_T("Tawkeel App allows you to maintain the your profile information such as your full name, email address, mobile number, profile picture and gender")}</p>
                                        <h3 className='section-sub-heading'>{_T("2. App Language")}</h3>
                                        <p className='section-paragraph'>{_T("Tawkeel App contains a wide range of languages, more than six languages.")}</p>
                                        <h3 className='section-sub-heading'>{_T("3. Payment Details")}</h3>
                                        <p className='section-paragraph'>{_T("You can view the transactions made to purchase orders along with their invoices.")}</p>
                                        <h3 className='section-sub-heading'>{_T("4. Notifications")}</h3>
                                        <p className='section-paragraph'>{_T("You can choose to enable or disable your notifications")}</p>
                                        <h3 className='section-sub-heading'>{_T("5. Location Service")}</h3>
                                        <p className='section-paragraph'>{_T("Activate the location service to identify the Umrah performer's current location for a better tracking follow-up.")}</p>
                                        <h3 className='section-sub-heading'>{_T("6. Discount Code")}</h3>
                                        <p className='section-paragraph'>{_T("Since discount code can be availed one time. From setting, another discount code can be added to avail the service.")}</p>
                                        <h3 className='section-sub-heading'>{_T("7. About the App")}</h3>
                                        <p className='section-paragraph'>{_T("You can get additional information about the application from the about section such as about Tawkeel, User Manual, Partners, FAQs etc")}</p>
                                    </div>
                                    <div className='section-column contains-image'>
                                        <img src={image_08}  style={{maxWidth: "380px"}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='helpCenter-loading' style={{width: "14.28%"}}></div>
                    </div>
                </div>
            </section>
            <Footer language={languages[language]}/>
        </>
    )
}

export default SeekerHelpCenter;