function _T(text){
    const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
    const translations = {
        "Home": {
            "en": "Home",
            "ar": "الرئيسية",
            "ur": ""
        },
        "Tawkeel": {
            "en": "Tawkeel",
            "ar": "توكيل",
            "ur": ""
        },
        "About Tawkeel": {
            "en": "About Tawkeel",
            "ar": "نبذة عن توكيل",
            "ur": ""
        },
        "Our Distinguishes": {
            "en": "Our Distinguishes",
            "ar": "ما يميزنا",
            "ur": ""
        },
        "News": {
            "en": "News",
            "ar": "أخبار",
            "ur": ""
        },
        "Gallery": {
            "en": "Gallery",
            "ar": "معرض الصور",
            "ur": ""
        },
        "Certificates": {
            "en": "Certificates",
            "ar": "الشهادات",
            "ur": ""
        },
        "Contact Us": {
            "en": "Contact Us",
            "ar": "اتصل بنا",
            "ur": ""
        },
        "About": {
            "en": "About",
            "ar": "نبذة عنا",
            "ur": ""
        },
        "Why Us?": {
            "en": "Why Us?",
            "ar": "لماذا نحن؟",
            "ur": ""
        },
        "Agents": {
            "en": "Agents",
            "ar": "وكلاء",
            "ur": ""
        },
        "How It Works": {
            "en": "How It Works?",
            "ar": "كيف نعمل؟",
            "ur": ""
        },
        "Our News": {
            "en": "Our News",
            "ar": "أخبارنا",
            "ur": ""
        },
        "Contact": {
            "en": "Contact",
            "ar": "تواصل معنا",
            "ur": ""
        },
        "Register Now": {
            "en": "Register Now",
            "ar": "سجّل الآن",
            "ur": ""
        },
        "Register as a Performer": {
            "en": "Register as a Performer",
            "ar": "التسجيل كمؤدي",
            "ur": ""
        },
        "There are special terms and conditions for registration as a performer,": {
            "en": "There are special terms and conditions for registration as a performer,",
            "ar": "یوجدشروطواحكام خاصھ للتسجیل كمؤدي",
            "ur": ""
        },
        "The applicant must be 18 years old and above": {
            "en": "The applicant must be 18 years old and above",
            "ar": "ان یكون عمرالمتقدم18سنھ فمافوق",
            "ur": ""
        },
        "The applicant must be a resident of one of these cities: Jeddah, Mecca, taif": {
            "en": "The applicant must be a resident of one of these cities: Jeddah, Mecca, taif",
            "ar": " ان یكون المتقدم من سكان احدھذه المدن : جده ,مكه ,الطايف",
            "ur": ""
        },
        "The applicant must have a valid Saudi national identity card, or legally reside in the kingdom of Saudi Arabia and have a valid residency": {
            "en": "The applicant must have a valid Saudi national identity card, or legally reside in the kingdom of Saudi Arabia and have a valid residency",
            "ar": "أن يكون لدى المتقدم بطاقة هوية وطنية سارية المفعول، أو مقیم بشكل نظامي بالمملكةالعربیھ السعودیةویوجداقامةساریةالمفعول",
            "ur": ""
        },
        "The applicant must have his / her own bank account": {
            "en": "The applicant must have his / her own bank account",
            "ar": "ان یكون لدى المتقدم لدیھ حساب بنكي خاص بھ",
            "ur": ""
        },
        "The applicant must have previously performed Umrah or Hajj": {
            "en": "The applicant must have previously performed Umrah or Hajj",
            "ar": "ان یكون المتقدم قدعمل عمرةاوحج سابقاً",
            "ur": ""
        },
        "The applicant should have knowledge in using applications on smartphones.": {
            "en": "The applicant should have knowledge in using applications on smartphones.",
            "ar": "انیكون المتقدم لدیھ معرفةفي استخدام التطبیقات على الھواتف الذكیة",
            "ur": ""
        },
        "I agree to the above terms and conditions": {
            "en": "I agree to the above terms and conditions",
            "ar": "أوافق على الشروط والأحكام المذكورة أعلاه",
            "ur": ""
        },
        "Continue": {
            "en": "Continue",
            "ar": "التالي",
            "ur": ""
        },
        "To register as a performer, follow these steps,": {
            "en": "To register as a performer, follow these steps,",
            "ar": "لتسجيلك كمؤدي تتبع الخطوات التاليه",
            "ur": ""
        },
        "Log in through the attached link": {
            "en": "Log in through the attached link",
            "ar": "أختر تسجيل الدخول من خلال الرابط المرفق",
            "ur": ""
        },
        "Login through the mobile number or through the social platforms present in the application": {
            "en": "Login through the mobile number or through the social platforms present in the application",
            "ar": "تسجيل الدخول من خلال رقم الجوال او من خلال المنصات الاجتماعيه الموجوده بالتطبيق",
            "ur": ""
        },
        "Create or enter your 6-digit PIN number": {
            "en": "Create or enter your 6-digit PIN number",
            "ar": "انشاء او ادخال الرقم السري الخاص بك و المكون من 6 ارقام",
            "ur": ""
        },
        "Use the following referral code (SHAH_1)": {
            "en": "Use the following referral code (SHAH_1)",
            "ar": "استخدام كود الإحالة التالي (SHAH_1)",
            "ur": ""
        },
        "Finish": {
            "en": "Finish",
            "ar": "تسجيل",
            "ur": ""
        },
        "Please choose your registration type": {
            "en": "Please choose your registration type",
            "ar": "الرجاء اختيار نوع التسجيل الخاص بك",
            "ur": ""
        },
        "Register as an Agency": {
            "en": "Register as an Agency",
            "ar": "سجل كوكالة",
            "ur": ""
        },
        "Register Agent Agency": {
            "en": "Register Agent Agency",
            "ar": "التسجيل كوكيل",
            "ur": ""
        },
        "Register as Marketing Agency": {
            "en": "Register as Marketing Agency",
            "ar":"التسجيل كوكالة تسويق",
            "ur": ""
        },
    }
    return translations[text] ? translations[text][language] : text; 
}
export default _T;