import React from 'react'
import {Link} from 'react-router-dom'
import helpCenterHero from '../../assets/images/help-center-image.png'
import mainLogo from "../../assets/images/main-logo.png"
import _T from '../../locale/HelpCenter/translator';

function Hero({heading, language}){
    return (
        <section id='helpCenterHome'>
            <div className='wrapper helpCenterHome-wrapper'>
                <div className='section-left'>
                    <h1 className='primary-heading'>{_T("Tawkeel")}</h1>
                    <h2 className='secondary-heading ibm-plex-sans-arabic-bold'>{_T("Help Center")}</h2>
                    <p className='text-paragraph'>{_T("The opportunity and ability to find someone to perform Umrah on your behalf at any time.")}</p>
                    <div className='btn-group hero-btn-group'>
                        <Link to={language.text === "English" ? "/en/help-center/performer" : "/help-center/performer"} className='btn btn-primary-home mt-1'>{ _T("For Performer")}</Link>
                        <Link to={language.text === "English" ? "/en/help-center/seeker" : "/help-center/seeker"} className='btn btn-primary-home mt-1'>{ _T("For Seeker")}</Link>
                    </div>
                </div>
                <div className='section-right'>
                    {/* <img src={helpCenterHero} alt="Hero Section"/> */}
                </div>
            </div>
        </section>
    )
}

export default Hero;