import PhoneInput, { isPossiblePhoneNumber , isValidPhoneNumber } from 'react-phone-number-input';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import Hero from "./Hero";
import Navbar from "../../components/NavbarInner";
import Footer from "../../components/FooterInner";
import noImage from "../../assets/images/no-image.jpg";
import {languages} from '../../common/languages'
import { countries } from "../../common/countries";
import _T from "../../locale/QuickRegister/translator";
import 'react-phone-number-input/style.css'
import "../../assets/css/quick-register.css";
import '../../components/TikTokPixel.js';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { GetBaseURL } from '../../common/getBaseURL.js';

const QuickRegister = ({language}) => {
  const [ search, setSearch] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState({ text: "", status: false, completed: false });
  const [logo, setLogo] = useState({ file: null, preview: noImage });
  const [agencyTitle,setAgencyTitle] = useState("");
  const [agentName,setAgentName] = useState("");
  const [agentNumber,setAgentNumber] = useState("");
  const [agentEmail,setAgentEmail] = useState("");
  const [agencyNumber,setAgencyNumber] = useState("");
  const [agencyEmail,setAgencyEmail] = useState("");
  const [registrationNo,setRegistrationNo] = useState("");
  const [registrationYear,setRegistrationYear] = useState("");

  const [registrationDate,setRegistrationDate] = useState(moment().format("YYYY-MM-DD"));
  const [postalCode,setPostalCode] = useState("");
  const [designation,setDesignation] = useState("");
  const [industry,setIndustry] = useState("");
  const [contractEnd,setContractEnd] = useState(moment().add(90, 'days').format("YYYY-MM-DD"));
  const [bankCountry,setBankCountry] = useState("");
  const [bankCity,setBankCity] = useState("");
  const [bankIBAN,setBankIBAN] = useState("");
  const [bankName,setBankName] = useState("");

  const [agencyAddress,setAgencyAddress] = useState("");
  const [agencyCountry,setAgencyCountry] = useState("0");
  const [agencyCity,setAgencyCity] = useState("");
  const [files, setFiles] = useState([ { file: null, size: "", extension: "" }, { file: null, size: "", extension: "" }, { file: null, size: "", extension: "" }, { file: null, size: "", extension: "" }, { file: null, size: "", extension: "" } ])
  const [errors,setErrors] = useState({ logo: "", agencyTitle: "", agencyNumber: "", agencyEmail: "", agentName: "", agentNumber: "", agentEmail: "", registrationNo: "", registrationYear: "", agencyAddress: "", agencyCountry: "", agencyCity: "", registrationDate: "" ,postalCode: "" ,designation: "" ,industry: "" ,contractEnd: "" ,bankCountry: "" ,bankCity: "" ,bankIBAN: "" ,bankName: "" , files: "" })
  
  useEffect(()=>{
    window.scrollTo({ top: 0});
    let direction = languages[language].direction
    if(direction === "RTL"){
      document.getElementsByTagName("body")[0].classList.add("RTL");
    }
    else{
      document.getElementsByTagName("body")[0].classList.remove("RTL");
    }
  },[])

  function section_scroll(id = "registrationForm"){
    let scrollDiv = document.getElementById(id).offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
  }

  const validateSubmit = (e) => {
    e.preventDefault();
    const error = { logo: "", agencyTitle: "", agencyNumber: "", agencyEmail: "", agentName: "", agentNumber: "", agentEmail: "", registrationNo: "", registrationYear: "", agencyAddress: "", agencyCountry: "", agencyCity: "", registrationDate: "" ,postalCode: "" ,designation: "" ,industry: "" ,contractEnd: "" ,contractTarget: "" ,contractAmount: "" ,bankCountry: "" ,bankCity: "" ,bankIBAN: "" ,bankName: "" , files: "" };

    let flag = false;
    if(!logo || logo.file === null || logo.file === undefined ){
      error.logo = _T("Agency Logo is required");
      flag = true;
    }
    if(!agencyTitle || !/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(agencyTitle) || agencyTitle.replaceAll(/\s/g, '').length < 1 ){
      error.agencyTitle = _T("Provide valid agency title");
      flag = true;
    }
    if (!agencyEmail || agencyEmail.replaceAll(/\s/g, '').length < 3) {
      error.agencyEmail = _T("Provide valid email address");
      flag = true;
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(agencyEmail)) {
      error.agencyEmail = _T("Provide valid email address");
      flag = true;
    }
    if (!agencyNumber || !isValidPhoneNumber(agencyNumber) || !isPossiblePhoneNumber(agencyNumber)) {
        error.agencyNumber = _T("Provide valid phone number");
        flag = true;
    }
    if(!agentName || !/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(agentName) || agentName.replaceAll(/\s/g, '').length < 1 ){
      error.agentName = _T("Provide valid agent name");
      flag = true;
    }
    if (!agentEmail || agentEmail.replaceAll(/\s/g, '').length < 3) {
      error.agentEmail = _T("Provide valid email address");
      flag = true;
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(agentEmail)) {
      error.agentEmail = _T("Provide valid email address");
      flag = true;
    }
    if (!agentNumber || !isValidPhoneNumber(agentNumber) || !isPossiblePhoneNumber(agentNumber)) {
        error.agentNumber = _T("Provide valid phone number");
        flag = true;
    }
    if (!registrationNo || registrationNo.replaceAll(/\s/g, '').length < 2) {
      error.registrationNo = _T("Provide valid registration no");
      flag = true;
    }
    if (!registrationYear || isNaN(registrationYear) || parseInt(registrationYear) > new Date().getFullYear() || registrationYear.replaceAll(/\s/g, '').length < 4) {
      error.registrationYear = _T("Provide year when agency was founded");
      flag = true;
    }
    if (!agencyAddress || agencyAddress.replaceAll(/\s/g, '').length < 4) {
      error.agencyAddress = _T("Provide agency address");
      flag = true;
    }
    if (!agencyCountry || agencyCountry == 0 ) {
      error.agencyCountry = _T("Select agency country");
      flag = true;
    }
    if (!agencyCity || agencyCity.replaceAll(/\s/g, '').length < 4) {
      error.agencyCity = _T("Provide agency city");
      flag = true;
    }

    if(!moment(contractEnd).isValid() || moment(contractEnd).isBefore(moment().add(90, 'days').format("YYYY-MM-DD"))){
      error.contractEnd = _T("Ending date should be after")+" "+moment().add(90, 'days').format("DD-MM-YYYY");
      flag = true;
    }
    if(!moment(registrationDate).isValid() || moment(registrationDate).isAfter(moment())){
      error.registrationDate = _T("Provide a registration date");
      flag = true;
    }

    if (!bankName || bankName.replaceAll(/\s/g, '').length < 3) {
      error.bankName = _T("Provide bank name");
      flag = true;
    }
    if (!bankCountry || bankCountry == 0 ) {
      error.bankCountry = _T("Select bank country");
      flag = true;
    }

    if (!bankIBAN || bankIBAN.replaceAll(/\s/g, '').length < 15) {
      error.bankIBAN = _T("Provide valid account IBAN");
      flag = true;
    }
    
    if (!bankCity || bankCity.replaceAll(/\s/g, '').length < 4) {
      error.bankCity = _T("Provide bank city");
      flag = true;
    }
    if (!industry || industry.replaceAll(/\s/g, '').length < 3) {
      error.industry = _T("Provide valid industry name");
      flag = true;
    }
    if (!designation || designation.replaceAll(/\s/g, '').length < 2) {
      error.designation = _T("Provide valid designation");
      flag = true;
    }
    if (!postalCode || postalCode.replaceAll(/\s/g, '').length < 4) {
      error.postalCode = _T("Provide valid postal code");
      flag = true;
    }


    let fileError = files.find((f)=>{ return f.file !== null && f.file !== undefined })
    if(!fileError){
      error.files = _T("Atleast one verification document is required");
      flag = true;
    }
    setErrors(error);
    if(!flag && !isLoading){
      setIsLoading(true);
      const data = new FormData();

      data.append('commercial_registration_date', registrationDate);
      data.append('agency_postal_code', postalCode);
      data.append('agent_designation', designation);
      data.append('agency_industry', industry);
      data.append('contract_end_date', moment(contractEnd).format("DD"));
      data.append('contract_end_month', moment(contractEnd).format("MM"));
      data.append('contract_end_year', moment(contractEnd).format("YYYY"));
      data.append('bank_country', bankCountry);
      data.append('bank_city', bankCity);
      data.append('bank_iban', bankIBAN);
      data.append('bank_name', bankName);
      
      data.append('agency_type', 1);
      data.append('agency_name', agencyTitle);
      data.append('agency_code', agencyTitle.replaceAll(/\s/g,'_'));
      data.append('agent_name', agentName);
      data.append('agent_email', agentEmail);
      data.append('registration_no', registrationNo);
      data.append('city', agencyCity);
      data.append('balance', 0);
      data.append('reference_commission', 0);
      data.append('country', agencyCountry);
      data.append('is_exclusive', false);
      data.append('status', false);
      data.append('codes', []);
      data.append('agent_target', 0);
      data.append('agent_target_achived', 0);
      data.append('agent_commission', 0);
      data.append('first_commission', 0);
      data.append('performer_commission', 0);
      data.append('refered', []);
      data.append('languages', []);
      data.append('swift_code', "");
      data.append('beneficiary_name', "");
      data.append('beneficiary_address', "");
      data.append('iban_number', "");
      data.append('agent_phone_number', agentNumber);
      data.append('agency_email', agencyEmail);
      data.append('agency_phone_number', agencyNumber);
      data.append('agency_address', agencyAddress);
      data.append('year_founded', registrationYear);
      data.append('agency_logo_image', logo.file);
      data.append('agency_file_url_0', files[0].file);
      data.append('agency_file_url_1', files[1].file);
      data.append('agency_file_url_2', files[2].file);
      data.append('agency_file_url_3', files[3].file);
      data.append('agency_file_url_4', files[4].file);
      axios.post(process.env.REACT_APP_API_BASEURL + 'admin/web-agencies', data, { headers: { 'content-type': 'multipart/form-data' }})
        .then(({data}) => {
          if(data.status){
            setServerResponse({ text: _T("Your registration has been completed. You will recieve a confirmation email at your given agency email address"), status: true, completed: true });
            setIsLoading(false);
            section_scroll("quickRegister")
          }
          else{
            setServerResponse({ text: _T("Your registration could not be completed at the moment. Please try again"), status: false, completed: true });
            setIsLoading(false);
            section_scroll("quickRegister")
          }
        })
        .catch(error => {
          setServerResponse({ text: _T("Your registration could not be completed at the moment. Please try again"), status: false, completed: true });
          setIsLoading(false);
          section_scroll("quickRegister")
        });
    }
    else{
      section_scroll()
    }
  }

  const resetForm = () => {
    setLogo({ file: null, preview: noImage });
    setAgencyTitle("");
    setAgentName("");
    setAgentNumber("");
    setAgentEmail("");
    setAgencyNumber("");
    setAgencyEmail("");
    setRegistrationNo("");
    setRegistrationYear("");
    setAgencyAddress("");
    setAgencyCountry(0);
    setAgencyCity("");
    setRegistrationDate(moment().format("YYYY-MM-DD"));
    setPostalCode("");
    setDesignation("");
    setIndustry("");
    setContractEnd(moment().add(90, 'days').format("YYYY-MM-DD"));
    setBankCountry("");
    setBankCity("");
    setBankIBAN("");
    setBankName("");
    setServerResponse({ text: "", status: false, completed: false })
    setFiles([ { file: null, size: "", extension: "" }, { file: null, size: "", extension: "" }, { file: null, size: "", extension: "" }, { file: null, size: "", extension: "" }, { file: null, size: "", extension: "" } ])
    setErrors({ logo: "", agencyTitle: "", agencyNumber: "", agencyEmail: "", agentName: "", agentNumber: "", agentEmail: "", registrationNo: "", registrationYear: "", agencyAddress: "", agencyCountry: "", agencyCity: "", registrationDate: "" ,postalCode: "" ,designation: "" ,industry: "" ,contractEnd: "" ,bankCountry: "" ,bankCity: "" ,bankIBAN: "" ,bankName: "" , files: "" })
    document.getElementById("agencyLogo").value = "";
    document.getElementById("agencyFile01").value = "";
    document.getElementById("agencyFile02").value = "";
    document.getElementById("agencyFile03").value = "";
    document.getElementById("agencyFile04").value = "";
    document.getElementById("agencyFile05").value = "";
  }

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const validateFile = (event,id) => {
    const file = event.target.files[0];
    const ext =  file.name.split('.').pop();
    const allowed_formats = ["jpg","jpeg","png","pdf"]
    const fileObjects = ["agencyFile01","agencyFile02","agencyFile03","agencyFile04","agencyFile05"]
    const index = fileObjects.indexOf(id);
    const tempFiles = files;
    if(allowed_formats.includes(ext.toLowerCase())){
      tempFiles[index] = { file: file, size: formatBytes(file.size), extension: ext.toLowerCase() }
      setErrors((prev)=>{ return {...prev, files: "" } })
      setFiles(tempFiles)
    }
    else{
      tempFiles[index] = { file: null, size: "", extension: "" }
      setErrors((prev)=>{ return {...prev, files: _T("Please only upload files with allowed formats (jpg, jpeg, png, pdf)") } })
      setFiles(tempFiles)
      document.getElementById(id).value = "";
    }
  };

  const validateLogo = (event,id) => {
    const file = event.target.files[0];
    const ext =  file.name.split('.').pop();
    const allowed_formats = ["jpg","jpeg","png"]
    if(allowed_formats.includes(ext.toLowerCase())){
      setErrors((prev)=>{ return {...prev, logo: "" } })
      setLogo({ file: file, preview: URL.createObjectURL(file) })
    }
    else{
      setErrors((prev)=>{ return {...prev, logo: _T("Please only upload image with allowed formats (jpg, jpeg, png)") } })
      setLogo({ file: null, preview: noImage })
      document.getElementById(id).value = "";
    }
  };

  const handleNumber = (value,min) => {
    let v = value.replace(/\D/g, '');
    if(v === "" || !v.length){
        v = min;
    }
    else{
        v = parseInt(value.replace(/\D/g, ''))
        if(v < min){
          v = min
        }
    }
    return ""+v
}

  const extensions = {
    "jpg":  <i className="ri-file-image-line"></i>,
    "jpeg": <i className="ri-file-image-line"></i>,
    "png":  <i className="ri-file-image-line"></i>,
    "pdf":  <i className="ri-file-pdf-2-line"></i>,
  }

  return( 
    <>
      <Navbar language={languages[language]}/>
      <Hero/>
      <section id="quickRegister">
        <div className="quickRegister-wrapper wrapper">
          <div className="quick-register-body">
            {
              !serverResponse.completed ?
              <>
                <div className="quick-register-body-header mb-5 mt-5 pt-3 pb-1 text-center">
                  <h2 className="secondary-heading mb-0">{_T("Registration Information")}</h2>
                  <p className="text-paragraph">{_T("Please provide the following information to create your agency account with Tawkeel")}</p>
                </div>
                <form className="quick-register-form" onSubmit={validateSubmit} id="registrationForm">
                  <div className="form-fields">
                    <div className="form-group logo-container">
                      <label htmlFor="agencyLogo">
                        <img src={logo.preview} alt="Agency Logo"/>
                        <input type="file" id="agencyLogo" onChange={(e)=>{ validateLogo(e,"agencyLogo") }} accept="image/jpg,image/jpeg,image/gif,image/png"/>
                        <span className="text-white">{_T("Upload Logo")}<span>*</span></span>
                      </label>
                      { errors.logo ? <p className="text-danger fs-14 mt-1 mb-0">{errors.logo}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="agencyTitle">{_T('Agency Name')}<span>*</span></label>
                      <input type="text" id="agencyTitle" value={agencyTitle} onChange={(e)=>{setAgencyTitle(e.target.value.slice(0, 80))}} placeholder={_T('Enter Agency Name')}/>
                      { errors.agencyTitle ? <p className="text-danger fs-14 mt-1 mb-0">{errors.agencyTitle}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="agencyNumber">{_T("Agency's Number")}<span>*</span></label>
                      <PhoneInput id="agencyNumber" international defaultCountry="SA" placeholder={_T("Enter Agency's Number")} value={agencyNumber} onChange={(e)=>{ setAgencyNumber(e); }}/>
                      { errors.agencyNumber ? <p className="text-danger fs-14 mt-1 mb-0">{errors.agencyNumber}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="agencyEmail">{_T("Agency's Email")}<span>*</span></label>
                      <input type="email" id="agencyEmail" value={agencyEmail} onChange={(e)=>{setAgencyEmail(e.target.value.slice(0, 100))}} placeholder={_T("Enter Agency's Email")}/>
                      { errors.agencyEmail ? <p className="text-danger fs-14 mt-1 mb-0">{errors.agencyEmail}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="industry">{_T("Agency Industry")}<span>*</span></label>
                      <input type="text" id="industry" value={industry} onChange={(e)=>{setIndustry(e.target.value.slice(0, 50))}} placeholder={_T("Enter Agency Industry e.g Marketing, Travel")}/>
                      { errors.industry ? <p className="text-danger fs-14 mt-1 mb-0">{errors.industry}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="registrationNo">{_T("Commercial Registration No")}<span>*</span></label>
                      <input type="text" id="registrationNo" value={registrationNo} onChange={(e)=>{setRegistrationNo(e.target.value.slice(0, 30))}} placeholder={_T("Enter Commercial Registration No")}/>
                      { errors.registrationNo ? <p className="text-danger fs-14 mt-1 mb-0">{errors.registrationNo}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="registrationDate">{_T("Commercial Registration Date")}<span>*</span></label>
                      <input type="date" max={moment().format("YYYY-MM-DD")} id="registrationDate" value={registrationDate} onChange={(e)=>{setRegistrationDate(e.target.value)}}/>
                      { errors.registrationDate ? <p className="text-danger fs-14 mt-1 mb-0">{errors.registrationDate}</p> : null }
                    </div>
                    <div className="form-group w-100">
                      <label htmlFor="agencyAddress">{_T("Agency Address")}<span>*</span></label>
                      <input type="text" id="agencyAddress" value={agencyAddress} onChange={(e)=>{setAgencyAddress(e.target.value.slice(0, 200))}} placeholder={_T("Enter Agency Address")}/>
                      { errors.agencyAddress ? <p className="text-danger fs-14 mt-1 mb-0">{errors.agencyAddress}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="postalCode">{_T("Agency Postal Code")}<span>*</span></label>
                      <input type="text" id="postalCode" value={postalCode} onChange={(e)=>{setPostalCode(e.target.value.replace(/\D/g, '').slice(0, 9))}} placeholder={_T("Enter Agency Postal Code")}/>
                      { errors.postalCode ? <p className="text-danger fs-14 mt-1 mb-0">{errors.postalCode}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="registrationYear">{_T("Year Founded")}<span>*</span></label>
                      <input type="text" id="registrationYear" value={registrationYear} onChange={(e)=>{setRegistrationYear(e.target.value.replace(/\D/g, '').slice(0, 4))}} placeholder={_T("Enter Year Founded")}/>
                      { errors.registrationYear ? <p className="text-danger fs-14 mt-1 mb-0">{errors.registrationYear}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="agencyCountry">{_T("Agency Country")}<span>*</span></label>
                      <select id="agencyCountry" value={agencyCountry} onChange={(e)=>{setAgencyCountry(e.target.value)}}>
                        <option value={0}>{_T("Select Agency Country")}</option>
                        {
                          countries.map((item,key)=>{
                            return <option key={key} value={item.name}>{item.name}</option>
                          })
                        }
                      </select>
                      { errors.agencyCountry ? <p className="text-danger fs-14 mt-1 mb-0">{errors.agencyCountry}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="agencyCity">{_T("Agency City")}<span>*</span></label>
                      <input type="text" id="agencyCity" value={agencyCity} onChange={(e)=>{setAgencyCity(e.target.value.slice(0, 100))}} placeholder={_T("Enter Agency City")}/>
                      { errors.agencyCity ? <p className="text-danger fs-14 mt-1 mb-0">{errors.agencyCity}</p> : null }
                    </div>

                    <div className='w-100 mt-3 mb-3'><hr style={{color: "#efefef"}}/></div>
                    <div className='w-100'>
                        <h3 className='m-0 text-gray-200 fw-500 subtitle'>{_T("Agent Information")}</h3>
                        <p className='mt-0 mb-3 fs-14 fw-400 text-white'>{_T("Please provide your agency's agent/representative information")}</p>
                    </div>
                    <div className="form-group">
                      <label htmlFor="agentName">{_T("Agent's Name")}<span>*</span></label>
                      <input type="text" id="agentName" value={agentName} onChange={(e)=>{setAgentName(e.target.value.slice(0, 80))}} placeholder={_T("Enter Agent Name")}/>
                      { errors.agentName ? <p className="text-danger fs-14 mt-1 mb-0">{errors.agentName}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="agentNumber">{_T("Agent's Number")}<span>*</span></label>
                      <PhoneInput id="agentNumber" international defaultCountry="SA" placeholder={_T("Enter Agent's Number")} value={agentNumber} onChange={(e)=>{ setAgentNumber(e); }}/>
                      { errors.agentNumber ? <p className="text-danger fs-14 mt-1 mb-0">{errors.agentNumber}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="agentEmail">{_T("Agent's Email")}<span>*</span></label>
                      <input type="email" id="agentEmail" value={agentEmail} onChange={(e)=>{setAgentEmail(e.target.value.slice(0, 100))}} placeholder={_T("Enter Agent's Email")}/>
                      { errors.agentEmail ? <p className="text-danger fs-14 mt-1 mb-0">{errors.agentEmail}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="designation">{_T("Agent's Designation")}<span>*</span></label>
                      <input type="text" id="designation" value={designation} onChange={(e)=>{setDesignation(e.target.value.slice(0, 100))}} placeholder={_T("Enter Agent's Designation")}/>
                      { errors.designation ? <p className="text-danger fs-14 mt-1 mb-0">{errors.designation}</p> : null }
                    </div>

                    <div className='w-100 mt-3 mb-3'><hr style={{color: "#efefef"}}/></div>
                    <div className='w-100'>
                        <h3 className='m-0 text-gray-200 fw-500 subtitle'>{_T("Bank Information")}</h3>
                        <p className='mt-0 mb-3 fs-14 fw-400 text-white'>{_T("Please provide your agency's bank information")}</p>
                    </div>

                    <div className="form-group">
                      <label htmlFor="bankCountry">{_T("Bank Country")}<span>*</span></label>
                      <select id="bankCountry" value={bankCountry} onChange={(e)=>{setBankCountry(e.target.value)}}>
                        <option value={0}>{_T("Select Bank Country")}</option>
                        {
                          countries.map((item,key)=>{
                            return <option key={key} value={item.name}>{item.name}</option>
                          })
                        }
                      </select>
                      { errors.bankCountry ? <p className="text-danger fs-14 mt-1 mb-0">{errors.agencyCountry}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="bankCity">{_T("Bank City")}<span>*</span></label>
                      <input type="text" id="bankCity" value={bankCity} onChange={(e)=>{setBankCity(e.target.value.slice(0, 100))}} placeholder={_T("Enter Bank City")}/>
                      { errors.bankCity ? <p className="text-danger fs-14 mt-1 mb-0">{errors.bankCity}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="bankName">{_T("Bank Name")}<span>*</span></label>
                      <input type="text" id="bankName" value={bankName} onChange={(e)=>{setBankName(e.target.value.slice(0, 34))}} placeholder={_T("Enter Bank Name")}/>
                      { errors.bankName ? <p className="text-danger fs-14 mt-1 mb-0">{errors.bankName}</p> : null }
                    </div>
                    <div className="form-group">
                      <label htmlFor="bankIBAN">{_T("Account IBAN")}<span>*</span></label>
                      <input type="text" id="bankIBAN" value={bankIBAN} onChange={(e)=>{setBankIBAN(e.target.value.slice(0, 34))}} placeholder={_T("Enter Account IBAN")}/>
                      { errors.bankIBAN ? <p className="text-danger fs-14 mt-1 mb-0">{errors.bankIBAN}</p> : null }
                    </div>

                    <div className='w-100 mt-3 mb-3'><hr style={{color: "#efefef"}}/></div>
                    <div className='w-100'>
                        <h3 className='m-0 text-gray-200 fw-500 subtitle'>{_T("Contract Information")}</h3>
                        <p className='mt-0 mb-3 fs-14 fw-400 text-white'>{_T("Please provide your agency's contract information")}</p>
                    </div>
                    <div className="form-group">
                      <label htmlFor="contractEnd">{_T("Contract Ending Date")}<span>*</span></label>
                      <input type="date" id="contractEnd" min={moment().add(90, 'days').format("YYYY-MM-DD")} value={contractEnd} onChange={(e)=>{setContractEnd(e.target.value)}}/>
                      { errors.contractEnd ? <p className="text-danger fs-14 mt-1 mb-0">{errors.contractEnd}</p> : null }
                    </div>
                    <div className='w-100 mt-3 mb-3'><hr style={{color: "#efefef"}}/></div>
                    <div className='w-100'>
                        <h3 className='m-0 text-gray-200 fw-500 subtitle'>{_T("Verification Documents")}</h3>
                        <p className='mt-0 mb-3 fs-14 fw-400 text-white'>{_T("Upload Company License and Verification Documents")}</p>
                    </div>
                    <div className="form-group file-container">
                      <div className="file-list">
                        <label htmlFor="agencyFile01">
                          <input type="file" id="agencyFile01" onChange={(e)=>{validateFile(e,'agencyFile01')}} accept="image/jpg,image/jpeg,image/gif,image/png,image/bmp,application/pdf"/>
                          {
                            files[0].file && files[0].extension ?
                            <span className="file-info">
                              { extensions[files[0].extension] }
                              <h6>{ files[0].file.name.length > 15 ? files[0].file.name.slice(0,6)+"..."+ files[0].file.name.substr(files[0].file.name.length - 6) : files[0].file.name }</h6>
                              <p>{ files[0].size }</p>
                            </span> :
                            <span>+</span>
                          }
                        </label>
                        <label htmlFor="agencyFile02">
                          <input type="file" id="agencyFile02" onChange={(e)=>{validateFile(e,'agencyFile02')}} accept="image/jpg,image/jpeg,image/gif,image/png,image/bmp,application/pdf"/>
                          {
                            files[1].file && files[1].extension ?
                            <span className="file-info">
                              { extensions[files[1].extension] }
                              <h6>{ files[1].file.name.length > 15 ? files[1].file.name.slice(0,6)+"..."+ files[1].file.name.substr(files[1].file.name.length - 6) : files[1].file.name }</h6>
                              <p>{ files[1].size }</p>
                            </span> :
                            <span>+</span>
                          }
                        </label>
                        <label htmlFor="agencyFile03">
                          <input type="file" id="agencyFile03" onChange={(e)=>{validateFile(e,'agencyFile03')}} accept="image/jpg,image/jpeg,image/gif,image/png,image/bmp,application/pdf"/>
                          {
                            files[2].file && files[2].extension ?
                            <span className="file-info">
                              { extensions[files[2].extension] }
                              <h6>{ files[2].file.name.length > 15 ? files[2].file.name.slice(0,6)+"..."+ files[2].file.name.substr(files[2].file.name.length - 6) : files[2].file.name }</h6>
                              <p>{ files[2].size }</p>
                            </span> :
                            <span>+</span>
                          }
                        </label>
                        <label htmlFor="agencyFile04">
                          <input type="file" id="agencyFile04" onChange={(e)=>{validateFile(e,'agencyFile04')}} accept="image/jpg,image/jpeg,image/gif,image/png,image/bmp,application/pdf"/>
                          {
                            files[3].file && files[3].extension ?
                            <span className="file-info">
                              { extensions[files[3].extension] }
                              <h6>{ files[3].file.name.length > 15 ? files[3].file.name.slice(0,6)+"..."+ files[3].file.name.substr(files[3].file.name.length - 6) : files[3].file.name }</h6>
                              <p>{ files[3].size }</p>
                            </span> :
                            <span>+</span>
                          }
                        </label>
                        <label htmlFor="agencyFile05">
                          <input type="file" id="agencyFile05" onChange={(e)=>{validateFile(e,'agencyFile05')}} accept="image/jpg,image/jpeg,image/gif,image/png,image/bmp,application/pdf"/>
                          {
                            files[4].file && files[4].extension ?
                            <span className="file-info">
                              { extensions[files[4].extension] }
                              <h6>{ files[4].file.name.length > 15 ? files[4].file.name.slice(0,6)+"..."+ files[4].file.name.substr(files[4].file.name.length - 6) : files[4].file.name }</h6>
                              <p>{ files[4].size }</p>
                            </span> :
                            <span>+</span>
                          }
                        </label>
                      </div>
                    </div>
                    { errors.files ? <p className="text-danger fs-14 mt-1 mb-0">{errors.files}</p> : null }
                  </div>
                  <button className="btn btn-primary mt-4" disabled={isLoading} type="submit">{ isLoading ? _T("Please Wait...") : _T("Register Now") }</button>
                </form> 
              </>: 
              serverResponse.status ?
              <div className="success-response text-center mt-5">
                <h2 className="secondary-heading-alt mb-0">{_T("Registration Completed")}</h2>
                <p className="text-paragraph mt-2">{serverResponse.text}</p>
              </div> :
              <div className="failure-response text-center mt-5">
                <h2 className="secondary-heading-alt mb-0">{_T("Registration Failed")}</h2>
                <p className="text-paragraph mt-2 mb-3">{serverResponse.text}</p>
                <button className="btn btn-primary" type="button" onClick={()=>{ resetForm(); }}>{_T("Try Again")}</button>
              </div>
            }
          </div>
        </div>
      </section>
      <Footer language={languages[language]}/>
    </>
)}

export default QuickRegister;

