import React, {useState} from 'react'
import aboutScreenshot from "../../assets/images/about-screenshot.png"
import mainLogo from "../../assets/images/main-logo.png"
import p1 from '../../assets/images/gallery/Picture1.jpg'
import p2 from '../../assets/images/gallery/Picture2.jpg'
import p3 from '../../assets/images/gallery/Picture3.jpg'
import p4 from '../../assets/images/gallery/Picture4.jpg'
import defaultImage from '../../assets/images/default-news.jpg'
import _T from '../../locale/Home/translator';

function PhotoGallery({ language }) {
    const [showModal, setShowModal] = useState(false)
    const [showVideoModal, setShowVideoModal] = useState(false)
    const [selected, setSelected] = useState(null)
    const [selectedVideo, setSelectedVideo] = useState(null)
    const [caption, setCaption] = useState("")
    return (
        <section id='about'>
            <div className='wrapper about-wrapper'>
                <div className="overlay"></div>
                <div className='section-right'>
                    <img src={mainLogo} alt="Tawkeel" />
                    <h2 className='secondary-heading'>{_T("Photo Gallery")}</h2>
                    <div className='photo-gallery'>
                        <div className='single-image' onClick={() => { setShowModal(true); setSelected(p1); setCaption("With His Excellency the Minister of Hajj and Umrah, Dr. Tawfiq Al-Rabiah, during his visit to Tawkeel at the Hajj and Umrah Exhibition for the year 2023") }}>
                            <img src={p1} alt="With His Excellency the Minister of Hajj and Umrah, Dr. Tawfiq Al-Rabiah, during his visit to Tawkeel at the Hajj and Umrah Exhibition for the year 2023" />
                            <i className="ri-image-2-line"></i>
                        </div>
                        <div className='single-image' onClick={() => { setShowModal(true); setSelected(p2); setCaption("The General Manager of Tawkeel Eng. Raed Kayyal with the news anchor during a TV interview about the Tawkeel application, which was broadcast on Al-Ekhbariya channel") }}>
                            <img src={p2} alt="The General Manager of Tawkeel Eng. Raed Kayyal with the news anchor during a TV interview about the Tawkeel application, which was broadcast on Al-Ekhbariya channel" />
                            <i className="ri-image-2-line"></i>
                        </div>
                        <div className='single-image' onClick={() => { setShowModal(true); setSelected(p3); setCaption("Some of Tawkeel's visitors at the Hajj and Umrah exhibition for the year 2023") }}>
                            <img src={p3} alt="Some of Tawkeel's visitors at the Hajj and Umrah exhibition for the year 2023" />
                            <i className="ri-image-2-line"></i>
                        </div>
                        <div className='single-image' onClick={() => { setShowModal(true); setSelected(p4); setCaption("During a TV interview with Iqraa TV at the Hajj and Umrah Fair for the year 2023") }}>
                            <img src={p4} alt="During a TV interview with Iqraa TV at the Hajj and Umrah Fair for the year 2023" />
                            <i className="ri-image-2-line"></i>
                        </div>
                        <div className='single-image' onClick={() => { setShowVideoModal(true); setSelectedVideo("https://tawkeelprod.blob.core.windows.net/webvideos/gallery-video-01.mp4"); }}>
                            <video>
                                <source src="https://tawkeelprod.blob.core.windows.net/webvideos/gallery-video-01.mp4" type="video/mp4" />
                            </video>
                            <i className="ri-vidicon-line"></i>
                        </div>
                    </div>
                </div>
            </div>
            {
                showModal ?
                    <div className='modal'>
                        <div className='moda-close' onClick={() => { setShowModal(false) }}><i className="ri-close-line"></i></div>
                        <div className='moda-overlay' onClick={() => { setShowModal(false) }}></div>
                        <div className='modal-body'>
                            <img src={selected} alt={caption} />
                            <p>{_T(caption)}</p>
                        </div>
                    </div> : <></>
            }
            {
                showVideoModal ?
                    <div className='modal'>
                        <div className='moda-close' onClick={() => { setShowVideoModal(false) }}><i className="ri-close-line"></i></div>
                        <div className='moda-overlay' onClick={() => { setShowVideoModal(false) }}></div>
                        <div className='modal-body'>
                            <video src={selectedVideo} type="video/mp4" controls={true}>
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div> : <></>
            }
        </section>
    )
}

export default PhotoGallery;