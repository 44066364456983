function _T(text){
    const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
    const translations = {
        "Commonly Asked Questions": {
            "en": "Commonly Asked Questions",
            "ar": "الأسئلة الشائعة",
            "ur": ""
        },
        "Frequently Asked Questions": {
            "en": "Frequently Asked Questions",
            "ar": "الأسئلة المتداولة",
            "ur": ""
        },
        "Tawkeel": {
            "en": "Tawkeel",
            "ar": "توكيل",
            "ur": ""
        },
        "We understand that you may have queries regarding our offerings, and we have compiled this comprehensive list of FAQs to provide you with the information you need.": {
            "en": "We understand that you may have queries regarding our offerings, and we have compiled this comprehensive list of FAQs to provide you with the information you need.",
            "ar": "نحن ندرك أنه قد تكون لديك استفسارات حول عروضنا، ولذلك قمنا بإعداد قائمة شاملة من الأسئلة الشائعة لتزويدك بالمعلومات التي تحتاجها.",
            "ur": ""
        },
        "View FAQs": {
            "en": "View FAQs",
            "ar": 'عرض الأسئلة الشائعة',
            "ur": ""
        },
        "General Questions": {
            "en": "General Questions",
            "ar": 'الأسئلة العامة',
            "ur": ""
        },
        "Performer Related Questions": {
            "en": "Performer Related Questions",
            "ar": 'الأسئلة المتعلقة بالمؤدي',
            "ur": ""
        },
        "Questions Related to a Performer for Umrah": {
            "en": "Questions Related to a Performer for Umrah",
            "ar": 'الأسئلة المتعلقة بطالب للعمرة',
            "ur": ""
        },
        "Payment Currency Questions": {
            "en": "Payment Currency Questions",
            "ar": 'الأسئلة المتعلقة بعملة الدفع',
            "ur": ""
        },
        "Answer:": {
            "en": "Answer:",
            "ar": 'إجابة:',
            "ur": ""
        },
        "Question: What is the Tawkeel application?": {
            "en": "Question: What is the Tawkeel application?",
            "ar": 'سؤال: ماهو تطبيق توكيل؟',
            "ur": ""
        },
        "Question: Is it permissible to perform Umrah instead of another person?": {
            "en": "Question: Is it permissible to perform Umrah instead of another person?",
            "ar": 'سؤال: هل يجوز الاعتمار بدلاً عن شخص اخر؟',
            "ur": ""
        },
        "Question: Is the application available in my language?": {
            "en": "Question: Is the application available in my language?",
            "ar": 'سؤال: هل التطبيق متاح بلغتي؟',
            "ur": ""
        },
        "Question: Who is the Umrah Seeker?": {
            "en": "Question: Who is the Umrah Seeker?",
            "ar": 'سؤال: من هو طالب العمرة؟',
            "ur": ""
        },
        "Question: Who is the Performer?": {
            "en": "Question: Who is the Performer?",
            "ar": 'سؤال: من هو المؤدي؟',
            "ur": ""
        },
        "Question: How can I contact an agent?": {
            "en": "Question: How can I contact an agent?",
            "ar": 'سؤال: كيف يمكنني الاتصال بتوكيل؟',
            "ur": ""
        },
        "Question: How can I send a complaint to Tawkeel?": {
            "en": "Question: How can I send a complaint to Tawkeel?",
            "ar": 'سؤال: كيف يمكنني ارسال شكوى الى توكيل؟',
            "ur": ""
        },
        "Question: What is the Umrah of the allowance?": {
            "en": "Question: What is the Umrah of the allowance?",
            "ar": 'سؤال: ماهي عمرة البدل؟',
            "ur": ""
        },
        "Question: Is Umrah Al-Badal legally permissible?": {
            "en": "Question: Is Umrah Al-Badal legally permissible?",
            "ar": 'سؤال: هل عمرة البدل جائزة شرعيا؟',
            "ur": ""
        },
        "Question: What is the basis for selecting Umrah performers?": {
            "en": "Question: What is the basis for selecting Umrah performers?",
            "ar": 'سؤال: ما هو أساس اختيار مؤدين العمرة؟',
            "ur": ""
        },
        "Question: How is my personal information protected?": {
            "en": "Question: How is my personal information protected?",
            "ar": 'سؤال: كيف تتم حماية معلوماتي الشخصية؟',
            "ur": ""
        },
        "Question: How can I share my opinions about my Umrah experience?How can I share my opinions about my Umrah experience?": {
            "en": "Question: How can I share my opinions about my Umrah experience?How can I share my opinions about my Umrah experience?",
            "ar": 'سؤال: كيف يمكنني مشاركة ارائي عن تجربة أداء العمرة الخاصة بي؟',
            "ur": ""
        },
        "Question: Why is the application not working on my device?": {
            "en": "Question: Why is the application not working on my device?",
            "ar": 'سؤال: لماذا التطبيق لا يعمل على جهازي؟',
            "ur": ""
        },
        "Question: I am facing a technical problem with the application?": {
            "en": "Question: I am facing a technical problem with the application?",
            "ar": 'سؤال: اواجه مشكله تقنيه في التطبيق؟',
            "ur": ""
        },
        "An application based on providing pilgrims to perform the rituals of Umrah, on behalf of Muslims who are unable to perform Umrah and the deceased among them, which was permitted by the true Islamic religion.": {
            "en": "An application based on providing pilgrims to perform the rituals of Umrah, on behalf of Muslims who are unable to perform Umrah and the deceased among them, which was permitted by the true Islamic religion.",
            "ar": 'تطبيق مبنى على توفير معتمرين للقيام بأداء مناسك العمرة،بالنيابة عن المسلمين الغير قادرين على اداء العمرة والمتوفين منهم،والتي سمح بها الدين الاسلامي الحنيف.',
            "ur": ""
        },
        'لا حرج إن شاء الله؛ لقوله ﷺ: العمرة إلى العمرة كفارة لما بينهما، ولم يشترط أياماً معلومة بينهما فالحديث عام، فإذا اعتمر الإنسان لنفسه وفرغ من عمرته ثم ذهب إلى التنعيم واعتمر لأبيه الميت أو الهرم الذي لا يستطيع، أو لأمه أو لغيرهما من الأموات أو لعجزهم لكبر السن فلا بأس، لكن إذا كان زحمة كأيام الحج فالأفضل أن يكتفي بعمرة واحدة، حتى يخفف على الناس، ولا يشق عليهم - ابن باز.': {
            "en": 'لا حرج إن شاء الله؛ لقوله ﷺ: العمرة إلى العمرة كفارة لما بينهما، ولم يشترط أياماً معلومة بينهما فالحديث عام، فإذا اعتمر الإنسان لنفسه وفرغ من عمرته ثم ذهب إلى التنعيم واعتمر لأبيه الميت أو الهرم الذي لا يستطيع، أو لأمه أو لغيرهما من الأموات أو لعجزهم لكبر السن فلا بأس، لكن إذا كان زحمة كأيام الحج فالأفضل أن يكتفي بعمرة واحدة، حتى يخفف على الناس، ولا يشق عليهم - ابن باز.',
            "ar": 'لا حرج إن شاء الله؛ لقوله ﷺ: العمرة إلى العمرة كفارة لما بينهما، ولم يشترط أياماً معلومة بينهما فالحديث عام، فإذا اعتمر الإنسان لنفسه وفرغ من عمرته ثم ذهب إلى التنعيم واعتمر لأبيه الميت أو الهرم الذي لا يستطيع، أو لأمه أو لغيرهما من الأموات أو لعجزهم لكبر السن فلا بأس، لكن إذا كان زحمة كأيام الحج فالأفضل أن يكتفي بعمرة واحدة، حتى يخفف على الناس، ولا يشق عليهم - ابن باز.',
            "ur": ""
        },
        "The application has more than 4 languages.": {
            "en": "The application has more than 4 languages.",
            "ar": 'يوجد بالتطبيق  اكثر من 4 لغات.',
            "ur": ""
        },
        'He is the one who is looking for someone to perform the Umrah on his behalf or the so-called "Seeker"': {
            "en": 'He is the one who is looking for someone to perform the Umrah on his behalf or the so-called "Seeker"',
            "ar": 'هو من يبحث عن من يقوم بتأدية أداء العمرة بنيابه عنه او ما يسمى "الموكل"',
            "ur": ""
        },
        "He is a sane adult Muslim from the people of Makkah or its surroundings who has performed the Umrah of Islam before and has the desire or intention to perform the Umrah on behalf of his client.": {
            "en": "He is a sane adult Muslim from the people of Makkah or its surroundings who has performed the Umrah of Islam before and has the desire or intention to perform the Umrah on behalf of his client.",
            "ar": 'هو شخص مسلم بالغ عاقل من اهل مكة او من حوليها قام بأداء عمرة الإسلام من قبل ولديه الرغبة او النية في ان  يقوم بأداء العمرة بنيابه عن موكله.',
            "ur": ""
        },
        "You have multiple ways to communicate with us. You can contact us via the unified customer service number 80024422203, via WhatsApp, or through the chat in the application. Regarding a specific question or issue, whether you need to speak with a customer service employee or any other matter.": {
            "en": "You have multiple ways to communicate with us. You can contact us via the unified customer service number 80024422203, via WhatsApp, or through the chat in the application. Regarding a specific question or issue, whether you need to speak with a customer service employee or any other matter.",
            "ar": 'تتوفر امامكم طرق متعددة للتواصل معنا.يمكنكم التواصل معنا عبر الرقم الموحد الخاص بخدمة العملاء ٨٠٠٢٤٤٢٢٢٠٣ او عن طريق الواتساب او من خلال الدردشة الموجودة بالتطبيق.بشأن سؤال آو مسألة محددة،سواء كنتم بحاجة الى التحدث مع احد موظفي خدمة العملاء او أي امر اخر.',
            "ur": ""
        },
        "We take all customer feedback very seriously and our priority is to ensure that you are satisfied with your Umrah experience with us. If you need to report any problem or complaint about your experience, you can communicate through our e-mail or communicate with us via the unified number of the customer service center or through chatting with the application. It is always useful for us to review your comments to know how we can improve the services we provide. We will review your complaint as soon as possible and we send you a reference number via e-mail to assure you that we are resolving your complaint.": {
            "en": "We take all customer feedback very seriously and our priority is to ensure that you are satisfied with your Umrah experience with us. If you need to report any problem or complaint about your experience, you can communicate through our e-mail or communicate with us via the unified number of the customer service center or through chatting with the application. It is always useful for us to review your comments to know how we can improve the services we provide. We will review your complaint as soon as possible and we send you a reference number via e-mail to assure you that we are resolving your complaint.",
            "ar": 'نتعامل مع جميع الملاحظات  التي يقدمها العملاء بجدية مطلقة وتكمن آولويتنا في ضمان رضاكم  بتجربة أداء العمرة معنا. اذا احتجتم للإبلاغ عن أي مشكلة او شكوى بشأن تجربتكم ,يمكنكم التواصل من خلال البريد الالكتروني الخاص بنا او التواصل معنا عبر الرقم الموحد الخاص بمركز خدمة العملاء او من خلال المحاثه بالتطبيق.يفيدنا دائماً الاطلاع على ملاحظاتكم لمعرفة كيف يمكننا تحسين الخدمات التي نقدمها.سوف نراجع الشكوى التي تقدمونها بأسرع وقت ممكن ونرسل لكم رقماً مرجعياً عبر البريد الالكتروني لنؤكد لكم اننا ندرس الشكوى الخاصة بكم.',
            "ur": ""
        },
        "It is the Umrah that a person performs on behalf of another person who is unable to perform it either due to illness, death, etc": {
            "en": "It is the Umrah that a person performs on behalf of another person who is unable to perform it either due to illness, death, etc",
            "ar": 'هي العمرة التي يقوم بتأديتها شخص بالنيابة عن شخص آخر لا يستطيع أدائها اما بسبب المرض او الوفاة وغيره',
            "ur": ""
        },
        "Yes, it is legally permissible. There are legal fatwas that support this. We have attached its links through the application’s web page www.tawkeel.com": {
            "en": "Yes, it is legally permissible. There are legal fatwas that support this. We have attached its links through the application’s web page www.tawkeel.com",
            "ar": 'نعم جائزة شرعيا، يوجد فتاوي شرعية تؤيد ذلك ارفقنا روابطها من خلال الصفحة الالكترونية للتطبيق www.tawkeel.com',
            "ur": ""
        },
        "Qualified performers are selected from the students of Al-Haram, Quran memorizers, and students of the Islamic University in Makkah Al-Mukarramah": {
            "en": "Qualified performers are selected from the students of Al-Haram, Quran memorizers, and students of the Islamic University in Makkah Al-Mukarramah",
            "ar": 'يتم اختيار المؤدين المؤهلين من طلبة الحرم وحفظة القرآن وطلاب الجامعة الإسلامية بمدينة مكة المكرمة',
            "ur": ""
        },
        "We have implemented and are working to maintain appropriate technical, organizational and security procedures, policies and processes, which are designed to protect information.": {
            "en": "We have implemented and are working to maintain appropriate technical, organizational and security procedures, policies and processes, which are designed to protect information.",
            "ar": 'قمنا بتنفيذ إجراءات وسياسات وعمليات تقنية وتنظيمية وأمنية مناسبة ونعمل على المحافظة عليها، وهي مصممة لحماية المعلومات.',
            "ur": ""
        },
        "We would like to know all your opinions and suggestions to help us improve the service we provide to you, you can email us at Support@tawkeel.com": {
            "en": "We would like to know all your opinions and suggestions to help us improve the service we provide to you, you can email us at Support@tawkeel.com",
            "ar": 'نود معرفة جميع ارائكم ومقترحاتكم لمساعدتنا في تحسين الخدمة التي نقدمها لكم، يمكنكم مراسلتنا عبر البريد الالكتروني Support@tawkeel.com',
            "ur": ""
        },
        "In the event that the application is on your device, and it did not work as required, this may be due to the old version of the application. You can update the application or contact the customer service center.": {
            "en": "In the event that the application is on your device, and it did not work as required, this may be due to the old version of the application. You can update the application or contact the customer service center.",
            "ar": 'في حال كان التطبيق على جهازك ،ولم يعمل بالشكل المطلوب قد يكون ذالك بسبب النسخة القديمة من الاصدار للتطبيق يمكنك تحديث التطبيق او التواصل بمركز خدمة العملاء.',
            "ur": ""
        },
        "In the event that you are unable to order or encounter a technical problem while using the application, contact us immediately to assist you.": {
            "en": "In the event that you are unable to order or encounter a technical problem while using the application, contact us immediately to assist you.",
            "ar": 'في حال عدم القدرة على الطلب او مواجهة مشكلة تقنيه اثناء استخدام التطبيق قم بمراسلتنا فوراً لمساعدتك.',
            "ur": ""
        },
        "Question: How can I register as a Umrah Performer?": {
            "en": "Question: How can I register as a Umrah Performer?",
            "ar": 'سؤال:كيف يمكني التسجيل كمؤدي بتوكيل؟',
            "ur": ""
        },
        "Question: What are the conditions for registering as a performer by applying at Tawkeel?": {
            "en": "Question: What are the conditions for registering as a performer by applying at Tawkeel?",
            "ar": 'سؤال:ماهي شروط لتسجيل كمؤدي بتطبيق توكيل؟',
            "ur": ""
        },
        "Question: Why do I not have requests for Umrah allowance?": {
            "en": "Question: Why do I not have requests for Umrah allowance?",
            "ar": 'سؤال:لماذا لايوجد لدي طلبات بدل العمرة؟',
            "ur": ""
        },
        "Question: If I am a visitor to the Kingdom, do I have the right to work?": {
            "en": "Question: If I am a visitor to the Kingdom, do I have the right to work?",
            "ar": 'سؤال:في حال كنت زائر لدى المملكة هل يحق لي العمل؟',
            "ur": ""
        },
        "Question: While I was performing Umrah and the application did not work for me, what can I do?": {
            "en": "Question: While I was performing Umrah and the application did not work for me, what can I do?",
            "ar": 'سؤال:اثناء قيامي بأداء العمرة ولم يعمل التطبيق معي،ماذا يمكنني ان افعل؟',
            "ur": ""
        },
        "Question: If I already have an account and it does not work, what should I do?": {
            "en": "Question: If I already have an account and it does not work, what should I do?",
            "ar": 'سؤال:في حال كان لدي حساب مسبقاً ولايعمل،ماذا علي ان افعل؟',
            "ur": ""
        },
        "Question: How can I join Tawkeel as a performer?": {
            "en": "Question: How can I join Tawkeel as a performer?",
            "ar": 'سؤال:كيف يمكنني الانضمام لتوكيل كمؤدي؟',
            "ur": ""
        },
        "Question: What is the value of the amount that the performer charges for each completed request?": {
            "en": "Question: What is the value of the amount that the performer charges for each completed request?",
            "ar": 'سؤال:ماهي قيمة المبلغ الذي يتقاضاه المؤدي  مقابل اجراء كل طلب مكتمل؟',
            "ur": ""
        },
        "Question: How long does it take for the amount to be deposited into the bank account of the performer after performing the Umrah?": {
            "en": "Question: How long does it take for the amount to be deposited into the bank account of the performer after performing the Umrah?",
            "ar": 'سؤال:ماهي المدة المستغرقة لاايداع المبلغ الى حساب البنك لدى المؤدي بعد تأديه العمرة؟',
            "ur": ""
        },
        "Question: Does the performer have the right to cancel the order?": {
            "en": "Question: Does the performer have the right to cancel the order?",
            "ar": 'سؤال:هل يحق للمؤدي الغاء الطلب؟',
            "ur": ""
        },
        "Question: What happens if the Umrah is canceled after the start of the steps?": {
            "en": "Question: What happens if the Umrah is canceled after the start of the steps?",
            "ar": 'سؤال: ماذا يحصل في حال تم الغاء العمرة بعد بدء الخطوات؟',
            "ur": ""
        },
        "Question: Can I change the account from a requester for Umrah to a performer?": {
            "en": "Question: Can I change the account from a requester for Umrah to a performer?",
            "ar": 'سؤال:هل يمكنني تغير الحساب من طالب للعمرة الى مؤدي؟',
            "ur": ""
        },
        "You can register as an Umrah performer through the application": {
            "en": "You can register as an Umrah performer through the application",
            "ar": 'يمكنك التسجيل كمؤدي للعمرة من خلال التطبيق',
            "ur": ""
        },
        "The applicant must be a Muslim of the religion. - The applicant must be a Saudi citizen or an official resident in the Kingdom of Saudi Arabia, and registration is not allowed for those who have an expired identity card or residence permit. - The applicant's age must be 18 years or more. - The applicant must have submitted an Umrah or work in advance. - That the applicant has completed the doses for the Corona virus. - That the applicant has his own bank account. - The applicant must be residing in Jeddah, Makkah, or Taif.": {
            "en": "The applicant must be a Muslim of the religion. - The applicant must be a Saudi citizen or an official resident in the Kingdom of Saudi Arabia, and registration is not allowed for those who have an expired identity card or residence permit. - The applicant's age must be 18 years or more. - The applicant must have submitted an Umrah or work in advance. - That the applicant has completed the doses for the Corona virus. - That the applicant has his own bank account. - The applicant must be residing in Jeddah, Makkah, or Taif.",
            "ar": 'ان يكون المتقدم / ه مسلم /ه الديانه. -ان يكون المتقدم مواطن سعودي او مقيم بشكل رسمي بالمملكة العربيه السعوديه,ولايسمح بالتسجيل لمن لديه بطاقه هويه او اقامة منتهية الصلاحيه.-ان يكون عمر المتقدم / ه 18 سنه فما فوق.- ان يكون المتقدم / ه قدم عمل عمرة او حج مسبقاً.-ان يكون المتقدم / ه قد اكمل الجرعات الخاصه بفايروس كورونا.-ان يكون لدى المتقدم /ه حساب بنكي خاص به.-لابد بأن يكون المتقدم / ه مقيم بجده,او مكة,او الطايف.',
            "ur": ""
        },
        "If you do not receive a request to perform Umrah on behalf of others, please check the option to activate notifications until you receive requests for Umrah allowance, and you can also create a request to perform Umrah": {
            "en": "If you do not receive a request to perform Umrah on behalf of others, please check the option to activate notifications until you receive requests for Umrah allowance, and you can also create a request to perform Umrah",
            "ar": 'اذا كان لايوجد لديك طلب اداء عمرة بالنيابه عن الغير ،يرجى التحقق من خيار تفعيل الاشعارات حتى تصلك طلبات بدل العمرة,كما يمكنك انشأ طلب اداء العمرة',
            "ur": ""
        },
        "According to the terms and conditions, the pilgrims must be of Saudi nationality or officially residing in the Kingdom of Saudi Arabia.": {
            "en": "According to the terms and conditions, the pilgrims must be of Saudi nationality or officially residing in the Kingdom of Saudi Arabia.",
            "ar": 'وفق الشروط والاحكام يجب ان يكون المعتمر سعودي الجنسية او مقيم بشكل رسمي بالمملكة العربية السعودية.',
            "ur": ""
        },
        "If you encounter a problem while performing Umrah, you must immediately contact us so that we can serve you as required.": {
            "en": "If you encounter a problem while performing Umrah, you must immediately contact us so that we can serve you as required.",
            "ar": 'اذا كنت تواجه مشكلة اثناء تآدية العمرة يجب عليك فوراً التواصل معنا حتى نتمكن من خدمتك بالشكل المطلوب.',
            "ur": ""
        },
        "When you encounter a problem related to the account, you can contact customer service via the unified number or through the chat in the application.": {
            "en": "When you encounter a problem related to the account, you can contact customer service via the unified number or through the chat in the application.",
            "ar": 'عند مواجة مشكلة متعلقة بالحساب يمكنك التواصل مع خدمة العملاء عبر الرقم الموحد او من خلال المحادثه الموجوده بالتطبيق.',
            "ur": ""
        },
        "You can register as an Umrah performer by downloading the copy of the performers. Please contact customer service to assist you in the registration process": {
            "en": "You can register as an Umrah performer by downloading the copy of the performers. Please contact customer service to assist you in the registration process",
            "ar": 'يمكنك التسجيل كمؤدي للعمرة من خلال تحميل النسخة الخاصة بالمؤدين، الرجاء التواصل مع خدمة العملاء لمساعدتك في عملية التسجيل',
            "ur": ""
        },
        "The amounts charged by the performer range from 300 riyals to 500 Saudi riyals for each completed application.": {
            "en": "The amounts charged by the performer range from 300 riyals to 500 Saudi riyals for each completed application.",
            "ar": 'تترواح المبالغ التي يتقاضاها المؤدي مابين 300 ريال الى 500 ريال سعودي مقابل آجراء كل طلب مكتمل.',
            "ur": ""
        },
        "The Umrah amount is transferred within 5 working days from the date the application is properly completed.": {
            "en": "The Umrah amount is transferred within 5 working days from the date the application is properly completed.",
            "ar": 'يتم تحويل المبلغ المعتمر خلال 5 أيام عمل من تاريخ اكتمال الطلب بالشكل الصحيح.',
            "ur": ""
        },
        "The performer has the right to cancel before making the intention to perform the Umrah, after that the pilgrim will not have the right to cancel the Umrah.": {
            "en": "The performer has the right to cancel before making the intention to perform the Umrah, after that the pilgrim will not have the right to cancel the Umrah.",
            "ar": 'يحق للمؤدي إلغاء قبل عمل خطوة نية العمرة،بعد ذلك لن يكون من حق المعتمر إلغاء العمرة.',
            "ur": ""
        },
        "Canceling the Umrah after starting it exposes the performer to a ban from using the application according to the mentioned terms of use": {
            "en": "Canceling the Umrah after starting it exposes the performer to a ban from using the application according to the mentioned terms of use",
            "ar": 'الغاء العمرة بعد البدء بها يعرض المؤدي للحظر من استخدام التطبيق حسب شروط الاستخدام المذكورة ',
            "ur": ""
        },
        "If you want to change the account from Umrah researcher to Umrah performer, you can create a new account with other data.": {
            "en": "If you want to change the account from Umrah researcher to Umrah performer, you can create a new account with other data.",
            "ar": 'اذا كانت رغبتك  بتغير الحساب من باحث عمرة الى معتمر،يمكنك عمل حساب جديد ببيانات أخرى.',
            "ur": ""
        },
        "Question: The performer did not share the steps of performing Umrah with me?": {
            "en": "Question: The performer did not share the steps of performing Umrah with me?",
            "ar": 'سؤال: لم يشارك المؤدي خطوات أداء العمرة معي؟',
            "ur": ""
        },
        "Question: How do I know if my application has been accepted?": {
            "en": "Question: How do I know if my application has been accepted?",
            "ar": 'سؤال: كيف يمكنني معرفة اذا كان طلبي قد تم قبوله؟',
            "ur": ""
        },
        "Question: Can I cancel the order?": {
            "en": "Question: Can I cancel the order?",
            "ar": 'سؤال: هل يمكنني الغاء الطلب؟',
            "ur": ""
        },
        "Question: Can I contact the performer?": {
            "en": "Question: Can I contact the performer?",
            "ar": 'سؤال: هل يمكنني التواصل مع المؤدي؟',
            "ur": ""
        },
        "Question: Can I request several requests to perform Umrah at one time?": {
            "en": "Question: Can I request several requests to perform Umrah at one time?",
            "ar": 'سؤال: هل يمكنني طلب عدة طلبات لااداء العمرة بوقت واحد؟',
            "ur": ""
        },
        "Question: Will a certificate of completion be obtained after completing the Umrah?": {
            "en": "Question: Will a certificate of completion be obtained after completing the Umrah?",
            "ar": 'سؤال: هل سيتم الحصول على شهادة انجاز بعد اكتمال العمرة؟',
            "ur": ""
        },
        "Question: What is the mechanism for making sure that the Umrah is performed by the performer?": {
            "en": "Question: What is the mechanism for making sure that the Umrah is performed by the performer?",
            "ar": 'سؤال: ماهي الية التأكد من تأدية العمرة من قبل المؤدي؟',
            "ur": ""
        },
        "Question: Why should I store my credit card details?": {
            "en": "Question: Why should I store my credit card details?",
            "ar": 'سؤال: لماذا يستحسن بي تخزين تفاصيل بطاقة الائتمان الخاصة بي؟',
            "ur": ""
        },
        "In the event that the performer does not share with the client the steps to perform the Umrah, you can contact us immediately to assist you.": {
            "en": "In the event that the performer does not share with the client the steps to perform the Umrah, you can contact us immediately to assist you.",
            "ar": 'في حال لم يشارك المؤدي مع العميل خطوات أداء العمرة ، يمكنك  التواصل معنا فوراً لمساعدتك.',
            "ur": ""
        },
        "You can know the status of a request and track it by clicking on requests, then clicking on the current request, and thus you can know the status of its request": {
            "en": "You can know the status of a request and track it by clicking on requests, then clicking on the current request, and thus you can know the status of its request",
            "ar": 'يمكنك  معرفة حالة طلب وتتبعه من خلال النقر على الطلبات ثم النقر على الطلب الحالي ،وهكذا يمكنك معرفة حالة طلبه',
            "ur": ""
        },
        "When the customer submits the order through the application, he will not be able to cancel the order under special conditions": {
            "en": "When the customer submits the order through the application, he will not be able to cancel the order under special conditions",
            "ar": 'عندما يتم تقديم العميل للطلب من خلال التطبيق فإنه لن يكون بإمكانه إلغاء الطلب الابشروط خاصة',
            "ur": ""
        },
        "You can communicate with the performer through chatting with the application while performing Umrah.": {
            "en": "You can communicate with the performer through chatting with the application while performing Umrah.",
            "ar": 'يمكنك التواصل مع المؤدي من خلال المحادثه بالتطبيق اثناء تأدية العمرة.',
            "ur": ""
        },
        "You can request multiple Umrah allowance requests at the same time.": {
            "en": "You can request multiple Umrah allowance requests at the same time.",
            "ar": 'يمكنك  طلب عدة طلبات بأداء عمرة البدل بنفس الوقت.',
            "ur": ""
        },
        "Yes, and you can share and refer to it at any time": {
            "en": "Yes, and you can share and refer to it at any time",
            "ar": 'نعم ويمكنك مشاركتها والرجوع اليها في اى وقت ',
            "ur": ""
        },
        "There are places in the application during the Umrah trip to make sure of this by listening to record the intention of Umrah or video recording before starting the circumambulation or checking the pictures of the performer at the start and when cutting the ihram": {
            "en": "There are places in the application during the Umrah trip to make sure of this by listening to record the intention of Umrah or video recording before starting the circumambulation or checking the pictures of the performer at the start and when cutting the ihram",
            "ar": 'يوجد في التطبيق خلال رحلة العمرة مواضع للتاكد من ذلك عبر الاستماع لتسجيل نية العمرة او التسجيل المرئي قبل البدئ بالطواف او التحقق من صور المؤدي عند البدء وعند قص التحلل',
            "ur": ""
        },
        "Storing your credit card details facilitates and speeds up the booking process, since you will not have to go through the process of entering your details every time.": {
            "en": "Storing your credit card details facilitates and speeds up the booking process, since you will not have to go through the process of entering your details every time.",
            "ar": 'ان تخزين تفاصيل بطاقة الائتمان الخاصة بك يسهل ويسرع من عملية الحجز باعتبار أنك لن تكون مضطرا للمرور بعملية إدخال التفاصيل الخاصة بك في كل مرة.',
            "ur": ""
        },
        "Question: What are the available payment methods for the Tawkeel application?": {
            "en": "Question: What are the available payment methods for the Tawkeel application?",
            "ar": 'سؤال: ماي طرق الدفع المتاحة بتطبيق توكيل؟',
            "ur": ""
        },
        "Question: Value Added Tax?": {
            "en": "Question: Value Added Tax?",
            "ar": 'سؤال: الضريبة القيمة المضافة؟',
            "ur": ""
        },
        "Question: Why was my payment declined?": {
            "en": "Question: Why was my payment declined?",
            "ar": 'سؤال: لماذا تم رفض عملية الدفع؟',
            "ur": ""
        },
        "Question: What is an electronic wallet?": {
            "en": "Question: What is an electronic wallet?",
            "ar": 'سؤال: ماهي المحفظة الالكترونيه؟',
            "ur": ""
        },
        "In Tawkeel Application, you can pay via MADA online (ATM card) - payment service by credit card (Visa - MasterCard), - Apple Pay. - Or through the electronic wallet in the application.": {
            "en": "In Tawkeel Application, you can pay via MADA online (ATM card) - payment service by credit card (Visa - MasterCard), - Apple Pay. - Or through the electronic wallet in the application.",
            "ar": 'نظهر لك طرق الدفع المتاحة بتوكيل حيث يمكنك الدفع عن طريق مدى اون لاين(بطاق الصراف الالي)-خدمة الدفع عن طريق البطاقة الائتمانية(فيزا-ماستركارد)، -ابل باي. - أو من خلال المحفظة الإلكترونية الموجودة بالتطبيق',
            "ur": ""
        },
        "Value Added Tax is an indirect tax imposed on all goods and services as applied by the General Authority of Zakat and Tax at the rate of 15%": {
            "en": "Value Added Tax is an indirect tax imposed on all goods and services as applied by the General Authority of Zakat and Tax at the rate of 15%",
            "ar": 'ضريبة القيمة المضافة هي ضريبة غير مباشرة تُفرض على جميع السلع والخدمات كما تطبقها الهيئة العامة للزكاة والدخل بالمعدل البالغ ٪15',
            "ur": ""
        },
        "The payment attempt failed may be for one of the following reasons:": {
            "en": "The payment attempt failed may be for one of the following reasons:",
            "ar": 'فشلت محاولة الدفع قد يكون لا احد الأسباب التالية :',
            "ur": ""
        },
        "1- Insufficient balance, please check your account balance to make sure that you have enough funds to complete the payment process and have not exceeded the allowed limit.": {
            "en": "1- Insufficient balance, please check your account balance to make sure that you have enough funds to complete the payment process and have not exceeded the allowed limit.",
            "ar": '1- الرصيد غير كافي ، يرجى التحقق من رصيد حسابك للتأكد من امتلاكك أموالاً كافية لإكمال عملية الدفع ولم يتجاوز الحد المسموح .',
            "ur": ""
        },
        "2- The card verification code is incorrect, please check and try again.": {
            "en": "2- The card verification code is incorrect, please check and try again.",
            "ar": '2- رمز التحقق من البطاقة غير صحیح ، يرجى التحقق و تجربه مره اخرى.',
            "ur": ""
        },
        "3- An expired card, please remove the card or amend its data.": {
            "en": "3- An expired card, please remove the card or amend its data.",
            "ar": '3- بطاقة منتهية الصلاحية ،يرجى إزالة البطاقة أو تعديل بياناتها .',
            "ur": ""
        },
        "4- Entering wrong card information, please check the information and try payment again.": {
            "en": "4- Entering wrong card information, please check the information and try payment again.",
            "ar": '4- إدخال معلومات البطاقة خاطئة ،يرجى التحقق من المعلومات وتجربة الدفع مرة اخرى"',
            "ur": ""
        },
        "A Tawkeel wallet is an electronic wallet linked to your account that allows you to make online purchases. You can use the available balance in your wallet to pay.": {
            "en": "A Tawkeel wallet is an electronic wallet linked to your account that allows you to make online purchases. You can use the available balance in your wallet to pay.",
            "ar": 'محفظة توكيل هي محفظة إلكترونية مرتبطة بالحساب الخاص بك والتي تسمح لك بالقيام بعمليات شراء عبر الإنترنت. يمكنك استخدام الرصيد المتاح في محفظتك للدفع',
            "ur": ""
        },
    }
    return translations[text] ? translations[text][language] : text; 
}
export default _T;