import React from 'react'
import heroScreenshot from '../../assets/images/FAQ.png'
import mainLogo from "../../assets/images/main-logo.png"
import _T from '../../locale/FAQ/translator';


function Hero({language}){
    
    function section_scroll(id){
        let scrollDiv = document.getElementById(id).offsetTop;
        window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
    }

    return (
        <section id='faqHero'>
            <div className='wrapper faqHero-wrapper'>
                <div className='section-left'>
                    <img src={mainLogo} alt="Tawkeel" />
                    <h1 className='primary-heading ibm-plex-sans-arabic-bold'>{ _T("Tawkeel") }</h1>
                    <h2 className='sub-heading'>{_T("Frequently Asked Questions")}</h2>
                    <p className='text-paragraph'>{ _T("We understand that you may have queries regarding our offerings, and we have compiled this comprehensive list of FAQs to provide you with the information you need.") }</p>
                    <div className='btn-group'>
                        <button className='btn btn-primary-home' onClick={()=>{section_scroll('faqBody')}}>{_T("View FAQs")}</button>
                    </div>
                </div>
                <div className='section-right'>
                    {/* <img src={heroScreenshot} alt="Frequently Asked Questions"/> */}
                </div>
            </div>
        </section>
    )
}

export default Hero;