import React from 'react'
import step_01 from '../../assets/images/step-icon-01.png'
import step_02 from '../../assets/images/step-icon-02.png'
import step_03 from '../../assets/images/step-icon-03.png'
import step_04 from '../../assets/images/step-icon-04.png'
import step_05 from '../../assets/images/step-icon-05.png'
import step_06 from '../../assets/images/step-icon-06.png'
import separator from '../../assets/images/separator.png'
function ApplicationSteps({language}){
    return (
        <section id='applicationSteps'>
            <div className='wrapper applicationSteps-wrapper'>
                <div className='section-content text-center'>
                    <h2 className='secondary-heading'>{ language.text === "English" ? "Application Steps" : "خطوات التقديم"}</h2>
                    <p className='text-paragraph'>{ language.text === "English" ? "We've provided simple steps for you to get started with Tawkeel" : "لقد قدمنا ​​لك خطوات بسيطة لتبدأ في خدمة توكيل"}</p>
                </div>
                <div className='section-steps'>
                    <div className='step-column'>
                        <div className='single-step'>
                            <img className='step-icon' src={step_01}/>
                            <h5 className='step-heading'>{ language.text === "English" ? "Step 01" : "الخطوة  ٠١"}</h5>
                            <p className='step-description'>{ language.text === "English" ? "The customer downloads the application and asks the performer to perform the Hajj or Umrah on behalf of the deceased, the sick or himself." : "يقوم العميل بتحميل التطبيق ويطلب من المؤدي أداء فريضة الحج أو العمرة نيابة عن المتوفى أو المريض أو نفسه."}</p>
                        </div>
                        <div className='single-step'>
                            <img className='step-icon' src={step_02}/>
                            <h5 className='step-heading'>{ language.text === "English" ? "Step 02" : "الخطوة  ٠٢"}</h5>
                            <p className='step-description'>{ language.text === "English" ? "The participant who performs the rituals of Hajj or Umrah must enter the application and accept the request and communicate with the person who assigned him." : "يجب على المشارك الذي يؤدي مناسك الحج أو العمرة إدخال الطلب وقبول الطلب والتواصل مع من قام بتكليفه."}</p>
                        </div>
                        <div className='single-step'>
                            <img className='step-icon' src={step_03}/>
                            <h5 className='step-heading'>{ language.text === "English" ? "Step 03" : "الخطوة  ٠٣"}</h5>
                            <p className='step-description'>{ language.text === "English" ? "The application allows sharing rituals between the two parties, whereby the person performing the rituals confirms the steps of the rituals, starting from the intention of lhram until cutting hair." : "يسمح التطبيق بمشاركة الطقوس بين الطرفين ، حيث يقوم الشخص الذي يؤدي الطقوس بتأكيد خطوات الطقوس ، بدءًا من نية اللهرام حتى قص الشعر."}</p>
                        </div>
                    </div>
                    <div className='step-column-seperator'>
                        <img src={separator} className="animate-pulse"/>
                    </div>
                    <div className='step-column'>
                        <div className='single-step'>
                            <img className='step-icon' src={step_04}/>
                            <h5 className='step-heading'>{ language.text === "English" ? "Step 04" : "الخطوة  ٠٤"}</h5>
                            <p className='step-description'>{ language.text === "English" ? "The application records the steps of the rituals in full and shows them to the one who appointed them to live the atmosphere with the person performing the ritual." : "يسجل التطبيق خطوات الطقوس بالكامل ويظهرها للشخص الذي عينها ليعيشوا الجو مع الشخص الذي يؤدي الطقوس."}</p>
                        </div>
                        <div className='single-step'>
                            <img className='step-icon' src={step_05}/>
                            <h5 className='step-heading'>{ language.text === "English" ? "Step 05" : "الخطوة  ٠٥"}</h5>
                            <p className='step-description'>{ language.text === "English" ? "The assigned person can write the prayer that he wants the performer to say on his behalf during the performance of the rituals." : "يمكن للشخص المعين أن يكتب الصلاة التي يريد أن يتلوها المؤدي نيابة عنه أثناء أداء الطقوس."}</p>
                        </div>
                        <div className='single-step'>
                            <img className='step-icon' src={step_06}/>
                            <h5 className='step-heading'>{ language.text === "English" ? "Step 06" : "الخطوة  ٠٦"}</h5>
                            <p className='step-description'>{ language.text === "English" ? "The application preserves the rights of all parties in the contractual process." : "يحافظ التطبيق على حقوق جميع الأطراف في العملية التعاقدية."}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ApplicationSteps;