import React from 'react'
import "../../assets/css/faq.css"
import _T from '../../locale/UmrahAlBadal/translator'

function FAQ({language}){
    function toggleQuestion(event) {
        const el = event.currentTarget
        if(el.classList.contains("single-faq")){
            if(el.classList.contains("open")){
                el.classList.remove('open')
            }
            else{
                el.classList.add('open')
            }
        }
    }
    
    return (
        <section id='faqBody' className='umrah-al-badal-faq'>
            <div className='wrapper faqBody-wrapper'>
                <h2 className='secondary-heading text-center'>{_T('FAQ about Umrah Badal')}</h2>
                <div className='faq-listing' id='faq01'>
                    <div className='single-faq' onClick={toggleQuestion}>
                        <h3 className='faq-question'>{_T('Is it permissible to perform Umrah on behalf of two people at a time?')}</h3>
                        <div className='faq-answer'>
                            <p> {_T('Umrah shall only be done for one person at a time.')}</p>
                        </div>
                    </div>
                    <div className='single-faq' onClick={toggleQuestion}>
                        <h3 className='faq-question'>{_T('Is it permissible to perform Umrah on behalf of a living person who is able to perform it?')}</h3>
                        <div className='faq-answer RTL'>
                            <p>{_T('It is not permissible to perform Umrah on behalf of a living person who is able to perform it.')}</p>
                        </div>
                    </div>
                    <div className='single-faq' onClick={toggleQuestion}>
                        <h3 className='faq-question'>{_T('Is it permissible to perform Umrah Badal on behalf of a person who has already performed Umrah?')}</h3>
                        <div className='faq-answer'>
                            <p> {_T('Yes, it is permissible to perform Umrah Badal on behalf of a person who has already performed Umrah, provided that he shall be unable to perform Umrah again for any reason, including illness, old age, physical disability, or death.')}</p>
                        </div>
                    </div>
                    <div className='single-faq' onClick={toggleQuestion}>
                        <h3 className='faq-question'>{_T('Is it permissible to perform Umrah Badal more than once for the same person?')}</h3>
                        <div className='faq-answer'>
                            <p> {_T('Yes, it is permissible to perform Umrah Badal more than once for the same person, as long as he is unable to perform Umrah on his own, for the aforementioned reasons.')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQ;