function _T(text){
    const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
    const translations = {
        "Tawkeel application partners with a wide network of accredited offices and external agents across the world to showcase our Umrah services to Muslims around the world.": {
            "en": "Tawkeel application partners with a wide network of accredited offices and external agents across the world to showcase our Umrah services to Muslims around the world.",
            "ar": "تعمل شركة توكيل بالشراكة مع شبكة واسعة من المكاتب المعتمدة والوكلاء الخارجيين في عدة دول حول العالم، بهدف شرح ونشر خدماتها للمسلمين في جميع أنحاء العالم بما يخص عمرة البدل",
            "ur": ""
        },
        "Agents": {
            "en": "Agents",
            "ar": "وكلاء",
            "ur": ""
        },
        "Find Agent": {
            "en": "Find Agent",
            "ar": "ابحث عن الوكيل",
            "ur": ""
        },
        "Egypt": {
            "en": "Egypt",
            "ar": "مصر",
            "ur": ""
        },
        "Egypt": {
            "en": "Egypt",
            "ar": "مصر",
            "ur": ""
        },
        "Cairo": {
            "en": "Cairo",
            "ar": "القاهرة",
            "ur": ""
        },
        "Malaysia": {
            "en": "Malaysia",
            "ar": "ماليزيا",
            "ur": ""
        },
        "Kuala Lumpur": {
            "en": "Kuala Lumpur",
            "ar": "كوالا لمبور",
            "ur": ""
        },
        "Indonesia": {
            "en": "Indonesia",
            "ar": "أندونيسيا",
            "ur": ""
        },
        "Jakarta": {
            "en": "Jakarta",
            "ar": "جاكرتا",
            "ur": ""
        },
        "Philippines": {
            "en": "Philippines",
            "ar": "الفلبين",
            "ur": ""
        },
        "Manila": {
            "en": "Manila",
            "ar": "مانيلا",
            "ur": ""
        },
        "Turkey": {
            "en": "Turkey",
            "ar": "تركيا",
            "ur": ""
        },
        "Ankara": {
            "en": "Ankara",
            "ar": "أنقرة",
            "ur": ""
        },
        "Rwanda": {
            "en": "Rwanda",
            "ar": "رواندا",
            "ur": ""
        },
        "Kigali": {
            "en": "Kigali",
            "ar": "كيغالي",
            "ur": ""
        },
        "Netherlands": {
            "en": "Netherlands",
            "ar": "هولندا",
            "ur": ""
        },
        "Amsterdam": {
            "en": "Amsterdam",
            "ar": "أمستردام",
            "ur": ""
        },
        "France": {
            "en": "France",
            "ar": "فرنسا",
            "ur": ""
        },
        "Paris": {
            "en": "Paris",
            "ar": "باريس",
            "ur": ""
        },
        "Spain": {
            "en": "Spain",
            "ar": "إسبانيا",
            "ur": ""
        },
        "Madrid": {
            "en": "Madrid",
            "ar": "مدريد",
            "ur": ""
        },
        "England": {
            "en": "England",
            "ar": "انجلترا",
            "ur": ""
        },
        "London": {
            "en": "London",
            "ar": "لندن",
            "ur": ""
        },
        "Singapore": {
            "en": "Singapore",
            "ar": "سنغافورة",
            "ur": ""
        },
        "Tunisia": {
            "en": "Tunisia",
            "ar": "تونس",
            "ur": ""
        },
        "Tunis": {
            "en": "Tunis",
            "ar": "تونس",
            "ur": ""
        },
        "Morocco": {
            "en": "Morocco",
            "ar": "المغرب",
            "ur": ""
        },
        "Rabat": {
            "en": "Rabat",
            "ar": "الرباط",
            "ur": ""
        },
        "India": {
            "en": "India",
            "ar": "الهند",
            "ur": ""
        },
        "New Delhi": {
            "en": "New Delhi",
            "ar": "نيودلهي",
            "ur": ""
        },
        "Pakistan": {
            "en": "Pakistan",
            "ar": "باكستان",
            "ur": ""
        },
        "Islamabad": {
            "en": "Islamabad",
            "ar": "اسلام اباد",
            "ur": ""
        },
        "Britain": {
            "en": "Britain",
            "ar": "بريطانيا",
            "ur": ""
        },
        "America": {
            "en": "America",
            "ar": "أمريكا",
            "ur": ""
        },
        "Washington, D.C.": {
            "en": "Washington, D.C.",
            "ar": "واشنطن العاصمة",
            "ur": ""
        }
    }
    return translations[text] ? translations[text][language] : text; 
}
export default _T;