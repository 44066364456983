import React from 'react'
import section_icon from '../../assets/images/main-logo.png'
import performer_screenshot_01 from '../../assets/images/screenshots/performer-screens/screenshot-01.png'
import performer_screenshot_02 from '../../assets/images/screenshots/performer-screens/screenshot-02.png'
import performer_screenshot_03 from '../../assets/images/screenshots/performer-screens/screenshot-03.png'
import performer_screenshot_04 from '../../assets/images/screenshots/performer-screens/screenshot-04.png'
import performer_screenshot_05 from '../../assets/images/screenshots/performer-screens/screenshot-05.png'
import performer_screenshot_06 from '../../assets/images/screenshots/performer-screens/screenshot-06.png'
import performer_screenshot_07 from '../../assets/images/screenshots/performer-screens/screenshot-07.png'
import performer_screenshot_08 from '../../assets/images/screenshots/performer-screens/screenshot-08.png'
import performer_screenshot_09 from '../../assets/images/screenshots/performer-screens/screenshot-09.png'
import performer_screenshot_10 from '../../assets/images/screenshots/performer-screens/screenshot-10.png'
import performer_screenshot_11 from '../../assets/images/screenshots/performer-screens/screenshot-11.png'
import performer_screenshot_12 from '../../assets/images/screenshots/performer-screens/screenshot-12.png'
import performer_screenshot_13 from '../../assets/images/screenshots/performer-screens/screenshot-13.png'
import performer_screenshot_14 from '../../assets/images/screenshots/performer-screens/screenshot-14.png'
import performer_screenshot_15 from '../../assets/images/screenshots/performer-screens/screenshot-15.png'
import performer_screenshot_16 from '../../assets/images/screenshots/performer-screens/screenshot-16.png'
import performer_screenshot_17 from '../../assets/images/screenshots/performer-screens/screenshot-17.png'
import performer_screenshot_18 from '../../assets/images/screenshots/performer-screens/screenshot-18.png'
import performer_screenshot_19 from '../../assets/images/screenshots/performer-screens/screenshot-19.png'
import performer_screenshot_20 from '../../assets/images/screenshots/performer-screens/screenshot-20.png'
import seeker_screenshot_01 from '../../assets/images/screenshots/seeker-screens/screenshot-01.png'
import seeker_screenshot_02 from '../../assets/images/screenshots/seeker-screens/screenshot-02.png'
import seeker_screenshot_03 from '../../assets/images/screenshots/seeker-screens/screenshot-03.png'
import seeker_screenshot_04 from '../../assets/images/screenshots/seeker-screens/screenshot-04.png'
import seeker_screenshot_05 from '../../assets/images/screenshots/seeker-screens/screenshot-05.png'
import seeker_screenshot_06 from '../../assets/images/screenshots/seeker-screens/screenshot-06.png'
import seeker_screenshot_07 from '../../assets/images/screenshots/seeker-screens/screenshot-07.png'
import seeker_screenshot_08 from '../../assets/images/screenshots/seeker-screens/screenshot-08.png'
import seeker_screenshot_09 from '../../assets/images/screenshots/seeker-screens/screenshot-09.png'
import seeker_screenshot_10 from '../../assets/images/screenshots/seeker-screens/screenshot-10.png'
import seeker_screenshot_11 from '../../assets/images/screenshots/seeker-screens/screenshot-11.png'
import seeker_screenshot_12 from '../../assets/images/screenshots/seeker-screens/screenshot-12.png'
import seeker_screenshot_13 from '../../assets/images/screenshots/seeker-screens/screenshot-13.png'
import seeker_screenshot_14 from '../../assets/images/screenshots/seeker-screens/screenshot-14.png'
import seeker_screenshot_15 from '../../assets/images/screenshots/seeker-screens/screenshot-15.png'
import seeker_screenshot_16 from '../../assets/images/screenshots/seeker-screens/screenshot-16.png'
import seeker_screenshot_17 from '../../assets/images/screenshots/seeker-screens/screenshot-17.png'
import seeker_screenshot_18 from '../../assets/images/screenshots/seeker-screens/screenshot-18.png'
import seeker_screenshot_19 from '../../assets/images/screenshots/seeker-screens/screenshot-19.png'
import seeker_screenshot_20 from '../../assets/images/screenshots/seeker-screens/screenshot-20.png'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import _T from '../../locale/Home/translator'
function Screenshots({ language }) {
    return (
        <section id='screenshots'>
            <div className='wrapper screenshots-wrapper'>
                <div className='section-left'>
                    <img src={section_icon} className="section-icon" alt='' />
                    <h2 className='secondary-heading'>{_T("How Tawkeel Works?")}</h2>
                    <p className='subtitle fs-20'>{_T("Tawkeel is divided in two parts")}</p>
                    <p className='text-paragraph fs-20'>{_T("A section for the Seeker, who is the person who delegates someone to perform the Umrah on their behalf.")}</p>
                    <p className='text-paragraph fs-20'>{_T("A section for the Performer, who is the person who performs the Umrah on behalf of the Seeker.")}</p>
                </div>
                <div className='section-right'>
                    <div>
                        <h2 className='secondary-heading text-center'>{_T("Seeker")}</h2>
                        <Swiper slidesPerView={1} spaceBetween={30} grabCursor={true} loop={true} autoplay={{ delay: 2500, disableOnInteraction: false }} modules={[Autoplay]} className="mySwiper" >
                            {
                                language && language.code === "en" ?
                                <React.Fragment>
                                    <SwiperSlide><img src={seeker_screenshot_01} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_02} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_03} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_04} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_05} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_06} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_07} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_08} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_09} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_10} alt="Tawkeel Screenshot" /></SwiperSlide>
                                </React.Fragment> :
                                <React.Fragment>
                                    <SwiperSlide><img src={seeker_screenshot_11} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_12} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_13} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_14} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_15} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_16} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_17} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_18} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_19} alt="Tawkeel Screenshot" /></SwiperSlide>
                                    <SwiperSlide><img src={seeker_screenshot_20} alt="Tawkeel Screenshot" /></SwiperSlide>
                                </React.Fragment>
                            }
                        </Swiper>
                    </div>
                    <div>
                        <h2 className='secondary-heading text-center'>{_T("Performer")}</h2>
                        <Swiper slidesPerView={1} spaceBetween={30} grabCursor={true} loop={true} autoplay={{ delay: 2500, disableOnInteraction: false }} modules={[Autoplay]} className="mySwiper">
                        {
                            language && language.code === "en" ?
                            <React.Fragment>
                                <SwiperSlide><img src={performer_screenshot_01} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_02} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_03} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_04} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_05} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_06} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_07} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_08} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_09} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_10} alt="Tawkeel Screenshot" /></SwiperSlide>
                            </React.Fragment> :
                            <React.Fragment>
                                <SwiperSlide><img src={performer_screenshot_11} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_12} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_13} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_14} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_15} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_16} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_17} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_18} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_19} alt="Tawkeel Screenshot" /></SwiperSlide>
                                <SwiperSlide><img src={performer_screenshot_20} alt="Tawkeel Screenshot" /></SwiperSlide>
                            </React.Fragment>
                        }
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Screenshots;