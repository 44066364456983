import React from 'react'
import _T from '../../locale/UmrahAlBadal/translator';

function Benefits({ language }) {
    return (
        <section id='banifitsUmrahabadal'>
            <div className='wrapper'>
                <div className='umrah-badal-content-wrapper'>
                    <h2>{_T('The benefits of performing Umrah is that it removes poverty and sins')}</h2>
                    <p><span className='italic-bold-quotaion'>{_T('“Alternate between Hajj and Umrah; for those two remove poverty and sins”')}</span> {_T('(Narrated by Al-Tirmidhi 810).')}</p>
                </div>
                <div className='umrah-badal-content-wrapper'>
                    <h2>{_T('Expiation of sins')}</h2>
                    <p><span className='italic-bold-quotaion'>{_T('"(The performance of) `Umrah is an expiation for the sins committed between it and the previous `Umrah; and the reward of Hajj Mabrur (i.e., one accepted) is nothing but Jannah." ')}</span> {_T('[Al-Bukhari].')}</p>
                    <p>{_T('Is Umrah imposed only on those who are physically able to perform it? What about the deceased, the sick and those who are unable to perform Umrah for a legitimate reason?')}</p>
                    <p>{_T('Due to the tolerance of Islam, it has permitted those who are unable to perform Umrah to appoint someone to undertake such acts on their behalf, which is called "Al- Inabah" (delegation) in Umrah. What is Umrah Badal?')}</p>
                </div>
                <div className='umrah-badal-content-wrapper'>
                    <h2>{_T('Umrah Badal:')}</h2>
                    <p>{_T('Umrah Badal is one of the legitimate solutions that Muslims utilize to delegate performing Umrah to someone else for a legitimate reason (Deceased or disabled or ill).')}</p>
                    <p>{_T('A performer, Muslim, sane and adult performs the Umrah rituals on behalf of the person who is not able to do it')}</p>
                </div>
                <div className='umrah-badal-content-wrapper'>
                    <h2>{_T('The Sharia Opinion on Umrah Badal:')}</h2>
                    <p>{_T('All sheikhs and scholars agreed unanimously that Umrah Badal is permissible, provided that the performer shall has first performed Umrah on his own behalf before performing it on behalf of any other person. As long as this condition is met; there is no impediment for performing Umrah on behalf of anyone.')}</p>
                    <p>{_T('This is evidenced by the following:')}</p>
                    <ul>
                        <li>{_T('The analogy of Hajj Badal shall be applied to Umrah Badal, which is established by Sunnah and the consensus of the Islamic scholars.')}</li>
                        <li>{_T('The narrations the Prophet’s Companions who performed Umrah instead of others.')}</li>
                        <li>{_T('The opinions of the scholars that Umrah Badal may be performed instead of those who are not able to do it themselves')}</li>
                    </ul>
                </div>
                <div className='umrah-badal-content-wrapper'>
                    <h2>{_T('Sharia Fatwas')}</h2>
                    <h4>{_T('Sheikh Saleh Al-Fawzan')}</h4>
                    <div className='container-fatwas'>
                        <iframe className='fatwas-video' width="560" height="800" src="https://www.youtube.com/embed/xY7_rYzikuw?si=Su4XxSpqlw_b16Qc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <p className='caption'>{_T('Fatwa on performing Umrah for the deceased person')}</p>
                    </div>
                </div>
                <div className='umrah-badal-content-wrapper'>
                    <h2>{_T('Sheikh Muhammad bin Uthaymeen')}</h2>
                    <div className='container-fatwas'>
                        <iframe className='fatwas-video' width="560" height="800" src="https://www.youtube.com/embed/Z22F9c2mxf0?si=92ynPL8PEl7N0Q7A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <p className='caption'>{_T('Fatwa on performing Umrah for the deceased who did not perform Umrah during his lifetime')}</p>
                    </div>
                </div>
                <div className='umrah-badal-content-wrapper'>
                    <h2>{_T('Sheikh Saleh Al-Fawzan')}</h2>
                    <div className='container-fatwas'>
                        <iframe className='fatwas-video' width="560" height="800" src="https://www.youtube.com/embed/BHIUSu0nkHM?si=Q9oL3zgDZvIaAolf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <p className='caption'>{_T('Fatwa on taking money to perform Umrah on behalf of a sick or deceased person')}</p>
                    </div>
                </div>
                <div className='umrah-badal-content-wrapper'>
                    <h2>{_T('Conditions of Umrah Badal:')}</h2>
                    <ul>
                        <li>{_T('The person for whom Umrah Badal is performed shall be a Muslim, whether deceased or living and unable to perform Umrah for a legitimate reason.')}</li>
                        <li>{_T('The performer shall be a Muslim, sane and adult.')}</li>
                        <li>{_T('The performer must have first performed Umrah on his own behalf')}</li>
                        <li>{_T("The performer's intention must be to perform Umrah for the seeker, not for himself or any other person.")}</li>
                    </ul>
                </div>
                <div className='umrah-badal-content-wrapper'>
                    <h2>{_T('How to perform Umrah Badal:')}</h2>
                    <p>{_T("The rituals of Umrah Badal are performed by following all the rituals of Umrah for oneself (Ihram, Tawaf and Sa'i), with one simple difference, i.e.: the intention and the proclaiming Talbiah, instead of intending to perform Umrah for oneself, the performer must announce his intention to perform Umrah on behalf of the seeker, and in proclaiming Talbiah, he must say")} <span className='italic-bold-quotaion'>{_T("“Here I am, O Allah, here I am for Umrah on behalf of [Seeker’s name].”")} </span> {_T("The intention may be said loudly or privily.")}</p>
                </div>
                <div className='umrah-badal-content-wrapper'>
                    <h2>{_T('Performing Umrah for a deceased person:')}</h2>
                    <h3>{_T('The Sharia Opinion on performing Umrah for the deceased:')}</h3>
                    <p>{_T('It has been established by the scholars of jurisprudence that performing Umrah for the deceased is permissible, as Umrah is a financial physical act in which delegation is permissible. Further, any person may preform Umrah on behalf of the deceased person without permission.')}</p>
                    <p>{_T('The scholars have unanimously stated that the reward of such acts reaches the deceased, and his degree will be elevated therewith, Insha’Allah.')}</p>
                </div>
            </div>
        </section>
    )
}

export default Benefits;