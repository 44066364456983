import React from 'react'
import icon_02 from '../../assets/images/qualified-performer.png'
import icon_03 from '../../assets/images/immediate-service.png'
import icon_01 from '../../assets/images/real-time-tracking.png'
import icon_04 from '../../assets/images/service-date-time.png'
import icon_05 from '../../assets/images/customer-service.png'
import icon_06 from '../../assets/images/supervisors.png'
import icon_07 from '../../assets/images/multiple-languages.png'
import icon_08 from '../../assets/images/payment.png'
import _T from '../../locale/Home/translator'
function AppObjectives({language}){
    return (
        <section id='appObjectives'>
            <div className='wrapper appObjectives-wrapper'>
                <div className='section-content'>
                    <h2 className='secondary-heading'>{  _T("OUR DISTINGUISHES") }</h2>
                    <div className='objectives'>
                        <div className='single-objective'>
                            <img src={icon_01} className='objective-icon' alt="Real-time tracking feature"/>
                            <h3 className='objective-heading'>{ _T("Real-time tracking feature") }</h3>
                            {/* <p className='text-paragraph'>{ _T("Tawkeel provides the possibility of documenting the rituals during their performance and sharing photos and videos with the client") }</p> */}
                        </div>
                        <div className='single-objective'>
                            <img src={icon_02} className='objective-icon' alt="Qualified and verified performers via Nafath"/>
                            <h3 className='objective-heading'>{ _T("Qualified and verified performers via Nafath") } </h3>
                            {/* <p className='text-paragraph'>{ _T("Keeping pace with 2030 Kingdom's vision digital transformation in all services") } </p> */}
                        </div>
                        <div className='single-objective'>
                            <img src={icon_03} className='objective-icon' alt="The Umrah Al-Badal service is available immediately upon request"/>
                            <h3 className='objective-heading'>{ _T("The Umrah Al-Badal service is available immediately upon request") } </h3>
                            {/* <p className='text-paragraph'>{ _T("Finding an innovative way to increase the income of young men and women who are qualified for part-time work") }</p> */}
                        </div>
                        <div className='single-objective'>
                            <img src={icon_04} className='objective-icon' alt="Umrah Al-Badal service with Specify date and time"/>
                            <h3 className='objective-heading'>{ _T("Umrah Al-Badal service with Specify date and time") } </h3>
                            {/* <p className='text-paragraph'>{ _T("Tawkeel follows a secure payment system and maintains the confidentiality of bank card information, as it allows payment in multiple ways") }</p> */}
                        </div>
                        <div className='single-objective'>
                            <img src={icon_05} className='objective-icon' alt="Customer service and direct communication"/>
                            <h3 className='objective-heading'>{ _T("Customer service and direct communication") } </h3>
                            {/* <p className='text-paragraph'>{ _T("The application tracks the performer while performing the ritual through the") } GPS</p> */}
                        </div>
                        <div className='single-objective'>
                            <img src={icon_06} className='objective-icon' alt="A supervisor follows those performing Umrah in the Haram on behalf of others"/>
                            <h3 className='objective-heading'>{ _T("A supervisor follows those performing Umrah in the Haram on behalf of others") } </h3>
                            {/* <p className='text-paragraph'>{ _T("An Umrah certificate is sent to the client upon completion of the Umrah, and a gift card can be requested in the name of the client") }</p> */}
                        </div>
                        <div className='single-objective'>
                            <img src={icon_07} className='objective-icon' alt="Multiple Language"/>
                            <h3 className='objective-heading'>{ _T("Multiple Language") } </h3>
                            {/* <p className='text-paragraph'>{ _T("The performers of Umrah in a proxy are a group of students from the Islamic University and memorizers of the Book of God") }</p> */}
                        </div>
                        <div className='single-objective'>
                            <img src={icon_08} className='objective-icon' alt="Safe and fast payment methods" style={{width: "100%", maxWidth: "128px", height: "auto"}}/>
                            <h3 className='objective-heading'>{ _T("Safe and fast payment methods") } </h3>
                            {/* <p className='text-paragraph'>{ _T("Tawkeel allows the application to be used in more than 9 languages, many performers speak two languages or more, which facilitates the process of communication between the performer and the seeker") }</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AppObjectives;