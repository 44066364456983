function _T(text){
    const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
    const translations = {
        "Umrah Badal": {
            "en": "Umrah Badal",
            "ar": 'عمرة البدل',
            "ur": ""
        },
        "Tawkeel": {
            "en": "Tawkeel",
            "ar": "توكيل",
            "ur": ""
        },
        "The benefits of performing Umrah is that it removes poverty and sins": {
            "en": "The benefits of performing Umrah is that it removes poverty and sins",
            "ar": 'من فضل العمرة, تبعد الفقر والذنوب',
            "ur": ""
        },
        "The Messenger of Allah - may the blessings and peace of Allah be upon him - said: ": {
            "en": "The Messenger of Allah - may the blessings and peace of Allah be upon him - said: ",
            "ar": "روابط سريعة",
            "ur": ""
        },
        '“Alternate between Hajj and Umrah; for those two remove poverty and sins”': {
            "en": '“Alternate between Hajj and Umrah; for those two remove poverty and sins”',
            "ar": '"تابِعوا بينَ الحجِّ والعُمرةِ، فإنَّهُما ينفيانِ الفقرَ والذُّنوبَ"',
            "ur": ""
        },
        "(Narrated by Al-Tirmidhi 810).": {
            "en": "(Narrated by Al-Tirmidhi 810).",
            "ar": "(رواه الترميذي 810 )",
            "ur": ""
        },
        "Expiation of sins": {
            "en": "Expiation of sins",
            "ar": "تكفيير الذنوب",
            "ur": ""
        },
        '"(The performance of) `Umrah is an expiation for the sins committed between it and the previous `Umrah; and the reward of Hajj Mabrur (i.e., one accepted) is nothing but Jannah." ': {
            "en": '"(The performance of) `Umrah is an expiation for the sins committed between it and the previous `Umrah; and the reward of Hajj Mabrur (i.e., one accepted) is nothing but Jannah." ',
            "ar": '"العمرةُ إلى العمرةِ كفَّارَةٌ لمَا بينَهمَا، والحجُّ المبرورُ ليسَ لهُ جزاءٌ إلّا الجنَّةُ"',
            "ur": ""
        },
        "[Al-Bukhari].": {
            "en": "[Al-Bukhari].",
            "ar": "(البخاري).",
            "ur": ""
        },
        "Is Umrah imposed only on those who are physically able to perform it? What about the deceased, the sick and those who are unable to perform Umrah for a legitimate reason?": {
            "en": "Is Umrah imposed only on those who are physically able to perform it? What about the deceased, the sick and those who are unable to perform Umrah for a legitimate reason?",
            "ar": "فهل العمرة فقط للقادرين على القيام بها؟ وماذا عن المتوفين والمرضى والعاجزين عن القيام بها لسبب شرعي؟",
            "ur": ""
        },
        'Due to the tolerance of Islam, it has permitted those who are unable to perform Umrah to appoint someone to undertake such acts on their behalf, which is called "Al- Inabah" (delegation) in Umrah. What is Umrah Badal?': {
            "en": 'Due to the tolerance of Islam, it has permitted those who are unable to perform Umrah to appoint someone to undertake such acts on their behalf, which is called "Al- Inabah" (delegation) in Umrah. What is Umrah Badal?',
            "ar": 'نظرًا لسماحة الدين الإسلامي فقد أجاز لغير القادرين على القيام بأداء مناسك العمرة وتأدية هذه العبادة العظيمة بما يسمى العمرة بالنيابة فما هي عمرة البدل؟',
            "ur": ""
        },
        "Umrah Badal:": {
            "en": "Umrah Badal:",
            "ar": "عمرة البدل:",
            "ur": ""
        },
        "Umrah Badal is one of the legitimate solutions that Muslims utilize to delegate performing Umrah to someone else for a legitimate reason (Deceased or disabled or ill).": {
            "en": "Umrah Badal is one of the legitimate solutions that Muslims utilize to delegate performing Umrah to someone else for a legitimate reason (Deceased or disabled or ill).",
            "ar": "عمرة البدل هي إحدى الحلّول الشرعيةُ التي يلّجأ إليهُا المسلّمونِ غير القادرينَ علّى أداءٌ العمرة لسبب شرعي (المتوفينَ , المرضى أو العاجزينَ)",
            "ur": ""
        },
        "A performer, Muslim, sane and adult performs the Umrah rituals on behalf of the person who is not able to do it": {
            "en": "A performer, Muslim, sane and adult performs the Umrah rituals on behalf of the person who is not able to do it",
            "ar": "حيث يقوم شخص (مسلّم , عاقل , بالغ )  بتأديةُ مناسك العمرة كاملّةُ بدلّا  عنَ شخص آخر متوفى أو غير قادر علّى القيام بهُا",
            "ur": ""
        },
        "The Sharia Opinion on Umrah Badal:": {
            "en": "The Sharia Opinion on Umrah Badal:",
            "ar": "حكم عمرة البدل:",
            "ur": ""
        },
        "All sheikhs and scholars agreed unanimously that Umrah Badal is permissible, provided that the performer shall has first performed Umrah on his own behalf before performing it on behalf of any other person. As long as this condition is met; there is no impediment for performing Umrah on behalf of anyone.": {
            "en": "All sheikhs and scholars agreed unanimously that Umrah Badal is permissible, provided that the performer shall has first performed Umrah on his own behalf before performing it on behalf of any other person. As long as this condition is met; there is no impediment for performing Umrah on behalf of anyone.",
            "ar": "جميع الشيوخ والعلماء أجمعوا على إجازة عمرة البدل بشرط أن يكون المعتمر أتم مناسك العمرة لنفسه أولاً قبل اتمامها لأي شخص وبعد إتمام هذا الشرط لا يوجد ما يمنع إتمام العمرة بدلاً من اي شخص.",
            "ur": ""
        },
        "This is evidenced by the following:": {
            "en": "This is evidenced by the following:",
            "ar": "استنادًا في ذلك إلى ما يلي:",
            "ur": ""
        },
        "The analogy of Hajj Badal shall be applied to Umrah Badal, which is established by Sunnah and the consensus of the Islamic scholars.": {
            "en": "The analogy of Hajj Badal shall be applied to Umrah Badal, which is established by Sunnah and the consensus of the Islamic scholars.",
            "ar": "قياس عمرة البدل على حج البدل وهو ما ثبت بالسنة وإجماع العلماء.",
            "ur": ""
        },
        "The narrations the Prophet’s Companions who performed Umrah instead of others.": {
            "en": "The narrations the Prophet’s Companions who performed Umrah instead of others.",
            "ar": "ما روي عن الصحابة الذين اعتمروا بدلًا عن غيرهم.",
            "ur": ""
        },
        "The opinions of the scholars that Umrah Badal may be performed instead of those who are not able to do it themselves": {
            "en": "The opinions of the scholars that Umrah Badal may be performed instead of those who are not able to do it themselves",
            "ar": "أقوال أهل العلم في جواز العمرة بدلًا لمن لا يستطيع أن يؤديها بنفسه",
            "ur": ""
        },
        "Sharia Fatwas": {
            "en": "Sharia Fatwas",
            "ar": "الفتاوي الشرعية",
            "ur": ""
        },
        "Sheikh Saleh Al-Fawzan": {
            "en": "Sheikh Saleh Al-Fawzan",
            "ar": "الشيخ صالح الفوزان",
            "ur": ""
        },
        "Fatwa on performing Umrah for the deceased person": {
            "en": "Fatwa on performing Umrah for the deceased person",
            "ar": "فتوى أداء عمرة عن الميت",
            "ur": ""
        },
        "Sheikh Muhammad bin Uthaymeen": {
            "en": "Sheikh Muhammad bin Uthaymeen",
            "ar": "الشيخ محمد بن عثيمين",
            "ur": ""
        },
        "Fatwa on performing Umrah for the deceased who did not perform Umrah during his lifetime": {
            "en": "Fatwa on performing Umrah for the deceased who did not perform Umrah during his lifetime",
            "ar": "فتوى العمرة عن الميت الذي لم يعتمر في حياته",
            "ur": ""
        },
        "Fatwa on taking money to perform Umrah on behalf of a sick or deceased person": {
            "en": "Fatwa on taking money to perform Umrah on behalf of a sick or deceased person",
            "ar": "فتوى أخذ المال لأداء العمرة عن مريض أو متوفى",
            "ur": ""
        },
        "Conditions of Umrah Badal:": {
            "en": "Conditions of Umrah Badal:",
            "ar": "شروط عمرة البدل:",
            "ur": ""
        },
        "The person for whom Umrah Badal is performed shall be a Muslim, whether deceased or living and unable to perform Umrah for a legitimate reason.": {
            "en": "The person for whom Umrah Badal is performed shall be a Muslim, whether deceased or living and unable to perform Umrah for a legitimate reason.",
            "ar": "أن يكون الشخص الذي تؤدي عنه عمرة البدل مسلمًا متوفيًا أو حيًا غير قادر على القيام بها لسبب شرعي.",
            "ur": ""
        },
        "The performer shall be a Muslim, sane and adult.": {
            "en": "The performer shall be a Muslim, sane and adult.",
            "ar": "أن يكون مؤدي عمرة البدل مسلماً، عاقلاً، بالغًا.",
            "ur": ""
        },
        "The performer must have first performed Umrah on his own behalf": {
            "en": "The performer must have first performed Umrah on his own behalf",
            "ar": "أن يكون مؤدي عمرة البدل قد أدّى العمرة عن نفسه أولًا.",
            "ur": ""
        },
        "The performer's intention must be to perform Umrah for the seeker, not for himself or any other person.": {
            "en": "The performer's intention must be to perform Umrah for the seeker, not for himself or any other person.",
            "ar": "يجب أن تكون نية المؤدي لأداء العمرة  عن الموكل، وليس لنفسه أو لغيره.",
            "ur": ""
        },
        "How to perform Umrah Badal:": {
            "en": "How to perform Umrah Badal:",
            "ar": "كيفية أداء عمرة البدل:",
            "ur": ""
        },
        "The rituals of Umrah Badal are performed by following all the rituals of Umrah for oneself (Ihram, Tawaf and Sa'i), with one simple difference, i.e.: the intention and the proclaiming Talbiah, instead of intending to perform Umrah for oneself, the performer must announce his intention to perform Umrah on behalf of the seeker, and in proclaiming Talbiah, he must say": {
            "en": "The rituals of Umrah Badal are performed by following all the rituals of Umrah for oneself (Ihram, Tawaf and Sa'i), with one simple difference, i.e.: the intention and the proclaiming Talbiah, instead of intending to perform Umrah for oneself, the performer must announce his intention to perform Umrah on behalf of the seeker, and in proclaiming Talbiah, he must say",
            "ar": " تؤدى مناسك عمرة البدل  باتّباع جميع مناسك العمرة عن النفس",
            "ur": ""
        },
        "“Here I am, O Allah, here I am for Umrah on behalf of [Seeker’s name].”": {
            "en": "“Here I am, O Allah, here I am for Umrah on behalf of [Seeker’s name].”",
            "ar": '"الإحرام، والطواف والسعي"',
            "ur": ""
        },
        "The intention may be said loudly or privily.": {
            "en": "The intention may be said loudly or privily.",
            "ar": 'مع اختلاف واحد وبسيط؛ وهو النية والتلبية، فبدلاً من نية العمرة عن النفس، على مؤدي العمرة إعلان نيّة العمرة عن المعتمر عنه، والتلبية بقوله "لبيك اللهم عمرة عن فلان"، وتصح النية جهراً كانت أو سراً.',
            "ur": ""
        },
        "Performing Umrah for a deceased person:": {
            "en": "Performing Umrah for a deceased person:",
            "ar": "أداء العمرة عن شخص متوفي:",
            "ur": ""
        },
        "The Sharia Opinion on performing Umrah for the deceased:": {
            "en": "The Sharia Opinion on performing Umrah for the deceased:",
            "ar": "مسكن",
            "ur": ""
        },
        "It has been established by the scholars of jurisprudence that performing Umrah for the deceased is permissible, as Umrah is a financial physical act in which delegation is permissible. Further, any person may preform Umrah on behalf of the deceased person without permission.": {
            "en": "It has been established by the scholars of jurisprudence that performing Umrah for the deceased is permissible, as Umrah is a financial physical act in which delegation is permissible. Further, any person may preform Umrah on behalf of the deceased person without permission.",
            "ar": "حكم أداء العمرة عن الميت ثبت عن علماء الفقه أن أداء العمرة عن الميت جائزة، حيث إن العمرة عبادة بدنية مالية تجوز النيابة فيها؛ ومن الممكن الاعتمار بدلاً من الشخص المتوفى دون إذن.",
            "ur": ""
        },
        "The scholars have unanimously stated that the reward of such acts reaches the deceased, and his degree will be elevated therewith, Insha’Allah.": {
            "en": "The scholars have unanimously stated that the reward of such acts reaches the deceased, and his degree will be elevated therewith, Insha’Allah.",
            "ar": "وقد نصّ العلماء على أنّ ثواب هذه الأعمال يصل إلى الميّت بلا خلاف، وينتفع بها في قبره إن شاء الله",
            "ur": ""
        },
        "FAQ about Umrah Badal":{
            "en": "FAQ about Umrah Badal",
            "ar": "الأسئلة الشائعة عن عمرة البدل:",
            "ur": "",
        },
        "Is it permissible to perform Umrah on behalf of two people at a time?":{
            "en": "Is it permissible to perform Umrah on behalf of two people at a time?",
            "ar": "هل يجوز عمل العمرة بنية شخصين؟",
            "ur": "",
        },
        "Umrah shall only be done for one person at a time.":{
            "en": "Umrah shall only be done for one person at a time.",
            "ar": "العمرة لا تكون إلا عن شخص واحد فقط",
            "ur": "",
        },
        "Is it permissible to perform Umrah on behalf of a living person who is able to perform it?":{
            "en": "Is it permissible to perform Umrah on behalf of a living person who is able to perform it?",
            "ar": "هل يجوز أن أعتمر عن شخص حي قادرعلى القيام بها؟",
            "ur": "",
        },
        "It is not permissible to perform Umrah on behalf of a living person who is able to perform it.":{
            "en": "It is not permissible to perform Umrah on behalf of a living person who is able to perform it.",
            "ar": "لا يجوز القيام بالعمرة بدلًا عن أحد الأحياء القادر على فعلها.",
            "ur": "",
        },
        "Is it permissible to perform Umrah Badal on behalf of a person who has already performed Umrah?":{
            "en": "Is it permissible to perform Umrah Badal on behalf of a person who has already performed Umrah?",
            "ar": "هل يجوز أداء عمرة البدل لمن سبق له أن أدى العمرة من قبل؟",
            "ur": "",
        },
        "Yes, it is permissible to perform Umrah Badal on behalf of a person who has already performed Umrah, provided that he shall be unable to perform Umrah again for any reason, including illness, old age, physical disability, or death.":{
            "en": "Yes, it is permissible to perform Umrah Badal on behalf of a person who has already performed Umrah, provided that he shall be unable to perform Umrah again for any reason, including illness, old age, physical disability, or death.",
            "ar": "نعم، يجوز أداء عمرة البدل لمن سبق له أن أدى العمرة من قبل بشرط عدم قدرته على أداء العمرة مرة أخرى، لأسباب مثل المرض، أو الشيخوخة، أو العجز البدني، أو الوفاة",
            "ur": "",
        },
        "Is it permissible to perform Umrah Badal more than once for the same person?":{
            "en": "Is it permissible to perform Umrah Badal more than once for the same person?",
            "ar": "هل يجوز أداء عمرة البدل أكثر من مرة لنفس الشخص؟",
            "ur": "",
        },
        "Yes, it is permissible to perform Umrah Badal more than once for the same person, as long as he is unable to perform Umrah on his own, for the aforementioned reasons.":{
            "en": "Yes, it is permissible to perform Umrah Badal more than once for the same person, as long as he is unable to perform Umrah on his own, for the aforementioned reasons.",
            "ar": "نعم يجوز أداء عمرة البدل أكثر من مرة لنفس الشخص، طالما أنه غير قادر على أداء العمرة بنفسه، لأسباب سبق ذكرها.",
            "ur": "",
        }
    }
    return translations[text] ? translations[text][language] : text; 
}
export default _T;