import React from 'react'
import {Link} from 'react-router-dom'
import section_image from '../../assets/images/screenshots/hero-screenshot.png' 
import section_icon from '../../assets/images/headphones-icon.png'
function HelpCenter({language}){
    return (
        <section id='helpCenter'>
            <div className='wrapper helpCenter-wrapper'>
                <div className='section-left'>
                    <img src={section_icon} className="section-icon"/>
                    <h2 className='secondary-heading'>{ language.text === "English" ? "Tawkeel Help Center!" : "مركز مساعدة توكيل!"}</h2>
                    <p className='text-paragraph'>{ language.text === "English" ? "You can get a complete overview of the application for seekers and performers side." : "يمكنك الحصول على لمحة كاملة عن التطبيق للباحثين وفناني الأداء."}</p>
                    <div className='btn-group'>
                        <Link to={language.text === "English" ? "/en/help-center/performer" : "/help-center/performer"} className='btn btn-primary'>{ language.text === "English" ? "Performer" : "المؤدي"}</Link>
                        <Link to={language.text === "English" ? "/en/help-center/seeker" : "/help-center/seeker"} className='btn btn-secondary'>{ language.text === "English" ? "Seeker" : "باحث"}</Link>
                    </div>
                </div>
                <div className='section-right'>
                    <img src={section_image}/>
                </div>
            </div>
        </section>
    )
}

export default HelpCenter;