import React, { useEffect, useState } from "react";
import axios from "axios"
import { useParams } from "react-router-dom";
import "../../assets/css/reset-password.css";
import tawkeelLogo from "../../assets/images/Tawkeel_GOLD.png"
import successImg from "../../assets/images/checked.png"
import failImg from "../../assets/images/cancel.png"
import showPwd from "../../assets/icons/show-pwd.svg"
import hidePwd from "../../assets/icons/hidepwd.svg"
import { RP } from "../../components/FacebookPixel";
import '../../components/TikTokPixel.js';

function GetToken() {
  let params = useParams()
  return params.token
}

function Index() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [samePassword, setSamePassword] = useState(true);
  const [passwordReset, setPasswordReset] = useState(false);
  const [message, setMessage] = useState({ color: "", message: "" });
  const [error, setError] = useState("")
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false)
  let token = GetToken();

  const regPassword = (pwd) => {
    return pwd && pwd.length && pwd.length > 7 ? true : false;
  }

  useEffect(() => {
    RP.pageView();
  }, [])

  useEffect(() => {
    if (password.length > 0 && confirmPassword.length > 0) {
      if (password === confirmPassword) {
        setSamePassword(true);
        setError("")
      }
      else {
        setSamePassword(false);
        setError("Password does not match")
      }
    }
    else {
      setSamePassword(true)
      setError("")
    }
  }, [password, confirmPassword])

  const handlerSubmit = (e) => {
    e.preventDefault();

    if (password === confirmPassword) {
      if (regPassword(password)) {
        setLoader(true);
        axios.post(process.env.REACT_APP_API_BASEURL + 'reset-password', { token, password }).then(function (response) {
          setPassword("");
          setConfirmPassword("");
          setPasswordReset(true);

          if (response.data.status) {
            setMessage({ color: "success", message: response.data.message })
            setLoader(false)
          }
          else {
            setMessage({ color: "fail", message: response.data.message })
            setLoader(false)
          }
        })
          .catch(function (error) {
            setMessage({ color: "fail", message: error })
            setLoader(false)
          });
      }
      else {
        setError(<ul>
          <li>Password must be at least 8 characters long</li>
        </ul>)
      }
    }
    else {
      setError("Password does not match")
    }


  }
  return (
    <section className="reset-page">
      <form onSubmit={e => handlerSubmit(e)} className="reset-card">

        {!passwordReset ? (
          <>
            <img src={tawkeelLogo} alt="Tawkeel Logo" className="heartbeat logo" />
            <h2>Reset Password</h2>
            <p>You can now set a new password for your account.</p>

            <div className="input-group">
              <input maxLength={40} required value={password} onChange={(e) => setPassword(e.target.value.replace(/\s+/g, ''))} type={`${showPassword ? "text" : "password"}`} className={`custom-input ${samePassword ? "" : "error-input"}`} placeholder="Password" />
              <a className="show-pwd-icon" onClick={() => setShowPassword(!showPassword)}>{showPassword ? <img src={hidePwd} /> : <img src={showPwd} />}</a>
            </div>

            <div className="input-group">
              <input maxLength={40} required value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value.replace(/\s+/g, ''))} type={`${showPassword ? "text" : "password"}`} className={`custom-input ${samePassword ? "" : "error-input"}`} placeholder="Confirm Password" />
              <a className="show-pwd-icon" onClick={() => setShowPassword(!showPassword)}>{showPassword ? <img src={hidePwd} /> : <img src={showPwd} />}</a>
            </div>

            <small className="error">{error}</small>
            <button type="submit" id="submit-reset-pass" className={`btn btn-primary ${loader | !samePassword ? "disabled-btn" : ""}`} disabled={loader} >{!loader ? "Reset Password" : (
              <svg id="loading-spinner" width="25" height="25" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle id="loading-circle" cx="20" cy="20" r="18" stroke="#fff" stroke-width="4" />
              </svg>
            )}

            </button>
          </>
        ) : (
          <>
            <img className="status" src={`${message.color === "fail" ? failImg : successImg}`} alt="Tawkeel Reset Password" />
            <h1 className={message.color}>{`${message.color === "fail" ? "Request Failed" : "Password Reset"}`}</h1>
            <p className={message.color}>{`${message.color === "fail" ? message.message : "Your password has been successfully reset. Please login using your new password."}`}</p>
          </>
        )}
      </form>
    </section>

  );
}

export default Index;
