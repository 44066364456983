import Home from './pages/Home/index'
import Campaign from './pages/Home/Campaign'
import QuickRegister from './pages/QuickRegister/index'
import HelpCenter from './pages/HelpCenter/index'
import PerformerHelpCenter from './pages/HelpCenter/PerformerHelpCenter'
import SeekerHelpCenter from './pages/HelpCenter/SeekerHelpCenter'
import TermsAndConditions from './pages/TermsAndConditions/index'
import PerformerTermsAndConditions from './pages/TermsAndConditions/PerformerTermsAndConditions'
import SeekerTermsAndConditions from './pages/TermsAndConditions/SeekerTermsAndConditions'
import PrivacyPolicy from './pages/PrivacyPolicy/index'
import PerformerPrivacyPolicy from './pages/PrivacyPolicy/PerformerPrivacyPolicy'
import SeekerPrivacyPolicy from './pages/PrivacyPolicy/SeekerPrivacyPolicy'
import ResetPassword from './pages/ResetPassword'
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { useMemo } from 'react'
import FAQ from './pages/FAQ/Index'
import { useEffect } from 'react'
import { RP } from './components/FacebookPixel'
import { Helmet } from 'react-helmet'
import Agents from './pages/Agents'
import Contract from './pages/Contract'
import AgencyRegister from './pages/AgencyRegister'
import News from './pages/News/Index'
import UmraAlBadal from './pages/UmraAlBadal'

document.getElementsByTagName('body')[0].onscroll = function() {
  let position = document.documentElement.scrollTop || document.body.scrollTop;
  let height = window.innerHeight;
  if(position > height){
    document.getElementsByTagName("body")[0].classList.add("scrollTop");
  }
  else{
    document.getElementsByTagName("body")[0].classList.remove("scrollTop");
  }
};

function scrollTop(){
  window.scrollTo({top: 0, behavior: 'smooth'});
}

function LayoutWrapper(props){
  useMemo(()=>{
    localStorage.setItem('lang',props.language)
  },[])

  const pageTitle = props.language === 'en' ? 'Tawkeel Umrah Badal' : 'توكيل عمرة البدل';
  const pageDescription = props.language === 'en' ? 'Tawkeel, Tawkeel for Umrah Badal, Umrah, Hajj, Asceticism, Rites, Acting Umrah' : 'توكيل,توكيل عمرة البدل,العمرة,الحج,العمرة بالنيابة,مناسك,نسك';
  document.getElementsByTagName('meta')["description"].content = pageDescription;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription}/>
      </Helmet>
      {props.children}
    </>
  )
}


function App() {
  
  useEffect(()=>{
    RP.pageView();
  },[])
  
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={  <LayoutWrapper language="ar"> <Home language="ar"/> </LayoutWrapper> }/>
          <Route exact path="/en" element={  <LayoutWrapper language="en"> <Home language="en" />  </LayoutWrapper> }/>
          <Route exact path="/campaign" element={  <LayoutWrapper language="ar"> <Campaign language="ar" />  </LayoutWrapper> }/>
          <Route exact path="/quick-register" element={  <LayoutWrapper language="ar"> <QuickRegister language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/en/quick-register" element={  <LayoutWrapper language="en"> <QuickRegister language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/agency-register" element={  <LayoutWrapper language="ar"> <AgencyRegister language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/en/agency-register" element={  <LayoutWrapper language="en"> <AgencyRegister language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/agents" element={  <LayoutWrapper language="ar"> <Agents language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/en/agents" element={  <LayoutWrapper language="en"> <Agents language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/news" element={  <LayoutWrapper language="ar"> <News language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/en/news" element={  <LayoutWrapper language="en"> <News language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/help-center" element={  <LayoutWrapper language="ar"> <HelpCenter language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/en/help-center" element={  <LayoutWrapper language="en"> <HelpCenter language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/help-center/performer" element={  <LayoutWrapper language="ar"> <PerformerHelpCenter language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/help-center/seeker" element={  <LayoutWrapper language="ar"> <SeekerHelpCenter language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/en/help-center/performer" element={  <LayoutWrapper language="en"> <PerformerHelpCenter language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/en/help-center/seeker" element={  <LayoutWrapper language="en"> <SeekerHelpCenter language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/terms-and-conditions" element={  <LayoutWrapper language="ar"> <TermsAndConditions language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/ar/terms-and-conditions" element={  <LayoutWrapper language="ar"> <TermsAndConditions language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/en/terms-and-conditions" element={  <LayoutWrapper language="en"> <TermsAndConditions language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/terms-and-conditions/performer" element={  <LayoutWrapper language="ar"> <PerformerTermsAndConditions language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/terms-and-conditions/seeker" element={  <LayoutWrapper language="ar"> <SeekerTermsAndConditions language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/ar/terms-and-conditions/performer" element={  <LayoutWrapper language="ar"> <PerformerTermsAndConditions language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/ar/terms-and-conditions/seeker" element={  <LayoutWrapper language="ar"> <SeekerTermsAndConditions language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/en/terms-and-conditions/performer" element={  <LayoutWrapper language="en"> <PerformerTermsAndConditions language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/en/terms-and-conditions/seeker" element={  <LayoutWrapper language="en"> <SeekerTermsAndConditions language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/frequently-asked-questions" element={  <LayoutWrapper language="ar"> <FAQ language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/en/frequently-asked-questions" element={  <LayoutWrapper language="en"> <FAQ language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/privacy-policy" element={  <LayoutWrapper language="ar"> <PrivacyPolicy language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/ar/privacy-policy" element={  <LayoutWrapper language="ar"> <PrivacyPolicy language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/en/privacy-policy" element={  <LayoutWrapper language="en"> <PrivacyPolicy language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/privacy-policy/performer" element={  <LayoutWrapper language="ar"> <PerformerPrivacyPolicy language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/privacy-policy/seeker" element={  <LayoutWrapper language="ar"> <SeekerPrivacyPolicy language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/ar/privacy-policy/performer" element={  <LayoutWrapper language="ar"> <PerformerPrivacyPolicy language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/ar/privacy-policy/seeker" element={  <LayoutWrapper language="ar"> <SeekerPrivacyPolicy language="ar"/>  </LayoutWrapper> }/>
          <Route exact path="/en/privacy-policy/performer" element={  <LayoutWrapper language="en"> <PerformerPrivacyPolicy language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/en/privacy-policy/seeker" element={  <LayoutWrapper language="en"> <SeekerPrivacyPolicy language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/reset-user-password/:token" element={  <LayoutWrapper language="en"> <ResetPassword language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/contract/:agent/:contract" element={  <LayoutWrapper language="en"> <Contract language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/en/umrah-al-badal" element={  <LayoutWrapper language="en"> <UmraAlBadal language="en"/>  </LayoutWrapper> }/>
          <Route exact path="/umrah-al-badal" element={  <LayoutWrapper language="ar"> <UmraAlBadal language="ar"/>  </LayoutWrapper> }/>
          <Route path="*" element={  <LayoutWrapper language="ar"> <Home language="ar"/>  </LayoutWrapper> }/>
        </Routes>
      </Router>
      <div id='scrollTop' onClick={scrollTop}><i className="ri-arrow-up-line"></i></div>
    </div>
  );
}

export default App;
