function _T(text) {
    const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
    const translations = {
        "About Tawkeel": {
            "en": "About Tawkeel",
            "ar": "نبذة عن توكيل",
            "ur": ""
        },
        "Tawkeel": {
            "en": "Tawkeel",
            "ar": "توكيل",
            "ur": ""
        },
        "For Umrah Al-Badal": {
            "en": "For Umrah Al-Badal",
            "ar": "عمرة البدل",
            "ur": ""
        },
        "For more details": {
            "en": "For more details",
            "ar": "لمزيد من التفاصيل",
            "ur": ""
        },
        "What is Tawkeel?": {
            "en": "What is Tawkeel?",
            "ar": "ما هو توكيل",
            "ur": ""
        },
        'The application provides the service of "Umrah Al-Badal" by appointing qualified, trusted, and authorized performers from Nafath to perform Umrah for Muslims who are unable to perform it themselves, officially and reliably, and under full supervision.': {
            "en": 'The application provides the service of "Umrah Al-Badal" by appointing qualified, trusted, and authorized performers from Nafath to perform Umrah for Muslims who are unable to perform it themselves, officially and reliably, and under full supervision.',
            "ar": 'تطبيق يتيح خدمة عمرة البدل من خلال توكيل مؤدين مؤهلين وموثوقين ومعتمدين من نفاذ لأداء العمرة عن المسلمين غير القادرين على أدائها، بطريقة رسمية وموثوقة وتحت إشراف كامل',
            "ur": ""
        },
        "Download App": {
            "en": "Download App",
            "ar": "حمل التطبيق",
            "ur": ""
        },
        "OUR DISTINGUISHES": {
            "en": "OUR DISTINGUISHES",
            "ar": "ما يميزنا",
            "ur": ""
        },
        "Umrah Al-Badal service with Specify date and time": {
            "en": "Umrah Al-Badal service with Specify date and time",
            "ar": "خدمة عمرة البدل مع تحديد التاريخ والوقت",
            "ur": ""
        },
        "The Umrah Al-Badal service is available immediately upon request": {
            "en": "The Umrah Al-Badal service is available immediately upon request",
            "ar": "خدمة عمرة البدل متاحة فور الطلب",
            "ur": ""
        },
        "Qualified and verified performers via Nafath": {
            "en": "Qualified and verified performers via Nafath",
            "ar": "مؤدون مؤهلون ومعتمدون عبر نفاذ",
            "ur": ""
        },
        "Real-time tracking feature": {
            "en": "Real-time tracking feature",
            "ar": "التتبع المباشر",
            "ur": ""
        },
        "Safe and fast payment methods": {
            "en": "Safe and fast payment methods",
            "ar": "طرق دفع آمنة وسريعة",
            "ur": ""
        },
        "A supervisor follows those performing Umrah in the Haram on behalf of others": {
            "en": "A supervisor follows those performing Umrah in the Haram on behalf of others",
            "ar": "مشرفون متابعون لمؤديّ عمرة البدل في الحرم المكي خطوة بخطوة",
            "ur": ""
        },
        "Customer service and direct communication": {
            "en": "Customer service and direct communication",
            "ar": "خدمة العملاء والتواصل المباشر",
            "ur": ""
        },
        "Multiple Language": {
            "en": "Multiple Language",
            "ar": "لغة متعددة",
            "ur": ""
        },
        "It is an application through which the process of delegating qualified persons to perform Umrah Al Badal is carried out with the aim of facilitating the Muslims who are unable to perform the rituals, such as the sick, the elderly.": {
            "en": "It is an application through which the process of delegating qualified persons to perform Umrah Al Badal is carried out with the aim of facilitating the Muslims who are unable to perform the rituals, such as the sick, the elderly.",
            "ar": "عبارة عن تطبيق يتم من خلاله عملية توكيل  أشخاص مؤهلين  للقيام بعمرة البدل بهدف التسهيل  على المسلمين الغير قادرين  على أداء المناسك من  المرضى وكبار السن  ",
            "ur": ""
        },
        "Sharia Fatwa": {
            "en": "Sharia Fatwa",
            "ar": "الفتاوي الشرعية",
            "ur": ""
        },
        "TAWKEEL AGENTS": {
            "en": "TAWKEEL AGENTS",
            "ar": "وكلاء توكيل",
            "ur": ""
        },
        "Tawkeel Company partners with a wide network of accredited offices and external agents across the world to explain and promote its Umrah services to Muslims around the world.": {
            "en": "Tawkeel Company partners with a wide network of accredited offices and external agents across the world to explain and promote its Umrah services to Muslims around the world.",
            "ar": "تعمل شركة توكيل بالشراكة مع شبكة واسعة من المكاتب المعتمدة والوكلاء الخارجيين في عدة دول حول العالم، بهدف شرح ونشر خدماتها للمسلمين في جميع أنحاء العالم بما يخص عمرة البدل",
            "ur": ""
        },
        "REGISTER NOW": {
            "en": "REGISTER NOW",
            "ar": "سجل الان",
            "ur": ""
        },
        "Please choose your registration type": {
            "en": "Please choose your registration type",
            "ar": "الرجاء اختيار نوع التسجيل الخاص بك",
            "ur": ""
        },
        "Register as A Performer": {
            "en": "Register as A Performer",
            "ar": "سجل كمؤدي",
            "ur": ""
        },
        "Register as Agent Agency": {
            "en": "Register as Agent Agency",
            "ar": "سجل كوكالة تسويق",
            "ur": ""
        },
        "Fatwa on performing Umrah on behalf of the deceased": {
            "en": "Fatwa on performing Umrah on behalf of the deceased",
            "ar": "فتوى أداء عمرة عن الميت",
            "ur": ""
        },
        "Sheikh Saleh Al-Fawzan": {
            "en": "Sheikh Saleh Al-Fawzan",
            "ar": "الشيخ صالح الفوزان",
            "ur": ""
        },
        "Fatwa on Hajj and Umrah for the dead": {
            "en": "Fatwa on Hajj and Umrah for the dead",
            "ar": "فتوى الحج والعمرة عن الميت",
            "ur": ""
        },
        "Sheikh Abdul Aziz bin Baz": {
            "en": "Sheikh Abdul Aziz bin Baz",
            "ar": "الشيخ عبد العزيز بن باز",
            "ur": ""
        },
        "Umrah fatwa for the deceased who did not perform Umrah during his lifetime": {
            "en": "Umrah fatwa for the deceased who did not perform Umrah during his lifetime",
            "ar": "فتوى العمرة عن الميت الذي لم يعتمر في حياته",
            "ur": ""
        },
        "Sheikh Muhammad bin Uthaymeen": {
            "en": "Sheikh Muhammad bin Uthaymeen",
            "ar": "الشيخ محمد بن عثيمين",
            "ur": ""
        },
        "Fatwa performing Umrah on behalf of my deceased father": {
            "en": "Fatwa performing Umrah on behalf of my deceased father",
            "ar": "فتوى أداء العمرة عن والدي المتوفي ",
            "ur": ""
        },
        "Sheikh Abdulaziz bin Baz": {
            "en": "Sheikh Abdulaziz bin Baz",
            "ar": "الشيخ عبدالعزيز بن باز",
            "ur": ""
        },
        "Fatwa taking money to perform Umrah on behalf of a sick or deceased": {
            "en": "Fatwa taking money to perform Umrah on behalf of a sick or deceased",
            "ar": "فتوى أخذ المال لأداء العمرة عن مريض أو متوفى",
            "ur": ""
        },
        "Fatwa authorizing a person to perform Hajj on behalf of others": {
            "en": "Fatwa authorizing a person to perform Hajj on behalf of others",
            "ar": "فتوى توكيل شخص ليحج عن غيره",
            "ur": ""
        },
        "Sheikh Muhammad bin Saleh Al-Othaymeen": {
            "en": "Sheikh Muhammad bin Saleh Al-Othaymeen",
            "ar": "الشيخ محمد بن صالح العثيمين",
            "ur": ""
        },
        "May ALLAH have mercy on him": {
            "en": "May ALLAH have mercy on him",
            "ar": "رحمه الله",
            "ur": ""
        },
        "Fatwa Umrah allowance and the permissibility of taking in exchange for it": {
            "en": "Fatwa Umrah allowance and the permissibility of taking in exchange for it",
            "ar": "فتوى عمرة البدل وجواز اخذ مقابلها ",
            "ur": ""
        },
        "Al Azhar Shareef": {
            "en": "Al Azhar Shareef",
            "ar": "الأزهر الشريف",
            "ur": ""
        },
        "Performing Umrah on behalf of the dead and the living and repeating it": {
            "en": "Performing Umrah on behalf of the dead and the living and repeating it",
            "ar": "أداء العمرة عن الميت والحي وتكرارها",
            "ur": ""
        },
        "Find out more": {
            "en": "Find out more",
            "ar": "اكتشف المزيد",
            "ur": ""
        },

        "There is nothing wrong with the agent performing the Umrah On behalf of another person, provided that he performed it for himself first.": {
            "en": "There is nothing wrong with the agent performing the Umrah On behalf of another person, provided that he performed it for himself first.",
            "ar": "لا حرج على الوكيل في أداء العمرة عن غيره بشرط أن يؤديها لنفسه أولاً.",
            "ur": ""
        },
        "The Idea": {
            "en": "The Idea",
            "ar": "الفكرة",
            "ur": ""
        },
        "of Application": {
            "en": "of Application",
            "ar": "التطبيق",
            "ur": ""
        },
        "Tawkeel application provides a service for those who cannot perform Hajj and Umrah.": {
            "en": "Tawkeel application provides a service for those who cannot perform Hajj and Umrah.",
            "ar": "يوفر هذا التطبيق خدمة التوكيل لمن لا يستطيع أداء مناسك الحج والعمرة.",
            "ur": ""
        },
        "Where the company has devised a program through which the delegation process to others who carried out on a regular and innovative basis and in different languages that enable both parties to communicate and benefit as stated in the legal fatwa to facilitate the Umrah performance on their behalf.": {
            "en": "Where the company has devised a program through which the delegation process to others who carried out on a regular and innovative basis and in different languages that enable both parties to communicate and benefit as stated in the legal fatwa to facilitate the Umrah performance on their behalf.",
            "ar": "حيث ابتكرت الشركة برنامجًا يتم من خلاله عملية التفويض للآخرين الذين يتم إجراؤهم بشكل منتظم ومبتكر وبلغات مختلفة تمكن الطرفين من التواصل والاستفادة كما ورد في الفتوى القانونية لتسهيل أداء العمرة نيابة عنهم.",
            "ur": ""
        },
        "Why us?": {
            "en": "Why us?",
            "ar": "لماذا نحن؟",
            "ur": ""
        },
        "Documentation of asceticism": {
            "en": "Documentation of asceticism",
            "ar": "توثيق النسك",
            "ur": ""
        },
        "Tawkeel provides the possibility of documenting the rituals during their performance and sharing photos and videos with the client": {
            "en": "Tawkeel provides the possibility of documenting the rituals during their performance and sharing photos and videos with the client",
            "ar": "يوفر توكيل إمكانية توثيق النسك أثناء أدائها ومشاركة الصور والفيديو مع الموكل ",
            "ur": ""
        },
        "Digital Transformation": {
            "en": "Digital Transformation",
            "ar": "التحول الرقمي",
            "ur": ""
        },
        "Keeping pace with 2030 Kingdom's vision digital transformation in all services": {
            "en": "Keeping pace with 2030 Kingdom's vision digital transformation in all services",
            "ar": "مواكبة رؤية المملكة 2030 التحول الرقمي في جميع الخدمات",
            "ur": ""
        },
        "Part-time Money": {
            "en": "Part-time Money",
            "ar": "نقود بدوام جزئي",
            "ur": ""
        },
        "Finding an innovative way to increase the income of young men and women who are qualified for part-time work": {
            "en": "Finding an innovative way to increase the income of young men and women who are qualified for part-time work",
            "ar": "إيجاد طريقة مبتكرة لزيادة دخل الشباب والشابات المؤهلين للعمل الجزئي",
            "ur": ""
        },
        "Safe Payment Methods": {
            "en": "Safe Payment Methods",
            "ar": "طرق دفع آمنة",
            "ur": ""
        },
        "Tawkeel follows a secure payment system and maintains the confidentiality of bank card information, as it allows payment in multiple ways": {
            "en": "Tawkeel follows a secure payment system and maintains the confidentiality of bank card information, as it allows payment in multiple ways",
            "ar": "يتبع توكيل نظام دفع آمن ويحافظ على سرية معلومات البطاقة البنكية كما انه يتيح الدفع بطرق متعددة",
            "ur": ""
        },
        "Instant Tracking": {
            "en": "Instant Tracking",
            "ar": "ميزة التتبع الفوري",
            "ur": ""
        },
        "The application tracks the performer while performing the ritual through the": {
            "en": "The application tracks the performer while performing the ritual through the",
            "ar": "يقوم التطبيق بتتبع المؤدي اثناء أدائه للنسك من خلال ال",
            "ur": ""
        },
        "Umrah Completion Certificate/Gift Card": {
            "en": "Umrah Completion Certificate/Gift Card",
            "ar": "شهادة انجاز عمرة/ بطاقة اهداء",
            "ur": ""
        },
        "An Umrah certificate is sent to the client upon completion of the Umrah, and a gift card can be requested in the name of the client": {
            "en": "An Umrah certificate is sent to the client upon completion of the Umrah, and a gift card can be requested in the name of the client",
            "ar": "يتم ارسال شهادة عمرة للموكل عند إتمام العمرة، كما يمكن طلب بطاقة اهداء باسم الموكل عنه",
            "ur": ""
        },
        "Qualified Performers": {
            "en": "Qualified Performers",
            "ar": "مؤدين مؤهلين",
            "ur": ""
        },
        "The performers of Umrah in a proxy are a group of students from the Islamic University and memorizers of the Book of God": {
            "en": "The performers of Umrah in a proxy are a group of students from the Islamic University and memorizers of the Book of God",
            "ar": "مؤدين العمرة في توكيل عبارة عن نخبة من طلاب الجامعة الاسلامية وحفظة كتاب الله",
            "ur": ""
        },
        "Multilingual": {
            "en": "Multilingual",
            "ar": "لغات متعددة",
            "ur": ""
        },
        "Tawkeel allows the application to be used in more than 9 languages, many performers speak two languages or more, which facilitates the process of communication between the performer and the seeker": {
            "en": "Tawkeel allows the application to be used in more than 9 languages, many performers speak two languages or more, which facilitates the process of communication between the performer and the seeker",
            "ar": "يتيح توكيل إمكانية استخدام التطبيق بأكثر من ٩ لغات، كم يوجد عدد من المؤدين من يتحدث لغتين وأكثر مما يسهل عملية التواصل بين المؤدي والموكل",
            "ur": ""
        },
        "How Tawkeel Works?": {
            "en": "How Tawkeel Works?",
            "ar": "كيف يعمل توكيل؟",
            "ur": ""
        },
        "Tawkeel is divided in two parts": {
            "en": "Tawkeel is divided in two parts",
            "ar": "ينقسم توكيل الى جزئين",
            "ur": ""
        },
        "A section for the Seeker, who is the person who delegates someone to perform the Umrah on their behalf.": {
            "en": "A section for the Seeker, who is the person who delegates someone to perform the Umrah on their behalf.",
            "ar": "جزء خاص بالموكل وهو الشخص الذي يوكل من ينوب عنه لأداء العمرة",
            "ur": ""
        },
        "A section for the Performer, who is the person who performs the Umrah on behalf of the Seeker.": {
            "en": "A section for the Performer, who is the person who performs the Umrah on behalf of the Seeker.",
            "ar": "جزء خاص بالمؤدي وهو الشخص الذي يقوم بأداء العمرة عن الموكل",
            "ur": ""
        },
        "Performer": {
            "en": "Performer",
            "ar": "المؤدي",
            "ur": ""
        },
        "Join us as a Performer": {
            "en": "Join us as a Performer",
            "ar": "انضم لنا كمؤدي",
            "ur": ""
        },
        "Seeker": {
            "en": "Seeker",
            "ar": "الموكل",
            "ur": ""
        },
        "Join us as a Seeker": {
            "en": "Join us as a Seeker",
            "ar": "انضم لنا كموكل",
            "ur": ""
        },
        "Our News": {
            "en": "Our News",
            "ar": "أخبارنا",
            "ur": ""
        },
        "Photo Gallery": {
            "en": "Photo Gallery",
            "ar": "معرض الصور",
            "ur": ""
        },
        "With His Excellency the Minister of Hajj and Umrah, Dr. Tawfiq Al-Rabiah, during his visit to Tawkeel at the Hajj and Umrah Exhibition for the year 2023": {
            "en": "With His Excellency the Minister of Hajj and Umrah, Dr. Tawfiq Al-Rabiah, during his visit to Tawkeel at the Hajj and Umrah Exhibition for the year 2023",
            "ar": "مع معالي وزير الحج والعمرة الدكتور/ توفيق الربيعة أثناء زيارته لتوكيل في معرض الحج والعمرة لعام ٢٠٢٣",
            "ur": ""
        },
        "The General Manager of Tawkeel Eng. Raed Kayyal with the news anchor during a TV interview about the Tawkeel application, which was broadcast on Al-Ekhbariya channel": {
            "en": "The General Manager of Tawkeel Eng. Raed Kayyal with the news anchor during a TV interview about the Tawkeel application, which was broadcast on Al-Ekhbariya channel",
            "ar": "المدير العام لتوكيل المهندس/ رائد كيال مع مذيع الاخبار أثناء لقاء تلفزيوني عن تطبيق توكيل والذي تم بثه على قناة الإخبارية",
            "ur": ""
        },
        "Some of Tawkeel's visitors at the Hajj and Umrah exhibition for the year 2023": {
            "en": "Some of Tawkeel's visitors at the Hajj and Umrah exhibition for the year 2023",
            "ar": "بعض من زوار توكيل في معرض الحج والعمرة لعام ٢٠٢٣",
            "ur": ""
        },
        "During a TV interview with Iqraa TV at the Hajj and Umrah Fair for the year 2023": {
            "en": "During a TV interview with Iqraa TV at the Hajj and Umrah Fair for the year 2023",
            "ar": "أثناء لقاء تلفزيوني مع قناة إقرأ في معرض الحج والعمرة لعام ٢٠٢٣",
            "ur": ""
        },
        "Contact Us!": {
            "en": "Contact Us!",
            "ar": "اتصل بنا",
            "ur": ""
        },
        "Provide a valid name": {
            "en": "Provide a valid name",
            "ar": "الرجاء إدخال اسم ",
            "ur": ""
        },
        "Provide a valid email address": {
            "en": "Provide a valid email address",
            "ar": "يرجى تقديم عنوان بريد إلكتروني ",
            "ur": ""
        },
        "Provide a valid phone number (+XXXXXXXXXX)": {
            "en": "Provide a valid phone number (+XXXXXXXXXX)",
            "ar": "يرجى تقديم رقم هاتف  (+ XX XXXX XXXX)",
            "ur": ""
        },
        "Provide a valid subject line": {
            "en": "Provide a valid subject line",
            "ar": "يرجى تقديم موضوع",
            "ur": ""
        },
        "Provide your message": {
            "en": "Provide your message",
            "ar": "يرجى تقديم رسالتك",
            "ur": ""
        },
        "Your message was sent successfully. We will get back to you shortly.": {
            "en": "Your message was sent successfully. We will get back to you shortly.",
            "ar": "لقد تم ارسال رسالتك بنجاح. سيتم الرد في أقرب وقت.",
            "ur": ""
        },
        "Something went wrong. Please try again!": {
            "en": "Something went wrong. Please try again!",
            "ar": "هناك خطأ ما. حاول مرة اخرى!",
            "ur": ""
        },
        "Your Name": {
            "en": "Your Name",
            "ar": "اسمك",
            "ur": ""
        },
        "Your Email Address": {
            "en": "Your Email Address",
            "ar": "عنوان بريدك  الإلكتروني",
            "ur": ""
        },
        "Your Phone": {
            "en": "Your Phone",
            "ar": "هاتفك",
            "ur": ""
        },
        "Your Subject": {
            "en": "Your Subject",
            "ar": "موضوعك",
            "ur": ""
        },
        "Your Message": {
            "en": "Your Message",
            "ar": "رسالتك",
            "ur": ""
        },
        "Submit": {
            "en": "Submit",
            "ar": "إرسال",
            "ur": ""
        },
        "Our Social Accounts": {
            "en": "Our Social Accounts",
            "ar": "حساباتنا في مواقع التواصل الاجتماعي",
            "ur": ""
        },
        "Facebook": {
            "en": "Facebook",
            "ar": "فيسبوك",
            "ur": ""
        },
        "Instagram": {
            "en": "Instagram",
            "ar": "إنستقرام",
            "ur": ""
        },
        "Twitter": {
            "en": "Twitter",
            "ar": "تويتر",
            "ur": ""
        },
        "LinkedIn": {
            "en": "LinkedIn",
            "ar": "لينكد إن",
            "ur": ""
        },
        "Certificates": {
            "en": "Certificates",
            "ar": "شهادات",
            "ur": ""
        },
        "Maroof Certificate": {
            "en": "Maroof Certificate",
            "ar": "ﺷﻬﺎدة ﻣﻌﺮوف",
            "ur": ""
        },
        "E-Commerce Authentication Certificate": {
            "en": "E-Commerce Authentication Certificate",
            "ar": "شهادة توثيق التجارة الإلكترونية",
            "ur": ""
        },
        "Authentication Number:": {
            "en": "Authentication Number:",
            "ar": "رقم التوثيق:",
            "ur": ""
        },
        "View Certificate": {
            "en": "View Certificate",
            "ar": "عرض الشهادة",
            "ur": ""
        },
        "Register Now": {
            "en": "Register Now",
            "ar": "سجّل الآن",
            "ur": ""
        },
        "Register as a Performer": {
            "en": "Register as a Performer",
            "ar": "التسجيل كمؤدي",
            "ur": ""
        },
        "There are special terms and conditions for registration as a performer,": {
            "en": "There are special terms and conditions for registration as a performer,",
            "ar": "یوجدشروطواحكام خاصھ للتسجیل كمؤدي",
            "ur": ""
        },
        "The applicant must be 18 years old and above": {
            "en": "The applicant must be 18 years old and above",
            "ar": "ان یكون عمرالمتقدم18سنھ فمافوق",
            "ur": ""
        },
        "The applicant must be a resident of one of these cities: Jeddah, Mecca, taif": {
            "en": "The applicant must be a resident of one of these cities: Jeddah, Mecca, taif",
            "ar": " ان یكون المتقدم من سكان احدھذه المدن : جده ,مكه ,الطايف",
            "ur": ""
        },
        "The applicant must have a valid Saudi national identity card, or legally reside in the kingdom of Saudi Arabia and have a valid residency": {
            "en": "The applicant must have a valid Saudi national identity card, or legally reside in the kingdom of Saudi Arabia and have a valid residency",
            "ar": "أن يكون لدى المتقدم بطاقة هوية وطنية سارية المفعول، أو مقیم بشكل نظامي بالمملكةالعربیھ السعودیةویوجداقامةساریةالمفعول",
            "ur": ""
        },
        "The applicant must have his / her own bank account": {
            "en": "The applicant must have his / her own bank account",
            "ar": "ان یكون لدى المتقدم لدیھ حساب بنكي خاص بھ",
            "ur": ""
        },
        "The applicant must have previously performed Umrah or Hajj": {
            "en": "The applicant must have previously performed Umrah or Hajj",
            "ar": "ان یكون المتقدم قدعمل عمرةاوحج سابقاً",
            "ur": ""
        },
        "The applicant should have knowledge in using applications on smartphones.": {
            "en": "The applicant should have knowledge in using applications on smartphones.",
            "ar": "انیكون المتقدم لدیھ معرفةفي استخدام التطبیقات على الھواتف الذكیة",
            "ur": ""
        },
        "I agree to the above terms and conditions": {
            "en": "I agree to the above terms and conditions",
            "ar": "أوافق على الشروط والأحكام المذكورة أعلاه",
            "ur": ""
        },
        "Continue": {
            "en": "Continue",
            "ar": "التالي",
            "ur": ""
        },
        "To register as a performer, follow these steps,": {
            "en": "To register as a performer, follow these steps,",
            "ar": "لتسجيلك كمؤدي تتبع الخطوات التاليه",
            "ur": ""
        },
        "Log in through the attached link": {
            "en": "Log in through the attached link",
            "ar": "أختر تسجيل الدخول من خلال الرابط المرفق",
            "ur": ""
        },
        "Login through the mobile number or through the social platforms present in the application": {
            "en": "Login through the mobile number or through the social platforms present in the application",
            "ar": "تسجيل الدخول من خلال رقم الجوال او من خلال المنصات الاجتماعيه الموجوده بالتطبيق",
            "ur": ""
        },
        "Create or enter your 6-digit PIN number": {
            "en": "Create or enter your 6-digit PIN number",
            "ar": "انشاء او ادخال الرقم السري الخاص بك و المكون من 6 ارقام",
            "ur": ""
        },
        "Use the following referral code (SHAH_1)": {
            "en": "Use the following referral code (SHAH_1)",
            "ar": "استخدام كود الإحالة التالي (SHAH_1)",
            "ur": ""
        },
        "Finish": {
            "en": "Finish",
            "ar": "تسجيل",
            "ur": ""
        },
        "Please choose your registration type": {
            "en": "Please choose your registration type",
            "ar": "الرجاء اختيار نوع التسجيل الخاص بك",
            "ur": ""
        },
        "Register as an Agency": {
            "en": "Register as an Agency",
            "ar": "سجل كوكالة",
            "ur": ""
        },
        "Register Agent Agency": {
            "en": "Register Agent Agency",
            "ar": "التسجيل كوكيل",
            "ur": ""
        },
        "Register as Marketing Agency": {
            "en": "Register as Marketing Agency",
            "ar": "التسجيل كوكالة تسويق",
            "ur": ""
        }
    }
    return translations[text] ? translations[text][language] : text;
}
export default _T;