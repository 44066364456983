import React from "react";
import "../../assets/css/home.css";
import Hero from "./Hero";
import ShariaFatwa from "./ShariaFatwa";
import AppIdea from "./AppIdea";
import AppObjectives from "./AppObjectives";
import DownloadApp from "./DownloadApp";
import ApplicationSteps from "./ApplicationSteps";
import Screenshots from "./Screenshots";
import HelpCenter from "./HelpCenter";
import News from "./News";
import Contact from "./Contact";
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import {languages} from '../../common/languages'
import { useEffect } from "react";
import {RP} from "../../components/FacebookPixel"
import { initTwitter } from '../../common/PixelEvents';
import '../../components/TikTokPixel.js';
import About from "./About.js";
import PhotoGallery from "./PhotoGallery.js";
import TawkeelAgents from "./TawkeelAgents.js";
function Index({language}) {
  window.scrollTo({ top: 0});
  let direction = languages[language].direction
  if(direction === "RTL"){
    document.getElementsByTagName("body")[0].classList.add("RTL");
  }else{
    document.getElementsByTagName("body")[0].classList.remove("RTL");
  }


  
  function section_scroll(id){
    if(id){
      let scrollDiv = document.getElementById(id) ? document.getElementById(id).offsetTop : 0;
      window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
    }
  }

  useEffect(()=>{
    let url = window.location.href
    const el = url.split("#")
    if(el.length > 1){
      section_scroll(el[el.length-1])
    }
    RP.pageView();
    initTwitter();
  },[])

  return (
    <>
        <Navbar language={languages[language]}/>
        <Hero language={languages[language]}/>
        <About language={languages[language]}/>
        {/* <ShariaFatwa language={languages[language]}/> */}
        {/* <AppIdea language={languages[language]}/> */}
        <AppObjectives language={languages[language]}/>
        {/* <DownloadApp language={languages[language]}/> */}
        {/* <ApplicationSteps language={languages[language]}/> */}
        <Screenshots language={languages[language]}/>
        <TawkeelAgents language={languages[language]}/>
        {/* <HelpCenter language={languages[language]}/> */}
        <News language={languages[language]}/>
        {/* <PhotoGallery language={languages[language]}/> */}
        <Contact language={languages[language]}/>
        <Footer language={languages[language]}/>
    </>
  );
}

export default Index;
